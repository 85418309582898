import { useEffect, useState, useMemo } from "react";
import BrowseMentorsComponent from "./BrowseMentors.component";
import algoliasearch, { SearchIndex } from "algoliasearch";
import { MentorProfileCardData } from "components/Molecules/MentorProfileCard";
import debounce from "lodash.debounce";
import { SKILLS } from "../../../../constants/domains";
import useFavourites from "hooks/mentee/useFavourites";
import useMentorCollections from "hooks/admin/useMentorCollections";
import { MentorCollectionQueryResult } from "entities/MentorCollection";
import { CategoryLabelProps } from "components/Atoms/CategoryLabel/CategoryLabel";
import {
  ICON_MAP,
  SORT_OPTIONS,
  PRICE_FILTERS,
} from "./BrowseMentors.constants";
import { CollectionItem } from "components/Molecules/CollectionFilter/CollectionFilter";
import { usePostHog } from "posthog-js/react";

const client = algoliasearch("NKDVXX6N6U", "78aec582d399491ca6541f389fc1adb7");

const shapeData = (
  mentor: Hit
): MentorProfileCardData & { collections: string[] } => {
  return {
    _id: mentor.objectID,
    given_name: mentor.given_name || mentor?.firstname || "",
    family_name: mentor.family_name || mentor?.lastname || "",
    headline: mentor.headline,
    pitch: mentor.pitch,
    bio: mentor.biography,
    profile_image: mentor.profile_image,
    session_cost: mentor.hourly_rate / 2000,
    //   (mentor.available_30_mins ? mentor.hourly_rate / 2 : mentor.hourly_rate) /
    //   1000,
    slug: mentor.slug,
    collections: mentor.collections || [],
  };
};

interface Hit {
  collections: string[];
  _id: string;
  biography: string;
  created_at: string;
  given_name: string;
  firstname?: string;
  lastname?: string;
  headline: string;
  hourly_rate: number;
  family_name: string;
  pitch: string;
  profile_image: string;
  slug: string;
  objectID: string;
  available_30_mins: boolean;
  available_60_mins: boolean;
  calendar_integrated: false;
  number_of_articles: number;
  number_of_highlights: number;
  number_of_reviews: number;
  rank: number;
  review_rating: number;
  shadow_ban: boolean;
}

const BrowseMentors = () => {
  const [finished_inital_loading, setFinishedInitalLoading] =
    useState<boolean>(false);
  const posthog = usePostHog();
  const [filter_term, setFilterTerm] = useState("");
  const [selected_skill, setSelectedSkill] = useState("");
  const [collection, selectCollection] = useState<string | undefined>();
  const { collections } = useMentorCollections();
  const [favourites_filter, setFavouritesFilter] = useState<boolean>(false);
  const { favourites } = useFavourites();
  const [MENTORS, setMentors] = useState<MentorProfileCardData[]>([]);
  const [sort_type, setSortType] = useState({
    algolia_index: client.initIndex("mentor"),
    option: {
      title: "Sort by",
      value: "mentor",
    },
  });
  const [price_filter, setPriceFilter] = useState({
    title: "Price",
    value: "hourly_rate > 0",
  });
  const fetchMentors = useMemo(
    () =>
      debounce(
        (
          algolia: SearchIndex,
          filter_term: string,
          selected_skill: string,
          price_filter: string
        ) =>
          algolia
            .search<Hit>(`${filter_term} ${selected_skill}`, {
              filters: price_filter
                ? `${price_filter} AND shadow_ban:false`
                : "shadow_ban:false",
              // facets: ["skills"],
              hitsPerPage: 500,
            })
            .then(({ hits, facets }) => {
              const data = hits.map((hit) => shapeData(hit));
              setMentors(data);
              setFinishedInitalLoading(true);
            }),
        500
      ),
    []
  );

  useEffect(() => {
    fetchMentors(
      sort_type.algolia_index,
      filter_term,
      selected_skill,
      price_filter.value
    );
  }, [sort_type]);

  useEffect(() => {
    fetchMentors(
      sort_type.algolia_index,
      filter_term,
      selected_skill,
      price_filter.value
    );
  }, [filter_term, price_filter, selected_skill]);

  // HANDLE USER ACTIONS

  const clearFilters = () => {
    setPriceFilter({
      title: "Price",
      value: "hourly_rate > 0",
    });
    setSortType({
      algolia_index: client.initIndex("mentor"),
      option: {
        title: "Sort by",
        value: "mentor",
      },
    });
    setSelectedSkill("");
    setFilterTerm("");
    setFavouritesFilter(false);
    selectCollection(undefined);
    posthog.capture("[MENTOR_BROWSER] filters_cleared");
  };

  const handelSetPriceFilter = (price: { title: string; value: string }) => {
    setPriceFilter(price);
    posthog.capture("[MENTOR_BROWSER] price_filter_selected", {
      price_filter: price.title,
    });
  };

  const handleSelectCollection = (new_collection: CollectionItem) => {
    posthog.capture("[MENTOR_BROWSER] collection_filter_selected", {
      collection: new_collection.title,
    });
    // One of the collections has the behaviour of filtering by favourites.
    if (new_collection.id === "favourites") {
      setFavouritesFilter(true);
    }
    if (collection === "favourites" && new_collection.id !== "favourites") {
      setFavouritesFilter(false);
    }

    // !TODO: this is suspect..
    // New Mentors is a special collection that filters by the number of reviews.
    if (new_collection.title === "New Mentors") {
      setSortType({
        algolia_index: client.initIndex("mentor_newest"),
        option: {
          title: "Sort by",
          value: "mentor_newest",
        },
      });
    }

    // toggle it off if the new collection is not "New Mentors"
    if (new_collection.title !== "New Mentors") {
      setSortType({
        algolia_index: client.initIndex("mentor"),
        option: {
          title: "Sort by",
          value: "mentor",
        },
      });
    }

    selectCollection(new_collection.id);
  };

  const handleSetSortOptions = (option: { title: string; value: string }) => {
    setSortType({
      algolia_index: client.initIndex(option.value),
      option,
    });
    posthog.capture("[MENTOR_BROWSER] sort_option_selected", {
      sort_option: option.title,
    });
  };

  const toggleFavouritesFilter = () => {
    const new_value = !favourites_filter;
    setFavouritesFilter(new_value);
    posthog.capture("[MENTOR_BROWSER] favourites_filter_toggled", {
      is_active: new_value,
    });
  };

  const hangleSetSelectedSkill = (skill: string) => {
    setSelectedSkill(skill);
    posthog.capture("[MENTOR_BROWSER] skill_selected", {
      skill,
    });
  };

  const createMentorCollections = (
    collections?: MentorCollectionQueryResult[]
  ): Omit<CategoryLabelProps, "selected" | "onClick">[] => {
    return (
      (collections &&
        [
          {
            id: "favourites",
            title: "Favorites",
            value: "favourites",
            Icon: ICON_MAP["favourites"],
          },
        ].concat(
          collections.map((collection) => ({
            id: collection._id,
            title: collection.name,
            value: collection._id,
            Icon: ICON_MAP[collection.name as keyof typeof ICON_MAP],
          }))
        )) ||
      []
    );
  };

  return (
    <BrowseMentorsComponent
      is_loading={!finished_inital_loading}
      filters={{
        favourites: favourites_filter,
        price: price_filter,
        sort_type: sort_type.option,
        skill: selected_skill,
      }}
      options={{
        sort: SORT_OPTIONS,
        price: PRICE_FILTERS,
        skills: SKILLS.map((s) => ({ title: s, value: s })),
      }}
      setPriceFilter={handelSetPriceFilter}
      filter_term={filter_term}
      setFilterTerm={setFilterTerm}
      mentors={MENTORS.filter((hit) => {
        const favourite =
          !favourites_filter ||
          (favourites && favourites.includes(String(hit._id)));
        // If the favourites filter is the collection type then we dont need to filter for collection.
        if (collection === "favourites") {
          return favourite;
        }
        const collections = !collection || hit.collections.includes(collection);
        return favourite && collections;
      })}
      selected_collection={collection}
      selectCollection={handleSelectCollection}
      mentor_collections={createMentorCollections(collections)}
      setSortOption={handleSetSortOptions}
      clearFilters={clearFilters}
      setSelectedSkill={hangleSetSelectedSkill}
      toggleFavouritesFilter={toggleFavouritesFilter}
    />
  );
};

export default BrowseMentors;
