import Styles from "./OrderDetails.module.css";

const OrderDetails = ({ quantity_of_credits, rate }: { quantity_of_credits: number, rate: number }) => {
    const total = (Math.round(rate * quantity_of_credits) / 100)
    const total_as_USD = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(total);

    const formatted_unit_rate = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(rate / 100);
    const formatted_quantity_of_credits = new Intl.NumberFormat('en-US').format(quantity_of_credits);
    const description = `${formatted_unit_rate} x ${formatted_quantity_of_credits}`

    return (<div className={Styles.container}>
        <h3>Order details</h3>
        <div className={Styles.order_details}>
            <div className={Styles.line_item}>
                <p className={Styles.title}>Credits</p>
                <p className={Styles.description}>{description}</p>
                <p className={Styles.price}>{total_as_USD}</p>
            </div>
            <div className={Styles.summary}>
                <p className={Styles.total}>Total</p>
                <p className={Styles.total_cost}>{total_as_USD}</p>
            </div>
        </div>
    </div >)
}

export default OrderDetails;
