import React, { useEffect } from "react";
import styles from "./Avatar.module.css";

interface AvatarProps {
  size?: number;
  src?: string;
  name: string;
  loading?: "lazy" | "eager";
  rounded?: number | string;
}

const Avatar: React.FC<AvatarProps> = ({
  size = 32,
  src,
  name,
  loading,
  rounded = 9999,
}) => {
  return (
    <div
      data-testid="avatar"
      className={styles.container}
      style={{ width: size, height: size, borderRadius: rounded }}
    >
      {src ? (
        <img className={styles.image} src={src} alt={name} loading={loading} />
      ) : (
        <span className={styles.name}>{name.charAt(0).toUpperCase()}</span>
      )}
    </div>
  );
};

export default Avatar;
