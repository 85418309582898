import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setObjectiveId, updateBookingDescription } from "../../../../../../store/dux/booking/booking.actions";
import Agenda from "./Agenda.component";
import { useObjectives } from "hooks/mentee/okr/useObjectives";

const DurationSelection = ({ openCreateObjective }: { openCreateObjective: () => void }) => {
    const dispatch = useDispatch();
    const { data } = useObjectives();
    const description = useSelector((state: any) => state.booking.description);
    const updateDescription = (value: string) => dispatch(updateBookingDescription(value));
    const updateObjective = (objective_id: string) => dispatch(setObjectiveId(objective_id));

    const mentee_objective_options = (data || []).map((objective) => ({
        _id: objective._id,
        title: objective.title,
    }));

    return (
        <Agenda mentee_objective_options={mentee_objective_options} description={description} updateDescription={updateDescription} updateObjective={updateObjective} openCreateObjective={openCreateObjective} />
    );
};

export default DurationSelection;
