import React, { useState } from "react";
import styles from "./UserNotification.module.css";
import OnboardingPrompt from "../../Molecules/OnboardingPrompt/OnboardingPrompt";

export interface UserNotificationProps {
  mentee_onboarding: {
    display: boolean;
    onClick: () => void;
    dismiss: () => void;
  };
}

const UserNotification = ({ mentee_onboarding }: UserNotificationProps) => {
  return (
    <div data-testid="user_notification" className={styles.container}>
      {mentee_onboarding.display && (
        <OnboardingPrompt
          onClick={mentee_onboarding.onClick}
        />
      )}
    </div>
  );
};

export default UserNotification;
