import styles from "./Company.module.css";
import DropdownSelector from "components/Atoms/DropdownSelector";
import TickCheckbox from "components/Atoms/TickCheckbox";
import UploadAttachment from "components/Molecules/UploadAttachment";
import Button from "components/Atoms/Button";
import {
  BUSINESS_MODELS,
  REVENUE_OPTIONS,
  FUNDRAISING_OPTIONS,
} from "../../../../../constants/options";
import {
  Business,
  BusinessUpdate,
  BusinessUpdateSchema,
} from "../../../../../entities/Business";
import { Form, Formik } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";

const business_model_options = Object.values(BUSINESS_MODELS);

interface Props {
  business: Business;
  update: (values: BusinessUpdate) => void;
  next: () => void;
  is_loading: boolean;
}

const mapBusinessToForm = (business: Business): BusinessUpdate => ({
  name: business.name,
  website: business.website,
  industry: business.industry,
  revenue: business.revenue,
  fundraising: business.fundraising,
  funding: business.funding,
  mission: business.mission,
  customer_persona: business.customer_persona,
  pain_points: business.pain_points,
  attachment: business.attachment,
})

const Company = ({ business, update, is_loading, next }: Props) => {
  return (
    <div className={styles.container}>
      <div>
        <h3>Company Profile</h3>
        <p>
          Give your mentor a snapshot of what you do and where you are in your
          journey.
        </p>
      </div>
      <Formik
        initialValues={mapBusinessToForm(business)}
        validationSchema={toFormikValidationSchema(BusinessUpdateSchema)}
        onSubmit={update}
      >
        {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
          <Form className={styles.company_form}>
            <div className={styles.input_container}>
              <label htmlFor="name">Business Name</label>
              <input
                id="name"
                type="text"
                onChange={handleChange}
                name="name"
                value={values.name}
                placeholder=""
              />
            </div>
            <div className={styles.input_container}>
              <label htmlFor="website">Website</label>
              <input
                id="website"
                type="text"
                onChange={handleChange}
                name="website"
                value={values.website}
                placeholder=""
              />
            </div>
            <DropdownSelector
              label="BUSINESS TYPE"
              // style={{ border: "1px var(--body-text-on-white) solid" }}
              placeholder="Select One"
              outline={true}
              options={business_model_options}
              dark={true}
              value={values.industry}
              onSelect={(value) => setFieldValue("industry", value)}
            />
            <DropdownSelector
              label="TRAILING 12 MONTHS REVENUE"
              // style={{ border: "1px var(--body-text-on-white) solid" }}
              placeholder="Select One"
              outline={true}
              options={REVENUE_OPTIONS}
              dark={true}
              value={values.revenue}
              onSelect={(value) => setFieldValue("revenue", value)}
            />
            <DropdownSelector
              label="LIFETIME FUNDRAISING"
              // style={{ border: "1px var(--body-text-on-white) solid" }}
              placeholder="Select One"
              outline={true}
              options={FUNDRAISING_OPTIONS}
              dark={true}
              value={values.funding}
              onSelect={(value) => setFieldValue("funding", value)}
              />
            <div className={styles.fundraising}>
              <span>ARE YOU CURENTLY FUNDRAISING?</span>
              <TickCheckbox
                id="fundraising"
                onCheck={() =>
                  setFieldValue("fundraising", !values.fundraising)
                }
                checked={values.fundraising}
                className={styles.fundraising_checkbox}
              />
            </div>
            <div
              className={styles.input_container}
              style={{ marginTop: "18px" }}
            >
              <label htmlFor="mission">Mission</label>
              <textarea
                style={{ paddingRight: "12px" }}
                id="mission"
                onChange={handleChange}
                name="mission"
                rows={4}
                value={values.mission}
                placeholder="Example: Tesla's mission is to accelerate the world's transition to sustainable energy."
              />
            </div>
            <div className={styles.input_container}>
              <label htmlFor="customer_persona">Ideal Customer Persona</label>
              <textarea
                style={{ paddingRight: "12px" }}
                id="customer_persona"
                onChange={handleChange}
                name="customer_persona"
                value={values.customer_persona}
                rows={6}
                placeholder="A great customer persona includes demographics (age, geography, income) and psychographics (hobbies, favorite brands, concerns or anxieties, world views)."
              />
            </div>
            <div
              className={styles.input_container}
              style={{ marginBottom: "16px" }}
            >
              <label htmlFor="pain_points">KEY CHALLENGES</label>
              <textarea
                style={{ paddingRight: "12px" }}
                id="pain_points"
                onChange={handleChange}
                name="pain_points"
                value={values.pain_points}
                placeholder="This should describe the biggest risk between you and your strategic business objectives - such as 'building a profitable customer acquisition machine,' 'improving customer retention,' or 'building a delightful product."
                rows={6}
              />
            </div>
            <UploadAttachment
              filename={
                (values.attachment && values.attachment.filename) || undefined
              }
              cta={"Add Attachment"}
              title="ATTACHMENT (PITCH DECK, ETC.)"
              onSuccess={(value) => setFieldValue("attachments", value)}
              onError={(error) => {}}
              deleteAttachment={() => setFieldValue("attachments", null)}
            />
            <Button
              className={styles.submit_button}
              type="submit"
              loading={is_loading}
            >
              Save
            </Button>
          </Form>
        )}
      </Formik>
      <Button variant="dark" loading={is_loading} onClick={next}>
        Next
      </Button>
    </div>
  );
};

export default Company;
