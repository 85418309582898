import React, { useState } from "react";
import Styled from "styled-components";
import logoImage from "../../../../../images/mp-text-logo.svg";
import { MobileNavOpen, MobileNavClose } from "../../../../../components/Icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import useLogout from "hooks/useLogout";

const Logo = Styled.img`
    width: 135px;
    height: 26px;
    margin-top: 6px;
    @media only screen
    and (max-device-width: 736px)
    and (orientation: portrait) {
        margin-top: 0px;
    }
`;

const StyledLink = Styled(Link)`
    font-family: 'GT-America-Standard';
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-top: 13px;
    color: #6e6e6e;
    &:hover {
        color: #fff;
    }
`;

const MobileNavButton = Styled.img`
    display: none;
    @media only screen
    and (max-device-width: 736px)
    and (orientation: portrait) {
        display: block;
        position: relative;
    }
`;

const MobileNav = Styled.div`
    width: 100vw;
    height: 100vh;
    background-color: #000;
    position: absolute;
    left: -100vw;
    right: 0px;
    top: 62px;
    display: flex;
    flex-direction: column;
    padding: 24px;
    z-index: 10;
    transition: all 0.7s;
    display: none;
    @media only screen
    and (max-device-width: 736px)  {
        display: flex;
    }

`;

const CheckoutHeader = ({ }) => {
	const navigate = useNavigate();
	const { isAuthenticated } = useAuth0();
	const logout = useLogout();

	const [open, setOpen] = useState(false);

	const onButtonClick = () => {
		if (isAuthenticated) {
			logout();
		} else {
			setOpen(false);
			window.open("https://app.mentorpass.co/signup", "_blank");
		}
	};

	return (
		<div className="header-container">
			<Logo src={logoImage} />
			<MobileNavButton
				src={open ? MobileNavClose : MobileNavOpen}
				onClick={() => setOpen(!open)}
			/>
			<MobileNav className={open ? "open-mobile-nav" : ""}>
				<div
					style={{
						position: "absolute",
						bottom: "58px",
						width: "100%",
						left: "0px",
						padding: "24px",
					}}
				>
					{!isAuthenticated && (
						<button
							style={{ width: "100%", marginBottom: "12px" }}
							className="btn-small btn-dark"
							onClick={() => {
								setOpen(false);
								navigate("/login");
							}}
						>
							Login
						</button>
					)}
					<button
						style={{ width: "100%" }}
						className="btn-small"
						onClick={onButtonClick}
					>
						{!isAuthenticated ? "Apply" : "Logout"}
					</button>
				</div>
			</MobileNav>
			<div className="login-action-container">
				{!isAuthenticated && (
					<StyledLink to="/login" style={{ marginRight: "24px" }}>
						Login
					</StyledLink>
				)}
				<p className=".btn-small" onClick={onButtonClick}>
					{!isAuthenticated ? "Apply" : "Logout"}
				</p>
			</div>
		</div>
	);
};

export default CheckoutHeader;
