import { Reducer } from "@reduxjs/toolkit";

const SET_MODAL = "SET_MODAL";
const CLOSE_MODAL = "CLOSE_MODAL";

interface ModalSlice {
  modal: string | null;
  data: any;
}

const initialState: ModalSlice = {
  modal: null,
  data: null,
};

const modalReducer: Reducer<ModalSlice> = (state = initialState, action) => {
  switch (action.type) {
    case SET_MODAL:
      return {
        ...state,
        modal: action.payload,
        data: action.data || null,
      };
    case CLOSE_MODAL:
      return {
        ...state,
        modal: null,
        data: null,
      };
    default:
      return state;
  }
};

export default modalReducer;
