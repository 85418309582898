/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import styles from "./MentorProfile.module.css";
import { Formik, Form } from "formik";
import * as filestack from "filestack-js";
import * as Yup from "yup";
import TextInput from "components/Atoms/TextInput";
import AvatarPlaceholder from "../../../../../images/avatar-placeholder.gif";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import useMyProfile from "hooks/useMyProfile";
import { createImageOptions, createVideoOptions } from "utils/upload.options";
import InfoContainer from "components/Molecules/InfoContainer";
import { Camera, PlayIcon } from "components/Icons";
import { ThreeDots } from "react-loader-spinner";
import { useAlert } from "@blaumaus/react-alert";
import TagCloudInput from "components/Organisms/TagCloudInput/TagCloudInput";
import { isMentor } from "entities/Mentor";
import TextArea from "components/Molecules/Inputs/TextArea/TextArea.component";
const FS = filestack.init(String(process.env.REACT_APP_FILESTACK_API));

interface ProfileFields {
  headline: string;
  pitch: string;
  bio: string;
  tags: string[];
  social: {
    linkedin: string;
    twitter: string;
    instagram: string;
  };
}

const ProfileSchema = Yup.object().shape({
  headline: Yup.string().required("Headline is required"),
  pitch: Yup.string().required("Pitch is required"),
  bio: Yup.string().required("Bio is required"),
  social: Yup.object().shape({
    linkedin: Yup.string().url("Must be a valid URL").nullable(),
    twitter: Yup.string().url("Must be a valid URL").nullable(),
    instagram: Yup.string().url("Must be a valid URL").nullable(),
  }),
  tags: Yup.array().of(Yup.string()).default([]),
});

interface ProfileSettingsProps {
  id: string;
}

const MentorProfileForm = ({ id }: ProfileSettingsProps) => {
  const refVideo = useRef<HTMLVideoElement | null>(null);
  const [videoPlaying, setVideoPlaying] = useState(false);
  const { profile } = useMyProfile();
  const { update, is_loading, error } = useUpdateMentor();

  const alert = useAlert();
  useEffect(() => {
    if (error) {
      alert.error(error);
    }
  }, [error]);

  if (!isMentor(profile)) {
    throw Error("Profile is not a mentor");
  }

  const initialValues: ProfileFields = {
    headline: profile.headline,
    pitch: profile.pitch,
    bio: profile.bio,
    tags: profile.tags || [],
    social: {
      linkedin: profile.social.linkedin || "",
      twitter: profile.social.twitter || "",
      instagram: profile.social.instagram || "",
    },
  };

  const onSubmit = async (values: ProfileFields) => {
    if (!profile) return null;
    await update(values);
  };

  const uploadImage = () => {
    const image_options = createImageOptions((image_data) => {
      update({
        profileImage: image_data,
      });
    });
    FS.picker(image_options).open();
  };

  const uploadVideo = () => {
    const video_options = createVideoOptions((video_data) => {
      update({
        profileVideo: video_data,
      });
    });
    FS.picker(video_options).open();
  };

  const videoControl = () => {
    const video = refVideo.current;
    if (video && video?.paused) {
      video.play();
      setVideoPlaying(true);
    } else {
      video && video.pause();
      setVideoPlaying(false);
    }
  };

  const removeProfileImage = () => {
    update({
      profileImage: {
        url: null,
      },
    });
  };
  const removeProfileVideo = () => {
    update({
      profileVideo: {
        url: null,
      },
    });
  };

  return (
    <div className="section" id={id}>
      <div className="section-content">
        <div className="section-header">
          <h3>Profile</h3>
        </div>
        <div className="profile-section-media">
          <div className="profile-photo">
            <div className="media-header">
              <p className="section-label">Photo</p>
              <button
                className="input-button"
                onClick={
                  profile?.profileImage?.url
                    ? () => removeProfileImage()
                    : uploadImage
                }
              >
                {profile?.profileImage?.url ? "Remove Photo" : "Upload"}
              </button>
            </div>
            <div className="media-content">
              <img
                className="account-avatar-img"
                alt="account-avatar-img"
                src={profile?.profileImage?.url || AvatarPlaceholder}
              />
            </div>
          </div>
          <div className="profile-video">
            <div className="media-header">
              <p className="section-label">Video</p>
              <button
                className="input-button"
                onClick={
                  profile?.profileVideo?.url
                    ? () => removeProfileVideo()
                    : uploadVideo
                }
              >
                {profile?.profileVideo?.url ? "Remove Video" : "Upload"}
              </button>
            </div>
            <div className="media-content">
              {profile?.profileVideo?.url ? (
                <div>
                  <video ref={refVideo} height="200" onClick={videoControl}>
                    <source src={profile?.profileVideo?.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  {!videoPlaying ? (
                    <img
                      onClick={videoControl}
                      className="video-control-button"
                      src={PlayIcon}
                      alt="video-control-button"
                    />
                  ) : null}
                </div>
              ) : (
                <InfoContainer
                  Icon={Camera}
                  helperText={
                    "Upload a video to introduce yourself, your company, and why you joined MentorPass."
                  }
                  buttonText="Upload"
                  onClick={uploadVideo}
                />
              )}
            </div>
          </div>
        </div>
        <hr className="border-black" />
        <Formik
          initialValues={initialValues}
          validationSchema={ProfileSchema}
          onSubmit={onSubmit}
        >
          {({
            values,
            errors,
            handleChange,
            handleBlur,
            setFieldValue,
            submitForm,
          }) => (
            <Form className={styles.profile_form}>
              <TextInput
                name="headline"
                label="Headline"
                tooltip="Limit your headline to 100 characters."
                type="text"
                value={values.headline}
                error={errors["headline"]}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextInput
                name="pitch"
                label="Pitch"
                tooltip="Limit your headline to 280 characters."
                type="text"
                value={values.pitch}
                error={errors["pitch"]}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextArea
                id="bio"
                label="Bio"
                value={values.bio}
                error={errors["bio"]}
                onChange={handleChange}
                dark
              />
              <TagCloudInput
                title="Tags"
                tag_id="tags"
                input_prompt="Press [enter] to add a tag"
                tooltip="Tags are not displayed on your profile but help you get matched with the right mentees. (limit 8)"
                values={values.tags || []}
                tag_limit={8}
                onChange={(tags) => {
                  // if removing a tag submit the form
                  // let old_tags_length = values.tags?.length || 0;
                  setFieldValue("tags", tags);
                  // if (old_tags_length > tags.length) {
                  submitForm();
                  // }
                }}
              />
              <hr className="border-black" />
              <TextInput
                name="social.linkedin"
                label="LinkedIn"
                type="text"
                value={values.social.linkedin}
                error={errors.social?.linkedin}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextInput
                name="social.twitter"
                label="Twitter"
                type="text"
                value={values.social.twitter}
                error={errors.social?.twitter}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextInput
                name="social.instagram"
                label="Instagram"
                type="text"
                value={values.social.instagram}
                error={errors.social?.instagram}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <button type="submit" onClick={submitForm}>
                {is_loading ? (
                  <ThreeDots
                    color="#FFF"
                    height={18}
                    width={24}
                    wrapperStyle={{
                      margin: "auto",
                      display: "block",
                    }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MentorProfileForm;
