import moment, { Moment } from "moment-timezone";

export const getAvailabilityOnGivenDate = (
	all_available_slots: string[],
	given_date: string,
	user_timezone: string
): Moment[] => {
	const date = moment(given_date).tz(user_timezone);
	const month = date.month();
	const date_day = date.date();
	const availability: Moment[] = all_available_slots
		.map((slot) => moment(slot).tz(user_timezone))
		.filter((slot) => slot.month() === month && slot.date() === date_day);
	return availability;
};
