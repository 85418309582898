import React from "react";
import styles from "./CategoryLabel.module.css";
import classNames from "classnames";

type SVGProps = Record<string, string | number>;
type IconNodeChild = readonly [tag: string, attrs: SVGProps];
type IconNode = readonly [
  tag: string,
  attrs: SVGProps,
  children?: IconNodeChild[]
];

export interface CategoryLabelProps {
  id: string;
  title: string;
  selected: boolean;
  Icon: React.ReactElement;
  onClick: () => void;
}

const cx = classNames.bind(styles);

const CategoryLabel: React.FC<CategoryLabelProps> = ({
  id,
  title,
  selected,
  Icon,
  onClick,
}) => {
  const classes = cx({
    [styles.container]: true,
    [styles.selected]: selected,
  });

  return (
    <div
      data-testid={`${id}_category_label`}
      id={id}
      className={classes}
      onClick={onClick}
    >
      {Icon}
      <span>{title}</span>
    </div>
  );
};

export default CategoryLabel;
