import { useAuth0 } from "@auth0/auth0-react";
import { useInterpret } from "@xstate-ninja/react";
import business_machine from "./Business.machine";
import { fetchBusiness, updateBusiness } from "./Business.data";
import { BUSINESS_EVENT, UPDATE_BUSINESS_EVENT } from "./Business.schema";
import { useEffect } from "react";
// @ts-ignore
import { useAlert } from "@blaumaus/react-alert";
import { InterpreterFrom } from "xstate";
import profile_machine from "../Profile/Profile.machine";
import { useActor } from "@xstate/react";
import { useFeatureFlagEnabled } from "posthog-js/react";

const useBusinessService = (
  profile_service: InterpreterFrom<typeof profile_machine>
) => {
  const [profile_state] = useActor(profile_service);
  const { getAccessTokenSilently } = useAuth0();
  const { user } = useAuth0();
  const alert = useAlert();
  const paywall_disabled = useFeatureFlagEnabled("paywall_disabled");

  const business_service = useInterpret(business_machine, {
    services: {
      fetchBusiness: async () => {
        const token = await getAccessTokenSilently();
        const business = await fetchBusiness(token);
        return {
          business,
        };
      },
      updateBusiness: async (context) => {
        const token = await getAccessTokenSilently();
        if (!context?.pending_update) {
          throw Error("Business has no pending updates.");
        }
        const { data, error } = await updateBusiness(
          token,
          context.pending_update
        );
        if (error || !data) {
          alert.error("Failed to update business.");
        } else {
          alert.success("Updated company details!");
          return {
            business: data?.business,
          };
        }
      },
      validateSubscription: async (context) => {
        return {
          is_valid: paywall_disabled || context?.business?.has_stripe,
        }
      },
    },
    devTools: true,
  });

  useEffect(() => {
    if (!!user && profile_state.matches("mentee")) {
      business_service.send(BUSINESS_EVENT.USER_READY);
    }
  }, [user, profile_state]);

  return {
    business_service,
  };
};

export default useBusinessService;
