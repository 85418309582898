// FIXME: This component is a molecule that is dependent on another molecules, which is not permissible for Atomic Design Pattern
import React from "react";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { z } from "zod";
import { Formik, Form, FieldArray } from "formik";

import TextInput from "components/Atoms/TextInput";
import DropdownSelector from "components/Atoms/DropdownSelector";
import KeyResultItem from "../KeyResult";
import AddKeyResultInput from "components/Atoms/AddKeyResultInput";
import Button from "components/Atoms/Button";
import {
  KeyResultSchema,
  OBJECTIVE_SUBJECT,
  Attachment,
} from "../../../entities/OKR";
import { generateDueDates } from "./ModifyObjective.helpers";
import UploadAttachment from "../UploadAttachment";
import styles from "./ModifyObjective.module.css";
import * as Yup from "yup";
import Chip from "components/Atoms/Chip";
import { TextInputType } from "components/Atoms/TextInput/NewTextInput.wip";

const SUBJECTS = [
  {
    title: OBJECTIVE_SUBJECT.enum.Growth,
    value: OBJECTIVE_SUBJECT.enum.Growth,
  },
  {
    title: OBJECTIVE_SUBJECT.enum.Ideation,
    value: OBJECTIVE_SUBJECT.enum.Ideation,
  },
  {
    title: OBJECTIVE_SUBJECT.enum.Operations,
    value: OBJECTIVE_SUBJECT.enum.Operations,
  },
  {
    title: OBJECTIVE_SUBJECT.enum.Product,
    value: OBJECTIVE_SUBJECT.enum.Product,
  },
  {
    title: OBJECTIVE_SUBJECT.enum.Performance,
    value: OBJECTIVE_SUBJECT.enum.Performance,
  },
];

const ObjectiveValidationSchema = z.object({
  title: z.string().nonempty("Objective is required"),
  due_date: z
    .object({
      title: z.string().nonempty(),
      value: z.string().nonempty(),
    })
    .refine((value) => value.title !== undefined && value.value !== undefined, {
      message: "A due date is required",
      path: ["due_date"],
    }),
  subject: z
    .object({
      title: z.string().nonempty(),
      value: z.string().nonempty(),
    })
    .refine((value) => value.title !== undefined && value.value !== undefined, {
      message: "A subject is required",
      path: ["subject"],
    }),
  status: z.enum(["in_progress", "completed", "archived"]),
  key_results: z.array(KeyResultSchema),
});

export type ObjectiveValidation = z.infer<typeof ObjectiveValidationSchema> & {
  attachments: Attachment[];
};

export interface ModifyObjectiveProps {
  modification_type: "create" | "update";
  initial_values?: ObjectiveValidation;
  saveObjective: (
    objective: ObjectiveValidation,
    setSubmitting: (is_submitting: boolean) => void
  ) => void;
  custom_styles?: React.CSSProperties;
  // uploadAttachment: (push: (attachement: Attachment) => void) => void;
}

const ModifyObjective = ({
  initial_values,
  saveObjective,
  modification_type,
  custom_styles,
}: ModifyObjectiveProps) => {
  return (
    <div
      data-testid="create_objective"
      className={styles.container}
      style={{ ...custom_styles }}
    >
      <h3>
        {
          {
            create: "Create Objective",
            update: "Update Objective",
          }[modification_type]
        }
      </h3>
      <p className={styles.info}>
        Make sure that you create a measurable objective so you can track your
        progress with key results. For example, an objective of 'create a robust
        sales pipeline' can be measured with key results 'add 200 leads' and
        'post ads 3x per week on social channels.
      </p>
      <div className={styles.form}>
        <Formik
          enableReinitialize={false}
          initialValues={{
            key_results: [],
            attachments: [],
            status: "in_progress",
            ...initial_values,
          }}
          validationSchema={toFormikValidationSchema(ObjectiveValidationSchema)}
          onSubmit={(values, { setSubmitting }) => {
            saveObjective(values as ObjectiveValidation, setSubmitting);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            submitForm,
          }) => (
            <Form>
              <TextInput
                name="title"
                label="OBJECTIVE"
                placeholder="Make it bold to stretch yourself"
                value={values.title || ""}
                error={errors?.title}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
                type={TextInputType.TEXT}
              />
              <DropdownSelector
                id=""
                label={"DUE DATE"}
                placeholder={"Select a due date"}
                options={generateDueDates()}
                onSelect={(due_date) => setFieldValue("due_date", due_date)}
                value={values.due_date}
                dark={true}
              />
              {touched.due_date && errors.due_date && (
                <Chip id="due_date" className={styles.error}>
                  {errors.due_date}
                </Chip>
              )}
              <DropdownSelector
                id=""
                label={"CATEGORY"}
                placeholder={"Select a category"}
                options={SUBJECTS}
                onSelect={(subject) => setFieldValue("subject", subject)}
                value={values.subject}
                dark={true}
              />
              {touched.subject && errors.subject && (
                <Chip id={"category"} className={styles.error}>
                  {errors.subject}
                </Chip>
              )}
              <FieldArray name="key_results">
                {({ push, remove, replace }) => (
                  <div>
                    <h5>KEY RESULTS</h5>
                    {values.key_results.map((kr, index) => (
                      <KeyResultItem
                        _id={kr._id}
                        key={kr._id}
                        title={kr.title}
                        completed={!!kr.completedAt}
                        deleteKeyResult={() => remove(index)}
                        toggleKeyResult={() =>
                          replace(index, {
                            ...kr,
                            completedAt: !!kr?.completedAt
                              ? ""
                              : new Date().toISOString(),
                          })
                        }
                      />
                    ))}
                    <AddKeyResultInput
                      key={"key_result_input"}
                      addKeyResult={push}
                      disable_submit_on_enter={true}
                    />
                  </div>
                )}
              </FieldArray>
              {/* <UploadAttachment
                filename={
                  (values.attachments && values.attachments[0]?.filename) ||
                  undefined
                }
                cta={"Add Attachment"}
                title="ATTACHMENT (PITCH DECK, ETC.)"
                onSuccess={(value) => setFieldValue("attachments", [value])}
                onError={(error) => console.error(error)}
                deleteAttachment={() => setFieldValue("attachments", [])}
              /> */}
              <Button type="submit" className={styles.submit}>
                {
                  {
                    create: "Create Objective",
                    update: "Update Objective",
                  }[modification_type]
                }
              </Button>
              {modification_type === "update" && (
                <Button
                  variant="dark"
                  style={{ marginTop: "12px" }}
                  onClick={() => {
                    setFieldValue(
                      "status",
                      values.status == "in_progress"
                        ? "completed"
                        : "in_progress"
                    );
                    submitForm();
                  }}
                >
                  {values.status == "in_progress"
                    ? "Mark Complete"
                    : "Mark In-Progress"}
                </Button>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ModifyObjective;
