import dayjs from "dayjs";
import classNames from "classnames/bind";
import styles from "./CalendarDay.module.css";
const cx = classNames.bind(styles);


export interface CalednarDayInfo {
    title: string;
    iso_date: string;
    has_availability: boolean;
    disabled: boolean;
}

interface CalendaDayProps {
    day: CalednarDayInfo;
    selected_iso_date: string;
    mentee_request_slots: string[];
    onSelect: (date: string) => void;
}

const CalendarDay = ({ day, selected_iso_date, mentee_request_slots, onSelect }: CalendaDayProps) => {
    const { title, iso_date, has_availability, disabled } = day;

    const classes = cx({
        calendarDay: true,
        active: has_availability,
        disable: disabled,
        selected: selected_iso_date === iso_date,
        blank: !iso_date,
    });

    const handleClick = () => {
        if (disabled || !onSelect) return;
        onSelect(day.iso_date);
    };

    const requests_today = mentee_request_slots.filter(slot => {
        const calendar_date = dayjs(day.iso_date).format("YYYY-MM-DD");
        const requested_slot_day = dayjs(slot).format("YYYY-MM-DD");
        return calendar_date == requested_slot_day;
    }).length;

    return (
        <div
            data-testid="CalendarDay_container"
            className={classes}
            onClick={handleClick}
        >
            {requests_today > 0 && <div className={styles.requests_today}>{requests_today}</div>}
            <p>{title}</p>
        </div>
    );
};

export default CalendarDay;
