import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OKRQueryResult, ObjectiveUpdate } from "entities/OKR";
import { API_URL, createHeaders } from "utils/api";
import { useAlert } from "@blaumaus/react-alert";

const createOKR = async (
  token: string,
  data: ObjectiveUpdate
): Promise<OKRQueryResult> => {
  const response = await fetch(`${API_URL}/okr/new`, {
    method: "POST",
    body: JSON.stringify(data),
    headers: createHeaders(token),
  });
  const new_okr = await response.json();
  return new_okr;
};

interface Props {
  onSuccess: (objective_id: string) => void;
  onError?: (error: Error) => void;
}

export const useCreateOKR = ({ onSuccess, onError }: Props) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  return useMutation<OKRQueryResult, Error, ObjectiveUpdate>({
    mutationFn: async (data: ObjectiveUpdate) =>
      getAccessTokenSilently().then((token) => createOKR(token, data)),
    onSuccess: (new_okr: OKRQueryResult) => {
      queryClient.setQueryData<OKRQueryResult[]>(
        ["okrs"],
        (existing_okrs = []) => [...existing_okrs, new_okr]
      );
      onSuccess(new_okr._id);
    },
    onError: (error) => {
      console.error(error);
      onError && onError(error);
    },
    onSettled: () => {
      queryClient.invalidateQueries(["okrs"]);
    },
  });
};
