/* eslint-disable react/prop-types */
import React, { ChangeEvent, useState } from "react";
import { BlackCrossIcon } from "../../../Icons";
import { Highlight } from "entities/Mentor";

interface EditHighlightProps {
	previous_highlight: Highlight;
	updateHighlight: (highlight: Highlight) => Promise<void>;
	closeModal: () => void;
}


const EditHighlightModal = ({
	previous_highlight,
	updateHighlight,
	closeModal,
}: EditHighlightProps) => {

	const [highlight, setHightlight] = useState({
		title: previous_highlight.title,
		description: previous_highlight.description,
	});

	const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		setHightlight({ ...highlight, [e.target.name]: e.target.value });


	return (
		<div className="modal-content">
			<div className="header">
				<div>
					<p className="account-title-black">Edit Highlights</p>
					<p className="modal-helper-text">
						Edit career highlights to let clients know what makes you, you.
					</p>
				</div>
				<div className="action">
					<img src={BlackCrossIcon} alt="cross" onClick={closeModal} />
				</div>
			</div>
			<div className="content">
				<div className="highlight-input">
					<div>
						<label htmlFor="title">
							Title<span className="required">*</span>
						</label>
						<input
							data-hj-allow
							name="title"
							type="text"
							value={highlight.title}
							onChange={onChange}
						/>
					</div>
				</div>
				<div className="highlight-textarea">
					<label htmlFor="description">
						Highlight Description<span className="required">*</span>
					</label>
					<textarea
						name="description"
						onChange={onChange}
						rows={5}
						cols={33}
						value={highlight.description}
					/>
				</div>
				<div className="modal-action">
					<button onClick={() => updateHighlight(highlight)}>Update</button>
				</div>
			</div>
		</div>
	);
};


export default EditHighlightModal;
