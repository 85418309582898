import React, { useEffect } from "react";
import { useAlert } from "@blaumaus/react-alert";
import { ThreeDots } from "react-loader-spinner";

import useStripeCustomerPortal from "hooks/mentee/useStripeCustomerPortal";
import useBusiness from "hooks/mentee/useBusiness";

const Billing = ({
  id,
}: {
  id: string;
}) => {
  const { user_is_admin } = useBusiness();
  
  const { goToCustomerPortal, is_loading, error } = useStripeCustomerPortal();
  const alert = useAlert();

  useEffect(() => {
    if (error) {
      alert.error("Failed to load Stripe, please contact support.")
    }
  }, [error])

  return (
    <div className="section" id={id}>
      <div className="section-content">
        <div className="section-header">
          <h3>Billing</h3>
        </div>
        {user_is_admin ? (
          <>
            <p>
              We partner with Stripe for simple and secure billing expereince.{" "}
              <br /> Click the button below to access your billing settings.
            </p>
            <br />
            <button onClick={goToCustomerPortal}>
              {is_loading ? (
                <ThreeDots
                  wrapperStyle={{ marginTop: "-2px" }}
                  color="#FFF"
                  height={24}
                  width={32}
                />
              ) : (
                "Access Stripe Dashboard"
              )}
            </button>
          </>
        ) : (
          <p>
            Only account admins have access to billing settings. <br /> If your
            require assistance please contact team@mentorpass.co
          </p>
        )}
      </div>
    </div>
  );
};

export default Billing;
