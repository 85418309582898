import { useState, useEffect } from 'react';

function useNotificationDismissal(key: string) {
  const [dismissed, setDismissed] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    const storedValue = window.localStorage.getItem(key);
    if (storedValue === 'true') {
      setDismissed(true);
    } else {
      setDismissed(false);
    }
  }, [key]);

  const dismissNotification = () => {
    setDismissed(true);
    window.localStorage.setItem(key, 'true');
  };

  return { dismissed, dismissNotification };
}

export default useNotificationDismissal;
