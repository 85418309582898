import classNames from "classnames/bind";

import styles from "./RightSidebar.module.css";

const cx = classNames.bind(styles);

export interface RightSidebarProps {
  open: boolean;
  close: () => void;
  children: React.ReactNode;
}
const RightSidebar: React.FC<RightSidebarProps> = ({
  open,
  close,
  children,
}) => {
  const background_classes = cx({
    background: true,
    background_active: open,
  });

  const content_classes = cx({
    container: true,
    container_active: open,
  });

  return (
    <>
      <div onClick={close} className={background_classes}></div>
      <div data-testid="right_sidebar" className={content_classes}>
        {children}
      </div>
    </>
  );
};

export default RightSidebar;
