import React, { useState } from "react";
import styles from "./CreateMentorCollection.module.css";
import { Formik, Form } from "formik";
import {
  MentorCollection,
  MentorCollectionSchema,
} from "entities/MentorCollection";
import { toFormikValidationSchema } from "zod-formik-adapter";
import TextInput from "components/Atoms/TextInput";
import TextArea from "components/Atoms/TextArea";
import Button from "components/Atoms/Button";
import { TextInputType } from "components/Atoms/TextInput/NewTextInput.wip";
type SetSubmitting = (isSubmitting: boolean) => void;

export interface CreateMentorCollectionProps {
  id: string;
  is_loading: boolean;
  createCollection: (
    collection: MentorCollection,
    setSubmitting: SetSubmitting
  ) => void;
}

const CreateMentorCollection = ({
  is_loading,
  createCollection,
}: CreateMentorCollectionProps) => {
  return (
    <div data-testid="create_mentor_collection" className={styles.container}>
      <Formik
        enableReinitialize={false}
        initialValues={{
          name: "",
          description: "",
          rank: 0,
        }}
        validationSchema={toFormikValidationSchema(MentorCollectionSchema)}
        onSubmit={(values, { setSubmitting }) => {
          createCollection(values as MentorCollection, setSubmitting);
        }}
      >
        {({
          values,
          errors,
          handleBlur,
          handleChange,
          isSubmitting,
          touched
        }) => (
          <Form className={styles.form}>
            <TextInput
              label="NAME"
              name="name"
              placeholder=""
              value={values.name || ""}
              error={touched?.name ? errors?.name : undefined}
              handleChange={handleChange}
              handleBlur={handleBlur}
              dark={true}
              type={TextInputType.TEXT}
            />
            <TextArea
              name="description"
              label="DESCRIPTION"
              value={values.description || ""}
              error={touched?.description ?  errors?.description : undefined}
              placeholder={""}
              handleChange={handleChange}
              handleBlur={handleBlur}
              dark={true}
              rows={4}
            />
            <TextInput
              name="rank"
              label="RANK"
              placeholder="0"
              value={values.rank || 0}
              error={touched.rank ? errors?.rank : undefined}
              handleChange={handleChange}
              handleBlur={handleBlur}
              dark={true}
              type={TextInputType.NUMBER}
            />
            <Button
              type="submit"
              loading={is_loading}
              className={styles.submit}
            >
              Create Collection
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default CreateMentorCollection;
