import { z } from "zod";

export enum PROFILE_TYPE {
  MENTOR = "mentor",
  MENTEE = "mentee",
  ADMIN = "admin",
}

export const ProfileSchema = z.object({
  user_id: z.string().refine((val) => /^[0-9a-fA-F]{24}$/.test(val)),
  given_name: z.string(),
  family_name: z.string(),
  type: z.nativeEnum(PROFILE_TYPE),
  bio: z.string(),
  headline: z.string(),
  pitch: z.string(),
  timezone: z.string(),
  slug: z.string(),
  gender: z.string().optional(),
  rank: z.number().optional(),
  social: z
    .object({
      twitter: z.string().nullable(),
      linkedin: z.string().nullable(),
      tiktok: z.string().nullable(),
      instagram: z.string().nullable(),
      crunchbase: z.string().nullable(),
      github: z.string().nullable(),
      angellist: z.string().nullable(),
    }),
  profileImage: z.object({
    url: z.string().nullable(),
  }),
  openGraph: z.string().nullable(),
  sb: z.boolean(),
  google: z.object({
    calendarsToAvoid: z.array(z.string()),
  }),
  favourites: z.array(z.string()),
  has_community_access: z.boolean(),
});

export type Profile = z.infer<typeof ProfileSchema>;



export type ProfileQueryResult = Profile & { _id: string };
