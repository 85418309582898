import {
  SmileyStar,
  FilledBlueStar,
  EmptyStar,
  Bulb,
  HeartCard,
  OutlinedEye,
  Flash,
  GivingSpirit,
} from "../../../../../components/Icons";
import styles from "../Profile.module.css";

const icons = {
  "Super Genius": Bulb,
  Inspirational: SmileyStar,
  "Great Listner": HeartCard,
  "Insightful Questions": OutlinedEye,
  "Specific Advice": Flash,
  "Extra Generous": GivingSpirit,
};

const Rating = ({ rating }: { rating: number }) => {
  const stars = [0, 0, 0, 0, 0].map((star, index) =>
    index + 0.5 <= rating / 2 ? FilledBlueStar : EmptyStar
  );
  return (
    <div className={styles.ratingBar}>
      {stars.map((star, index) => (
        <img key={`star-${index}`} src={star} className={styles.starIcon} />
      ))}
    </div>
  );
};

const avatarStyles = {
  width: "42px",
  height: "42px",
  background: "#000",
  borderRadius: "50%",
  marginRight: "16px",
  marginTop: "4px",
};

interface ReviewProps {
  reviewer: {
    avatarImage: string;
    name: string;
  };
  rating: number;
  comment: string;
  bestQuality: keyof typeof icons;
}

const Review = ({ reviewer, rating, comment, bestQuality }: ReviewProps) => {
  return (
    <li className={styles.reviewItem}>
      <div>
        {reviewer.avatarImage ? (
          <img src={reviewer.avatarImage} style={avatarStyles} />
        ) : (
          <img
            src={SmileyStar}
            alt="review-icon"
            style={{ ...avatarStyles, padding: "8px" }}
          />
        )}
      </div>
      <div className={styles.reviewContainer}>
        <h4>{reviewer.name}</h4>
        <Rating rating={rating} />
        {comment && <p style={{ color: "#fff" }}>&ldquo;{comment}&ldquo;</p>}
        {bestQuality && (
          <div className={styles.qualityBox}>
            {bestQuality in icons && (
              <img src={icons[bestQuality as "Super Genius"]} />
            )}
            <p>{bestQuality}</p>
          </div>
        )}
      </div>
    </li>
  );
};

export default Review;
