import { BookingAction, BookingCommand } from "./booking.reducer";

export const updateBookingDescription = (
	description: string
): BookingCommand => {
	return {
		type: BookingAction.SET_INFO,
		payload: {
			description,
		},
	};
};

export const updateBookingOkr = (okr_id: string): BookingCommand => {
	return {
		type: BookingAction.SET_INFO,
		payload: {
			okr: okr_id,
		},
	};
};

export const setBookingDuration = (duration: number): BookingCommand => {
	return {
		type: BookingAction.SET_INFO,
		payload: {
			duration,
			date: undefined,
			slot: undefined,
		},
	};
};

export const setBookingDate = (date: string): BookingCommand => {
    return {
        type: BookingAction.SET_INFO,
        payload: {
            date,
        }
    }
}

export const setBookingReschedulingNotes = (date: string): BookingCommand => {
    return {
        type: BookingAction.SET_INFO,
        payload: {
            date,
        }
    }
}

export const addRequestDatetime = (datetime: string): BookingCommand => {
	return {
		type: BookingAction.SELECT_MENTEE_REQUEST_SLOT,
		payload: {
			slot: datetime,
		},
	};
};

export const removeRequestDatetime = (datetime: string): BookingCommand => {
	return {
		type: BookingAction.REMOVE_MENTEE_REQUEST_SLOT,
		payload: {
			slot: datetime,
		},
	};
};

export const setBookingError = (error_message: string): BookingCommand => {
	return {
		type: BookingAction.SET_ERROR_MESSAGE,
		payload: {
			error_message,
		},
	};
};

export const clearRequestData = (): BookingCommand => {
	return {
		type: BookingAction.CLEAR_REQUEST_DATA,
	};
};

export const setObjectiveId = (objective_id: string): BookingCommand => {
	return {
		type: BookingAction.SET_INFO,
		payload: {
			okr: objective_id,
		}
	}
}

export const setPrimaryRequestSlot = (slot: string): BookingCommand => {
    return {
        type: BookingAction.SET_PRIMARY_REQUEST_SLOT,
        payload: {
            slot,
        }
    }
}

