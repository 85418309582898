import AnchorLink from "react-anchor-link-smooth-scroll";

import styles from "./BookingVerticalNavigation.module.css";

export interface RouteSchema {
  id: string;
  title: string;
  url: string;
  component?: React.ReactNode;
}

interface VerticalTabs {
  routes: RouteSchema[];
  selected_tab?: string;
  additional_components?: React.ReactNode;
}

const VerticalTabs: React.FC<VerticalTabs> = ({
  routes,
  selected_tab,
  additional_components,
}) => {
  let offset = 146;
  if (window.innerWidth < 900) {
    offset = 186;
  }

  return (
    <div data-testid="VerticalNavigation" className={styles.nav}>
      {routes.map((route, i) => {
        const selected =
          selected_tab && selected_tab === route.url ? styles.selected : "";
        return (
          <AnchorLink
            className={`${styles.nav_content} ${selected}`}
            href={route.url}
            key={i}
            offset={offset}
          >
            <div className={`${styles.nav_tab} ${selected}`}>{route.title}</div>
          </AnchorLink>
        );
      })}
      {additional_components}
    </div>
  );
};

export default VerticalTabs;
