import {
	ENTITY,
	SEARCH_PROFILES,
	CLICK_PROFILE,
	FOLLOW_MENTOR,
	CLICK_BOOK_SESSION,
} from "./ui.types";
import { caseCreatorHOC } from "../helpers";
const typeMaker = caseCreatorHOC(ENTITY);

export const UI = (store) => (next) => (action) => {
	next(action);
	const state = store.getState();

	switch (action.type) {
		case typeMaker(SEARCH_PROFILES):
			break;
		case typeMaker(CLICK_PROFILE):
			break;
		case typeMaker(FOLLOW_MENTOR):
			break;
		case typeMaker(CLICK_BOOK_SESSION):
			break;
		default:
			break;
	}
};

export default UI;
