import { useEffect, useState } from "react";
import styles from "../TeamManagment.module.css";
import Modal from "react-modal";
import { Formik } from "formik";
import TextInput from "components/Atoms/TextInput";
import { ThreeDots } from "react-loader-spinner";
import useAddTeamMember, {
  AddTeamMemberPayload,
} from "hooks/team/useAddTeamMember";
//@ts-ignore
import { useAlert } from "@blaumaus/react-alert";

Modal.setAppElement("#root");

const modal_styles: Modal.Styles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "172px",
    color: "#000",
  },
  overlay: {
    backgroundColor: "rgba(0,0,0,0.5)",
  },
};

const allValuesAreRequired = (values: Record<string, string>) => {
  const errors: Record<string, string> = {};
  for (let key of Object.keys(values)) {
    if (values[key].length < 1) {
      errors[key] = `A ${key} is required`;
    }
  }
  return errors;
};

const inital_request_details = {
  given_name: "",
  family_name: "",
};

const InviteTeammember = () => {
  const alert = useAlert();
  const [success_modal_open, setSuccessModal] = useState<boolean>(false);
  const [request_details, setRequestDetails] = useState(inital_request_details);

  const { add, is_loading, error } = useAddTeamMember({
    onSuccess: () => {
      setSuccessModal(true);
    },
  });

  const closeConfirmationModal = () => {
    setRequestDetails(inital_request_details);
    setSuccessModal(false);
  };

  const inviteTeamMember = (
    values: AddTeamMemberPayload,
    resetForm: () => void
  ) => {
    add(values);
    setRequestDetails(values);
    resetForm();
  };

  useEffect(() => {
    if (error) {
      alert.error("Failed to send invite!");
    }
  }, [error]);

  return (
    <div className={styles.invite_container}>
      <div className={styles.team_stats_header}>
        <h4>Invite a new team member</h4>
      </div>
      <Formik
        initialValues={{ email: "", given_name: "", family_name: "" }}
        validate={allValuesAreRequired}
        onSubmit={(values, { resetForm }) => {
          inviteTeamMember(values, resetForm);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <div className={styles.name_fields}>
              <TextInput
                type="text"
                label="First name"
                name="given_name"
                placeholder=""
                error={
                  errors.given_name && touched.given_name
                    ? errors.given_name
                    : undefined
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.given_name}
                dark={true}
              />
              <div style={{ width: "24px", height: "24px" }} />
              <TextInput
                label="Last name"
                type="text"
                name="family_name"
                placeholder=""
                error={
                  errors.family_name && touched.family_name
                    ? errors.family_name
                    : undefined
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.family_name}
                dark={true}
              />
              <div style={{ width: "24px", height: "24px" }} />
              <TextInput
                type="email"
                label="Email"
                name="email"
                placeholder=""
                error={errors.email && touched.email ? errors.email : undefined}
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.email}
                dark={true}
              />
              <div style={{ width: "24px" }} />
              <button
                type="submit"
                disabled={is_loading}
                style={{ paddingTop: isSubmitting ? "4px" : "12px" }}
              >
                {is_loading ? (
                  <ThreeDots
                    wrapperStyle={{ margin: "auto", display: "block" }}
                    color="#fff"
                    height={32}
                    width={32}
                  />
                ) : (
                  "Send Invite"
                )}
              </button>
            </div>
          </form>
        )}
      </Formik>
      <Modal
        isOpen={success_modal_open}
        onRequestClose={closeConfirmationModal}
        style={modal_styles}
      >
        <h3 style={{ color: "#000" }}>Success!</h3>
        <p>{`${request_details.given_name} ${request_details.family_name} has been successfully invited to your team.`}</p>
        <button onClick={closeConfirmationModal}>Close</button>
      </Modal>
    </div>
  );
};

export default InviteTeammember;
