import { ModalNames, useModal } from "components/Organisms/modals/ModalProvider";
import style from "./TruncatedText.module.css";

interface TruncatedTextProps {
  text: string;
}

const TruncatedText: React.FC<TruncatedTextProps> = ({ text }) => {

  const { openModal } = useModal();

  return (
    <div data-testid="TruncatedText">
      {text.substring(0, 250)}
      {text.length > 250 ? (
        <button className={style.buttonAsAnchor} onClick={() => openModal({
          name: ModalNames.FULL_TEXT,
          data: text
        })}>
          <span>... </span>Read more
        </button>
      ) : null}
    </div>
  );
};

export default TruncatedText;
