import React from "react";
import Styles from "./PaymentInfomation.module.css";
import { PaymentMethodStatus } from "./PaymentMethod.types";
import Button from "components/Atoms/Button";

export interface PaymentMethod {
  last_four_digits: string;
  expiring: string;
  status: PaymentMethodStatus;
}
type PaymentInformationProps = PaymentMethod & {
  is_loading: boolean;
  updateBillingDetails: () => void;
};

const PaymentInformation = ({
  status,
  last_four_digits,
  expiring,
  is_loading,
  updateBillingDetails,
}: PaymentInformationProps) => {
  return (
    <div
      className={`${Styles.container} ${
        status == PaymentMethodStatus.NO_METHOD_EXISTS ||
        status == PaymentMethodStatus.METHOD_EXPIRED
          ? Styles.no_method_exists
          : ""
      }`}
    >
      <div>
        <h4>Payment information</h4>
        {status == PaymentMethodStatus.NO_METHOD_EXISTS ? (
          <p className={Styles.no_valid_method}>
            No valid payment method exists on your account, please update now.
          </p>
        ) : (
          <p>
            Mastercard ending in {last_four_digits}, expiring {expiring}
          </p>
        )}
      </div>
      <Button
        loading={is_loading}
        variant="dark"
        className={Styles.update_button}
        onClick={updateBillingDetails}
      >
        Update
      </Button>
    </div>
  );
};

export default PaymentInformation;
