import { useLocation } from "react-router-dom";

import Header from "./Header";
import Finances from "./Finances/Finances.component";
import Availability from "./Availability";
import Experience from "./Experience";
import Company from "./Company/index";
import VerticalNavigation, {
  RouteSchema,
} from "components/Organisms/VerticalNavigation";
import AvatarPlaceholder from "images/avatar-placeholder.gif";
import Billing from "./Billing";
import ProfileSelector from "components/Organisms/ProfileSelector";
import CreditPackCheckout from "components/Organisms/CreditPackCheckout";
import useMyProfile from "hooks/useMyProfile";
import MentorProfileForm from "./MentorProfile/MentorProfile";
import MenteeProfileForm from "./MenteeProfile/MenteeProfile.component";
import GoogleCalendar from "./GoogleCalendar";
import UserSettings from "./UserSettings/UserSettings";

import styles from "./Account.module.css";
import { PROFILE_TYPE } from "entities/Profile";

const Titles = {
  account: "Account Details",
  mentor_profile: "Mentor Profile",
  mentee_profile: "Mentee Profile",
  finance: "Finances",
  availability: "Availability",
  experience: "Experience",
  company: "Company",
  google: "Calendar",
  credit_pack: "Buy Credits",
  billing: "Billing",
  timezone: "Timezone",
  // becomeMentee: "Mentee",
};

const ACCOUNT_MENU = {
  [PROFILE_TYPE.MENTOR]: [
    Titles.timezone,
    Titles.mentor_profile,
    Titles.finance,
    Titles.availability,
    Titles.experience,
    Titles.google,
    // Titles.becomeMentee,
  ],
  [PROFILE_TYPE.MENTEE]: [
    Titles.timezone,
    Titles.mentee_profile,
    Titles.company,
    Titles.billing,
    Titles.credit_pack,
  ],
  [PROFILE_TYPE.ADMIN]: [],
};

const Settings = () => {
  const { hash } = useLocation();
  const { profile, type: profile_type } = useMyProfile();

  const ROUTES: RouteSchema[] = [
    {
      id: "1",
      title: Titles.mentor_profile,
      url: "#mentor-profile",
      component: <MentorProfileForm id="mentor-profile" />,
    },
    {
      id: "2",
      title: Titles.mentee_profile,
      url: "#mentee-profile",
      component: <MenteeProfileForm id="mentee-profile" />,
    },
    {
      id: "timezone",
      title: Titles.timezone,
      url: "#timezone",
      component: <UserSettings id={"timezone"} />,
    },
    {
      id: "3",
      title: Titles.experience,
      url: "#experience",
      component: <Experience id="experience" />,
    },
    {
      id: "4",
      title: Titles.finance,
      url: "#finances",
      component: <Finances componentId="finances" />,
    },
    {
      id: "5",
      title: Titles.availability,
      url: "#availability",
      component: <Availability id="availability" />,
    },
    {
      id: "6",
      title: Titles.company,
      url: "#company",
      component: <Company id="company" />,
    },
    {
      id: "7",
      title: Titles.google,
      url: "#calendar",
      component: <GoogleCalendar id="calendar" />,
    },
    {
      id: "8",
      title: Titles.billing,
      url: "#billing",
      component: <Billing id="billing" />,
    },
    {
      id: "9",
      title: Titles.credit_pack,
      url: "#credits",
      component: (
        <CreditPackCheckout
          id="credits"
          toggleCreditWindow={() => console.log("Nada")}
        />
      ),
    },
  ];

  const WhitelistedRoute =
    (profile_type &&
      ROUTES.length > 0 &&
      ROUTES.filter((route) =>
        ACCOUNT_MENU[profile_type].includes(route.title)
      )) ||
    [];

  return profile ? (
    <>
      <Header
        username={`${profile?.given_name || ""} ${profile?.family_name || ""}`}
        profileImage={
          profile.profileImage.url
            ? profile.profileImage.url
            : AvatarPlaceholder
        }
      />
      <div className="account-content-container">
        <VerticalNavigation
          routes={WhitelistedRoute}
          selected_tab={hash}
          additional_components={<ProfileSelector />}
        />
        <div className="component-window">
          {WhitelistedRoute.map((route) => (
            <div className="component-tab" key={route.id}>
              {route?.component && route.component}
            </div>
          ))}
          <div className={styles.mobile_profile_selector}>
            <ProfileSelector />
          </div>
        </div>
      </div>
    </>
  ) : null;
};

export default Settings;
