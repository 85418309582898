import Styles from "./Offer.module.css";
import { BlueCircleWhiteTick } from "../../../../Icons";
import { Offer as OfferSchema } from "../../schema"
interface OfferProps extends OfferSchema {
  selected: boolean
  onSelect: () => void
}

const Offer = ({ _id, quantity_of_credits, cost_per_credit, selected, onSelect }: OfferProps) => {
  return (
    <div id={`offer_${_id}`} className={Styles.container} data-selected={selected} onClick={onSelect}>
      <div className={Styles.credits}>
        {selected ? <img alt={`checkmark indicating selection of offer`} className={Styles.selected} src={BlueCircleWhiteTick} /> : <div className={Styles.unselected} />}
        <h3>{quantity_of_credits} Credits</h3>
      </div>
      <div className={Styles.cost}>
        <p>${cost_per_credit / 100}/credit</p>
        <p>${(quantity_of_credits * cost_per_credit / 100).toFixed(2)}</p>
      </div>
    </div>);
}

export default Offer;
