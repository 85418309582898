import { BrowseMentorsActive, Configure } from "../../Icons";
import useCycleValues from "hooks/ui/useCycleValues";

import styles from "./MentorSearch.module.css";

interface MentorSearchProps {
  id: string;
  search_term: string;
  onChangeSearchTerm: (search_term: string) => void;
  launchFilters: () => void;
  clearFilters: () => void;
}

const MentorSearch: React.FC<MentorSearchProps> = ({
  id,
  search_term,
  onChangeSearchTerm,
  launchFilters,
  clearFilters,
}) => {
  const SEARCH_SUGGESTIONS: string[] = [
    "DTC",
    "Amazon",
    "TikTok",
    "OLIPOP",
    "SaaS",
  ];
  const search_suggestion = useCycleValues(SEARCH_SUGGESTIONS, 1600);

  return (
    <div data-testid="mentor_search" className={styles.container}>
      <img
        data-testid="search_icon"
        className={styles.search_icon}
        src={BrowseMentorsActive}
      />
      <input
        role="searchbox"
        type="text"
        id={id}
        className={styles.textarea}
        placeholder={`Try "${search_suggestion}"`}
        value={search_term}
        onChange={(e) => onChangeSearchTerm(e.target.value)}
      />
      <div role="menu" onClick={launchFilters}>
        <img src={Configure} />
        <p>Filter & Sort</p>
      </div>
      <div role="menu" onClick={clearFilters}>
        {/* <img src={} /> */}
        <p>Clear Filters</p>
      </div>
    </div>
  );
};

export default MentorSearch;
