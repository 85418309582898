import { MenteeQueryResult } from "entities/Mentee";
import { ProfileContext, ProfileEvent } from "../Profile.schema";
import { Sender } from "xstate";
import { MentorQueryResult, isMentor } from "entities/Mentor";

export const isBusinessAdmin = (profile?: MenteeQueryResult): boolean => {
  return !!profile && profile?.is_admin;
};

export const isTeamMember = (profile?: MenteeQueryResult) => {
  return !!profile && !profile.is_admin;
};

export const hasTeamInviteCode = (context: ProfileContext): boolean =>
  !!context.signup_options.team_invite_code;

export const detailsRequiredForTeamMemberSignup = (
  context: ProfileContext
): boolean => {
  return !(!!context?.user?.given_name && !!context?.user?.family_name);
};

export const hasTeamInviteCodeRequiresDetails = (context: ProfileContext) => {
  return (
    hasTeamInviteCode(context) && detailsRequiredForTeamMemberSignup(context)
  );
};

export const detailsRequiredForAdminSignup = (context: ProfileContext): boolean => {
  return !(!!context?.user?.given_name && !!context?.user?.family_name);
};

export const dispatchSignupEvent = (
  profile: MenteeQueryResult | undefined,
  context: ProfileContext,
  callback: Sender<ProfileEvent>
) => {
  if(!!profile && context.signup_options.pending_session_id) {
    callback({ type: "CLAIM_PENDING_SESSION", data: { profile } });
    return;
  } 
  if (isBusinessAdmin(profile)) {
    if(!profile) throw new Error("Profile is undefined");
    callback({ type: "OWNER_READY", data: { profile } });
    return;
  } else if (isTeamMember(profile)) {
    if(!profile) throw new Error("Profile is undefined");
    callback({ type: "TEAM_MEMBER_READY", data: { profile } });
    return;
  } else if (hasTeamInviteCodeRequiresDetails(context)) {
    callback("TEAM_MEMBER_DETAILS_REQUIRED");
    return;
  } else if (hasTeamInviteCode(context)) {
    callback("TEAM_MEMBER_INVITED");
  } else if (detailsRequiredForAdminSignup(context)) {
    callback("OWNER_DETAILS_REQUIRED");
    return;
  } else {
    callback("CREATE_OWNER");
    return;
  }
};