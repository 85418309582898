import TickCheckbox from "../../Atoms/TickCheckbox";
import { GreyInfo, GreenTick } from "../../Icons";
import ToggleSwitch from "../../Atoms/ToggleSwitch";
import Button from "components/Atoms/Button";
import { PLAN_TERM } from "./Types";
import styles from "./PlanSelection.module.css";

interface MentorDetails {
  name: string;
  hourly_rate: number;
}

interface Plan {
  _id: string;
  title: string;
  headline: string | null;
  byline: string;
  benifits: {
    b1: string;
  }[];
  price: string;
}

interface PlanSelectionComponentProps {
  plans: Plan[];
  refering_mentor: MentorDetails | null;
  plan_term: PLAN_TERM;
  setPlanTerm: (term: PLAN_TERM) => void;
  selected_plan: string | null;
  selectPlan: (plan_id: string) => void;
  continueToCheckout: () => void;
  is_loading: boolean;
}

const PlanSelectionComponent: React.FC<PlanSelectionComponentProps> = ({
  plan_term,
  setPlanTerm,
  refering_mentor,
  plans,
  selected_plan,
  selectPlan,
  continueToCheckout,
  is_loading,
}) => {
  const togglePlanTerm = () => {
    setPlanTerm(
      plan_term == PLAN_TERM.MONTHLY ? PLAN_TERM.QUARTERLY : PLAN_TERM.MONTHLY
    );
  };

  return (
    <div
      data-testid="PlanSelection"
      className={styles.container}
      data-test="plan-selection"
    >
      <div className={styles.header_container}>
        <div className={styles.duration_selection}>
          <p
            style={{
              color: `${plan_term === PLAN_TERM.MONTHLY ? "#000" : "#BFBFBF"}`,
            }}
          >
            Billed Monthly
          </p>
          <ToggleSwitch
            onToggle={togglePlanTerm}
            isToggled={plan_term == PLAN_TERM.QUARTERLY}
          />
          <p
            style={{
              color: `${
                plan_term === PLAN_TERM.QUARTERLY ? "#000" : "#BFBFBF"
              }`,
            }}
          >
            Billed Quarterly
          </p>
        </div>
        <h3>Pick your plan.</h3>
        {refering_mentor && (
          <div className={styles.reffering_mentor}>
            <img src={GreyInfo} />
            <p>
              <span>
                {refering_mentor.name} costs {refering_mentor.hourly_rate / 2}{" "}
                credits per session.
              </span>
            </p>
          </div>
        )}
      </div>
      <div className={styles.plan_container}>
        {plans.map((plan, index) => (
          <div
            data-testid={`option-${index + 1}`}
            className={styles.plan}
            style={{
              border: `${
                selected_plan === plan._id
                  ? "2px #5440F0 solid"
                  : "1px #000 solid"
              }`,
            }}
            onClick={() => selectPlan(plan._id)}
          >
            {selected_plan === plan._id &&
              plan.headline &&
              plan.headline.length > 0 && (
                <div className={styles.headline}>
                  <p className={styles.hline}>{plan.headline}</p>
                  <p className={styles.bline}>{plan.byline}</p>
                </div>
              )}
            <div className={styles.heading}>
              <div>
                <TickCheckbox
                  id={`t-${index}`}
                  checked={!!(selected_plan === plan._id)}
                  className={styles.checkbox}
                />
                <h4
                  style={{
                    color: `${selected_plan === plan._id ? "#5440F0" : "#000"}`,
                  }}
                >
                  {plan.title}
                </h4>
              </div>
              <p className={styles.price}>
                <span
                  style={{
                    color: `${
                      selected_plan === plan._id
                        ? "var(--primary-colour)"
                        : "#111827"
                    }`,
                  }}
                >
                  ${plan.price}/month
                </span>
              </p>
            </div>
            <ul className={styles.benifits}>
              {plan.benifits.map((benifit: { b1: string }) => (
                <li className={styles.benifit}>
                  <img src={GreenTick} alt="green tick" />
                  <p
                    className={styles.benifit_pt1}
                    style={{
                      color: `${
                        selected_plan === plan._id ? "#000" : "#6e6e6e"
                      }`,
                    }}
                  >
                    {benifit.b1}
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
      <Button
        className={styles.select_btn}
        loading={is_loading}
        style={{ borderRadius: "8px", width: "100%" }}
        onClick={continueToCheckout}
      >
        Checkout with Stripe
      </Button>
      <p>Subscribe now, cancel anytime. </p>
    </div>
  );
};

export default PlanSelectionComponent;
export { PLAN_TERM };
