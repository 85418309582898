import React from "react";
import styles from "./Testimonial.module.css";
import TestimonialImage from "./Images/bennett.png";

const Testimonial = () => {
	return (
		<div className={styles.testimonial}>
			<h3>
				“The best dollar for dollar investment we’ve ever made in our business.”
			</h3>
			<div>
				<img src={TestimonialImage} />
				<p>Greg W., MySpinDr</p>
			</div>
		</div>
	);
};

export default Testimonial;
