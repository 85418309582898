import React from 'react';
import styles from "./ToolTip.module.css"
import { InfoIcon } from '../../Icons';

export interface ToolTipProps {
   id: string;
   title: string;
   help: string;
}

const ToolTip: React.FC<ToolTipProps> = ({ id, title, help }: ToolTipProps) => {
   return (
      <div data-testid={id} className={styles.container}>
         <img src={InfoIcon} alt={`${title} help toolip`} />
         <p className={styles.toolTip}>{help}</p>
      </div>
   );
}

export default ToolTip;
