import Card from "components/Molecules/Card";

import OnDemandMentors from "./Images/access-metors-min.png";
import AccessFromAnyWhere from "./Images/access-from-anywhere-min.png";
import PowerUpYourTeam from "./Images/power-up-team-min.png";
import TackleYourGoals from "./Images/tackle-your-goals-min.png";

import styles from "./HowItWorks.module.css";

const goToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
};

type CardType = React.ComponentProps<typeof Card>;

const CARDS: CardType[] = [
  {
    image: OnDemandMentors,
    title: "Access to 150+ vetted mentors on-demand",
    content:
      "Find yourself in need of sudden legal help? There’s a mentor for that. Need to tighten up your deck for a last minute pitch? We’ve got you covered.",
    cta: "Get access",
    onClick: goToTop,
  },
  {
    image: AccessFromAnyWhere,
    title: "Get mentored anytime, anywhere",
    content:
      "Book calls, top up credits, and talk with your mentor - on web, mobile or tablet.",
    cta: "Get mentored",
    onClick: goToTop,
  },
  {
    image: PowerUpYourTeam,
    title: "Invite your team",
    content:
      "One-to-many, or one-to-one. Get private mentorship or invite your team and level up your whole organization.",
    cta: "Get started",
    onClick: goToTop,
  },
  {
    image: TackleYourGoals,
    title: "Align your goals",
    content:
      "Mentoring sessions are based around OKR’s, so you can make sure you’re working towards a tangible result, tied to your business.",
    cta: "Achieve my goals",
    onClick: goToTop,
  },
];

const HowItWorks = () => {
  return (
    <div className={styles.bg}>
      <div className={styles.container}>
        <div className={styles.left_container}>
          <h3>What’s in your MentorPass?</h3>
        </div>
        <div className={styles.card_container}>
          {CARDS.map((card) => (
            <Card {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
