import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";
import { PROFILE_TYPE, ProfileQueryResult } from "entities/Profile";
import { SessionQueryResult } from "entities/Session";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createHeaders, API_URL } from "utils/api";

interface SessionData {
  session?: SessionQueryResult;
  profile?: ProfileQueryResult;
  availability: string[];
  user_role?: PROFILE_TYPE;
}

export interface UseRescheduleSessionReturn extends SessionData {
  loading_session_data: boolean;
  selected_slots: string[];
  primary_slot: string | undefined;
  toggleSelectSlot: (slot: string) => void;
  removePrimarySlot: () => void;
  setPrimarySlot: (slot: string) => void;
  addAllSlotsForDate: (date: string) => void;
  clearDate: (date: string) => void;
  requestReschedule: (onDone: () => void) => Promise<void>;
  error_loading_session_data?: string;
  reschedule_session_is_submitting: boolean;
}

const useRescheduleSession = ({
  session_id,
}: {
  session_id: string;
}): UseRescheduleSessionReturn => {
  const navigate = useNavigate();
  /** Inital data fetching logic - Getting the sessio data */
  const [loading_session_data, setLoadingSessionData] = useState<boolean>(true);

  const [session_data, setSessionData] = useState<SessionData>({
    availability: [],
    session: undefined,
    profile: undefined,
    user_role: undefined,
  });

  const [error_loading_session_data, setErrorLoadingSessionData] = useState<
    string | undefined
  >();
  const { getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    const fetchSessionData = async () => {
      const token = await getAccessTokenSilently();

      try {
        const response = await fetch(
          `${API_URL}/session/reschedule/${session_id}`,
          {
            method: "GET",
            mode: "cors",
            headers: createHeaders(token),
          }
        );
        if (response.status === 403) {
          setErrorLoadingSessionData(
            "User is not authorized to access this session."
          );
          setLoadingSessionData(false);
          return;
        } else if (response.status === 500) {
          setErrorLoadingSessionData("Failed to fetch session data.");
          setLoadingSessionData(false);
          return;
        }
        const data = await response.json();
        setSessionData({
          session: data.session,
          profile: data.profile,
          availability: data.session_availability,
          user_role: data.user_role,
        });
        setLoadingSessionData(false);
      } catch (error) {
        setErrorLoadingSessionData("Something went wrong.");
        console.error("Error fetching session data:", error);
        setLoadingSessionData(false);
      }
    };

    if (session_id) {
      fetchSessionData();
    }
  }, [session_id]);

  // * Logic for selecting reschedule slots datetimes
  const [selected_slots, setSelectedSlots] = useState<string[]>([]);
  const [primary_slot, setPrimarySlot] = useState<string | undefined>(
    undefined
  );

  const toggleSelectSlot = useCallback((slot: string) => {
    setSelectedSlots((prevSlots) => {
      if (prevSlots.includes(slot)) {
        // If the slot is already selected, remove it
        return prevSlots.filter((s) => s !== slot);
      } else {
        // If it's the first slot, set it as primary
        if (prevSlots.length === 0) {
          setPrimarySlot(slot);
        }
        // Add the new slot
        return [...prevSlots, slot];
      }
    });
  }, []);

  const removePrimarySlot = useCallback(() => {
    if (primary_slot) {
      setSelectedSlots((prevSlots) =>
        prevSlots.filter((s) => s !== primary_slot)
      );
      setPrimarySlot(undefined);
    }
  }, [primary_slot]);

  const setPrimarySlotCallback = useCallback(
    (slot: string) => {
      if (selected_slots.includes(slot)) {
        setPrimarySlot(slot);
      }
    },
    [selected_slots]
  );

  const clearDate = (date: string) => {
    const calendar_date = dayjs(date);
    setSelectedSlots((prevSlots) => {
      return prevSlots.filter(
        (slot) => !dayjs(slot).isSame(calendar_date, "day")
      );
    });
  };

  const addAllSlotsForDate = (date: string) => {
    const calendar_date = dayjs(date);
    setSelectedSlots((prevSlots) => {
      return Array.from(
        new Set([
          ...prevSlots,
          ...session_data.availability.filter((slot) =>
            dayjs(slot).isSame(calendar_date, "day")
          ),
        ])
      );
    });
  };

  const [reschedule_session_is_submitting, setRescheduleSessionIsSubmitting] =
    useState<boolean>(false);

  const requestReschedule = async (onDone: () => void) => {
    setRescheduleSessionIsSubmitting(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/session/reschedule/${session_id}`,
        {
          method: "POST",
          mode: "cors",
          headers: createHeaders(token),
          body: JSON.stringify({
            proposed_times: selected_slots,
            ideal_proposed_time: primary_slot,
          }),
        }
      );
      if (response.status === 200) {
        onDone();
      }
    } catch (error) {
      console.error("Error rescheduling session:", error);
    } finally {
      setRescheduleSessionIsSubmitting(false);
    }
  };

  return {
    loading_session_data,
    ...session_data,
    selected_slots,
    primary_slot,
    toggleSelectSlot,
    removePrimarySlot,
    addAllSlotsForDate,
    clearDate,
    setPrimarySlot: setPrimarySlotCallback,
    requestReschedule,
    error_loading_session_data,
    reschedule_session_is_submitting,
  };
};

export default useRescheduleSession;
