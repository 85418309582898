import React from "react";
import style from "./Logos.module.css";
import MeUndies from "./Images/meundies.png";
import Glosser from "./Images/glossier.png";
import OliPop from "./Images/olipop.png";
import Allbirds from "./Images/allbirds.png";
import JonesRoad from "./Images/jonesroadbeauty.png";

const Logos = () => {
	return (
		<div className={style.container}>
			<div className={style.logos}>
				<img src={JonesRoad} />
				<img src={Glosser} />
				<img src={Allbirds} />
				<img src={MeUndies} />
				<img src={OliPop} />
			</div>
			<p>Learn from the operators of top DTC brands</p>
		</div>
	);
};

export default Logos;
