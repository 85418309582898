import React from "react";
import styles from "./MobileMenu.module.css";
import classNames from "classnames";

interface MobilemenuProps {
  text: string;
  active: boolean;
  onClick: () => void;
}

const cx = classNames.bind(styles);

const MobileMenuItem = ({ text, active, onClick }: MobilemenuProps) => {
  const menuItemStyles = cx({
    [styles.menu_item]: true,
    [styles.menu_item_active]: active,
  });

  return (
    <div className={menuItemStyles} onClick={onClick}>
      {text}
    </div>
  );
};

export default MobileMenuItem;
