//@ts-ignore
import { DraggingStyle } from "react-beautiful-dnd";

// a little function to help us with reordering the result
export const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (
  isDragging: boolean,
  draggableStyle: DraggingStyle
): React.CSSProperties => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  marginTop: "12px",
  background: isDragging ? "var(--primary-colour)" : "",
  // styles we need to apply on draggables
  ...draggableStyle,
});

export const getListStyle = (isDragOver: boolean) => ({
  width: "100%",
});
