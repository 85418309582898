/* eslint-disable react/prop-types */
import styles from "./Paywall.module.css";
import { BlueCircleWhiteTick } from "../../Icons";
import GreenTick from "../../../images/green-tick.svg";

const Paywall = ({ goToCheckout }: { goToCheckout: () => void }) => {
  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <img className={styles.check_image} src={GreenTick} />
        <h3>Finish your sign up!</h3>
        <div className={styles.key_benifits}>
          <ul>
            <li>
              <img src={BlueCircleWhiteTick} />
              <p>Access to 400+ top mentors.</p>
            </li>
            <li>
              <img src={BlueCircleWhiteTick} />
              <p>20% discount on all your calls.</p>
            </li>
            <li>
              <img src={BlueCircleWhiteTick} />
              <p>Free weekly group coaching calls.</p>
            </li>
            <li>
              <img src={BlueCircleWhiteTick} />
              <p>24/7 digital community to help you grow.</p>
            </li>
            <li>
              <img src={BlueCircleWhiteTick} />
              <p>Change or cancel your plan at any time.</p>
            </li>
          </ul>
        </div>
        <button onClick={goToCheckout}>Get started</button>
      </div>
    </>
  );
};

export default Paywall;
