import React, { useEffect, useState } from "react";
import styles from "./TimeSlotSelector.module.css";
import { ThreeDots } from "react-loader-spinner";
import dayjs from "utils/datetime";

interface TimeSlotSelectorProps {
  timezone: string;
  inital_slot: string; // must be a fixed value, eg. the session.ideal_proposed_time
  slots: string[];
  note?: string;
  accept_session_loading: boolean;
  decline_session_loading: boolean;
  confirmSession: (slot: string) => void;
  handleRescheduleSession: () => void;
  handleDeclineSession: () => void;
}

const TimeSlotSelector: React.FC<TimeSlotSelectorProps> = ({
  timezone,
  inital_slot,
  slots,
  note,
  accept_session_loading,
  confirmSession,
  decline_session_loading,
  handleRescheduleSession,
  handleDeclineSession,
}) => {
  const [selected_slot, setSelectedSlot] = useState<string>(inital_slot);
  const [hidden_slot, setHidden] = useState<string | undefined>();

  const handleSlotClick = (slot: string) => {
    setSelectedSlot(slot);
    setTimeout(() => {
      setHidden(slot);
    }, 800);
  };

  useEffect(() => {
    setHidden(inital_slot);
  }, [inital_slot]);

  return (
    <div className={styles.container}>
      <h4>Selected date & time</h4>
      <div className={styles.selected_slot_container}>
        <div
          className={`${styles.selected} ${
            selected_slot != hidden_slot && styles.glow
          }`}
        >
          {selected_slot
            ? dayjs(selected_slot).tz(timezone).format("ddd, MMM D, h:mm A")
            : "None"}
        </div>
        <button
          className={styles.blue_button}
          onClick={() => confirmSession(selected_slot)}
        >
          {accept_session_loading ? (
            <ThreeDots color="#FFF" height={18} width={24} />
          ) : (
            "Accept session"
          )}
        </button>
        <button
          onClick={handleRescheduleSession}
          className={styles.black_button}
        >
          Propose other times
        </button>
        <button onClick={handleDeclineSession} className={styles.red_button}>
          {" "}
          {decline_session_loading ? (
            <ThreeDots
              // style={{ marginRight: "2px" }}
              color="#FFF"
              height={18}
              width={24}
            />
          ) : (
            "Decline session"
          )}
        </button>
      </div>
      <h4>Alternative date & time</h4>
      <div className={styles.slot_list}>
        {slots
          .filter((slot) => slot != hidden_slot)
          .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0)) // SORT THE DATETIMES
          .map((slot, index) => (
            <div
              key={index}
              id={`timeslot-${slot}`}
              className={`${styles.slot} ${
                selected_slot === slot ? styles.hide_slot : ""
              }`}
              onClick={() => handleSlotClick(slot)}
            >
              {dayjs(slot).tz(timezone).format("ddd, MMM D, h:mm A")}
            </div>
          ))}
      </div>
      {note && (
        <div className={styles.note}>
          <span>Scheduling Notes: </span>
          {note}
        </div>
      )}
    </div>
  );
};

export default TimeSlotSelector;
