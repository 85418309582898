import React, { useState } from "react";
import styles from "../TeamManagment.module.css";
import { Grid } from "react-loader-spinner";
import useTeamMembers from "hooks/team/useTeamMembers";
import AvatarPlaceholder from "../../../../../images/avatar-placeholder.gif";
import RemoveTeamMember from "./RemoveTeamMember.component";
import { TeamMember } from "../TeamManagment.schema";

const Team = () => {
  const { team, is_loading, error, refetch } = useTeamMembers();

  const [team_member_pending_removal, setPendingRemoval] = useState<
    TeamMember | undefined
  >();

  const createSetPendingRemoval = (team_member: TeamMember) => () =>
    setPendingRemoval(team_member);

  const onTeamMemberRemoved = () => {
    refetch();
    setPendingRemoval(undefined);
  };

  return (
    <>
      <h3>Team</h3>
      <div className={styles.team_member_container}>
        {!is_loading &&
          team.map((member) => (
            <div key={`tm-${member.profile_id}`} className={styles.member}>
              <div className={styles.member_info}>
                <div>
                  <img src={member.avatar_image || AvatarPlaceholder} />
                  <div>
                    <h4>{member.name}</h4>
                    <p>{member.email}</p>
                  </div>
                </div>
                <button
                  onClick={createSetPendingRemoval({
                    ...member,
                    avatar_image: member.avatar_image || AvatarPlaceholder,
                  })}
                >
                  Remove
                </button>
              </div>
              <ul>
                {member.stats.map((stat, si) => (
                  <li key={`tms-${member.profile_id}-${si}`}>
                    <p>{stat.title}</p>
                    <p>{stat.value}</p>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        {is_loading && (
          <div style={{ margin: "auto", marginTop: "90px" }}>
            <Grid wrapperStyle={{ margin: "auto", display: "block" }} color="#5440F0" height={42} width={42} />
          </div>
        )}
      </div>
      {!!team_member_pending_removal && (
        <RemoveTeamMember
          team_member={team_member_pending_removal}
          onRemoved={onTeamMemberRemoved}
          closeModal={() => setPendingRemoval(undefined)}
        />
      )}
    </>
  );
};

export default Team;
