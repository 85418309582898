/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { BlackCrossIcon } from "../../../Icons";
import { Content } from "entities/Mentor";

interface AddContentProps {
	closeModal: () => void;
	saveContent: () => void;
	setNewContent: (content: Content) => void;
	newContent: Content;
}

const AddContent = ({ newContent, setNewContent, saveContent, closeModal }: AddContentProps) => {

	const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		setNewContent({ ...newContent, [e.target.name]: e.target.value });


	return (
		<div className="modal-content">
			<div className="header">
				<div>
					<p className="account-title-black">Add Contents</p>
					<p className="modal-helper-text">
						Journal article, blog post, youtube video, or killer Tweet. Add a
						link here to showcase it on your mentor profile.
					</p>
				</div>
				<div className="action">
					<img src={BlackCrossIcon} alt="cross" onClick={closeModal} />
				</div>
			</div>
			<div className="content">
				<div className="highlight-input">
					<div>
						<label htmlFor="title">
							TITLE<span className="required">*</span>
						</label>
						<input
							data-hj-allow
							name="title"
							type="text"
							value={newContent.title}
							onChange={onChange}
						/>
					</div>
					<div>
						<label htmlFor="url">
							LINK<span className="required">*</span>
						</label>
						<input
							data-hj-allow
							name="url"
							type="text"
							value={newContent.url}
							onChange={onChange}
						/>
					</div>
				</div>
				<div className="highlight-textarea">
					<label htmlFor="description">
						DESCRIPTION<span className="required">*</span>
					</label>
					<textarea
						name="description"
						onChange={onChange}
						rows={5}
						cols={33}
						value={newContent.description}
					/>
				</div>
				<div className="modal-action">
					<button onClick={() => saveContent()}>Save</button>
				</div>
			</div>
		</div>
	);
};

export default AddContent;
