import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useAppDispatch } from "hooks/redux";
import useLogout from "hooks/useLogout";

interface LogoutProps {
	closeModal: () => void;
	logout: () => void;
}

const LogoutComponent = ({ logout, closeModal }: LogoutProps) => {
	return (
		<div className="modal-content" style={{ width: "520px" }}>
			<h3 style={{ color: "#000" }}>Are you sure you want to Logout?</h3>
			<div
				style={{
					padding: "24px 0 0px",
					display: "flex",
					justifyContent: "space-around",
				}}
			>
				<button onClick={() => logout()}>Yes</button>
				<button onClick={closeModal}>No Way!</button>
			</div>
		</div>
	);
};


export default LogoutComponent;
