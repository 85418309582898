import { MentorSessionQueryResult } from "entities/MentorSession";
import MentorSessionCard from "./MentorSessionCard";
import styles from "./MentorSessions.module.css";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";

interface UpcomingSessionProps {
  session: MentorSessionQueryResult;
}

const UpcomingSession = ({ session }: UpcomingSessionProps) => {
  const navigate = useNavigate();

  const joinSession = () =>
    session.videoRoomLink && window.open(session.videoRoomLink, "_blank");

  const handleRescheduleSession = async () => {
    posthog.capture("mentor_clicked_reschedule_session", {
      status: session.status,
      session_id: session._id,
    });
    navigate(`/reschedule/${session._id}`);
  };

  const cancelSession = () => {
    window.open(
      "mailto:team@mentorpass.co?subject=Cancel%20Session&body=I'd%20like%20to%20cancel%20a%20session.",
      "_blank"
    );
  };

  return (
    <MentorSessionCard
      session={session}
      actions={
        <div className={styles.button_container}>
          <button className={styles.blue_button} onClick={joinSession}>
            Join Session
          </button>
          <button
            className={styles.black_button}
            onClick={handleRescheduleSession}
          >
            Reschedule Session
          </button>
          <button className={styles.red_button} onClick={cancelSession}>
            Cancel Session
          </button>
        </div>
      }
    />
  );
};

export default UpcomingSession;
