import OnboardingProgress from "components/Organisms/OnboardingProgress";
import React from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import Personal from "./Personal";
import Company from "./Company";
import Objective from "./Objective";
import styles from "./Onboarding.module.css";

const Onboarding = () => {
  return (
    <div className={styles.container}>
      <OnboardingProgress />
      <div className={styles.form_container}>
        <Routes>
          <Route path="/personal" element={<Personal />} />
          <Route path="/company" element={<Company />} />
          <Route path="/objective" element={<Objective />} />
        </Routes>
      </div>
    </div>
  );
};

export default Onboarding;
