import Header from "components/Organisms/Header";
import MentorSessionsDashboard from "components/Organisms/MentorSessions";
import MentorDashboardMarketingHub from "components/Organisms/MentorDashboardMarketingHub";
import MentorDashboardStats from "components/Organisms/MentorDashboardStats";

import styles from "./MentorDashboard.module.css";

const Dashboard = () => {
  return (
    <>
      <Header>
        <h3>Dashboard</h3>
      </Header>
      <div className={styles.contentContainer}>
        <div>
          {/* <NextSessionWidget {...nsw} isMobile={isMobile} /> */}
          <MentorSessionsDashboard />
          <MentorDashboardStats />
          <MentorDashboardMarketingHub />
        </div>
      </div>
    </>
  );
};

export default Dashboard;
