import { useEffect, useState } from "react";

const useCycleValues = (values: any[], duration: number) => {

    const [index, setIndex] = useState<number>(0)

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex((previous_index) =>
                previous_index === values.length - 1
                    ? 0
                    : previous_index + 1
            );
        }, duration || 1600);

        return () => clearInterval(interval);
    }, []);

    return values[index];
}


export default useCycleValues;