import * as Sentry from "@sentry/react";

import ErrorScreen from "../screens/ErrorScreen";

const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
  const ENVIROMENT = process.env.NODE_ENV;
  return (
    <>
      {ENVIROMENT === "production" ? (
        <Sentry.ErrorBoundary
          fallback={({ resetError }) => <ErrorScreen resetError={resetError} />}
        >
          {children}
        </Sentry.ErrorBoundary>
      ) : (
        children
      )}
    </>
  );
};

export default ErrorBoundary;
