import { RightArrowThinBlue } from "../../Icons";

import styles from "./Card.module.css";

interface ICardProps {
  image: string;
  title: string;
  content: string;
  cta: string;
  onClick: () => void;
}

const Card: React.FC<ICardProps> = ({
  image,
  title,
  content,
  cta,
  onClick,
}) => {
  return (
    <div data-testid="Card" className={styles.container}>
      <div className={styles.image_container}>
        <img src={image} />
      </div>
      <div className={styles.content}>
        <div>
          <h3>{title}</h3>
          <p>{content}</p>
        </div>
        <div data-testid="Card_button" className={styles.btn} onClick={onClick}>
          <h4>{cta}</h4>
          <img src={RightArrowThinBlue} />
        </div>
      </div>
    </div>
  );
};

export default Card;
