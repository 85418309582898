import React, { useState } from "react";
import { CopyToClipboard as Clipboard } from "react-copy-to-clipboard";
import { useAlert } from "@blaumaus/react-alert";
import classNames from "classnames/bind";

import styles from "./CopyToClipboard.module.css";

const cx = classNames.bind(styles);

interface CopyClipboardProps {
  value: string;
  title: string;
  style?: React.CSSProperties;
}

const CopyToClipboard: React.FC<CopyClipboardProps> = ({
  value,
  style,
  title,
}) => {
  const alert = useAlert();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    setCopied(true);
    alert.success("Copied to clipboard");
  };

  return (
    <div
      data-testid="CopyToClipboard"
      className={styles.copyToClipboard}
      style={style}
    >
      <Clipboard text={value} onCopy={copyToClipboard}>
        <button className={cx(["btn-dark", styles.button])}>
          {copied ? "Copied" : title}
        </button>
      </Clipboard>
      <input className={styles.input} value={value} readOnly />
    </div>
  );
};

export default CopyToClipboard;
