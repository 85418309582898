import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/react";
import styles from "./ErrorScreen.module.css";
import MpLogoText from "../../images/mp-logo-text.svg";

const ErrorScreen = ({ event, resetError }) => {
  const launchErrorReportingWidget = () => {
    if (!!event) {
      Sentry.showReportDialog({ eventId: event.event_id });
    }
  };

  const redirectToTheHomepage = () =>
    resetError
      ? resetError()
      : (window.location.href = process.env.REACT_APP_ORIGIN_URL);
  // eslint-disable-next-line no-undef

  return (
    <div className={styles.container}>
      <div className={styles.errorContents}>
        <img src={MpLogoText} />
        <h4>We&apos;ve encountered an error.</h4>
        <p>
          If you&apos;d like to report it, use the buttons below, or return to
          your dashboard.
        </p>
        <div className={styles.buttonContainer}>
          <button onClick={redirectToTheHomepage}>Go back</button>
          <button
            onClick={launchErrorReportingWidget}
            className={styles.reportErrorButton}
          >
            Report error
          </button>
        </div>
      </div>
    </div>
  );
};

ErrorScreen.propTypes = {
  resetError: PropTypes.func,
  event: PropTypes.object,
};

export default ErrorScreen;
