import { Grid } from "react-loader-spinner";

const DefaultLoadingSpinner: React.FC = () => (
  <Grid
    wrapperStyle={{ margin: "auto", display: "block" }}
    color="#5440F0"
    height={42}
    width={42}
  />
);

const mayLoad = (
  ChildComponent: React.FC<any>,
  LoadingComponent: React.FC<any> | null,
  className: string,
  styles: React.CSSProperties
) => {
  return ({ isLoading = false, is_loading = false, ...props }) => {
    const loading = isLoading || is_loading; // support either
    if (!loading) return <ChildComponent {...props} />;
    if (LoadingComponent) {
      return (
        <div className={className} style={styles}>
          {" "}
          <LoadingComponent />
        </div>
      );
    } else {
      return (
        <div className={className} style={styles}>
          <DefaultLoadingSpinner />
        </div>
      );
    }
  };
};

export default mayLoad;
