import { ChangeEvent, FocusEvent } from "react";

import defaultStyles from "./Checkbox.module.css";
import darkStyles from "./DarkCheckbox.module.css";

interface CheckBoxProps {
  id: string;
  name: string;
  label: string | JSX.Element;
  error?: string;
  checked: boolean;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  isDark?: boolean;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  id,
  name,
  label,
  error,
  checked,
  handleChange,
  handleBlur,
  isDark,
}) => {
  const styles = isDark ? darkStyles : defaultStyles;

  return (
    <div data-testid="Checkbox_container" className={styles.container}>
      <div>
        <input
          id={id}
          data-hj-allow
          type="checkbox"
          name={name}
          checked={checked}
          onChange={handleChange}
          onBlur={handleBlur}
          className={styles.input}
        />
        <label htmlFor={name} className={styles.label}>
          {label}
        </label>
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default CheckBox;
