import { useEffect, useState } from "react";
import Header from "components/Organisms/Header";
import VideoCard, { Video } from "components/Molecules/VideoCard";
import styles from "./VideoBrowser.module.css";
import { useNavigate } from "react-router-dom";
import { Grid } from "react-loader-spinner";
import useSessionRecordings from "hooks/useSessionRecordings";

const VideoBrowser = () => {
  const [videos, is_loading] = useSessionRecordings();
  const navigate = useNavigate();

  const launchVideo = (video_id: string) =>
    navigate(`/video_player/${video_id}`);

  return (
    <>
      <Header>
        <h3>Session Recordings</h3>
      </Header>
      <div className={styles.container}>
        <div className={styles.video_results}>
          {videos &&
            videos.map((video) => (
              <VideoCard video={video} launchVideo={launchVideo} />
            ))}
          {!is_loading && videos && videos?.length === 0 && (
            <div className={styles.pending_content}>
              <p>
                You have no session recordings. Record your next session to see
                it appear here.
              </p>
            </div>
          )}
          {is_loading && (
            <div className={styles.pending_content}>
              <Grid
                wrapperStyle={{
                  margin: "auto",
                  display: "block",
                  marginTop: "24vh",
                }}
                color="#5440F0"
                height={42}
                width={42}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default VideoBrowser;
