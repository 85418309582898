import React, { useState } from "react";
import { Form, Formik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import * as Yup from "yup";
import { MenteeQueryResult } from "entities/Mentee";
import styles from "./Personal.module.css";
import ImageUpload from "components/Molecules/ImageUpload";
import {
  TwitterShare,
  LinkedInShare,
  Instagram,
  TickTok,
  Crunchbase,
  Github,
  Angellist,
} from "components/Icons";
import { SOCIAL } from "constants/options";
import DropdownVariableInput from "components/Molecules/DropdownVariableInput";

const social_options = [
  {
    value: SOCIAL.TWITTER,
    title: (
      <div className={styles.social_option}>
        <img src={TwitterShare} />
        <p>Twitter</p>
      </div>
    ),
  },
  {
    value: SOCIAL.LINKEDIN,
    title: (
      <div className={styles.social_option}>
        <img src={LinkedInShare} />
        <p>LinkedIn</p>
      </div>
    ),
  },
  {
    value: SOCIAL.TIKTOK,
    title: (
      <div className={styles.social_option}>
        <img src={TickTok} />
        <p>TikTok</p>
      </div>
    ),
  },
  {
    value: SOCIAL.INSTA,
    title: (
      <div className={styles.social_option}>
        <img src={Instagram} />
        <p>Instagram</p>
      </div>
    ),
  },
  {
    value: SOCIAL.CRUNCHBASE,
    title: (
      <div className={styles.social_option}>
        <img src={Crunchbase} />
        <p>Crunchbase</p>
      </div>
    ),
  },
  {
    value: SOCIAL.GITHUB,
    title: (
      <div className={styles.social_option}>
        <img src={Github} />
        <p>Github</p>
      </div>
    ),
  },
  {
    value: SOCIAL.ANGELLLIST,
    title: (
      <div className={styles.social_option}>
        <img src={Angellist} />
        <p>Angellist</p>
      </div>
    ),
  },
];

enum SOCIAL_FIELD_CONSTANTS {
  ONE = "one",
  TWO = "two",
  THREE = "three",
}

const ProfileUpdateSchema = Yup.object({
  given_name: Yup.string()
    .min(1)
    .default("")
    .required("A firstname is required"),
  family_name: Yup.string()
    .min(1)
    .default("")
    .required("A last name is required"),
  headline: Yup.string().required("A title is required"),
  social: Yup.object().shape({
    linkedin: Yup.string().url("Must be a valid URL").nullable(),
    twitter: Yup.string().url("Must be a valid URL").nullable(),
    instagram: Yup.string().url("Must be a valid URL").nullable(),
    tiktok: Yup.string().url("Must be a valid URL").nullable(),
    crunchbase: Yup.string().url("Must be a valid URL").nullable(),
    github: Yup.string().url("Must be a valid URL").nullable(),
    angellist: Yup.string().url("Must be a valid URL").nullable(),
  }),
  profileImage: Yup.object().shape({
    url: Yup.string().url("Must be a valid URL").nullable(),
  }),
});

export type ProfileUpdate = Yup.InferType<typeof ProfileUpdateSchema>;

interface PersonalProps {
  profile: MenteeQueryResult;
  is_loading: boolean;
  update: (values: Partial<MenteeQueryResult>) => Promise<void>;
}

const makeProfileUpdateFields = (
  profile: MenteeQueryResult
): Partial<MenteeQueryResult> => ({
  given_name: profile.given_name,
  family_name: profile.family_name,
  headline: profile.headline,
  profileImage: profile.profileImage,
  social: profile.social,
});

const Personal = ({ profile, update, is_loading }: PersonalProps) => {
  const handleUploadProfileImage = () => {
    console.log("profile image upload");
  };

  const handleUploadProfileImageError = () => {
    console.log("profile image error");
  };

  const [social_fields, setSocialFields] = useState({
    [SOCIAL_FIELD_CONSTANTS.ONE]: SOCIAL.LINKEDIN,
    [SOCIAL_FIELD_CONSTANTS.TWO]: SOCIAL.TWITTER,
    [SOCIAL_FIELD_CONSTANTS.THREE]: SOCIAL.INSTA,
  });

  const createSetSocialField =
    (key: SOCIAL_FIELD_CONSTANTS) => (value: string) => {
      setSocialFields({
        ...social_fields,
        [key]: value,
      });
    };

  return (
    <div className={styles.container}>
      <div>
        <h3>Your Profile</h3>
        <p>Show your mentors who they're working with</p>
      </div>
      <ImageUpload
        call_to_action="Upload"
        image_url={profile.profileImage?.url}
        onSuccess={handleUploadProfileImage}
        onError={handleUploadProfileImageError}
      />
      <Formik
        initialValues={makeProfileUpdateFields(profile)}
        validationSchema={ProfileUpdateSchema}
        onSubmit={update}
      >
        {({ values, errors, handleChange, handleBlur, setFieldValue }) => (
          <Form className={styles.profile_form}>
            <div className={styles.input_container}>
              <label htmlFor="given_name">First Name</label>
              <input
                id="given_name"
                type="text"
                onChange={handleChange}
                name="given_name"
                value={values.given_name}
              ></input>
            </div>
            <div className={styles.input_container}>
              <label htmlFor="family_name">Last Name</label>
              <input
                id="family_name"
                type="text"
                onChange={handleChange}
                name="family_name"
                value={values.family_name}
              ></input>
            </div>
            <div className={styles.input_container}>
              <label htmlFor="headline">Title</label>
              <input
                id="headline"
                type="text"
                onChange={handleChange}
                name="headline"
                value={values.headline}
              ></input>
            </div>
            <DropdownVariableInput
              id="SOCIAL MEDIA 1"
              label="SOCIAL MEDIA 1"
              options={social_options}
              selected={social_fields[SOCIAL_FIELD_CONSTANTS.ONE]}
              onSelect={createSetSocialField(SOCIAL_FIELD_CONSTANTS.ONE)}
              value={profile.social[social_fields[SOCIAL_FIELD_CONSTANTS.ONE]]}
              onUpdate={(value: string) =>
                setFieldValue(SOCIAL_FIELD_CONSTANTS.ONE, value)
              }
            />
            <DropdownVariableInput
              id="SOCIAL MEDIA 2"
              label="SOCIAL MEDIA 2"
              options={social_options}
              selected={social_fields[SOCIAL_FIELD_CONSTANTS.TWO]}
              onSelect={createSetSocialField(SOCIAL_FIELD_CONSTANTS.TWO)}
              value={profile.social[social_fields[SOCIAL_FIELD_CONSTANTS.TWO]]}
              onUpdate={(value: string) =>
                setFieldValue(SOCIAL_FIELD_CONSTANTS.TWO, value)
              }
            />
            <DropdownVariableInput
              id="SOCIAL MEDIA 3"
              label="SOCIAL MEDIA 3"
              options={social_options}
              selected={social_fields[SOCIAL_FIELD_CONSTANTS.THREE]}
              onSelect={createSetSocialField(SOCIAL_FIELD_CONSTANTS.THREE)}
              value={
                profile.social[social_fields[SOCIAL_FIELD_CONSTANTS.THREE]]
              }
              onUpdate={(value: string) =>
                setFieldValue(SOCIAL_FIELD_CONSTANTS.THREE, value)
              }
            />
            <button type="submit" className={styles.submit_btn}>
              {is_loading ? (
                <ThreeDots color="#FFF" height={18} width={24} />
              ) : (
                "Save"
              )}
            </button>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Personal;
