import { Content, Highlight, WeekDay } from "entities/Mentor";
import { ReactNode, createContext, useContext, useState } from "react";

export enum ModalNames {
  UPDATE_TIMEZONE_ALERT = "UPDATE_TIMEZONE_ALERT",
  LOGOUT = "LOGOUT",
  ADD_SKILL = "ADD_SKILL",
  ADD_HIGHLIGHT = "ADD_HIGHLIGHT",
  EDIT_HIGHLIGHT = "EDIT_HIGHLIGHT",
  ADD_CONTENT = "ADD_CONTENT",
  EDIT_CONTENT = "EDIT_CONTENT",
  EDIT_AVAILABILITY = "EDIT_AVAILABILITY",
  TEXT_MODAL = "TEXT_MODAL",
  WELCOME_MENTOR = "WELCOME_MENTOR",
  STRIPE_CONNECTED = "STRIPE_CONNECTED",
  ONE_PAGE_CHECKOUT = "ONE_PAGE_CHECKOUT",
  CLAIM_PENDING_SESSION_SUCCESS = "CLAIM_PENDING_SESSION_SUCCESS",
  FULL_TEXT = "FULL_TEXT"
}

export type UPDATE_TIMEZONE_ALERT_MODAL = {
  name: ModalNames.UPDATE_TIMEZONE_ALERT;
  data: {
    browser_tz: string;
    profile_tz: string;
  };
};
export type LOGOUT_MODAL = { name: ModalNames.LOGOUT };
export type ADD_SKILL_MODAL = { name: ModalNames.ADD_SKILL };
export type ADD_HIGHLIGHT_MODAL = { name: ModalNames.ADD_HIGHLIGHT };
export type EDIT_HIGHLIGHT_MODAL = {
  name: ModalNames.EDIT_HIGHLIGHT;
  data: Highlight;
};
export type ADD_CONTENT_MODAL = { name: ModalNames.ADD_CONTENT };
export type EDIT_CONTENT_MODAL = {
  name: ModalNames.EDIT_CONTENT;
  data: Content;
};
export type AVAILABILITY_MODAL = {
  name: ModalNames.EDIT_AVAILABILITY;
  data: WeekDay;
};
export type TEXT_MODAL = { name: ModalNames.TEXT_MODAL; data: string };
export type WELCOME_MENTOR_MODAL = {
  name: ModalNames.WELCOME_MENTOR;
  data: any;
};
export type STRIPE_CONNECTED_MODAL = {
  name: ModalNames.STRIPE_CONNECTED;
  data: any;
};
export type ONE_PAGE_CHECKOUT_MODAL = {
  name: ModalNames.ONE_PAGE_CHECKOUT;
};
export type CLAIM_PENDING_SESSION_SUCCESS_MODAL = {
  name: ModalNames.CLAIM_PENDING_SESSION_SUCCESS;
  data: {
    pending_session_id: string;
  };
};

export type FULL_TEXT_MODAL = {
    name: ModalNames.FULL_TEXT;
    data: string;
}

export type MODAL_TYPE =
  | UPDATE_TIMEZONE_ALERT_MODAL
  | LOGOUT_MODAL
  | ADD_SKILL_MODAL
  | ADD_HIGHLIGHT_MODAL
  | EDIT_HIGHLIGHT_MODAL
  | ADD_CONTENT_MODAL
  | EDIT_CONTENT_MODAL
  | AVAILABILITY_MODAL
  | TEXT_MODAL
  | WELCOME_MENTOR_MODAL
  | STRIPE_CONNECTED_MODAL
  | ONE_PAGE_CHECKOUT_MODAL
  | CLAIM_PENDING_SESSION_SUCCESS_MODAL
  | FULL_TEXT_MODAL;

export type ModalState = {
  is_open: boolean;
  modal?: MODAL_TYPE;
};

export type IModalContext = {
  openModal: (modal: MODAL_TYPE) => void;
  closeModal: () => void;
  state: ModalState;
};

const ModalContext = createContext({} as IModalContext);

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [state, setModal] = useState<ModalState>({
    is_open: false,
    modal: undefined,
  });

  const openModal = (modal: MODAL_TYPE) =>
    setModal({ is_open: true, modal: modal });

  const closeModal = () => setModal({ is_open: false, modal: undefined });

  return (
    <ModalContext.Provider value={{ state, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};
