import { useState } from "react";

import { BlueChevron } from "../../Icons";

import styles from "./LargeDropdown.module.css";

export interface LargeDropdownProps {
  title: string;
  content: string;
}

const LargeDropdown = ({ title, content }: LargeDropdownProps) => {
  const [isOpen, toggleOpen] = useState(false);

  const toggle = () => toggleOpen(!isOpen);

  return (
    <div data-testid="LargeDropdown" className={styles.container}>
      <div
        data-testid="LargeDropdown_toggle"
        onClick={toggle}
        className={styles.toggle}
      >
        <div className={styles.title}>
          <h3>{title}</h3>
        </div>
        <img
          className={`${styles.arrow} ${isOpen && styles.arrow_open}`}
          src={BlueChevron}
        />
      </div>
      {isOpen && <div className={styles.hidden_content}>{content}</div>}
    </div>
  );
};

export default LargeDropdown;
