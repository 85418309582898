import { createHeaders, API_URL } from "utils/api";
import { OfferResponse, Offer, PaymentMethodResponse } from "./schema";

export const getPaymentMethod = (
  token: string
): Promise<PaymentMethodResponse> =>
  fetch(`${API_URL}/payment/get_cc_details`, {
    headers: createHeaders(token),
  }).then((response) => response.json() as Promise<PaymentMethodResponse>);

export const getOffers = (
  token: string
): Promise<{
  offers: Offer[];
  default_rate: number;
}> =>
  fetch(`${API_URL}/credits/get_offers`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  })
    .then((response) => {
      return response.json();
    })
    .then((body: OfferResponse[]) => {
      const offers_dtos: Offer[] = body
        .sort((a, b) => a.quantity - b.quantity)
        .map((offer, index) => ({
          _id: index,
          quantity_of_credits: offer.quantity,
          cost_per_credit: offer.cost,
        }));
      return {
        default_rate: offers_dtos[0].cost_per_credit,
        offers: offers_dtos.slice(1, offers_dtos.length),
      };
    });

export const purchaseCreditPack = async ({
  token,
  quantity,
  rate,
}: {
  token: string;
  quantity: number;
  rate: number;
}): Promise<Response> => {
  return fetch(`${API_URL}/credits/purchase`, {
    method: "POST",
    mode: "cors",
    headers: createHeaders(token),
    body: JSON.stringify({
      quantity,
      rate,
    }),
  }).then((response) => {
    if (response.ok) {
      return response;
    } else {
      throw Error("Failed to purchase credits.");
    }
  });
};

export const calculateCustomRate = (
  custom_quantity: number,
  offers: Offer[],
  default_rate: number
): number => {
  const offers_copy = JSON.parse(JSON.stringify(offers));
  let rate = default_rate;

  if (!offers.length) {
    return rate;
  }
  if (offers_copy.length > 0) {
    for (let index = 0; index < offers_copy.length; index++) {
      if (custom_quantity < offers_copy[index].quantity_of_credits) {
        break;
      }
      rate = offers_copy[index].cost_per_credit;
    }
  }
  return rate;
};

// getStripePortalLink fetches the url to the stripe customer portal from the api
export const getStripePortalLink = async (token: string) => {
  const result = await fetch(
    `${API_URL}/stripe/create-customer-portal-session`,
    {
      method: "GET",
      mode: "cors",
      headers: createHeaders(token),
    }
  );
  const data: unknown = await result.json();
  if (
    typeof data !== "object" ||
    !data ||
    !("url" in data) ||
    typeof data.url !== "string"
  ) {
    throw Error("Invalid response from server");
  }
  return data.url;
};

// getDaysUntilSubscriptionRenewal fetch the number of days until the subscription is renewed from the api
export const getDaysUntilSubscriptionRenewal = (
  token: string
): Promise<number> =>
  fetch(`${API_URL}/stripe/get-days-until-subscription-renewal`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  })
    .then((response) => response.json())
    .then((data) => data.days);
