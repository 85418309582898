import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";

const useStripeCustomerPortal = () => {
  const [error, setError] = useState<string | undefined>();
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const goToCustomerPortal = async () => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/stripe/create-customer-portal-session`,
        {
          headers: createHeaders(token),
        }
      );
      const data = await response.json();
      window.location.replace(data.url);
    } catch (error) {
      setError(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  return { goToCustomerPortal, is_loading, error };
};

export default useStripeCustomerPortal;
