import styles from "./SendButton.module.css";

interface IconButtonProps {
  size?: number;
  isDisabled?: boolean;
  onClick?: () => void;
  icon: JSX.Element | string;
  label: string;
  className?: string;
}

const SendButton: React.FC<IconButtonProps> = ({
  label,
  size = 32,
  isDisabled,
  onClick,
  icon,
  className,
}) => {
  const handleClick = () => {
    if (isDisabled || !onClick) return;
    onClick();
  };

  return (
    <button
      aria-label={label}
      data-testid="icon_button"
      disabled={isDisabled}
      className={`${styles.container}${className ? ` ${className}` : ""}`}
      onClick={handleClick}
      style={{ width: size, height: size, minWidth: size, minHeight: size }}
    >
      {typeof icon === "string" ? <img src={icon} alt="icon" /> : icon}
    </button>
  );
};

export default SendButton;
