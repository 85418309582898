import React, { useState } from "react";
import styles from "./CollectionFilter.module.css";
import CategoryLabel, {
  CategoryLabelProps,
} from "components/Atoms/CategoryLabel/CategoryLabel";

export type CollectionItem = Omit<CategoryLabelProps, "selected" | "onClick">;

export interface CollectionFilterProps {
  id: string;
  selected?: string;
  collection: CollectionItem[];
  onSelect: (category: CollectionItem) => void;
}

const CollectionFilter = ({ id, selected, collection, onSelect }: CollectionFilterProps) => {

  const createOnClick = (category: CollectionItem) => () => onSelect(category);

  return (
    <div data-testid="collection_filter" className={styles.container}>
      {collection.map((category) => (
        <CategoryLabel
          key={category.id}
          {...category}
          selected={selected === category.id}
          onClick={createOnClick(category)}
        />
      ))}
    </div>
  );
};

export default CollectionFilter;
