import moment from "moment";
import { WhiteCheckedCalendar } from "components/Icons";
import styles from "../../SessionReview.module.css";
import AvatarPlaceholder from "images/avatar-placeholder.gif";
import Avatar from "components/Atoms/Avatar";
import NotificationPin from "components/Atoms/NotificationPin";

interface FirstStepProps {
  id: string;
  data: {
    to: {
      given_name: string;
      profileImage: {
        url: string | null;
      };
    };
    from: {
      given_name: string;
    };
    slot: string;
  };
  nextStep: () => void;
}

const FirstStep = ({ id, data, nextStep }: FirstStepProps) => {
  return (
    <div data-testid={id} className={styles.firstStep}>
      <NotificationPin
        borderWidth={0}
        size={40}
        isActive
        top={-20}
        right={-20}
        icon={WhiteCheckedCalendar}
      >
        <Avatar
          src={
            (data.to.profileImage && data.to.profileImage.url) ||
            AvatarPlaceholder
          }
          rounded="var(--corner-radius)"
          name="profile"
          size={160}
        />
      </NotificationPin>
      <p className={styles.reviewHeading}>Session Review</p>
      <div className={styles.welcome}>
        <p>Hey {data.from.given_name}!</p>
        <p className={styles.introText}>
          Please submit your feedback on {data.to.given_name} for your session
          from {moment(data.slot).format("llll")}.
        </p>
      </div>
      <button id={`${id}-updateStep`} onClick={nextStep}>
        Start Review
      </button>
    </div>
  );
};

export default FirstStep;
