const isEnvVarString = (envVarName: string): envVarName is string => {
    const envVarValue = process.env[envVarName];
    return typeof envVarValue === "string";
};

const logFalsyKeys = (obj: { [key: string]: any }): void => {
    for (const key in obj) {
        if (!obj[key]) {
            console.error(`Config is missing valid: ${key}`);
        }
    }
};

interface Auth0Init {
    DOMAIN: string,
    CLIENT_ID: string,
    REDIRECT_URL: string,
    AUDIENCE: string,
}

const getAuthVariables = (): Auth0Init => {
    const DOMAIN = isEnvVarString("REACT_APP_AUTH0_DOMAIN") && process.env.REACT_APP_AUTH0_DOMAIN;
    const CLIENT_ID = isEnvVarString("REACT_APP_AUTH0_CLIENT_ID") && process.env.REACT_APP_AUTH0_CLIENT_ID;
    const REDIRECT_URL = isEnvVarString("REACT_APP_AUTH0_CALLBACK_URL") && process.env.REACT_APP_AUTH0_CALLBACK_URL;
    const AUDIENCE = isEnvVarString("REACT_APP_AUTH0_AUDIENCE") && process.env.REACT_APP_AUTH0_AUDIENCE;

    if (!(DOMAIN && CLIENT_ID && AUDIENCE && REDIRECT_URL)) {
        logFalsyKeys({
            DOMAIN,
            CLIENT_ID,
            REDIRECT_URL,
            AUDIENCE,
        });
        throw Error("Failed to load enviroment. Please contact support");
    }
    return {
        DOMAIN,
        CLIENT_ID,
        REDIRECT_URL,
        AUDIENCE,
    };
};

export default getAuthVariables;