import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Puff } from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";
import { createHeaders, API_URL } from "../../../../utils/api";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GoogleAuthLanding = () => {
  const query = useQuery();
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const code = query.get("code");

  const displayError = () =>
    dispatch({
      type: "SET_ALERT",
      payload: {
        type: "error",
        message: `There was an error. Contact support.`,
      },
    });

  // open google auth
  const sendAuthCode = async () => {
    try {
      // fetch link from API.
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/user/google/tokens`, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        // credentials: 'same-origin',
        body: JSON.stringify({
          code,
        }),
        headers: createHeaders(token),
      });
      if (response.status !== 200) {
        response.json().then((json) => {
          displayError();
          console.error(json.error);
        });
      } else {
        window.location.href = `${process.env.REACT_APP_ORIGIN_URL}/account#google`;
      }
    } catch (error) {
      console.error(error);
      setLoadingAuthLink(false);
      displayError();
    }
  };

  useEffect(() => {
    sendAuthCode();
  }, []);

  return (
    <div style={{ margin: "auto", marginTop: "10%" }}>
      <Puff
        color="var(--primary-colour)"
        height={100}
        width={100}
        timeout={8000} //3 secs
      />
    </div>
  );
};

export default GoogleAuthLanding;
