import styles from "../Reschedule.module.css";
import AvatarPlaceholder from "images/avatar-placeholder.gif";
import { Globe } from "components/Icons";
import { Grid } from "react-loader-spinner";
import { ProfileQueryResult } from "entities/Profile";

interface ProfileContentProps {
  profile?: ProfileQueryResult;
  is_loading: boolean;
}

const ProfileContent = ({ profile, is_loading }: ProfileContentProps) => {
  return (
    <div className={styles.mentorContent}>
      {!profile || is_loading ? (
        <Grid wrapperStyle={{ margin: "auto", display: "block" }}
          color="#5440F0"
          height={32}
          width={32}
        />
      ) : (
        <>
          <img
            className={styles.mentorAvatar}
            src={
              (profile.profileImage && profile.profileImage.url) ||
              AvatarPlaceholder
            }
            alt={`${profile.given_name}'s profile`}
          />
          <div>
            <h4 style={{ color: "#fff" }}>
              {profile.given_name} {profile.family_name}
            </h4>
            <p
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#fff",
              }}
            >
              {profile.headline}
            </p>
            <div className={styles.timezoneContainer}>
              <img
                src={Globe}
                className={styles.timezoneGlobe}
                alt="Globe timzone indicator"
              />
              <p className={styles.timezoneText}>
                Located in {profile.timezone}
              </p>
            </div>
            <p className={styles.mentorBio}>{profile.bio}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ProfileContent;
