import React from "react";
import classNames from "classnames/bind";
import { NavType } from "../constants";
import { LogoutIcon, LogoutIconActive } from "components/Icons";
import MenuItem from "./MobileMenuItem";

import styles from "./MobileMenu.module.css";
import { IMenuItem } from "../types";
import Button from "components/Atoms/Button";

const MobileSideMenu = ({
  path,
  menu,
  open,
  toggleMobileMenu,
  navigateTo,
  promptLogout,
}: {
  path: string;
  menu: IMenuItem[];
  open: boolean;
  navigateTo: (path: string) => void;
  toggleMobileMenu: () => void;
  promptLogout: () => void;
}) => {
  const cx = classNames.bind(styles);

  const mobileMenuStyles = cx({
    menu: true,
    open: open,
  });

  return (
    <div data-testid="SideMenu_mobile" className={mobileMenuStyles}>
      <div>
        {menu.map((item) => (
          <MenuItem
            key={item.id}
            {...item}
            active={item.id === path}
            onClick={() => navigateTo(item.id)}
          />
        ))}
      </div>
      <Button className={styles.logout} onClick={promptLogout}>
        Logout
      </Button>
    </div>
  );
};

export default MobileSideMenu;
