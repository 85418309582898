import { useLocation } from "react-router-dom";
import styles from "../BookSession.module.css";
import VerticalNavigation, {
  RouteSchema,
} from "../components/BookingVerticalNavigation/BookingVerticalNavigation";
import Header from "components/Organisms/Header";
import MentorContent from "../components/MentorContent";
import DurationSelection from "../components/DurationSelection";
import BookingCalendar from "../components/BookingCalendar";
import SessionSelector from "../components/SessionSelector";
import { ProfileQueryResult } from "entities/Profile";
import Agenda from "../components/Agenda";
import { ObjectiveService } from "../../Objectives/service/useObjectivesService";
import RightSidebar from "components/Atoms/RightSidebar";
import Objective from "components/Organisms/Objective/Objective.component";
import ProposedTimes from "../components/ProposedTimes";

interface BookSessionComponentProps {
  mentor?: ProfileQueryResult;
  mentor_slug: string;
  selected_iso_date?: string;
  request_submitting: boolean;
  availability_is_loading: boolean;
  objective_service: ObjectiveService;
}

const BookSessionComponent = ({
  mentor,
  mentor_slug,
  selected_iso_date,
  availability_is_loading,
  objective_service,
}: BookSessionComponentProps) => {
  const { hash } = useLocation();

  const Titles = {
    mentor: "Mentor",
    agenda: "Agenda",
    duration: "Duration",
    date: "Date",
    time: "Time",
  };

  const ROUTES: RouteSchema[] = [
    {
      id: "mentor",
      title: Titles.mentor,
      url: "#mentor",
    },
    {
      id: "agenda",
      title: Titles.agenda,
      url: "#agenda",
    },
    {
      id: "duration",
      title: Titles.duration,
      url: "#duration",
    },
    {
      id: "booking-date",
      title: Titles.date,
      url: "#booking-date",
    },
    {
      id: "booking-time",
      title: Titles.time,
      url: "#booking-time",
    },
  ];

  const { open, close } = objective_service;

  const openCreateObjective = () => {
    objective_service.create();
  };

  return (
    <>
      <Header>
        <h3>Book a Session</h3>
      </Header>
      <div className={styles.account_content_container}>
        <VerticalNavigation routes={ROUTES} selected_tab={hash} />
        <div className={styles.booking_container}>
          <div
            className="component-tab"
            key={ROUTES[0].id}
            id="mentor"
            style={{ marginTop: "0px" }}
          >
            <MentorContent mentor={mentor} is_loading={!mentor} />
          </div>
          <div className="component-tab" key={ROUTES[1].id} id="objective">
            <Agenda openCreateObjective={openCreateObjective} />
          </div>
          <div className="component-tab" key={ROUTES[2].id} id="duration">
            <DurationSelection mentor_slug={mentor_slug} />
          </div>
          <div className="component-tab" key={ROUTES[3].id} id="booking-date">
            <BookingCalendar />
          </div>
          <div
            className="component-tab"
            key={ROUTES[4].id}
            id="booking-time"
          >
            {selected_iso_date && !availability_is_loading && (
              <SessionSelector />
            )}
          </div>
        </div>
        <ProposedTimes />
      </div>
      <RightSidebar open={open} close={close}>
        <Objective {...objective_service} />
      </RightSidebar>
    </>
  );
};

export default BookSessionComponent;
