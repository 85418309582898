import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OKRQueryResult, ObjectiveUpdate } from "entities/OKR";
import { API_URL, createHeaders } from "utils/api";
import { useAlert } from "@blaumaus/react-alert";
import { ALL_OBJECTIVES_QUERY_KEY } from "./useObjectives";

const updateObjective = async (token: string, objective_id: string, data: ObjectiveUpdate): Promise<OKRQueryResult> => {
    const response = await fetch(`${API_URL}/okr/${objective_id}/update`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: createHeaders(token)
    });
    return await response.json();
};

export const useUpdateObjective = ({ onSuccess }: { onSuccess: (objective_id: string) => void }) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();
    const alert = useAlert();

    return useMutation({
        mutationFn: async ({ objective_id, data }: { objective_id: string, data: ObjectiveUpdate }): Promise<OKRQueryResult> => {
            const token = await getAccessTokenSilently()
            return await updateObjective(token, objective_id, data);
        },
        onSuccess: (objective, { objective_id }) => {
            queryClient.setQueryData<OKRQueryResult>(["okr", objective_id], () => objective);
            queryClient.invalidateQueries([ALL_OBJECTIVES_QUERY_KEY]);
            queryClient.invalidateQueries(["okr", objective_id]);
            alert.success("Objective updated successfully!");
            onSuccess(objective_id);
        },
        onError: (error) => {
            console.error(error);
            alert.error("Error updating objective.");
        }
    });
};
