import { Business } from "entities/Business";
import { createHeaders, API_URL } from "../../utils/api";

interface FetchBusinessResponse {
  business: Business;
}

function isBusinessResponse(value: unknown): value is FetchBusinessResponse {
  if (typeof value !== "object" || !value || !("has_stripe" in value)) {
    return false;
  }
  return true;
}

export const fetchBusiness = async (
  token: string
): Promise<FetchBusinessResponse> => {
  const response = await fetch(`${API_URL}/business/mine`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  });

  const data: unknown = await response.json();

  if (!isBusinessResponse(data)) {
    throw Error("Failed to fetch business");
  }
  return data;
};

export const updateBusiness = async (
  token: string,
  update: Partial<Business>
): Promise<{
  data?: FetchBusinessResponse;
  error?: string;
}> => {
  try {
    const response = await fetch(`${API_URL}/business/update`, {
      method: "POST",
      mode: "cors",
      headers: createHeaders(token),
      body: JSON.stringify(update),
    });
    if (response.status === 500) {
      throw Error("Failed to update business");
    } else {
      const data = await response.json();
      return {
        data,
      };
    }
  } catch (error) {
    return {
      error: String(error),
    };
  }
};
