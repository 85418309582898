import { BulletsInCircle } from "components/Icons";

interface IContentListProps {
  title: string;
  body: string;
  url?: string;
}

const ContentList: React.FC<IContentListProps> = ({ title, body, url }) => {
  const handleOpenNewTab = () => {
    if (!url) return;

    if (url.startsWith("http")) {
      return window.open(url, "_blank");
    }

    return window.open(`https://${url}`, "_blank");
  };

  return (
    <li className="about-content-item" onClick={handleOpenNewTab}>
      <div>
        {url ? (
          <div className="title-letter">
            <p>{title.slice(0, 1).toUpperCase()}</p>
          </div>
        ) : (
          <img src={BulletsInCircle} alt="blue-star" />
        )}
      </div>
      <div>
        <h5>{title}</h5>
        <p>{body}</p>
      </div>
    </li>
  );
};

export default ContentList;
