import { useState } from "react";
import styles from "./ToggleSwitch.module.css";

interface ToggleSwitchProps {
  onToggle: () => void;
  is_toggled: boolean;
}

function ToggleSwitch({ onToggle, is_toggled }: ToggleSwitchProps) {

  return (
    <label data-testid="ToggleSwitch" className={styles.toggle_switch}>
      <input
        data-testid="ToggleSwitch_input"
        type="checkbox"
        checked={is_toggled}
        onChange={onToggle}
      />
      <span className={styles.switch} />
    </label>
  );
}
export default ToggleSwitch;
