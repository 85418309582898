import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";

interface TeamMember {
    name: string;
    email: string;
    profile_id: string;
    avatar_image: string;
    stats: {
      title: string;
      value: number;
    }[];
  }
const useTeamMembers = () => {
  const [team, setTeam] = useState<TeamMember[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [refetch_id, setRefetchId] = useState<number>(0);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const { getAccessTokenSilently } = useAuth0();

  const refetch = () => setRefetchId(Math.random());

  useEffect(() => {
    const getTeamMembers = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${API_URL}/team/members`,
          {
            headers: createHeaders(token),
          }
        );
        const data = await response.json();
        setTeam(data);
      } catch (error) {
        setError(String(error));
      } finally {
        setIsLoading(false);
      }
    };
    getTeamMembers();
  }, [refetch_id]);

  return { team, is_loading, refetch, error };
};

export default useTeamMembers;
