import CalendarDay, { CalednarDayInfo } from "components/Atoms/CalendarDay";

import styles from "./CalendarWeek.module.css";

interface CalendarWeekProps {
  week: CalednarDayInfo[];
  selected_iso_date: string;
  mentee_request_slots: string[];
  onSelect: (date: string) => void;
}

const CalendarWeek: React.FC<CalendarWeekProps> = ({
  week,
  selected_iso_date,
  mentee_request_slots,
  onSelect,
}) => {
  return (
    <div data-testid="CalendarWeek" className={styles.calendarWeekRow}>
      {week &&
        week.map((day, index) => (
          <CalendarDay
            key={`day-${index}`}
            selected_iso_date={selected_iso_date}
            day={day}
            mentee_request_slots={mentee_request_slots}
            onSelect={onSelect}
          />
        ))}
    </div>
  );
};

export default CalendarWeek;
