import React, { useState } from "react";
import styles from "./OnboardingPrompt.module.css";
import IconButton from "components/Atoms/IconButton/IconButton.component";
import { CrossIcon } from "components/Icons";

export interface OnboardingPromptProps {
  onClick: () => void;
}

const OnboardingPrompt = ({ onClick }: OnboardingPromptProps) => {
  return (
    <div
      data-testid="onboarding_prompt"
      className={styles.container}
      onClick={onClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <g filter="url(#filter0_d_8186_1135)">
          <circle
            cx="20"
            cy="20"
            r="19"
            fill="#5440F0"
            stroke="#5440F0"
            strokeWidth="2"
          />
          <circle opacity="0.3" cx="20" cy="20" r="17" stroke="white" />
        </g>
      </svg>
      <div>
        <h2 className={styles.title}>Complete Account Setup</h2>
        <p className={styles.subheading}>Get set up for success today.</p>
      </div>
    </div>
  );
};

export default OnboardingPrompt;
