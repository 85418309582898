const GET_MESSENGER_TOKEN_START = "GET_MESSENGER_TOKEN_START";
const GET_MESSENGER_TOKEN_SUCCESS = "GET_MESSENGER_TOKEN_SUCCESS";
const GET_MESSENGER_TOKEN_ERROR = "GET_MESSENGER_TOKEN_ERROR";

const initialState = {
	isLoading: false,
	error: null,
	token: null,
};

const chatReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_MESSENGER_TOKEN_START:
			return {
				...state,
				isLoading: true,
			};
		case GET_MESSENGER_TOKEN_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
				token: action.payload,
			};
		case GET_MESSENGER_TOKEN_ERROR:
			return {
				...state,
				isLoading: false,
				error:
					action.payload && action.payload.message
						? action.payload.message
						: action.payload,
			};
		default:
			return state;
	}
};

export default chatReducer;
