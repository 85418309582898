import classNames from "classnames";

import RoundTick from "../../Atoms/RoundTick";

import styles from "./OboardingStep.module.css";

export interface OboardingStepProps {
  id: string;
  title: string;
  is_complete: boolean;
  is_current_step: boolean;
  onClick: () => void;
}

const classes = classNames.bind(styles);

const OboardingStep: React.FC<OboardingStepProps> = ({
  id,
  title,
  is_complete,
  is_current_step,
  onClick,
}) => {
  const active_classes = classes({
    [styles.container]: true,
    [styles.current_step]: is_current_step,
  });

  return (
    <div
      data-testid="oboarding_step"
      className={active_classes}
      onClick={onClick}
    >
      <div className={styles.check}>
        {is_current_step ? (
          <div className={styles.active_check}>
            <div />
          </div>
        ) : (
          <RoundTick id={id} checked={is_complete} />
        )}
      </div>
      <p>{title}</p>
    </div>
  );
};

export default OboardingStep;
