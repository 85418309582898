const MENTOR_RATES = [
  {
    title: "$0",
    tokens: 2,
    value: 2000,
  },
  {
    title: "$50",
    tokens: 6,
    value: 6000,
  },
  {
    title: "$100",
    tokens: 12,
    value: 12000,
  },
  {
    title: "$150",
    tokens: 18,
    value: 18000,
  },
  {
    title: "$200",
    tokens: 24,
    value: 24000,
  },
  {
    title: "$250",
    tokens: 30,
    value: 30000,
  },
  {
    title: "$300",
    tokens: 36,
    value: 36000,
  },
  {
    title: "$350",
    tokens: 42,
    value: 42000,
  },
  {
    title: "$400",
    tokens: 48,
    value: 48000,
  },
  {
    title: "$450",
    tokens: 54,
    value: 54000,
  },
  {
    title: "$500",
    tokens: 60,
    value: 60000,
  },
  {
    title: "$750",
    tokens: 90,
    value: 90000,
  },
  {
    title: "$1000",
    tokens: "120",
    value: 120000,
  },
];

export default MENTOR_RATES;
