import { ChangeEvent, FocusEvent } from "react";
import styles from "./TextInput.module.css";
import { useState } from "react";
import ToolTip from "../ToolTip";
import classNames from "classnames";

export enum TextInputType {
  TEXT = "text",
  EMAIL = "email",
  PASSWORD = "password",
  NUMBER = "number",
  URL = "url",
  TEL = "tel",
}

export enum InputStyleVariant {
  ON_BLACK = "on_black",
  ON_WHITE = "on_white",
  ON_GREY = "on_grey",
}

interface TextInputProps {
  name: string;
  label: string;
  type: TextInputType;
  value?: string | number;
  error: string | undefined;
  tooltip?: string;
  placeholder?: string;
  handleChange: (value: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (value: FocusEvent<HTMLInputElement>) => void;
  variant: InputStyleVariant;
}

const cx = classNames.bind(styles);

const TextInput = ({
  name,
  label,
  type,
  value,
  error,
  tooltip,
  placeholder,
  handleChange,
  handleBlur,
  variant,
}: TextInputProps) => {
  const classes = cx({
    container: true,
    [InputStyleVariant.ON_BLACK]: variant === InputStyleVariant.ON_BLACK,
    [InputStyleVariant.ON_WHITE]: variant === InputStyleVariant.ON_WHITE,
    [InputStyleVariant.ON_GREY]: variant === InputStyleVariant.ON_GREY,
  });

  const [hide_error, setHideError] = useState<boolean>(true);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setHideError(true);
  };

  const onBlur = (event: FocusEvent<HTMLInputElement, Element>) => {
    handleBlur && handleBlur(event);
    setHideError(false);
  };

  const show_error = !hide_error && !!error;

  const label_classes = show_error ? styles.error_label : undefined;
  const input_classes = show_error ? styles.error_input : undefined;

  return (
    <div id={`text_input_${name}`} className={styles.classes}>
      <label htmlFor={name} className={label_classes}>
        {label}
        {tooltip && (
          <ToolTip id={`${name}-tooltip`} title={label} help={tooltip} />
        )}
      </label>
      <input
        aria-label={`${name}-text-input`}
        className={input_classes}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
      />
      {show_error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default TextInput;
