import { caseCreatorHOC } from "../helpers";
import MentorActions from "./mentor.actions";

interface MentorCommand {
	type: MentorActions;
	payload?: any;
	error?: string;
}

const initialState = {
	error: null,
	mentors: [],
	is_loading: false,
};

const mentorReducer = (state = initialState, action: MentorCommand) => {
	switch (action.type) {
		case MentorActions.GET_ALL_START: {
			return {
				...state,
				error: null,
				is_loading: true,
			};
		}
		case MentorActions.GET_ALL_SUCCESS: {
			return {
				...state,
				error: null,
				is_loading: false,
				mentors: action.payload,
			};
		}
		case MentorActions.GET_ALL_ERROR: {
			return {
				...state,
				error: action.payload,
				is_loading: false,
			};
		}
		case MentorActions.FETCH_MENTOR_BY_SLUG_START: {
			return {
				...state,
				error: null,
				is_loading: true,
			};
		}
		case MentorActions.FETCH_MENTOR_BY_SLUG_SUCCESS: {
			return {
				...state,
				error: null,
				is_loading: false,
				mentors: [
					...state.mentors.filter(
						(mentor: any) => `${mentor._id}` !== `${action.payload._id}`
					),
					action.payload,
				],
			};
		}
		case MentorActions.FETCH_MENTOR_BY_SLUG_ERROR: {
			return {
				...state,
				error: action.error,
				is_loading: false,
			};
		}
		default:
			return state;
	}
};

export default mentorReducer;
