import { PROFILE_TYPE, ProfileQueryResult } from "entities/Profile";
import { PreferenceStorage } from "./Preference.storage";

interface ProfilePreferenceArgs {
  PreferenceStorage: PreferenceStorage;
  getProfiles: (token: string) => Promise<ProfileQueryResult[]>;
}

class ProfilePreference {
  private ProfilePreferenceStorage: PreferenceStorage;
  private getProfiles: (token: string) => Promise<ProfileQueryResult[]>;

  constructor({ PreferenceStorage, getProfiles }: ProfilePreferenceArgs) {
    this.ProfilePreferenceStorage = PreferenceStorage;
    this.getProfiles = getProfiles;
  }

  async get(token: string): Promise<PROFILE_TYPE> {
    const preference = this.ProfilePreferenceStorage.getPreference();
    if (preference) {
      return preference;
    }
    const team_invite = this.ProfilePreferenceStorage.getTeamInvite();
    if (team_invite) {
      return PROFILE_TYPE.MENTEE;
    }

    const profiles = await this.getProfiles(token);

    if (profiles.length >= 1) {
      const has_mentor_profile = !!profiles.find(
        (profile) => profile.type === "mentor"
      );
        return has_mentor_profile ? PROFILE_TYPE.MENTOR : profiles[0].type;
    }
    // given there are no profile and no preference, set the preference to mentee
    return PROFILE_TYPE.MENTEE;
  }
}

export default ProfilePreference;
