import { Link, useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

import useFavourites from "hooks/mentee/useFavourites";
import { DollarIcon, SuitcaseIcon } from "../../Icons";

import styles from "./MentorProfileCard.module.css";

export interface MentorProfileCardData {
  collections: string[];
  _id: string;
  given_name: string;
  family_name: string;
  headline: string;
  pitch: string;
  bio: string;
  profile_image: string;
  session_cost: number;
  slug: string;
}

interface MentorProfileCardProps {
  mentor: MentorProfileCardData;
}

const truncateString = (str: string, num: number): string => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

const MentorProfileCard: React.FC<MentorProfileCardProps> = ({ mentor }) => {
  const navigate = useNavigate();

  const toProfile = () => navigate(`/profile/${mentor.slug}`);

  const {
    favourites,
    toggle,
    is_loading: loading_set_favourite,
    ready,
  } = useFavourites();

  const is_favourite = ready && favourites.includes(mentor._id);

  const toggleFavourite = () => toggle(mentor._id);

  const full_name = `${mentor.given_name} ${mentor.family_name}`;

  return (
    <div
      data-testid="MentorProfileCard"
      className={styles.container}
      style={{
        background: `url("${mentor.profile_image}") center center / cover no-repeat`,
      }}
      id={mentor._id}
    >
      <div className={styles.mentorCardContainer}>
        <div className={styles.mentorContentContainer}>
          <h4 style={{ fontSize: "15px" }}>{full_name}</h4>
          <div className={styles.infoContainer}>
            <p className={styles.infoText}>
              <img src={SuitcaseIcon} className={styles.infoIcon} />
              {/* {truncateString(mentor.headline, full_name.length >= 14 ? 25 : 54)} */}
              {mentor.headline}
            </p>
          </div>
          <div className={styles.infoContainer}>
            <img
              src={DollarIcon}
              className={styles.infoIcon}
              style={{ top: "0px" }}
            />
            <p className={styles.infoText}>
              {mentor.session_cost} credits / session
            </p>
          </div>
          <div style={{ marginTop: "14px" }}>
            <p className={styles.bioText}>{truncateString(mentor.bio, 260)}</p>
          </div>
          <div className={styles.btnContainer}>
            <Link
              style={{ top: "0px", left: "0px", textAlign: "left" }}
              className={styles.btnClearSm}
              to={`/profile/${mentor.slug}`}
            >
              {" "}
              View Profile
            </Link>
            {/* <button
              className={styles.btnClearSm}
              style={{ top: "0px", left: "0px", textAlign: "left" }}
              onClick={toProfile}
            >
              View Profile
            </button> */}
            <button
              className={styles.btnClearSm}
              style={{ top: "0px", right: "0px", textAlign: "right" }}
              onClick={toggleFavourite}
            >
              {loading_set_favourite ? (
                <ThreeDots color="#FFF" height={24} width={32} />
              ) : is_favourite ? (
                "Unfollow"
              ) : (
                "Follow"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentorProfileCard;
