import { useState } from "react";
import styles from "./Community.module.css";
import Loading from "components/Molecules/Loading";
import { ThreeDots } from "react-loader-spinner";

//mentorpass-community.circle.so

const CommunityScreen = () => {
    const community_id = "07e53462";
    const widget_address = `https://community.mentorpass.co/widget/iframe/${community_id}?automatic_login=true`;
    const [is_loading, setLoading] = useState<boolean>(true);

    const handleIsReady = () => {
        setTimeout(() => {
            setLoading(false);
        }, 800);
    }

    return (
        <div className={styles.container}>
            {is_loading &&
                <div className={styles.loading_container}>
                    <h3>Loading your community...</h3>
                    <ThreeDots
                        wrapperStyle={{ margin: "auto", display: "block" }}
                        color="#fff"
                        height={20}
                        width={42}
                    />
                </div>}
            <iframe className={is_loading ? styles.community_iframe_hidden : styles.community_iframe} src={widget_address} onLoad={handleIsReady} />
        </div>
    );
};

export default CommunityScreen;

// .dark .trix-v2 .trix-content { 
//   background-color: #1a1c1d !important;
//  }

