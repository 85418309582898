import { SET_ALERT, CLEAR_ALERT } from "../actions/alter";

const initialState = {
  message: null,
  alert: null,
  type: null, // error, alert, info
  time: null,
};

const businessReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ALERT:
      return {
        ...state,
        message: action.payload.message,
        type: action.payload.type || "info",
        time: Date.now(),
      };
    case CLEAR_ALERT:
      return {
        ...state,
        message: null,
        type: null,
      };
    default:
      return state;
  }
};

export default businessReducer;
