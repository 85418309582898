import { useQuery } from '@tanstack/react-query';
import { API_URL, createHeaders } from 'utils/api';
import { useAuth0 } from '@auth0/auth0-react';

const fetchEarningsData = async (token: string, period: number): Promise<{ earnings: number }> => {
    const response = await fetch(`${API_URL}/stats/mentor/earnings?period=${period}`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
    });

    if (response.status !== 200) {
        const error = await response.json();
        throw new Error(error);
    }

    return await response.json();
};

const useEarningsData = (period: number) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<{ earnings: number }>({
        queryKey: ["mentor_earnings", period],
        queryFn: async () => getAccessTokenSilently().then(token => fetchEarningsData(token, period)),
    });
};

export default useEarningsData;
