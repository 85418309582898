import React, { useRef, useState } from "react";
import styles from "./OnePageCheckoutWelcome.module.css";
import Button from "components/Atoms/Button";
import VideoPlaceholder from "./VideoPlaceholder.png";

export interface OnePageCheckoutWelcomeProps {
  dismiss: () => void;
}

const OnePageCheckoutWelcome: React.FC<OnePageCheckoutWelcomeProps> = ({
  dismiss,
}) => {
  const [has_played, setHasPlayed] = useState<boolean>(false);

  const handlePlayButtonClick = () => {
    setHasPlayed(true);
  };

  return (
    <div
      className={styles.modalBackground}
      data-testid="one_page_checkout_welcome"
    >
      <div className={styles.modalContainer}>
        <div className={styles.videoContainer}>
          {!has_played ? (
            <>
              <button
                data-testid="play_button"
                className={styles.play_button}
                onClick={handlePlayButtonClick}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                >
                  <path
                    d="M16.0003 29.3334C8.63653 29.3334 2.66699 23.3638 2.66699 16.0001C2.66699 8.63628 8.63653 2.66675 16.0003 2.66675C23.3641 2.66675 29.3337 8.63628 29.3337 16.0001C29.3337 23.3638 23.3641 29.3334 16.0003 29.3334ZM14.1629 11.2195C14.0753 11.1611 13.9723 11.13 13.867 11.13C13.5725 11.13 13.3337 11.3687 13.3337 11.6633V20.3369C13.3337 20.4422 13.3649 20.5451 13.4233 20.6327C13.5866 20.8778 13.9178 20.9441 14.1629 20.7806L20.6681 16.4438C20.7266 16.4047 20.7769 16.3545 20.8159 16.2959C20.9794 16.0509 20.9131 15.7197 20.6681 15.5563L14.1629 11.2195Z"
                    fill="white"
                  />
                </svg>
                <span>Product tour</span>
              </button>
              <img
                src={VideoPlaceholder}
                alt="Video Placeholder"
                className={styles.videoPlaceholder}
              />
            </>
          ) : (
            <video
              data-testid="video"
              src={
                "https://storage.googleapis.com/marketing-assets.mentorpass.co/app-assets/MentorPass%20Onboarding%20Demo.mp4"
              }
              className={styles.video}
              controls
              autoPlay
            />
          )}
        </div>
        <div className={styles.content}>
          <h2>Welcome to MentorPass!</h2>
          <p>Congratulations on investing in your growth.</p>
        </div>
        <button
          data-testid="dismiss"
          className={styles.dismissButton}
          onClick={dismiss}
        >
          <span>Explore dashboard</span>
        </button>
      </div>
    </div>
  );
};

export default OnePageCheckoutWelcome;
