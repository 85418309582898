import React, { ChangeEvent, FocusEvent, KeyboardEventHandler } from "react";
import { useState } from "react";

import ToolTip from "../ToolTip";
import styles from "./TextInput.module.css";

interface TextInputProps {
  name: string;
  label: string;
  type: "text" | "email" | "password" | "number" | "url" | "tel";
  value?: string | number;
  error: string | undefined;
  tooltip?: string;
  placeholder?: string;
  handleChange: (value: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (value: FocusEvent<HTMLInputElement>) => void;
  handleKeyDown?: KeyboardEventHandler<HTMLInputElement>
  dark: boolean;
}

const TextInput: React.FC<TextInputProps> = ({
  name,
  label,
  type,
  value,
  error,
  tooltip,
  placeholder,
  handleChange,
  handleBlur,
  handleKeyDown,
  dark,
}) => {
  const [hide_error, setHideError] = useState<boolean>(true);

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleChange(event);
    setHideError(true);
  };

  const onBlur = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    handleBlur && handleBlur(event);
    setHideError(false);
  };

  const show_error = !hide_error && !!error;

  const label_classes = show_error ? styles.error_label : dark ? "black" : "";
  const input_classes = show_error ? styles.error_input : dark ? "black" : "";
  return (
    <div
      data-testid="TextInput"
      id={`text_input_${name}`}
      className={styles.container}
    >
      <label htmlFor={name} className={label_classes}>
        {label}
        {tooltip && (
          <ToolTip id={`${name}-tooltip`} title={label} help={tooltip} />
        )}
      </label>
      <input
        id={name}
        aria-label={`${name}-text-input`}
        className={input_classes}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        onBlur={onBlur}
        onKeyDown={handleKeyDown}
        value={value}
      />
      {show_error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default TextInput;
