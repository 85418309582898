import { useContext, useEffect, useState } from "react";
import SideMenuComponent from "./DesktopMenu/SideMenu.component";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { useActor } from "@xstate/react";
import { MENUS } from "./constants";
import { IMenuItem } from "./types";
import { MenuContext } from "./MobileMenu.machine";
import { useLocation } from "react-router-dom";
import MobileSideMenu from "./MobileMenu/MobileMenu.component";
import { ModalNames, useModal } from "../modals/ModalProvider";
import { ProfileContext } from "services/Profile/Profile.schema";

const SideMenu = () => {
  const globalServices = useContext(GlobalServiceContext);
  const [profile_state] = useActor(globalServices.profile_service);
  const menuService = useContext(MenuContext);
  const [menu_state, sendToMenu] = useActor(menuService.menu_service);
  const [menu, setMenu] = useState<IMenuItem[]>([]);

  const filterMenus = (
    menu: IMenuItem[],
    context: ProfileContext
  ): IMenuItem[] => {
    return menu.filter((item) => {
      // if the user dosen't have access to community filter out the option.
      return (
        item.id != "community" || context.user_settings.has_community_access
      );
    });
  };

  useEffect(() => {
    switch (true) {
      case profile_state.matches("mentee.team_member.ready"):
        setMenu(
          filterMenus(MENUS["mentee_team_member"], profile_state.context)
        );
        break;
      case profile_state.matches("mentee.owner.ready"):
        setMenu(filterMenus(MENUS["mentee_admin"], profile_state.context));
        break;
      case profile_state.matches("mentor.ready"):
        setMenu(filterMenus(MENUS["mentor"], profile_state.context));
        break;
      case profile_state.matches("admin.ready"):
        setMenu(MENUS["admin"]);
        break;
      default:
        setMenu([]);
    }
  }, [profile_state]);

  const navigateTo = (location: string) => {
    sendToMenu({
      type: "NAVIGATE",
      data: {
        path: location,
      },
    });
  };

  const toggleMobileMenu = () => sendToMenu("TOGGLE");

  const { openModal } = useModal();

  const promptLogout = () => openModal({ name: ModalNames.LOGOUT });

  const location = useLocation();
  const [path, setPath] = useState("");

  useEffect(() => {
    setPath(location.pathname.slice(1));
  }, [location.pathname]);

  return (
    <>
      <SideMenuComponent
        path={path}
        menu={menu}
        navigateTo={navigateTo}
        promptLogout={promptLogout}
      />
      <MobileSideMenu
        path={path}
        menu={menu}
        open={menu_state.matches("open")}
        navigateTo={navigateTo}
        promptLogout={promptLogout}
        toggleMobileMenu={toggleMobileMenu}
      />
    </>
  );
};

export default SideMenu;
