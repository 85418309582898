import { useEffect } from "react";
import {
  AVAILABILITY_MODAL,
  CLAIM_PENDING_SESSION_SUCCESS_MODAL,
  EDIT_HIGHLIGHT_MODAL,
  FULL_TEXT_MODAL,
  TEXT_MODAL,
  UPDATE_TIMEZONE_ALERT_MODAL,
  useModal,
} from "./ModalProvider";
import { useLocation, useSearchParams } from "react-router-dom";
import { ModalNames } from "./ModalProvider";
import AddContent from "./AddContent";
import AddHighlightModal from "./AddHighlight";
import AddSkillComponent from "./AddSkill";
import SetHours from "./SetHours";
import EditHighlightModal from "./EditHighlight";
import Logout from "./Logout";
import OnePageCheckoutWelcome from "components/Organisms/modals/OnePageCheckoutWelcome/OnePageCheckoutWelcome";
import MentorSuccessModal from "./MentorSuccessModal";
import ClaimPendingSessionSuccess from "./ClaimPendingSessionSuccess";
import FullText from "./FullText";
import UpdateTimezoneAlert from "./UpdateTimezoneAlert";
import { useAuth0 } from "@auth0/auth0-react";

const ModalContainer = () => {
  const location = useLocation();
  const [search_params, setSearchParams] = useSearchParams();
  const { state, openModal, closeModal } = useModal();
  const { user } = useAuth0();

  useEffect(() => {
    const params = Object.fromEntries(search_params.entries());

    if (params["modal"] === "claim_session_success") {
      openModal({
        name: ModalNames.CLAIM_PENDING_SESSION_SUCCESS,
        data: {
          pending_session_id: params["session_id"],
        },
      });
    }
    if (params["modal"] === "one_page_onboarding_complete") {
      openModal({
        name: ModalNames.ONE_PAGE_CHECKOUT,
      });
    }

  }, [location, search_params]);

  if (!state?.modal) {
    return null;
  }

  switch (state.modal?.name) {
    case ModalNames.ADD_CONTENT:
      return <AddContent closeModal={closeModal} />;
    case ModalNames.ADD_HIGHLIGHT:
      return <AddHighlightModal closeModal={closeModal} />;
    case ModalNames.ADD_SKILL:
      return <AddSkillComponent closeModal={closeModal} />;
    case ModalNames.EDIT_AVAILABILITY:
      let availability_state = state.modal as AVAILABILITY_MODAL;
      return <SetHours closeModal={closeModal} day={availability_state.data} />;
    case ModalNames.EDIT_CONTENT:
      return <AddContent closeModal={closeModal} />;
    case ModalNames.EDIT_HIGHLIGHT:
      const edit_highlight_state = state.modal as EDIT_HIGHLIGHT_MODAL;
      return (
        <EditHighlightModal
          previous_highlight={edit_highlight_state.data}
          closeModal={closeModal}
        />
      );
    case ModalNames.LOGOUT:
      return <Logout closeModal={closeModal} />;
    case ModalNames.ONE_PAGE_CHECKOUT:
      return <OnePageCheckoutWelcome dismiss={closeModal} />;
    case ModalNames.WELCOME_MENTOR:
      return (
        <MentorSuccessModal
          user_data={{
            given_name: user?.given_name || "",
          }}
        />
      );
    case ModalNames.TEXT_MODAL:
      let text_state = state.modal as TEXT_MODAL;
      return <FullText closeModal={closeModal} text={text_state.data} />;
    case ModalNames.UPDATE_TIMEZONE_ALERT:
      const update_timezone_state = state.modal as UPDATE_TIMEZONE_ALERT_MODAL;
      return (
        <UpdateTimezoneAlert
          data={update_timezone_state.data}
          closeModal={closeModal}
        />
      );
    case ModalNames.CLAIM_PENDING_SESSION_SUCCESS:
      const claim_pending_session_success_state =
        state.modal as CLAIM_PENDING_SESSION_SUCCESS_MODAL;
      return (
        <ClaimPendingSessionSuccess
          session_id={
            claim_pending_session_success_state.data.pending_session_id
          }
          closeModal={closeModal}
        />
      );
    case ModalNames.FULL_TEXT:
      const full_text = state.modal as FULL_TEXT_MODAL;
      return (<FullText text={full_text.data} closeModal={closeModal} />)
    default:
      closeModal();
      return null;
  }
};

export default ModalContainer;
