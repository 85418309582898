import LargeDropdown from "components/Atoms/LargeDropdown";

import styles from "./FAQ.module.css";

const FAQS: React.ComponentProps<typeof LargeDropdown>[] = [
  {
    title: "What is a MentorPass credit?",
    content:
      "Credits are used to book calls with mentors. Different mentors have different rates. Some cost one credit per session, others cost 50 credits for a session. Credits cost between $10 and $12, depending on which plan you’re on.",
  },
  {
    title: "What’s your cancellation policy?",
    content:
      "Cancel any time before the end of your billing cycle. We make it super easy to cancel and pause your account on the platform (account management portal).",
  },
  {
    title: "How much do the mentors cost?",
    content:
      "Our VIP mentors (Nik Sharma, Josh Snow, etc.) cost 31 - 50 credits per session. Our all star mentors (Eli Weiss, Erin Falter, etc.) cost 16 - 30 credits per session. Our team mentors cost 1 - 15 credits per session. Have specific questions? Send us a message! We’ll help you find the right mentor.",
  },
  {
    title: "Do the mentors get any context before the call?",
    content:
      "Yes, when you activate your subscription, the system will help you build a brand brief that is sent to your mentors before each call. That will include your social media profiles, company overview, mission, ideal customer persona, key challenges, objectives and key results, attachments (pitch deck), and more. ",
  },
  {
    title: "How long is a session?",
    content:
      "Standard sessions are 30-minutes long. Extended sessions are 60-minutes in duration and are offered by 90% of our mentors.",
  },
  {
    title: "Where do the sessions happen?",
    content:
      "We have integrated our own video conferencing software. You’ll receive an invite on your calendar with a link when your call is accepted. ",
  },
  {
    title: "What happens if my mentor can’t meet with me?",
    content:
      "Your credits will be automatically refunded if your mentor doesn’t respond within two business days. 80% of calls are accepted quickly, so that shouldn’t be a big issue. ",
  },
  {
    title: "How do you vet your mentors?",
    content:
      "We’ve had over 6,000 applications and accepted less than 1%. Most of our mentors come through a trusted referral process - from one of our top mentors or mentees. All of our mentors have demonstrated top-tier knowledge and practice of their domain, passed an onboarding interview, and are continuously assessed to ensure they’re delivering on mentee expectations. ",
  },
  {
    title: "How does the booking process work?",
    content:
      "You submit a booking request. Your mentor will get that by email. They can accept, decline, or request to reschedule the call. Once the call is accepted, you’ll receive a calendar invitation with a video conferencing link. ",
  },
  {
    title: "Is MentorPass a new company?",
    content:
      "We launched in October of 2020 and have mostly bootstrapped the business. As of January 2022, we are a team of three. ",
  },
];

const FAQ = () => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <label className={styles.tagline}>
          FREQUENTLY <span>ASKED</span>
        </label>
        <h2>Questions, answered.</h2>
        <p>
          Whether you’re just starting or you’ve just raised your first round,
          we have a mentor that will become your competitive advantage.
        </p>
      </div>
      <div className={styles.wrapper}>
        {FAQS.map((faq) => (
          <div className={styles.faq_item}>
            <LargeDropdown {...faq} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
