import React, { ChangeEventHandler, useState } from "react";
import style from "../../SessionReview.module.css";
import TextArea from "components/Atoms/TextArea";

interface FourthStepProps {
  id: string;
  data: {
    to: {
      given_name: string;
    };
  };
  nextStep: () => void;
  updatePublicFeedback: (value: string) => void;
}

const FourthStep = ({
  data,
  nextStep,
  updatePublicFeedback,
}: FourthStepProps) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | undefined>();

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setError(undefined);
    setValue(e.target.value);
  };

  const validateAndMove = () => {
    if (!value) {
      setError("Please add some feedback, otherwise click skip");
      return;
    }
    updatePublicFeedback(value);
  };

  return (
    <div className={style.fourthStep}>
      <p className={style.label}>Question 3/4</p>
      <p className={style.question}>
        Leave a public review for {data.to.given_name}
      </p>
      <p className={style.midCentered}>
        Help out the community by letting other mentees know what they can
        expect out of a sesssion with {data.to.given_name}
      </p>
      <div className={style.textAreaBox}>
        <TextArea
          label="FEEDBACK"
          name="feedback"
          placeholder="Type your answer here..."
          handleChange={onChange}
          value={value}
          error={error}
          style={{ marginBottom: "5px" }}
          dark
        />
      </div>
      <button onClick={validateAndMove}>Continue</button>
      <button className={style.skipBtn} onClick={nextStep}>
        Skip
      </button>
    </div>
  );
};

export default FourthStep;
