import { Business } from "entities/Business";

export interface BusinessContext {
  business: Business | undefined;
  pending_update: Partial<Business> | undefined;
}

export interface BusinessMachineStates {
  value: "ready" | "init" | "update" | "requires_purchase" | "loading";
  context: BusinessContext;
}

export enum BUSINESS_EVENT {
  USER_READY = "USER_READY",
  GOT_BUSINESS = "GOT_BUSINESS",
  GET_BUSINESS = "GET_BUSINESS",
  UPDATE_BUSINESS = "UPDATE_BUSINESS",
}

interface USER_READY_BUSINESS_EVENT {
  type: BUSINESS_EVENT.USER_READY;
}

interface GET_BUSINESS_EVENT {
  type: BUSINESS_EVENT.GET_BUSINESS;
}

export type GOT_BUSINESS_EVENT = {
  type: BUSINESS_EVENT.GOT_BUSINESS;
  data: {
    business: Business;
  };
};

export interface UPDATE_BUSINESS_EVENT {
  type: BUSINESS_EVENT.UPDATE_BUSINESS;
  data: {
    update: Partial<Business>;
  };
}

export type BusinessEvent =
  | USER_READY_BUSINESS_EVENT
  | GET_BUSINESS_EVENT
  | UPDATE_BUSINESS_EVENT
  | GOT_BUSINESS_EVENT;
