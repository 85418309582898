export enum MobileActionTypes {
  CLOSE_MOBILE_MENU = "CLOSE_MOBILE_MENU",
  OPEN_MOBILE_MENU = "OPEN_MOBILE_MENU",
  IS_MOBILE = "IS_MOBILE",
}

interface MobileState {
  is_mobile: boolean;
  menu_is_open: boolean;
}

const initial_state: MobileState = {
  is_mobile: false,
  menu_is_open: false,
};

interface MobileAction {
  type:
    | MobileActionTypes.CLOSE_MOBILE_MENU
    | MobileActionTypes.OPEN_MOBILE_MENU
    | MobileActionTypes.IS_MOBILE;
}

const mobileReducer = (
  state = initial_state,
  action: MobileAction
): MobileState => {
  switch (action.type) {
    case MobileActionTypes.CLOSE_MOBILE_MENU:
      return {
        ...state,
        menu_is_open: false,
      };
    case MobileActionTypes.OPEN_MOBILE_MENU:
      return {
        ...state,
        menu_is_open: true,
      };
    case MobileActionTypes.IS_MOBILE:
      return {
        ...state,
        is_mobile: true,
      };
    default:
      return state;
  }
};

export default mobileReducer;
