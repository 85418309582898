import { useAuth0 } from "@auth0/auth0-react";
import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { OKRQueryResult } from "entities/OKR";
import { useEffect } from "react";
import { API_URL, createHeaders } from "utils/api";

type StripeDashboardSigninURl = {
    url: string
}

type SpreadableQueryOptions = Partial<UseQueryOptions<StripeDashboardSigninURl, Error, StripeDashboardSigninURl, QueryKey>>;

const fetchStripeAccountDashboardLink = async (token: string): Promise<StripeDashboardSigninURl> => {
    const response = await fetch(`${API_URL}/mentor/stripe_dashboard`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
    });

    if (!response.ok) {
        const error = await response.json();
        throw new Error(error);
    }

    return await response.json();
}

const useStripeAccountDashboard = (options?: SpreadableQueryOptions) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data: stripe_dashboard, isFetched: stripe_link_ready, isFetching: is_loading, refetch: goToDashboard } = useQuery({
        queryKey: ['stripe_account_dashboard_link'],
        queryFn: async () => getAccessTokenSilently().then(token => fetchStripeAccountDashboardLink(token)),
        enabled: false,
        ...options,
    });

    useEffect(() => {
        if (!is_loading && stripe_dashboard?.url) {
            window.open(stripe_dashboard.url, "_blank")
        }
    }, [stripe_dashboard, stripe_link_ready])

    return { is_loading, goToDashboard };
}

export default useStripeAccountDashboard;
