import { useEffect, useRef, useState, memo } from "react";
import { CrossIcon, Trash } from "../../Icons";

import styles from "./TrashConfirmationButton.module.css";

interface ITrashConfirmationButtonProps {
  confirm: () => void;
}

const TrashConfirmationButton: React.FC<ITrashConfirmationButtonProps> = ({
  confirm,
}) => {
  const toggle_value = useRef<boolean>();
  const [show_confirmation, setShowConfirmation] = useState<boolean>(false);

  useEffect(() => {
    setShowConfirmation(toggle_value.current || false);
  }, []);

  const toggleConfirmation = () => {
    const new_value = !show_confirmation;
    toggle_value.current = new_value;
    setShowConfirmation(new_value);
  };

  return (
    <div className={styles.container}>
      <div
        className={styles.confirmation}
        style={{ display: show_confirmation ? "flex" : "none" }}
      >
        <span
          className={styles.button}
          data-testid="cancel-confirmation"
          onClick={toggleConfirmation}
        >
          <img src={CrossIcon} />
        </span>
        <div className={styles.divider} />
        <span
          className={styles.button}
          data-testid="execute-confirmation"
          onClick={confirm}
        >
          <div className={styles.checkmark} />
        </span>
      </div>
      <span
        className={styles.button}
        style={{ display: show_confirmation ? "none" : "flex" }}
        data-testid="trash"
        onClick={toggleConfirmation}
      >
        <img src={Trash} />
      </span>
    </div>
  );
};

export default memo(TrashConfirmationButton);
