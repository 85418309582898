import { ThreeDots } from "react-loader-spinner";
import Button from "../../Atoms/Button";
import useLogout from "hooks/useLogout";

import Styles from "./Loading.module.css";

interface LoadingProps {
  type: "general" | "mentor" | "mentee";
}

const Loading = (props: LoadingProps) => {
  const logout = useLogout();

  const text = {
    general: "Loading",
    mentor: "Loading Mentor Profile",
    mentee: "Loading Mentee Profile",
  }[props.type];

  return (
    <div className={Styles.container} data-testid="Loading">
      <Button
        variant="dark"
        style={{
          top: "24px",
          right: "24px",
          position: "fixed",
          maxWidth: "260px"
        }}
        onClick={logout}
      >
        Log out
      </Button>
      <h4>{text}</h4>
      <div>
        <ThreeDots
          wrapperStyle={{ margin: "auto", display: "block" }}
          color="#fff"
          height={20}
          width={42}
        />
      </div>
    </div>
  );
};

export default Loading;
