import React, { useState } from "react";
import PropTypes from "prop-types";
import style from "../MissedSession.module.css";

const OPTIONS = [
	{ id: "A", text: "Something came up at the same time." },
	{ id: "B", text: "There was confusion on timing." },
	{ id: "C", text: "I forgot." },
	{ id: "D", text: "There was a scheduling error." },
];
const PreTextReason = ({ updateSurvey, nextStep }) => {
	const [selected, setSelected] = useState(null);
	const onClick = (id) => {
		setSelected(id);
		const reason = OPTIONS.filter((o) => o.id === id);
		updateSurvey("reasonText", reason[0]);
	};
	return (
		<div className={style.step}>
			<p className={style.label}>Question 2</p>
			<p className={style.title}>Why did you miss your session?</p>
			<p className={style.description}>
				Help us improve future sessions by giving us insight into why you
				weren’t able to make the session with your mentor.
			</p>
			<div className={style.content}>
				{OPTIONS.map((option) => (
					<div
						className={`${style.listItem} ${
							selected === option.id ? style.selected : null
						}`}
						key={option.id}
						onClick={() => onClick(option.id)}
					>
						<span>
							<h4>{option.id}</h4>
						</span>
						<p>{option.text}</p>
					</div>
				))}
			</div>
			<button
				className={selected === null ? style.disabledBtn : null}
				onClick={async () => {
					await nextStep();
				}}
			>
				Continue
			</button>
		</div>
	);
};

PreTextReason.propTypes = {
	updateSurvey: PropTypes.func,
	nextStep: PropTypes.func,
};

export default PreTextReason;
