import { Grid, ThreeDots } from "react-loader-spinner";
import PropTypes from "prop-types";
import TickCheckbox from "components/Atoms/TickCheckbox";
import Styles from "./GoogleCalendar.module.css";

const GoogleCalendarComponent = ({
  componentId,
  accountConnected,
  loadingAuthLink,
  loadingCalendars,
  calendars,
  openGoogleAuth,
  calendarsToAvoid,
  selectCalToAvoid,
  disconnectCalendar,
}) => {
  return (
    <div className="section" style={{ marginBottom: "10%" }} id={componentId}>
      <div className="section-content">
        <div className="section-header">
          <h3>Google Calendar</h3>
        </div>
        <div className={Styles.headerContent}>
          {/* <div>
						<h4>Connected Account</h4>
						{accountConnected && <p>{email}</p>}
					</div> */}
          <button
            className="btn-small"
            onClick={() =>
              accountConnected ? disconnectCalendar() : openGoogleAuth()
            }
            style={{
              backgroundColor: `${accountConnected ? "#ff644d" : " #5440f0"}`,
            }}
          >
            {loadingAuthLink ? (
              <ThreeDots color="#fff" height={16} width={32} />
            ) : accountConnected ? (
              "Disconnect"
            ) : (
              "Connect Account"
            )}
          </button>
        </div>
        {accountConnected && (
          <>
            <hr className="border-black" />
            <h4>Check for conflicts</h4>
            <p>
              MentorPass will check these for confilict and prevent mentees from
              requesting a booking at times where you are unavailable.
            </p>
            <div className={Styles.calendarList}>
              {loadingCalendars ? (
                <div style={{ "margin-top": "24px" }}>
                  <Grid
                    wrapperStyle={{ margin: "auto", display: "block" }}
                    color="#5440F0"
                    height={32}
                    width={32}
                  />
                </div>
              ) : (
                calendars &&
                calendars.map((calendar, index) => (
                  <div
                    key={`check-cal-${index}`}
                    className={Styles.calendarListItem}
                  >
                    <TickCheckbox
                      id={`check-cal-${index}`}
                      checked={calendarsToAvoid.includes(calendar.id)}
                      onCheck={() => selectCalToAvoid(calendar.id)}
                    />
                    <p>{calendar.summary}</p>
                  </div>
                ))
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

GoogleCalendarComponent.propTypes = {
  componentId: PropTypes.string.isRequired,
  accountConnected: PropTypes.bool.isRequired,
  loadingAuthLink: PropTypes.bool.isRequired,
  loadingCalendars: PropTypes.bool.isRequired,
  calendars: PropTypes.array,
  profile: PropTypes.object.isRequired,
  openGoogleAuth: PropTypes.func.isRequired,
  selectCalToAvoid: PropTypes.func.isRequired,
  calendarsToAvoid: PropTypes.array,
  disconnectCalendar: PropTypes.func,
};

export default GoogleCalendarComponent;
