enum MentorActions {
	FILTER_BY_NAME = "[MENTOR] FILTER_BY_NAME",
	GET_ALL_START = "[MENTOR] GET_ALL_START",
	GET_ALL_SUCCESS = "[MENTOR] GET_ALL_SUCCESS",
	GET_ALL_ERROR = "[MENTOR] GET_ALL_ERROR",
	FETCH_MENTOR_BY_SLUG_START = "[MENTOR] FETCH_MENTOR_BY_SLUG_START",
	FETCH_MENTOR_BY_SLUG_SUCCESS = "[MENTOR] FETCH_MENTOR_BY_SLUG_SUCCESS",
	FETCH_MENTOR_BY_SLUG_ERROR = "[MENTOR] FETCH_MENTOR_BY_SLUG_ERROR",
	FETCH_REFERRING_MENTOR_BY_SLUG_SUCCESS = "FETCH_REFERRING_MENTOR_BY_SLUG_SUCCESS",
}

export default MentorActions;
