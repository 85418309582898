import { useState } from "react";
import { Grid } from "react-loader-spinner";

import { useObjectives } from "hooks/mentee/okr/useObjectives";
import useObjectiveService from "./service/useObjectivesService";

import Header from "components/Organisms/Header";
import ObjectiveBenifitsBanner from "components/Organisms/ObjectiveBenifitsBanner";
import TabHeader from "components/Molecules/SessionsTabHeader";
import RightSidebar from "components/Atoms/RightSidebar";
import Objective from "components/Organisms/Objective";
import Button from "components/Atoms/Button";
import ObjectiveListItemFragment from "./components/ObjectiveListItemFragment";

import styles from "./Objectives.module.css";

const options = [
  {
    id: "in_progress",
    title: "In Progress",
    alert_quantity: 0,
  },
  {
    id: "completed",
    title: "Completed",
    alert_quantity: 0,
  },
];

const Objectives = () => {
  const objective_service = useObjectiveService();
  const { create, edit, view, close, open } = objective_service;
  const is_mobile = window.innerWidth < 900;
  const [active_tab, setTab] = useState<string>(options[0].id);

  const { data, isFetched, dataUpdatedAt } = useObjectives();

  const OBJECTIVES = (data || []).filter((objective) => {
    return objective.status === active_tab;
  });

  return (
    <div className={styles.page}>
      <RightSidebar open={open} close={close}>
        <Objective {...objective_service} />
      </RightSidebar>
      <Header>
        <div className={styles.header_container}>
          <h3>Objectives {!is_mobile && "& Key Results"}</h3>
          <Button variant="dark" onClick={create}>
            Create objective
          </Button>
        </div>
      </Header>
      <div className={styles.container}>
        <ObjectiveBenifitsBanner />
        <div>
          <TabHeader
            active_tab={active_tab}
            onTabChange={setTab}
            options={options}
          />
          <div className={styles.header_row}>
            <label className={styles.goal_label}>GOAL</label>
            <label className={styles.progress_label}>PROGRESS</label>
            <label className={styles.due_label}>DUE</label>
            <label className={styles.placeholder_label}></label>
          </div>
        </div>
        <div className={styles.objectives_list}>
          {isFetched ? (
            <>
              {OBJECTIVES.map((objective) => (
                <ObjectiveListItemFragment
                  key={`${objective._id}`}
                  _id={objective._id}
                  title={objective.title}
                  due_date={objective.due_date}
                  status={objective.status}
                  key_results={
                    dataUpdatedAt || objective?.key_results
                      ? objective?.key_results
                      : []
                  }
                  viewObjective={view}
                  editObjective={edit}
                />
              ))}
              <Button variant="dark" onClick={create}>
                Create new objective
              </Button>
            </>
          ) : (
            <Grid
              wrapperStyle={{
                margin: "auto",
                display: "block",
                marginTop: "48px",
              }}
              color="#5440F0"
              height={42}
              width={42}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Objectives;
