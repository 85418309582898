import { useInterpret } from "@xstate-ninja/react";
import { useEffect, useState } from "react";
import objective_machine from "./Objectives.machine";
import { useCreateOKR } from "hooks/mentee/okr/useCreateOkr";
import { ObjectiveUpdate } from "entities/OKR";
import { useUpdateObjective } from "hooks/mentee/okr/useUpdateObjective";

export enum ObjectiveView {
  CREATE = "create",
  EDIT = "edit",
  VIEW = "view",
}

export interface ObjectiveService {
  objective_id: string;
  objective_view: ObjectiveView | undefined;
  create: () => void;
  edit: (objective_id: string) => void;
  view: (objective_id: string) => void;
  save: (new_objective: ObjectiveUpdate) => void;
  update: (objective_id: string, objective_updates: ObjectiveUpdate) => void;
  close: () => void;
  open: boolean;
}

const useObjectiveService = (): ObjectiveService => {
  const objective_service = useInterpret(objective_machine, { devTools: true });
  const { mutate: createObjective } = useCreateOKR({ onSuccess: (objective_id: string) => saved(objective_id) });
  const { mutate: updateObjective } = useUpdateObjective({ onSuccess: (objective_id: string) => saved(objective_id) });
  const [objective_view, setView] = useState<ObjectiveView | undefined>(ObjectiveView.CREATE);
  const [open, setOpen] = useState<boolean>(false);
  const [objective_id, setObjectiveId] = useState<string>("");

  const create = () => objective_service.send("CREATE");

  const edit = (objective_id: string) =>
    objective_service.send("EDIT", { data: { objective_id } });

  const view = (objective_id: string) =>
    objective_service.send("VIEW", { data: { objective_id } });

  const save = (new_objective: ObjectiveUpdate) => {
    createObjective(new_objective);
  };

  const update = (objective_id: string, objective_updates: ObjectiveUpdate) => {
    updateObjective({ objective_id, data: objective_updates })
  };

  const saved = (objective_id: string) => {
    objective_service.send("SAVED", { data: { objective_id } });
  }

  const close = () => {
    objective_service.send("CLOSE");
  };

  useEffect(() => {
    const subscription = objective_service.subscribe((state) => {
      const objective_id = state.context.objective_id;
      setObjectiveId(objective_id || "");
      switch (true) {
        case state.matches("open.new"):
          setView(ObjectiveView.CREATE);
          break;
        case state.matches("open.edit"):
          setView(ObjectiveView.EDIT);
          break;
        case state.matches("open.view"):
          setView(ObjectiveView.VIEW);
          break;
        default:
          setView(undefined);
      }
      setOpen(state.matches("open"));
      console.log(objective_view, objective_id, open);
    });
    return subscription.unsubscribe;
  }, [objective_service]);

  return {
    objective_id,
    objective_view,
    create,
    edit,
    view,
    save,
    update,
    close,
    open,
  };
};

export default useObjectiveService;
