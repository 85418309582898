import { useAuth0 } from "@auth0/auth0-react";
import { KeyResult, OKRQueryResult } from "entities/OKR";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { API_URL, createHeaders } from "utils/api";
import { useAlert } from "@blaumaus/react-alert";
import { ALL_OBJECTIVES_QUERY_KEY } from "../useObjectives";

const addKeyResult = async (token: string, okrId: string, key_result: KeyResult): Promise<void> => {
	const response = await fetch(`${API_URL}/okr/${okrId}/key_result/add`, {
		method: "POST",
		body: JSON.stringify(key_result),
		headers: createHeaders(token),
	});
	if (!response.ok) {
		throw new Error("Failed to add key result.");
	}
};

export const useAddKeyResult = (okr_id: string) => {
	const { getAccessTokenSilently } = useAuth0();
	const alert = useAlert();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (key_result: KeyResult) => getAccessTokenSilently().then((token) => addKeyResult(token, okr_id, key_result)),
		onMutate: async (key_result: KeyResult) => {

			await queryClient.cancelQueries(["okr", okr_id]);
			await queryClient.cancelQueries([ALL_OBJECTIVES_QUERY_KEY]);

			const previous_objective = queryClient.getQueryData([ALL_OBJECTIVES_QUERY_KEY]);
			const previous_objectives = queryClient.getQueryData([ALL_OBJECTIVES_QUERY_KEY]);

			queryClient.setQueryData<OKRQueryResult>(["okr", okr_id], (old_data) => {
				if (!old_data) return;
				return ({
					...old_data,
					key_results: [...(old_data?.key_results || []), key_result],
				});
			});

			queryClient.setQueryData<OKRQueryResult[]>([ALL_OBJECTIVES_QUERY_KEY], (objectives) => {
				if (!objectives) return [];
				const okr_index = objectives.findIndex((okr) => okr._id === okr_id);
				const existing_objective = objectives[okr_index];
				const updated_objective = {
					...existing_objective,
					key_results: [...(existing_objective?.key_results || []), key_result],
				}
				objectives[okr_index] = updated_objective;
				console.log('objectives', objectives);
				return objectives;
			});

			return { previous_objectives, previous_objective };
		},
		onError: async (error: Error, new_key_result, context) => {
			console.error(error.message);
			queryClient.setQueryData([ALL_OBJECTIVES_QUERY_KEY], context?.previous_objectives);
			queryClient.setQueryData(["okr", okr_id], context?.previous_objective);
			alert.error("Failed to add key result.");
		},
		onSettled: async () => {
			queryClient.invalidateQueries({
				queryKey: [ALL_OBJECTIVES_QUERY_KEY]
			});
			alert.success("Key result added!");
		},
	});
};
