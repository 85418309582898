import { useEffect } from "react";
import { useAlert } from "@blaumaus/react-alert";

import { useAppSelector } from "hooks/redux";

const AlertCreator = () => {
  const { alert, type, time } = useAppSelector((state) => state.alertReducer);

  const Alert = useAlert();
  useEffect(() => {
    if (alert) {
      Alert.show(alert, { type });
    }
  }, [alert, time, type]);

  return null;
};

export default AlertCreator;
