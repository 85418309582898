import React, { EventHandler, MouseEventHandler, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import styles from "./VideoTranscript.module.css"

export type Word = [number, number, string]

export type Phrase = Word[]

interface TranscriptProps {
  current_video_timestamp: number
  phrases: Phrase[],
  goTo: MouseEventHandler<HTMLSpanElement>
}

const Transcript = ({ current_video_timestamp, phrases, goTo }: TranscriptProps) => {

  return (
    <div className={styles.transcript_container}>
      {phrases.map((phrase, timestamp: number) => {
        if (!phrase.length) {
          return <></>
        }
        let time = phrase[0][0] as number
        let time_len = time > 3600 ? 8 : time > 600 ? 5 : 4
        let time_start = new Date(time * 1000)
          .toISOString()
          .substr(19 - time_len, time_len)
        return (
          <tr className={styles.phrase_block}>
            <td className={styles.phrase_time}>{time_start}</td>
            <td className={styles.phrase}>
              {phrase.map((word) => (
                <span
                  className={
                    word[0] < current_video_timestamp &&
                      current_video_timestamp < word[1]
                      ? `word ${styles.highlight}`
                      : 'word'
                  }
                  data-start={word[0]}
                  data-end={word[1]}
                  onClick={goTo}
                >
                  {',.?!-_'.includes(word[2]) ? word[2] : `\u00a0${word[2]}`}
                </span>
              ))}
            </td>
          </tr>
        )
      })}
    </div>
  )
}

export default Transcript
