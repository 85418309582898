export const REVENUE_OPTIONS = [
	// { title: "Pre-revenue", value: "Pre-revenue" },
	{ title: "$0 - $100K", value: "$0 - $100K" },
	{ title: "$100K - $1M", value: "$100K - $1M" },
	{ title: "$1M - $10M", value: "$1m - $10m" },
	{ title: "$10M - $100M", value: "$10M - $100M" },
	{ title: "$100M+", value: "$100M+" },
	{ title: "Prefer not to say", value: "Prefer not to say" },
];

export const FUNDRAISING_OPTIONS = [
	{ title: "Bootstrapped", value: "$0" },
	{ title: "$1 - $500K", value: "$1 - $500K" },
	{ title: "$500K - $2M", value: "$500K - $2M" },
	{ title: "$2M - $10M", value: "$2M - $10M" },
	{ title: "$10M - $100M", value: "$10M - $100M" },
	{ title: "$100M+", value: "$100M+" },
];

export const BUSINESS_MODELS = {
	SAAS_B2B: {
		title: "SaaS (B2B)",
		value: "saas-b2b",
	},
	SAAS_B2C: { title: "SaaS (B2C)", value: "saas-b2c" },
	E_COMMERCE: {
		title: "E-commerce",
		value: "e-commerce",
	},
	MEDIA: {
		title: "Media",
		value: "media",
	},
	SERVICES: { title: "Services", value: "services" },
	MARKETPLACE: { title: "Marketplace", value: "marketplace" },
	OTHER: { title: "Other", value: "other" },
};

export enum SOCIAL {
	TWITTER = "twitter",
	LINKEDIN = "linkedin",
	TIKTOK = "tiktok",
	INSTA = "instagram",
	CRUNCHBASE = "crunchbase",
	GITHUB = "github",
	ANGELLLIST = "angellist",
};
