import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { MentorCollectionQueryResult } from "entities/MentorCollection";
import { API_URL, createHeaders } from "utils/api";

const useMentorCollections = () => {
  const { getAccessTokenSilently } = useAuth0();

  const getMentorCollections = async () => {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/mentor_collection/all`, {
      method: "GET",
      mode: "cors",
      headers: createHeaders(token),
    });
    if (!response.ok) {
      throw new Error("Error fetching mentor collections");
    }
    return await response.json();
  };

  const { data, isLoading, refetch, error } = useQuery<
    MentorCollectionQueryResult[]
  >({
    queryKey: ["mentor_collections"],
    queryFn: getMentorCollections,
    staleTime: 1000 * 60 * 5, // 5 minutes
    placeholderData: [],
  });

  return {
    collections: data ? data.sort((a, b) => a.rank - b.rank) : [],
    is_loading: isLoading,
    refetch,
    error,
  };
};

export default useMentorCollections;
