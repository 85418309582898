import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ProfilePreferenceStorage from "../../services/Profile/Preference/Preference.storage";

const Preference = () => {
  const { preference } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (preference === "mentee") {
      ProfilePreferenceStorage.setPreferenceMentee();
    } else {
      ProfilePreferenceStorage.setPreferenceMentor();
    }
    navigate("/");
  }, []);

  return null;
};

export default Preference;
