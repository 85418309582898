import * as filestack from "filestack-js";

import { CrossIcon, PaperClipIcon } from "../../Icons";

import styles from "./UploadAttachment.module.css";

interface UploadAttachment {
  title?: string;
  cta?: string;
  filename?: string;
  deleteAttachment: () => void;
  onSuccess: (data: filestack.PickerFileMetadata) => void;
  onError: (error: any) => void;
}

const UploadAttachment = ({
  title = "ATTACHMENTS",
  cta = "Add Attachment",
  filename,
  onSuccess,
  onError,
  deleteAttachment,
}: UploadAttachment) => {
  const FS = filestack.init(process.env.REACT_APP_FILESTACK_API ?? "abc");
  // Profile Image Uploading
  const attachment_options: filestack.PickerOptions = {
    fromSources: ["local_file_system", "googledrive"],
    accept: [
      "image/*",
      "text/*",
      "video/*",
      "audio/*",
      "application/pdf",
      "application/vnd.oasis.opendocument.presentation",
      "application/vnd.oasis.opendocument.spreadsheet",
      "application/vnd.oasis.opendocument.text",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    ],
    maxFiles: 1,
    maxSize: 24 * 1024 * 1024, // 10mb
    onFileUploadFinished: onSuccess,
    onFileUploadFailed: (error) => {
      console.error(error);
      onError(error);
    },
  };

  const openFilestack = () => {
    FS.picker(attachment_options).open();
  };

  return (
    <div data-testid="UploadAttachment" className={styles.container}>
      <p>{title}</p>
      {filename ? (
        <div className={styles.file}>
          <img className={styles.attachmentIcon} src={PaperClipIcon} />
          <p>{filename}</p>
          <img
            onClick={deleteAttachment}
            src={CrossIcon}
            className={styles.deleteAttachment}
          />
        </div>
      ) : (
        <button onClick={openFilestack} className={styles.uploadBtn}>
          {cta}
        </button>
      )}
    </div>
  );
};

export default UploadAttachment;
