import { useState, useEffect, useRef } from "react";
import classNames from "classnames/bind";

import { BlueChevron, GreyChevron } from "../../Icons";

import styles from "./DropdownSelector.module.css";

const cx = classNames.bind(styles);

const pickborder = (
  dark: boolean,
  open: boolean,
  outline: boolean
): string | undefined => {
  if (dark) {
    if (outline && !open) {
      return "1px var(--body-text-on-white) solid";
    } else if (open) {
      return "1px solid #5440f0";
    } else {
      return undefined;
    }
  }

  return `1px solid ${open ? "#5440f0" : "#E9E9EA"}`;
};

interface IDropdownSelectorProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: any[];
  onSelect: (arg: any) => void;
  value: any;
  style?: React.CSSProperties;
  dark?: boolean;
  className?: string;
  listClassName?: string;
  itemClassName?: string;
  labelClassName?: string;
  buttonClassName?: string;
  disabledDefaultOutline?: boolean;
  fontSize?: number;
  disable?: boolean;
  outline?: boolean;
}

const DropdownSelector: React.FC<IDropdownSelectorProps> = ({
  id,
  label,
  placeholder,
  options,
  onSelect,
  value,
  style,
  dark,
  className,
  listClassName,
  itemClassName,
  buttonClassName,
  fontSize = 16,
  outline = true,
  disable,
  labelClassName,
  disabledDefaultOutline,
}) => {
  const node = useRef<HTMLDivElement>(null);
  const [selected, setSelected] = useState({ title: placeholder });
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (value) {
      setSelected(value);
    }
  }, [value]);

  useEffect(() => {
    const handleClicks = (e: MouseEvent) => {
      if (node && node.current && node.current.contains(e.target as Node)) {
        return;
      }
      setOpen(false);
    };

    document.addEventListener("click", handleClicks, false);
    return () => {
      document.removeEventListener("click", handleClicks, false);
    };
  }, []);

  const isSelected = selected.title !== placeholder;

  const onClickItem = (picked: any) => {
    setSelected(picked);
    onSelect(picked);
    setOpen(!open);
  };

  const handleOpen = () => {
    if (disable) return;

    setOpen(!open);
  };

  const containerStyles = cx([
    !!className && className,
    {
      container: true,
      open,
      dark,
    },
  ]);

  return (
    <div data-testid={id} style={style} ref={node} className={containerStyles}>
      {label && (
        <label
          className={cx({
            [styles.label]: true,
            [labelClassName || ""]: !!labelClassName,
          })}
        >
          {label}
        </label>
      )}
      <div
        data-testid={`${id}_button`}
        className={cx([styles.dropdownButton, buttonClassName])}
        style={{
          fontSize,
          border: !disabledDefaultOutline
            ? pickborder(!!dark, open, !!outline)
            : undefined,
        }}
        onClick={handleOpen}
      >
        {(typeof selected.title === "string" ? selected.title : "") ||
          placeholder}
        <img
          className={cx(["dropdown-right-chevron", styles.chevron])}
          src={isSelected ? BlueChevron : GreyChevron}
          alt="dropdown-right-chevron"
        />
      </div>
      {open && (
        <div
          data-testid="DropdownSelector_options"
          className={cx({
            [styles.dropdownOptions]: true,
            [listClassName || ""]: !!listClassName,
          })}
          style={{ fontSize }}
        >
          {options.map((c, i) => (
            <div
              data-testid="DropdownSelector_option"
              className={cx({
                [styles.item]: true,
                [itemClassName || ""]: !!itemClassName,
              })}
              key={`${c.value}-${i}`}
              onClick={onClickItem.bind(null, c)}
              id={c.value}
            >
              {c.title}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelector;
