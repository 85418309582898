const REPORT_ERROR_START = "REPORT_ERROR_START";
const REPORT_ERROR_SUCCESS = "REPORT_ERROR_SUCCESS";
const REPORT_ERROR_FAILURE = "REPORT_ERROR_FAILURE";

const UPDATE_ERROR_START = "UPDATE_ERROR_START";
const UPDATE_ERROR_SUCCESS = "UPDATE_ERROR_SUCCESS";
const UPDATE_ERROR_FAILURE = "UPDATE_ERROR_FAILURE";

const initialState = {
	errorId: null,
	profileId: null,
	error: null,
	isLoading: false,
};

const errorReducer = (state = initialState, action) => {
	switch (action.type) {
		case REPORT_ERROR_START:
			return {
				...state,
				errorId: action.payload.errorId,
				profileId: action.payload.profileId,
				error: action.payload.error,
				isLoading: true,
			};
		case REPORT_ERROR_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case REPORT_ERROR_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		case UPDATE_ERROR_START:
			return {
				...state,
				isLoading: true,
			};
		case UPDATE_ERROR_SUCCESS:
			return {
				...state,
				isLoading: false,
			};
		case UPDATE_ERROR_FAILURE:
			return {
				...state,
				isLoading: false,
			};
		default:
			return state;
	}
};

export default errorReducer;
