import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { MentorSessionQueryResult } from "entities/MentorSession";
import { API_URL, createHeaders } from "utils/api";

export enum SesssionViewStatus {
  UPCOMING = "upcoming",
  PAST = "past",
  REQUEST = "request",
}

export interface MentorSessions {
  [SesssionViewStatus.UPCOMING]: MentorSessionQueryResult[];
  [SesssionViewStatus.PAST]: MentorSessionQueryResult[];
  [SesssionViewStatus.REQUEST]: MentorSessionQueryResult[];
}

const useMentorSessions = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [refetch_id, setRefetchId] = useState<number>(0);
  const [sessions, setSessions] = useState<MentorSessions>({
    upcoming: [],
    past: [],
    request: [],
  });
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const refetch = () => setRefetchId(Math.random());

  useEffect(() => {
    const fetchSessions = async () => {
      setIsLoading(true);
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${API_URL}/session/mentor/all`, {
          method: "GET",
          mode: "cors",
          headers: createHeaders(token),
        });

        if (response.ok) {
          const sessions = await response.json();
          setSessions(sessions);
          setError(undefined);
        } else {
          throw new Error("Failed to fetch sessions");
        }
      } catch (error) {
        setError(String(error));
      } finally {
        setIsLoading(false);
      }
    };
    fetchSessions();
  }, [refetch_id]);

  return { sessions, refetch, is_loading, error };
};

export default useMentorSessions;
