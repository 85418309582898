import { useAuth0 } from "@auth0/auth0-react";
import { useActor, useSelector } from "@xstate/react";
import { useContext, useEffect, useState } from "react";
import { MentorQueryResult } from "entities/Mentor";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { API_URL, createHeaders } from "utils/api";

const useUpdateMentor = () => {
    const { profile_service } = useContext(GlobalServiceContext);
    const { getAccessTokenSilently } = useAuth0();
    const [_, send] = useActor(profile_service);
    const [is_loading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | undefined>();

    const update = async (update: Partial<MentorQueryResult>): Promise<void> => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading(true);
            const response = await fetch(`${API_URL}/mentor/update`, {
                method: "POST",
                mode: "cors",
                headers: createHeaders(token),
                body: JSON.stringify(update),
            });
            if (response.ok) {
                const data = await response.json();
                send({ type: "UPDATE_MENTOR_PROFILE", data });
            } else {
                setError("Failed to update mentor profile");
            }
        } catch (error) {
            setError("Failed to update mentor profile");
        } finally {
            setIsLoading(false);
        }
    };

    return { is_loading, error, update };
};

export default useUpdateMentor;
