import { createIcons, Star, Megaphone, Trophy,ShoppingCart, LineChart, Users } from "lucide";

const main = () => {
  createIcons({
    icons: {
        Star,
        Megaphone,
        Trophy,
        ShoppingCart,
        LineChart,
        Users,
    },
  });
};

export default main;
