import * as React from "react";
import { useSearchParams } from "react-router-dom";
import ProfilePreferenceStorage from "../../services/Profile/Preference/Preference.storage";
import { useAuth0 } from "@auth0/auth0-react";

const OnePageCheckoutSignup = () => {
  const [search_params] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  const given_name = search_params.get("given_name");
  const family_name = search_params.get("family_name");
  const pending_session_id = search_params.get("pending_session");

  if (!pending_session_id) {
    throw new Error("Missing pending_session_id");
  }

  React.useEffect(() => {
    ProfilePreferenceStorage.setPreferenceMentee();
    ProfilePreferenceStorage.setPendingSessionId(pending_session_id);
    ProfilePreferenceStorage.setGivenName(given_name);
    ProfilePreferenceStorage.setFamilyName(family_name);

    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
      appState: {
        returnTo: "/loading_profile",
      },
    });
  }, []);

  return null;
};

export default OnePageCheckoutSignup;
