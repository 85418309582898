import classNames from "classnames/bind";

import styles from "./InfoContainer.module.css";

interface IInfoContainerProps {
  label?: string;
  Icon: string;
  helperText: string;
  buttonText: string;
  onClick: () => void;
}

const cx = classNames.bind(styles);

const InfoContainer: React.FC<IInfoContainerProps> = ({
  label,
  Icon,
  helperText,
  buttonText,
  onClick,
}) => (
  <div data-testid="InfoContainer" className={styles.infoContainer}>
    {label ? <h4>{label}</h4> : null}
    <div className={styles.content}>
      <img src={Icon} alt="icon" />
      <p>{helperText}</p>
      <button
        className={cx([styles.ellipseHollow, styles.borderHollow])}
        style={{ width: "fit-content" }}
        onClick={onClick}
      >
        {buttonText}
      </button>
    </div>
  </div>
);

export default InfoContainer;
