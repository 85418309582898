import React, { useState, useEffect } from "react";
import PlanSelection, {
  PLAN_TERM,
} from "../../../../../components/Organisms/PlanSelection";
import styles from "./PickPlan.module.css";
import { Medal, Rocket, Coin } from "../../../../../components/Icons";
import { createHeaders, API_URL } from "../../../../../utils/api";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import useMentor from "../../../../../hooks/useMentor";
import { usePostHog } from "posthog-js/react";
import { LIVE_KEY, TEST_KEY } from "../../../constants";

const OFFERS = {
  MONTHLY: [
    {
      _id: "SCALE",
      title: "PREMIUM",
      price: "1,250",
      _price: 125000,
      headline: "Best Value",
      byline: "Save $150/month",
      benifits: [
        {
          b1: "120 credits per month",
          b2: "",
        },
        {
          b1: "$10.42 per credit to top up",
          b2: "",
        },
        {
          b1: "Unlimited users per plan",
          b2: "",
        },
      ],
    },
    {
      title: "STANDARD",
      _id: "STARTUP",
      price: "650",
      _price: 65000,
      headline: "Most Popular",
      byline: "Save $50/month",
      benifits: [
        {
          b1: "60 credits per month",
        },
        {
          b1: "$10.85 per credit to top up",
        },
        {
          b1: "Up to 10 users per plan",
        },
      ],
    },
    {
      _id: "SOLO",
      title: "LITE",
      headline: null,
      byline: "",
      price: "350",
      _price: 35000,
      benifits: [
        {
          b1: "30 credits per month",
        },
        {
          b1: "$11.67 per credit to top up",
        },
        {
          b1: "Up to 3 users per plan",
        },
      ],
    },
  ],
  QUARTERLY: [
    {
      _id: "SCALE",
      title: "PREMIUM",
      price: "1,200",
      _price: 360000,
      headline: "Best Value",
      byline: "Save $600/quarter",
      benifits: [
        {
          b1: "120 credits per month",
        },
        {
          b1: "$10 per credit to top up",
        },
        {
          b1: "Unlimited users per plan",
        },
      ],
    },
    {
      title: "STANDARD",
      _id: "STARTUP",
      price: "600",
      _price: 180000,
      headline: "Most Popular",
      byline: "Save $300/quarter",
      benifits: [
        {
          b1: "60 credits per month",
        },
        {
          b1: "$10 per credit to top up",
        },
        {
          b1: "Up to 10 users per plan",
        },
      ],
    },
    {
      _id: "SOLO",
      title: "LITE",
      headline: null,
      byline: "",
      price: "300",
      _price: 90000,
      benifits: [
        {
          b1: "30 credits per month",
        },
        {
          b1: "$10 per credit to top up",
        },
        {
          b1: "Up to 3 users per plan",
        },
      ],
    },
  ],
};

const Copy = () => (
  <div className={styles.item}>
    <ul style={{ marginTop: "24px" }}>
      <li>
        <img src={Medal} className={styles.icon} />
        <p>Develop your all-star talent.</p>
      </li>
      <li>
        <img src={Rocket} className={styles.icon} />
        <p>Scale your revenue and team.</p>
      </li>
      <li>
        <img src={Coin} className={styles.icon} />
        <p>Avoid expensive pitfalls.</p>
      </li>
    </ul>
  </div>
);

interface PickPlanProps {
  referring_mentor_id?: string;
}

const PickPlan = ({ referring_mentor_id }: PickPlanProps) => {
  const { mentor: referring_mentor_profile } = useMentor({
    slug: referring_mentor_id,
  });
  const [plan_term, setPlanTerm] = useState<PLAN_TERM>(PLAN_TERM.MONTHLY);
  const [selected_plan, setPlanSelected] = useState<string | null>(null);
  const [is_loading, setIsLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const posthog = usePostHog();

  const PLANS = OFFERS[plan_term];

  useEffect(() => {
    setPlanSelected(PLANS[1]._id);
  }, []);

  const fetchCheckoutSession = async (plan_id: string) => {
    const referral = window?.Rewardful && window.Rewardful?.referral;
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/stripe/create-checkout-session`, {
      method: "POST",
      mode: "cors",
      body: JSON.stringify({
        plan: plan_id,
        period: plan_term,
        ...(referral && { referral }),
      }),
      headers: createHeaders(token),
    });
    if (response.status !== 200) {
      throw Error("Failed to connect to stripe, please contatct support");
    }
    const session = await response.json();
    //When the customer clicks on the button, redirect them to Checkout.
    //Google GoogleAuth
    try {
      const details = PLANS.find((p) => p._id === plan_id);
    } catch (error) {
      console.error(error);
    }
    const stripe = await loadStripe(
      process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? LIVE_KEY : TEST_KEY
    );
    if (!stripe) throw Error("Failed to load stripe");
    const error = await stripe.redirectToCheckout({
      sessionId: session.id,
    });

    if (error) {
      console.error(error);
      throw Error(String(error));
    }
  };

  const mentor_details = referring_mentor_profile
    ? {
        name: `${referring_mentor_profile.given_name} ${referring_mentor_profile.family_name}`,
        hourly_rate: referring_mentor_profile.hourlyRate.tokens as number,
      }
    : null;

  const continueToCheckout = async () => {
    setIsLoading(true);
    try {
      if (!selected_plan) {
        throw Error("A plan must be selected");
      }
      await fetchCheckoutSession(selected_plan);
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };

  const handleSetPlanTerm = (plan_term: PLAN_TERM) => {
    setPlanTerm(plan_term);
    posthog.capture("[CHECKOUT] plan_term_changed", { plan_term });
  };

  const handleSelectPlan = (plan: string) => {
    setPlanSelected(plan);
    posthog.capture("[CHECKOUT] plan_selected", { plan, plan_term });
  };

  return (
    <div className={styles.container}>
      <div className={styles.main}>
        <div className={styles.copy}>
          <h2>Unlock your company’s competitive advantage. </h2>
          <Copy />
        </div>
        <PlanSelection
          plans={PLANS}
          refering_mentor={mentor_details}
          plan_term={plan_term}
          setPlanTerm={handleSetPlanTerm}
          selected_plan={selected_plan}
          selectPlan={handleSelectPlan}
          is_loading={is_loading}
          continueToCheckout={continueToCheckout}
        />
      </div>
    </div>
  );
};

export default PickPlan;
