import { z } from "zod";

export const MentorCollectionSchema = z.object({
    name: z.string(),
    description: z.string(),
    rank: z.number(),
});

export type MentorCollection = z.infer<typeof MentorCollectionSchema>;

export type MentorCollectionQueryResult = MentorCollection & { _id: string };
