import { PaymentMethodStatus } from "./Components/PaymentInformation/PaymentMethod.types";

export interface OfferResponse {
  price: string;
  quantity: number;
  cost: number;
}

export interface PaymentMethodResponse {
  expiring: string;
  last_four: string;
  status: PaymentMethodStatus;
}

export interface Offer {
  _id: number;
  quantity_of_credits: number;
  cost_per_credit: number;
}

export interface RenewalDetails {
  remaining_credits: number;
  days_until_sub_renews: number | null;
}

export interface OrderDetails {
  quantity_of_credits: number;
  rate: number;
  sub_total: number;
}

interface CheckoutDetails {}
// Credit Pack Machine
export interface CreditPackContext {
  offers: Offer[];
  default_rate: number;
  rate: number;
  custom_quantity: number;
  credit_quantity: number;
  payment_method: PaymentMethodResponse | undefined;
  days_until_sub_renews: number | undefined;
}

export enum OFFER_TITLE {
  OFFER_1 = "offer_1",
  OFFER_2 = "offer_2",
  OFFER_3 = "offer_3",
  CUSTOM_OFFER = "offer_custom",
}

type OffersReady = {
  offers_ready: {
    payment_method: "loading" | "ready" | "fetching_update_link";
    credit_offers: OFFER_TITLE;
    offer_custom_tab: "open" | "closed";
    subscription_status: "loading" | "ready";
  };
};

export enum CreditPackState {
  LOADING_OFFERS = "loading_offers",
  PURCHASE_NOT_ALLOWED = "purchase_not_allowed",
  CHECKOUT_COMPLETE = "checkout_complete",
  OFFERS_READY = "offers_ready",
  PURCHASING = "purchasing",
}

export type CreditPackStateSchema = {
  value: CreditPackState | OffersReady;
  context: CreditPackContext;
};

// EVENTS
export enum OFFER_TRANSITION_EVENT {
  SELECT_OFFER_1 = "SELECT_OFFER_1",
  SELECT_OFFER_2 = "SELECT_OFFER_2",
  SELECT_OFFER_3 = "SELECT_OFFER_3",
  SELECT_OFFER_CUSTOM = "SELECT_OFFER_CUSTOM",
}

export type GotOffersEvent = {
  type: "GOT_OFFERS";
  offers: Offer[];
  default_rate: number;
};

type SetPurchaseNotAllowedEvent = { type: "SET_PURCHASE_NOT_ALLOWED" };
type GotPaymentMethodEvent = { type: "GOT_PAYMENT_METHOD" };
type UpdatePaymentMethodEvent = { type: "UPDATE_PAYMENT_METHOD" };
type SetOffer1Event = { type: OFFER_TRANSITION_EVENT.SELECT_OFFER_1 };
type SetOffer2Event = { type: OFFER_TRANSITION_EVENT.SELECT_OFFER_2 };
type SetOffer3Event = { type: OFFER_TRANSITION_EVENT.SELECT_OFFER_3 };

type SetOfferCustomEvent = { type: OFFER_TRANSITION_EVENT.SELECT_OFFER_CUSTOM };

type ToggleCustomOfferTab = { type: "TOGGLE_CUSTOM_OFFER_TAB" };

export type UpdateCustomQuantityEvent = {
  type: "UPDATE_CUSTOM_QUANTITY";
  custom_quantity: number;
};
type CheckoutEvent = { type: "CHECKOUT"; data?: string };
type CheckoutSuccessEvent = { type: "CHECKOUT_SUCCESS" };
type Reset = { type: "RESET" };
type GetSubscriptionStatusEvent = { type: "GET_SUBSCRIPTION_STATUS" };

export type ChangeOfferEvent =
  | SetOffer1Event
  | SetOffer2Event
  | SetOffer3Event
  | SetOfferCustomEvent;

export type CreditPackEvent =
  | GotOffersEvent
  | SetPurchaseNotAllowedEvent
  | GotPaymentMethodEvent
  | UpdatePaymentMethodEvent
  | SetOffer1Event
  | SetOffer2Event
  | SetOffer3Event
  | SetOfferCustomEvent
  | UpdateCustomQuantityEvent
  | CheckoutEvent
  | CheckoutSuccessEvent
  | Reset
  | ToggleCustomOfferTab
  | GetSubscriptionStatusEvent;
