import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addRequestDatetime } from "../../../../../../store/dux/booking/booking.actions";
import SessionSelectorComponent from "./SessionSelector.component";
import { getAvailabilityOnGivenDate } from "./SessionSelection.helpers";
import { useAuth0 } from "@auth0/auth0-react";
import processAuthClaims from "utils/processAuthClaims";
import { useAppSelector } from "hooks/redux";

const SessionSelector = () => {
  const dispatch = useDispatch();
  const calendar_date = useAppSelector((state) => state.booking.date);
  const proposed_datetimes = useAppSelector(
    (state) => state.booking.mentee_request_slots
  );
  const ideal_proposed_time = useAppSelector(
    (state) => state.booking.ideal_proposed_time
  );
  const duration = useAppSelector((state) => state.booking.duration);

  const { user, getIdTokenClaims } = useAuth0();
  const { timezone } = useMemo(() => processAuthClaims(user), [user]);

  useEffect(() => {
    // I want to believe this is insurance
    // incase the timezone has been set in the backgroun on first login
    // but the user object from useAuth hasn't been refreshed with those values.
    if (!timezone) {
      getIdTokenClaims();
    }
  }, []);

  const total_availability = useAppSelector(
    (state) => state.booking.availability
  );

  const availability = useMemo(
    () =>
      (timezone &&
        calendar_date &&
        getAvailabilityOnGivenDate(
          total_availability,
          calendar_date,
          timezone
        )) ||
      [],
    [timezone, calendar_date, total_availability]
  );

  const onClickTimeslot = (datetime: string) =>
    dispatch(addRequestDatetime(datetime));

  const selectAllTimeSlot = () => {};
  const clearAllTimeSlot = () => {};

  return (
    <SessionSelectorComponent
      ideal_proposed_time={ideal_proposed_time}
      selected_date={calendar_date}
      proposed_datetimes={proposed_datetimes}
      duration={duration}
      availability={availability}
      onClickTimeslot={onClickTimeslot}
      selectAllTimeSlot={selectAllTimeSlot}
      clearAllTimeSlot={clearAllTimeSlot}
    />
  );
};

export default SessionSelector;
