import React, { useState } from "react";
import BannerNotification from "components/Atoms/BannerNotification";
import ModifyObjective, {
  ObjectiveValidation,
} from "components/Molecules/ModifyObjective/ModifyObjective";
import styles from "./Objective.module.css";

interface ObjectiveProps {
  saveObjective: (
    objective: ObjectiveValidation,
    setSubmitting: (is_submitting: boolean) => void
  ) => void;
}

const Objective = ({ saveObjective }: ObjectiveProps) => {
  const [show, setShow] = useState<boolean>(true);

  return (
    <div className={styles.container}>
      <BannerNotification
        isDisplayed={show}
        onDismiss={setShow.bind(null, false)}
      >
        <div className={styles.objective_info}>
          <h3>Let’s set your first objective.</h3>
          <p>
            Objectives help to focus your efforts on specific goals. Objectives
            are sent to your mentor to help them understand what you're trying
            to achieve and the progress you've made. <br /> Let's create a
            single objective for now. <br /> You can add more later from your
            Objectives dashboard.
          </p>
        </div>
      </BannerNotification>
      <div className={styles.form_container}>
        <ModifyObjective
          saveObjective={saveObjective}
          modification_type="create"
          custom_styles={{
            // backgroundColor: "#000",
            padding: "24px 32px",
            borderRadius: "3px",
          }}
        />
      </div>
    </div>
  );
};

export default Objective;
