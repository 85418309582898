import React, { useEffect, useMemo } from "react";
import DurationSelectionComponent from "./DurationSelection.component";
import { useDispatch, useSelector } from "react-redux";
import { setBookingDuration } from "../../../../../../store/dux/booking/booking.actions";
import useBusiness from "hooks/mentee/useBusiness";
import useMentor from "hooks/useMentor";
import { Grid } from "react-loader-spinner";

const DurationSelection = ({ mentor_slug }: { mentor_slug: string }) => {
  const dispatch = useDispatch();

  const { mentor } = useMentor({ slug: mentor_slug });

  const durations_available = useMemo(
    () => ({
      half_hour: !!mentor?.availabilityDetails?.halfHour,
      hour: !!mentor?.availabilityDetails?.hour,
    }),
    [mentor]
  );

  const hourly_token_rate = mentor?.hourlyRate.tokens;

  const { business } = useBusiness();

  const session_length: number = useSelector(
    (state: any) => state.booking.duration
  );

  const setSessionDuration = (duration: number) => {
    dispatch(setBookingDuration(duration));
    // scroll to booking section of the form
    const booking = window.document.getElementById("booking-date");
    booking?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (durations_available) {
      console.log("setting duration available...");
      if (durations_available.half_hour && !durations_available.hour) {
        dispatch(setBookingDuration(30));
      } else if (!durations_available.half_hour && durations_available.hour) {
        dispatch(setBookingDuration(60));
      }
    }
  }, [durations_available]);

  return (
    <>
      {mentor && hourly_token_rate && business ? (
        <DurationSelectionComponent
          hourly_token_rate={hourly_token_rate}
          durations_available={durations_available}
          balance={business?.balance}
          session_length={session_length}
          setSessionDuration={setSessionDuration}
        />
      ) : (
        <div style={{ minHeight: "220px", paddingTop: "15%" }}>
          <Grid
            wrapperStyle={{ margin: "auto", display: "block" }}
            color="var(--primary-colour)"
            height={32}
            width={32}
          />
        </div>
      )}
    </>
  );
};

export default DurationSelection;
