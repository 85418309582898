import { useParams } from "react-router-dom";
import RescheduleSessionComponent from "./Reschedule.screen";
import { PROFILE_TYPE, ProfileQueryResult } from "entities/Profile";
import useRescheduleSession from "./useReschduleSession";
import { useAuth0 } from "@auth0/auth0-react";
import { useMemo } from "react";
import processAuthClaims from "utils/processAuthClaims";
import dayjs from "dayjs";

const RescheduleSessionScreen = () => {
  const { session_id } = useParams();

  if (!session_id) {
    return <div>Session ID is required</div>;
  }

  const { user } = useAuth0();

  const { timezone } = useMemo(() => processAuthClaims(user), [user]);

  const use_reschedule_session = useRescheduleSession({
    session_id,
  });

  return (
    <RescheduleSessionComponent
      user_timezone={timezone || dayjs.tz.guess()}
      {...use_reschedule_session}
    />
  );
};

export default RescheduleSessionScreen;
