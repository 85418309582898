import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import { API_URL } from "utils/api";
import { createHeaders } from "utils/api";
import { AffiliateData } from "./types";

import MarketingHub from "./components/MarketingHub";
import AffiliateHub from "./components/AffiliateHub";

import styles from "./MentorDashboardMarketingHub.module.css";

const MentorDashboardMarketingHub: React.FC = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [affiliate_data, setAffiliateData] = useState<AffiliateData | null>(
    null
  );

  const getAffiliateData = async (token: string) => {
    try {
      const affiliate_response = await fetch(`${API_URL}/affiliate/dashboard`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      });
      const affiliate_data = await affiliate_response.json();
      setAffiliateData(affiliate_data);
    } catch (error) {
      console.error(String(error));
    }
  };

  useEffect(() => {
    getAccessTokenSilently().then(getAffiliateData);
  }, []);

  return (
    <div
      data-testid="MentorDashboardMarketingHub"
      className={styles.affilate_container}
    >
      <MarketingHub affiliate_token={affiliate_data?.link_token || null} />
      {!!affiliate_data && (
        <AffiliateHub
          affiliate_data={affiliate_data}
          isLoading={false}
          affiliate_id={affiliate_data.id}
        />
      )}
    </div>
  );
};

export default MentorDashboardMarketingHub;
