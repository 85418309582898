import React from "react";
import PropTypes from "prop-types";
import { TickInCircle } from "../../../../../components/Icons";
import style from "../MissedSession.module.css";

const ThankYou = ({ history }) => {
	return (
		<div className={style.step}>
			<div className={style.primeRect}>
				<div className={style.darkCircle}>
					<img src={TickInCircle} alt="dollar-block" />
				</div>
			</div>
			<div className={style.messageContent}>
				<h2>Thank you for your time.</h2>
				<p>
					Thank you for taking the time to help make MentorPass a better
					community for entrepreneurs to learn and grow. Schedule your next
					session now!
				</p>
			</div>

			<button className={style.button}>Schedule another session</button>
			<button onClick={() => history.push("/")} className={style.skipBtn}>
				Back to dashbaord
			</button>
		</div>
	);
};

ThankYou.propTypes = {
	history: PropTypes.object,
};

export default ThankYou;
