import { useAuth0 } from "@auth0/auth0-react";
import ProfilePreferenceStorage from "services/Profile/Preference/Preference.storage";

const useLogout = () => {
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    ProfilePreferenceStorage.clear();
    auth0Logout();
  };

  return logout;
};

export default useLogout;
