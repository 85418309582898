import { useAuth0 } from "@auth0/auth0-react";
import { useActor } from "@xstate/react";
import { PROFILE_TYPE } from "entities/Profile";
import { useContext, useEffect, useState } from "react";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { ProfileEventType } from "services/Profile/Profile.schema";
import { API_URL, createHeaders } from "utils/api";



const useSwitchProfile = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { profile_service } = useContext(GlobalServiceContext);
    const [state, send] = useActor(profile_service);
    const [is_loading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | undefined>();
    const [profile_types, setProfileTypes] = useState<PROFILE_TYPE[] | undefined>();

    // while fetching, display something
    useEffect(() => {
        if (!profile_types) {
            if (state.matches(PROFILE_TYPE.MENTEE)) {
                setProfileTypes([PROFILE_TYPE.MENTEE])
            }
            if (state.matches(PROFILE_TYPE.MENTOR)) {
                setProfileTypes([PROFILE_TYPE.MENTOR])
            }
        }
    }, [])

    useEffect(() => {
        const getUserProfileTypes = async () => {
            try {
                setIsLoading(true);
                const token = await getAccessTokenSilently();
                const response = await fetch(`${API_URL}/profile/types`, {
                    method: "GET",
                    headers: createHeaders(token)
                });
                if (response.ok) {
                    const { types } = await response.json();
                    setProfileTypes(types);
                } else {
                    throw Error("Failed to fetch profile types.")
                }
            } catch (error) {
                console.error(error);
                setError(String(error));
            } finally {
                setIsLoading(false);
            }
        }
        getUserProfileTypes();
    }, [])


    const switchTo = (to: PROFILE_TYPE) => {
        // if the preference isn't available don't send a switch event
        if (!profile_types || !profile_types?.includes(to)) {
            return null;
        }
        // if selected a profile change event
        const event_type = {
            [PROFILE_TYPE.MENTEE]: ProfileEventType.SWITCH_TO_MENTEE,
            [PROFILE_TYPE.MENTOR]: ProfileEventType.SWITCH_TO_MENTOR,
            [PROFILE_TYPE.ADMIN]: ProfileEventType.SWITCH_TO_ADMIN,
        }[to];

        send({
            type: event_type
        })
    }

    return { is_loading, error, profile_types, switchTo };
};

export default useSwitchProfile;