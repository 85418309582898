// @ts-ignore
import ISO3311 from "iso-3166-1-alpha-2";
import { ThreeDots } from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";
import { useState, useEffect, useRef } from "react";

import WORLD_FLAG from "./images/globe.png";
import USA_FLAG from "./images/US_flag.png";
import { createHeaders } from "../../../utils/api";
import { API_URL } from "utils/api";

import styles from "./StripeLocationSelector.module.css";

interface ModalProps {
  close: () => void;
}
interface RefObject {
  current: HTMLElement | null;
}

function useOnClickOutside(
  ref: RefObject,
  handler: (event: MouseEvent | TouchEvent) => void
) {
  useEffect(() => {
    const listener = (event: MouseEvent | TouchEvent) => {
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

const StripeLocationSelector = (props: ModalProps) => {
  const ref = useRef(null);
  const [is_loading, setIsLoading] = useState(false);
  const [is_cross_border, setCrossBoarder] = useState(false);
  const [country_code, setCountryCode] = useState("US");
  const { getAccessTokenSilently } = useAuth0();

  useOnClickOutside(ref, () => props.close());

  const handleLocationSelection = (choice: { value: string }) => {
    const country_code = ISO3311.getCode(choice.value);
    setCountryCode(country_code);
  };

  const connectStripe = async (country_code: string) => {
    setIsLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(
        `${API_URL}/stripe/connect?` +
          new URLSearchParams({
            country_code: country_code,
          }),
        {
          method: "GET",
          mode: "cors", // no-cors, *cors, same-origin
          // credentials: 'same-origin',
          headers: createHeaders(token),
        }
      );

      if (response.status !== 200) {
        // dispatch an error
        setIsLoading(false);
        return;
      }
      const data = await response.json();
      window.location = data.url;
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <div data-testid="StripeLocationSelector" className={styles.modalOverlay}>
      <div className={styles.modal} ref={ref}>
        {is_loading ? (
          <div className={styles.loading}>
            <ThreeDots
              wrapperStyle={{ margin: "auto", display: "block" }}
              color="#4837cf"
              height={42}
              width={42}
            />
          </div>
        ) : is_cross_border ? (
          <div>
            <h3 style={{ color: "#000" }}>International Accounts</h3>
            <p>
              Due to the limitations of "Stripe Connect" we currently don't
              support accounts outside the US. Please contact support to receive
              your payouts, and we can arrange payment via PayPal or bank
              transfer.
            </p>
          </div>
        ) : (
          <>
            <h3>Where are you from?</h3>
            <div className={styles.modalOptions}>
              <div
                className={styles.modalOption}
                onClick={() => connectStripe("US")}
              >
                <img
                  src={USA_FLAG}
                  alt="USA"
                  className={styles.modalOptionImg}
                />
                <p className={styles.modalOptionText}>USA</p>
              </div>
              <div
                className={styles.modalOption}
                onClick={() => setCrossBoarder(true)}
              >
                <img
                  src={WORLD_FLAG}
                  alt="Rest of the world"
                  className={styles.modalOptionImg}
                />
                <p className={styles.modalOptionText}>Rest of the world</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default StripeLocationSelector;
