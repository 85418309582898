import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { KeyResult, OKRQueryResult } from "entities/OKR";
import { useAlert } from "@blaumaus/react-alert";
import { API_URL, createHeaders } from "utils/api";
import { ALL_OBJECTIVES_QUERY_KEY } from "../useObjectives";

const toggleKeyResultStatus = async (token: string, okr_id: string, keyResultId: string): Promise<void> => {
    await fetch(`${API_URL}/okr/${okr_id}/key_result/${keyResultId}/toggle`, {
        method: 'POST',
        headers: createHeaders(token),
        mode: "cors",
    });
};

export const useToggleKeyResultStatus = (okr_id: string) => {
    const { getAccessTokenSilently } = useAuth0();
    const alert = useAlert();
    const query_client = useQueryClient();

    return useMutation({
        mutationFn: async (key_result_id: string) => getAccessTokenSilently().then(token => toggleKeyResultStatus(token, okr_id, key_result_id)),
        onMutate: async (key_result_id) => {
            await query_client.cancelQueries(['okr', okr_id]);
            await query_client.cancelQueries([ALL_OBJECTIVES_QUERY_KEY]);

            const previous_objective = query_client.getQueryData(['okr', okr_id]);

            query_client.setQueryData<OKRQueryResult>(["okr", okr_id], (old_okr) => {
                if (!old_okr) return;

                const updated_key_results = (old_okr?.key_results || []).map((key_result: KeyResult) =>
                    key_result._id === key_result_id
                        ? { ...key_result, completedAt: key_result.completedAt === null ? new Date().toISOString() : null }
                        : key_result
                );
                return {
                    ...old_okr,
                    key_results: updated_key_results,
                };
            });

            query_client.setQueryData<OKRQueryResult[]>([ALL_OBJECTIVES_QUERY_KEY], (okrs) => {
                if (!okrs) return [];
                const okr_index = okrs?.findIndex((okr) => okr._id === okr_id);
                if (okr_index !== -1) {
                    const updated_key_results = (okrs[okr_index]?.key_results || []).map((key_result: KeyResult) => {
                        if (key_result._id === key_result_id) {
                            // update key result status
                            const new_key_result = { ...key_result, completedAt: key_result.completedAt === null ? new Date().toISOString() : null }
                            console.log(new_key_result);
                        }
                        return key_result;
                    });
                    okrs[okr_index] = {
                        ...okrs[okr_index],
                        key_results: updated_key_results,
                    }
                    return okrs;
                }
            });

            return previous_objective;
        },
        onError: async (error, _, context) => {
            alert.error("Failed to toggle key result status.");
            console.error(error);
            query_client.setQueryData(['okr', okr_id], context);
        },
        onSettled: async () => {
            query_client.invalidateQueries([ALL_OBJECTIVES_QUERY_KEY]);
            query_client.invalidateQueries(['okr', okr_id]);
        },
    });
};