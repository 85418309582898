import React from "react";
import AddSkillComponent from "./AddSkill";
import { Skill } from "entities/Mentor";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import useMyProfile, { isMentor } from "hooks/useMyProfile";


const AddSkill = ({ closeModal }: { closeModal: () => void }) => {
    const { update } = useUpdateMentor();
    const { profile } = useMyProfile();

    if (!isMentor(profile)) {
        throw Error("Skills can only be added to Mentor profiles.")
    }

    const addSkill = (skill: Skill) => {
        const existing_skills = profile.skills || [];
        const skills = [...existing_skills, skill];
        update({ skills });
    };

    const selected_skills = profile?.skills.map(skill => skill.value).filter((value) => !!value);

    return <AddSkillComponent selected_skills={selected_skills} addSkill={addSkill} closeModal={closeModal} />
}

export default AddSkill