import AvatarPlaceholder from "images/avatar-placeholder.gif";
import { CalendarIcon } from "components/Icons";
import styles from "./AcceptSession.module.css";
import TimeSlotSelector from "components/Molecules/TimeSlotSelector/TimeSlotSelector";
import { SessionQueryResultsWithDetails } from "entities/Session";

interface AcceptSessionProps {
  profile: {
    profileImage: {
      url: string | null;
    };
    given_name: string;
  };
  session: SessionQueryResultsWithDetails;
  requires_submit: boolean;
  mentor_timezone: string;
  decline_session_loading: boolean;
  accept_session_loading: boolean;
  acceptSession: (slot: string) => void;
  declineSession: () => void;
  rescheduleSession: () => void;
}

const AcceptSession = ({
  profile,
  session,
  requires_submit,
  mentor_timezone,
  accept_session_loading,
  decline_session_loading,
  acceptSession,
  declineSession,
  rescheduleSession,
}: AcceptSessionProps) => {
  return (
    <div className={styles.successPage}>
      <div className={styles.successContent}>
        <div className={styles.successAvatar}>
          <div className={styles.successAvatarIcon}>
            <img src={CalendarIcon} alt="Calendar Icon" />
          </div>
          <img
            className={styles.successAvatarImage}
            src={profile.profileImage.url || AvatarPlaceholder}
            alt={`${profile.given_name} Avatar`}
          />
        </div>
        {requires_submit ? (
          <>
            <h4>When would you like to talk to {profile.given_name}?</h4>
            <TimeSlotSelector
              timezone={mentor_timezone}
              inital_slot={session.ideal_proposed_time}
              slots={session.proposed_times}
              accept_session_loading={accept_session_loading}
              decline_session_loading={decline_session_loading}
              confirmSession={acceptSession}
              handleRescheduleSession={rescheduleSession}
              handleDeclineSession={declineSession}
            />
          </>
        ) : (
          <>
            <h2>Session Accepted</h2>
            <p>
              You will recieve a calendar invite via email. <br /> If you're
              using Google calendar, it will appear there also.
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default AcceptSession;
