import { useState } from "react";
import { Grid } from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";

import { AffiliateData } from "../types";

import { API_URL } from "utils/api";
import { createHeaders } from "utils/api";

import PerformanceLineItem from "components/Atoms/PerformanceLineItem";
import Button from "components/Atoms/Button";

import styles from "../MentorDashboardMarketingHub.module.css";

interface IProps {
  affiliate_id: string;
  isLoading: boolean;
  affiliate_data: AffiliateData | null;
}

const AffiliateHub: React.FC<IProps> = ({ affiliate_data, affiliate_id }) => {
  const [sso_loading, setSSOLoading] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const data = [
    {
      title: "Visitors",
      value: String(affiliate_data?.visitors),
      help: "",
    },
    {
      title: "Leads",
      value: String(affiliate_data?.leads),
      help: "",
    },
    {
      title: "Conversions",
      value: String(affiliate_data?.conversions),
      help: "",
    },
    {
      title: "Total Comission",
      value: `$${affiliate_data?.total_comission}`,
      help: "",
    },
  ];

  const goToDashboard = () => {
    setSSOLoading(true);
    getAccessTokenSilently().then((token) =>
      fetch(`${API_URL}/affiliate/get_sso_link/${affiliate_id}`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      })
        .then(async (response) => {
          const { link } = await response.json();
          return link;
        })
        .then((link) => {
          setSSOLoading(false);
          window.open(link, "_blank");
        })
        .catch((error) => {
          setSSOLoading(false);
          console.error(String(error));
        })
    );
  };

  return (
    <div className={styles.performanceWidget}>
      <div className={styles.performanceWidgetHeader}>
        <h3>Affiliate Stats</h3>
        <p>
          Earn $100 Per Referral! Share a link from your Marketing Hub to get
          started.
        </p>
      </div>
      <ul className={styles.performanceItemsList}>
        {!affiliate_data ? (
          <div>
            <Grid
              wrapperStyle={{ margin: "auto", display: "block" }}
              color="#5440F0"
              height={32}
              width={32}
            />
          </div>
        ) : (
          data.map((d, i) => (
            <PerformanceLineItem
              key={`li- ${i}`}
              title={d.title}
              value={d.value}
              help={d.help}
            />
          ))
        )}
      </ul>
      <Button loading={sso_loading} onClick={goToDashboard}>
        Visit affiliate dashboard
      </Button>
    </div>
  );
};

export default AffiliateHub;
