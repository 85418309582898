import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import { useAlert } from "@blaumaus/react-alert";
import { API_URL, createHeaders } from "utils/api";

const useDeclineSession = () => {
    const [is_loading, setLoading] = useState<boolean>(false);
    const { getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState<string | undefined>();
    const alert = useAlert();

    const decline = async (session_id: string, reason: string, onDone: () => void): Promise<void> => {
        if (is_loading) return;
        setLoading(true);
        try {
            const token = await getAccessTokenSilently();
            const response = await fetch(`${API_URL}/session/decline/${session_id}`, {
                method: "POST",
                mode: "cors",
                headers: createHeaders(token),
                body: JSON.stringify({ reason }),
            });
            if (response.status === 200) {
                onDone();
            } else {
                const { error } = await response.json();
                throw Error(error);
            }
        } catch (error) {
            console.error(error);
            setError("Failed to decline session. Please contact support.")
            alert.error("Failed to decline session. Please contact support.");
        } finally {
            setLoading(false)
        }
    };

    return { decline, is_loading, error }

}

export default useDeclineSession;