import classNames from "classnames/bind";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { NavType } from "../constants";
import { IMenuItem } from "../types";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import MentorpassLogo from "images/mp-logo.svg";
import MentorpassLogoText from "images/mp-logo-text.svg";
import { LogoutIcon, LogoutIconActive, MobileNavClose } from "components/Icons";
import MenuItem from "./MenuItem";
import styles from "./SideMenu.module.css";

type SideMenuProps = {
  path: string,
  menu: IMenuItem[];
  navigateTo: (id: string) => void;
  promptLogout: () => void;
};

const SideMenu = ({
  path,
  menu,
  navigateTo,
  promptLogout,
}: SideMenuProps) => {
  return (
    <>
      <div data-testid="SideMenu_desktop" className={styles.menu}>
        <div
          className={styles.menuLogoContainer}
          onClick={() => navigateTo("/")}
        >
          <img alt="logo" src={MentorpassLogo} className={styles.mpLogoNav} />
          <img
            alt="logo"
            src={MentorpassLogoText}
            className={styles.mpLogoTextNav}
          />
        </div>
        <div>
          <>
            {menu.map(
              (item) =>
                item.type === NavType.NAVIGATION && (
                  <MenuItem
                    key={item.id}
                    {...item}
                    active={item.id === path}
                    onClick={() => navigateTo(item.id)}
                  />
                )
            )}
          </>
          <div
            style={{
              position: "absolute",
              bottom: "0px",
              marginBottom: "24px",
            }}
          >
            {menu.map(
              (item) =>
                item.type === NavType.ACCOUNT && (
                  <MenuItem
                    key={item.id}
                    {...item}
                    active={item.id === path}
                    onClick={() => navigateTo(item.id)}
                  />
                )
            )}
            <MenuItem
              id="logout"
              type={NavType.ACCOUNT}
              icon={LogoutIcon}
              activeIcon={LogoutIconActive}
              text={"Logout"}
              active={"logout" === path}
              onClick={promptLogout}
            />
          </div>
        </div>
      </div>
      <div className={styles.menuBg} />
    </>
  );
};

export default SideMenu;
