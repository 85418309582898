import { CrossIcon, WriteIcon } from "../../Icons";

import Styles from "./ProfileHighlight.module.css";

interface Highlight {
  title: string;
  description: string;
  url?: string;
}

interface ProfileHighlightProps {
  data: Highlight;
  onDelete: (id: string) => void;
  onSubmit: (value: Highlight) => void;
}

const ProfileHighlight: React.FC<ProfileHighlightProps> = ({
  data,
  onDelete,
  onSubmit,
}) => (
  <div data-testid="ProfileHighlight" className="highlight-container">
    <div className="header-action">
      <div>
        <h4>{data.title}</h4>
      </div>
      <div className={Styles.action_icon}>
        <img
          style={{ height: "20px" }}
          src={WriteIcon}
          onClick={() => onSubmit(data)}
          alt="Edit Hightlight Icon"
        />
        <img
          src={CrossIcon}
          onClick={() => onDelete(data.title)}
          style={{ margin: "0px 0px 3px 12px" }}
          alt="Delete Hightlight Icon"
        />
      </div>
    </div>
    <div className="flex-column">
      {data.url && (
        <p
          style={{
            fontStyle: "italic",
            color: "var(--body-text-on-white)",
          }}
        >
          {data.url}
        </p>
      )}
      <p>{data.description}</p>
    </div>
  </div>
);

export default ProfileHighlight;
