import React, { useContext, useEffect, useState } from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import styles from "./CreateProfile.module.css";
import { ThreeDots } from "react-loader-spinner";
import { AdditionalDetailsFormSubmission } from "./Schemas";
import AdditionalDetailsForm from "./AdditionalDetailsForm.component";

import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { useActor } from "@xstate/react";

const CreatingProfileLoadingScreen = ({
  loading_text,
}: {
  loading_text: string[];
}) => {
  const [index, setLoadingTextIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("new_interval");
      console.log(index);
      setLoadingTextIndex((previous_index) =>
        previous_index >= loading_text.length - 1 ? 0 : previous_index + 1
      );
    }, 3200);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className={styles.creating_profiles_loading}>
      <h4 className={styles.loading_text}>{loading_text[index]}</h4>
      <ThreeDots
        height={20}
        width={42}
        ariaLabel="progress-bar-loading"
        color="#5440F0"
      />
    </div>
  );
};

enum ProfileType {
  MENTEE_ADMIN = "mentee.owner",
  MENTEE_TEAM_MEMBER = "mentee.team_member",
  MENTOR = "mentor",
}

enum UI_STATE {
  DETAILS_REQUIRED = "details_required",
  CREATING = "creating",
  ERROR = "error",
  CREATED = "created",
  CLAIMING_PENDING_SESSION = "claiming_pending_session",
}

const signupStateMatches = (profile_type: ProfileType, state_leaf: UI_STATE) =>
  `${profile_type}.signup.${state_leaf}`;

const DETAILS_REQUIRED_STATES = [
  signupStateMatches(ProfileType.MENTEE_ADMIN, UI_STATE.DETAILS_REQUIRED),
  signupStateMatches(ProfileType.MENTEE_TEAM_MEMBER, UI_STATE.DETAILS_REQUIRED),
  signupStateMatches(ProfileType.MENTOR, UI_STATE.DETAILS_REQUIRED),
];

const CREATING_STATES = [
  signupStateMatches(ProfileType.MENTEE_ADMIN, UI_STATE.CREATING),
  signupStateMatches(ProfileType.MENTEE_TEAM_MEMBER, UI_STATE.CREATING),
  signupStateMatches(ProfileType.MENTOR, UI_STATE.CREATING),
];

const ERROR_STATES = [
  signupStateMatches(ProfileType.MENTEE_ADMIN, UI_STATE.ERROR),
  signupStateMatches(ProfileType.MENTEE_TEAM_MEMBER, UI_STATE.ERROR),
  signupStateMatches(ProfileType.MENTOR, UI_STATE.ERROR),
];

const CREATED_STATES = [
  signupStateMatches(ProfileType.MENTEE_ADMIN, UI_STATE.CREATED),
  signupStateMatches(ProfileType.MENTEE_TEAM_MEMBER, UI_STATE.CREATED),
  signupStateMatches(ProfileType.MENTOR, UI_STATE.CREATED),
];

const LOADING_TEXT: Record<ProfileType, string[]> = {
  [ProfileType.MENTEE_ADMIN]: [
    "Creating profile",
    "Assembling mentors",
    "Building reccomendations",
    "Connecting to AI",
  ],
  [ProfileType.MENTEE_TEAM_MEMBER]: [
    "Creating profile",
    "Checking invite code",
    "Joining Team",
    "Connecting to AI",
  ],
  [ProfileType.MENTOR]: [
    "Creating profile",
    "Building marketing page",
    "Generating OpenGraph image",
    "Optimizing mentor ranking",
  ],
};

const CreateProfileScreen = () => {
  const globalServices = useContext(GlobalServiceContext);
  const [state, send] = useActor(globalServices.profile_service);
  const [ui_state, setUIState] = useState<UI_STATE | undefined>();
  const [profile_type, setProfileType] = useState<ProfileType | undefined>();

  useEffect(() => {
    switch (true) {
      case DETAILS_REQUIRED_STATES.some(state.matches):
        setUIState(UI_STATE.DETAILS_REQUIRED);
        break;
      case CREATING_STATES.some(state.matches):
        setUIState(UI_STATE.CREATING);
        break;
      case ERROR_STATES.some(state.matches):
        setUIState(UI_STATE.ERROR);
        break;
      case CREATED_STATES.some(state.matches):
        setUIState(UI_STATE.CREATED);
        break;
      case state.matches("mentee.claming_pending_session"):
        setUIState(UI_STATE.CLAIMING_PENDING_SESSION);
        break;
      default:
        return;
    }
  }, [state]);

  useEffect(() => {
    switch (true) {
      case state.matches(ProfileType.MENTEE_ADMIN):
        setProfileType(ProfileType.MENTEE_ADMIN);
        break;
      case state.matches(ProfileType.MENTEE_TEAM_MEMBER):
        setProfileType(ProfileType.MENTEE_TEAM_MEMBER);
        break;
      case state.matches(ProfileType.MENTOR):
        setProfileType(ProfileType.MENTOR);
        break;
      default:
        return;
    }
  }, [state]);

  const updateAdditionalDetails = async (
    values: AdditionalDetailsFormSubmission,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    const name_segments = values.name.split(" ");
    const given_name = name_segments[0];
    let family_name =
      name_segments.length > 1 ? name_segments.slice(1).join(" ") : "";
    send({
      type: "SET_DETAILS",
      data: {
        given_name,
        family_name,
      },
    });
    setSubmitting(false);
  };

  const display = () => {
    switch (ui_state) {
      case UI_STATE.CREATING:
        return (
          <CreatingProfileLoadingScreen
            loading_text={
              !!profile_type ? LOADING_TEXT[profile_type] : ["Creating..."]
            }
          />
        );
      case UI_STATE.DETAILS_REQUIRED:
        return (
          <AdditionalDetailsForm
            updateAdditionalDetails={updateAdditionalDetails}
          />
        );
      default:
        return (
          <div data-test="create_profile_loading">
            <ThreeDots
              wrapperStyle={{ margin: "auto", display: "block" }}
              color="#5440F0"
              height={20}
              width={42}
            />
          </div>
        );
    }
  };

  return (
    <div data-testid="CreateProfile" className={styles.container}>
      <div className={styles.body}>
        <div>
          <div className={styles.login_header}>
            <img src="https://storage.googleapis.com/marketing-assets.mentorpass.co/app-assets/mentorpass-icon.png" />
          </div>
          <div>{display()}</div>
        </div>
      </div>
    </div>
  );
};

export default withAuthenticationRequired(CreateProfileScreen);
