export const DOMAINS = [
  "Ideation",
  "Product",
  "Growth",
  "Operations",
  "Performance",
];

const ideation = [
  "Idea Validation",
  "MVP",
  "Customer Development",
  "Market Research",
  "Business Models",
  "Business Planning",
];

const product = [
  "Product Management",
  "Product Design",
  "Tech Architecture",
  "No-Code",
  "Engineering",
];

const growth = [
  "Marketing Strategy",
  "Branding",
  "Copywriting",
  "Go to Market",
  "Social Media Marketing",
  "Search and SEO marketing",
  "Email Marketing",
  "Paid Marketing",
  "Growth Hacking",
  "Community Management",
  "Business Development",
  "Sales Funnels",
  "Direct Sales",
];

const operations = [
  "Business Leadership",
  "Business Strategy",
  "Business Operations",
  "Team Building",
  "Financials",
  "Bootstrapping",
  "Fundraising",
  "Culture",
  "Legal",
];

const performance = [
  "Founder Coach",
  "Mindset",
  "Relationships",
  "Health and Nutrition",
  "Mental Health",
  "Mindfulness",
];

export const SKILLS = [
  "Idea Validation",
  "MVP",
  "Customer Development",
  "Market Research",
  "Business Models",
  "Business Planning",
  "Product Management",
  "Product Design",
  "Tech Architecture",
  "No-Code",
  "Engineering",
  "Marketing Strategy",
  "Branding",
  "Copywriting",
  "Go to Market",
  "Social Media Marketing",
  "Search and SEO marketing",
  "Email Marketing",
  "Paid Marketing",
  "Growth Hacking",
  "Community Management",
  "Business Development",
  "Sales Funnels",
  "Direct Sales",
  "Business Leadership",
  "Business Strategy",
  "Business Operations",
  "Team Building",
  "Financials",
  "Bootstrapping",
  "Fundraising",
  "Culture",
  "Legal",
  "Founder Coach",
  "Mindset",
  "Relationships",
  "Health and Nutrition",
  "Mental Health",
  "Mindfulness",
];

export const FOCUS = { ideation, product, growth, operations, performance };
