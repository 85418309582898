import { useAuth0 } from "@auth0/auth0-react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { OKRQueryResult } from "entities/OKR";
import { useAlert } from "@blaumaus/react-alert";
import { API_URL, createHeaders } from "utils/api";
import { ALL_OBJECTIVES_QUERY_KEY } from "../useObjectives";

const deleteKeyResult = async (token: string, okrId: string, keyResultId: string): Promise<void> => {
    await fetch(`${API_URL}/okr/${okrId}/key_result/${keyResultId}/delete`, {
        method: 'DELETE',
        headers: createHeaders(token),
    });
};

export const useDeleteKeyResult = (okr_id: string) => {
    const { getAccessTokenSilently } = useAuth0();
    const query_client = useQueryClient();
    const alert = useAlert();

    return useMutation((key_result_id: string) => getAccessTokenSilently().then(token => deleteKeyResult(token, okr_id, key_result_id)), {
        onMutate: async (key_result_id: string) => {
            await query_client.cancelQueries(['okr', okr_id]);
            await query_client.cancelQueries([ALL_OBJECTIVES_QUERY_KEY]);

            const previous_objective = query_client.getQueryData(['okr', okr_id]);
            // Optimistically update the cache to remove the key result from the okr.
            query_client.setQueryData<OKRQueryResult>(['okr', okr_id], (old_data) => {
                if (!old_data) return;
                const updated_key_results = (old_data?.key_results || []).filter((key_result) => key_result._id !== key_result_id);
                return {
                    ...old_data,
                    key_results: updated_key_results,
                };
            });

            // Optimistically update the cache to remove the key result from the entry in the okrs list.
            query_client.setQueryData<OKRQueryResult[]>([ALL_OBJECTIVES_QUERY_KEY], (okrs) => {
                if (!okrs) return [];
                const okr_index = okrs?.findIndex((okr) => okr._id === okr_id);
                if (okr_index !== -1) {
                    const updated_key_results = (okrs[okr_index]?.key_results || []).filter((key_result) => key_result._id !== key_result_id);
                    okrs[okr_index] = {
                        ...okrs[okr_index],
                        key_results: updated_key_results,
                    }
                }
                return okrs;
            });

            return { previous_objective };
        },
        onError: async (err, _, context) => {
            alert.error("Failed to delete key result.");
            query_client.setQueryData(['okr', okr_id], context?.previous_objective);
        },
        onSettled: async () => {
            query_client.invalidateQueries(['okr', okr_id]);
            query_client.invalidateQueries([ALL_OBJECTIVES_QUERY_KEY]);
            alert.success("Key result deleted!");
        },
    });
};