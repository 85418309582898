import React from "react";
import PropTypes from "prop-types";
import { EyeIcon } from "../../../../../components/Icons";
import style from "../MissedSession.module.css";

const Step3 = ({ history }) => {
	return (
		<div className={style.step}>
			<div className={style.primeRect}>
				<div className={style.darkCircle}>
					<img src={EyeIcon} alt="eye icon" />
				</div>
			</div>
			<div className={style.messageContent}>
				<h2>We&apos;re investigating</h2>
				<p>
					You can expect a response from our team shortly. Thank you for taking
					the time to help make MentorPass a better community for entrepreneurs
					to learn and grow.
				</p>
			</div>

			<button className={style.button} onClick={() => history.push("/")}>
				Go to dashboard
			</button>
		</div>
	);
};

Step3.propTypes = {
	history: PropTypes.object,
};

export default Step3;
