import { useAuth0 } from "@auth0/auth0-react";
import { OKRQueryResult } from "entities/OKR";
import { useQuery } from "@tanstack/react-query";
import { API_URL, createHeaders } from "utils/api";

export const ALL_OBJECTIVES_QUERY_KEY = "objectives";

const fetchOKRs = async (token: string): Promise<OKRQueryResult[]> => {
    const response = await fetch(`${API_URL}/okr/all`, {
        headers: createHeaders(token),
        mode: "cors",
        method: "GET",
    });
    const data = await response.json();
    return data;
};

export const useObjectives = () => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery({
        queryKey: [ALL_OBJECTIVES_QUERY_KEY],
        queryFn: () => getAccessTokenSilently().then(token => fetchOKRs(token))
    });
};