import { z } from 'zod';

export const OBJECTIVE_STATUS = z.enum(["in_progress", "completed", "archived"]);

export const OBJECTIVE_SUBJECT = z.enum(["Growth", "Ideation", "Operations", "Performance", "Product"]);

export const KeyResultSchema = z.object({
  _id: z.string(),
  title: z.string(),
  completedAt: z.string().nullable(),
  createdAt: z.string(),
});

export const AttachmentSchema = z.object({
  filename: z.string(),
  handle: z.string().optional(),
  mimetype: z.string().optional(),
  status: z.string().optional(),
  source: z.string().optional(),
  size: z.number().optional(),
  uploadId: z.string().optional(),
  url: z.string(),
  addedFrom: z.string().optional(),
});

export const OKRSchema = z.object({
  owner: z.string(),
  title: z.string(),
  description: z.string().optional(),
  subject: OBJECTIVE_SUBJECT,
  due_date: z.string(),
  key_results: z.array(KeyResultSchema).default([]),
  attachments: z.array(AttachmentSchema).default([]),
  status: OBJECTIVE_STATUS.default("in_progress"),
  events: z.array(z.unknown()).optional(),
});

type OKR = z.infer<typeof OKRSchema>;

type OKRQueryResult = OKR & { _id: string };

type KeyResult = z.infer<typeof KeyResultSchema>;

type Attachment = z.infer<typeof AttachmentSchema>;

enum OKREventType {
  CREATED = "CREATED",
  DELETED = "DELETED",
  KEY_RESULT_ADDED = "KEY_RESULT_ADDED",
  KEY_RESULT_REMOVED = "KEY_RESULT_REMOVED",
  KEY_RESULT_CHECKED = "KEY_RESULT_CHECKED",
  USER_COMMENTED = "USER_COMMENTED",
}

interface OKREvent<T> {
  type: OKREventType;
  date: string;
  metadata: T;
}

interface CreateOKREvent extends OKREvent<OKR> {
  type: OKREventType.CREATED;
};
interface DeleteOKREvent extends OKREvent<{ _id: string }> {
  type: OKREventType.DELETED
}
interface AddKeyResultOKREvent extends OKREvent<KeyResult> {
  type: OKREventType.KEY_RESULT_ADDED
}
interface RemoveKeyResultOKREvent extends OKREvent<{ _id: string }> {
  type: OKREventType.KEY_RESULT_REMOVED
}
interface CheckKeyResultOKREvent extends OKREvent<{ _id: string }> {
  type: OKREventType.KEY_RESULT_CHECKED
}
interface UserCommentedOKREvent extends OKREvent<{ user_id: string, comment: string }> { }

type OKREvents = CreateOKREvent | DeleteOKREvent | AddKeyResultOKREvent | RemoveKeyResultOKREvent | CheckKeyResultOKREvent | UserCommentedOKREvent;

type ObjectiveUpdate = {
  title: string;
  due_date: string;
  subject: string;
  key_results: KeyResult[];
  attachments: Attachment[];
};

export type {
  Attachment,
  KeyResult,
  OKREvents,
  OKREventType,
  OKRQueryResult,
  OKR,
  ObjectiveUpdate,
}