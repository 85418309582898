import style from "./TabHeader.module.css";

interface TabOption {
  id: string;
  title: string;
  alert_quantity: number;
}

interface TabHeaderProps {
  active_tab: string;
  options: TabOption[];
  onTabChange: (value: string) => void;
}

const TabHeader: React.FC<TabHeaderProps> = ({
  active_tab,
  onTabChange,
  options,
}) => {
  return (
    <div className={style.header}>
      {options.map(({ id, title, alert_quantity }) => (
        <div
          key={`${id}-tab`}
          data-testid={`${id}-tab`}
          onClick={() => onTabChange(id)}
          className={active_tab === id ? style.selected : ""}
        >
          <h4>
            {title}
            {alert_quantity > 0 && ` (${alert_quantity})`}
          </h4>
        </div>
      ))}
    </div>
  );
};

export default TabHeader;
