import { useEffect, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  OnDragEndResponder,
  //@ts-ignore
} from "react-beautiful-dnd";

import { getItemStyle, getListStyle, reorder } from "./helpers";

type ItemsType = Array<{
  id: string;
  content: React.ReactNode;
  value: any;
}>;

interface IVerticalListProps {
  defaultItems: ItemsType;
  onUpdate: (arg: any) => void;
}

const VerticalList: React.FC<IVerticalListProps> = ({
  defaultItems,
  onUpdate,
}) => {
  const [items, setItems] = useState<ItemsType>([]);

  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);

  const onDragEnd: OnDragEndResponder = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const newItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(newItems);

    onUpdate(newItems.map((ni) => ni.value));
  };

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style as DraggingStyle
                    )}
                  >
                    {item.content}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VerticalList;
