import DropdownSelector from "components/Atoms/DropdownSelector";
import Button from "components/Atoms/Button";
import Checkbox from "components/Atoms/Checkbox";

import styles from "./MentorFilters.module.css";

export type PriceFilter = {
  title: string;
  value: string;
};

export type SortOption = {
  title: string;
  value: string;
};

interface MentorFilterProps {
  filters: {
    favourites: boolean;
    price: PriceFilter;
    sort_type: SortOption;
    skill: string;
  };
  options: {
    sort: SortOption[];
    price: PriceFilter[];
    skills: { title: string; value: string }[];
  };
  toggleFavouritesFilter: () => void;
  setPriceFilter: (value: PriceFilter) => void;
  setSortOption: (value: SortOption) => void;
  setSelectedSkill: (value: string) => void;
  clearFilters: () => void;
}

const MentorFilters: React.FC<MentorFilterProps> = ({
  filters,
  options,
  toggleFavouritesFilter,
  setPriceFilter,
  setSortOption,
  setSelectedSkill,
  clearFilters,
}) => {
  return (
    <div data-testid="mentor_filters" className={styles.container}>
      <h3 className={styles.title}>Filter Mentors</h3>
      <div>
        <h4>Skill</h4>
        <DropdownSelector
          placeholder="Skill"
          options={options.skills}
          value={{ title: filters.skill, value: filters.skill }}
          onSelect={(s) => setSelectedSkill(s.value)}
          dark
          style={{
            width: "100%",
            marginTop: "8px",
            transition: "all 0.4s linear",
          }}
        />
      </div>
      <div>
        <h4>Price</h4>
        <DropdownSelector
          value={filters.price}
          placeholder={""}
          options={options.price}
          onSelect={setPriceFilter}
          dark
          style={{
            width: "100%",
            marginTop: "8px",
          }}
        />
      </div>
      <div>
        <h4>Sort by</h4>
        <DropdownSelector
          value={filters.sort_type}
          placeholder={"Sort By"}
          options={options.sort}
          onSelect={(s) => setSortOption(s)}
          dark
          style={{
            width: "100%",
            marginTop: "8px",
          }}
        />
      </div>
      <Checkbox
        id={"favourites-filter"}
        name="following"
        label="Following"
        error={undefined}
        checked={filters.favourites}
        handleChange={toggleFavouritesFilter}
        isDark
      />
      <Button variant="dark" onClick={clearFilters} style={{ width: "100%" }}>
        Clear Filters
      </Button>
    </div>
  );
};

export default MentorFilters;
