/* eslint-disable react/prop-types */
import React, { ChangeEvent, useState } from "react";
import { BlackCrossIcon } from "../../../Icons";
import { Highlight } from "entities/Mentor";

interface AddHighlightProps {
	highlight: Highlight
	setHighlight: (highlight: Highlight) => void;
	saveHighlight: () => void;
	closeModal: () => void;
}

const AddHighlightModal = ({ highlight, setHighlight, saveHighlight, closeModal }: AddHighlightProps) => {

	const onChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
		setHighlight({ ...highlight, [e.target.name]: e.target.value });


	return (
		<div className="modal-content">
			<div className="header">
				<div>
					<p className="account-title-black">Add Highlights</p>
					<p className="modal-helper-text">
						Add career highlights to let clients know what makes you, you.
					</p>
				</div>
				<div className="action">
					<img src={BlackCrossIcon} alt="cross" onClick={closeModal} />
				</div>
			</div>
			<div className="content">
				<div className="highlight-input" style={{ width: "100%" }}>
					<div style={{ width: "100%" }}>
						<label htmlFor="title">
							Title<span className="required">*</span>
						</label>
						<input
							data-hj-allow
							name="title"
							type="text"
							value={highlight.title}
							onChange={onChange}
						/>
					</div>
				</div>
				<div className="highlight-textarea">
					<label htmlFor="description">
						Highlight Description <span className="required">*</span>
					</label>
					<textarea
						name="description"
						onChange={onChange}
						rows={5}
						cols={33}
						value={highlight.description}
					/>
				</div>
				<div className="modal-action">
					<button onClick={saveHighlight}>Save</button>
				</div>
			</div>
		</div>
	);
};

export default AddHighlightModal;
