import { useState } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import { UserMetadata, UserMetadataSchema } from "entities/User";


const usePasswordReset = () => {
    const { getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState<string | null>(null);
    const [is_loading, setIsLoading] = useState<boolean>(false);

    const redirectToPasswordReset = async () => {
        try {
            const token = await getAccessTokenSilently();
            setIsLoading(true);
            console.log(token);
            const response = await fetch(`${API_URL}/user/reset-password`, {
                method: "GET",
                headers: createHeaders(token),
            });
            if (!response.ok) {
                throw Error("Failed to update user metadata.");
            }

            const { url } = await response.json();

            window.location.replace(url);
        } catch (error) {
            console.log(error);
            setError(String(error));
            setIsLoading(false);
        }
    }

    return { error, is_loading, redirectToPasswordReset};
};

export default usePasswordReset;
