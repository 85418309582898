import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { API_URL, createHeaders } from "../../../../utils/api";
import style from "./MissedSession.module.css";
import {
  Step1,
  Step2,
  Investigating,
  PreTextReason,
  ThankYou,
} from "./components";
import { ThreeDots } from "react-loader-spinner";
import { useAuth0 } from "@auth0/auth0-react";

const MissedSession = ({ history }) => {
  const { session_id } = useParams();
  const dispatch = useDispatch();
  const profileId = useSelector(
    (state) => state.profileReducer.selectedProfile
  );
  const [tab, setTab] = useState(0);
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [survey, setServey] = useState({
    feedback: null,
    reason: null,
    reasonText: null,
  });

  const submitFeedback = async () => {
    const ops = { menteeMissedSurvey: survey };
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/session/${session_id}`, {
      method: "PATCH",
      mode: "cors",
      body: JSON.stringify({ ops }),
      headers: createHeaders(token),
    });
    if (response.status === 200) {
      // alert success
      dispatch({
        type: "SET_ALERT",
        payload: {
          type: "success",
          message: "Feedback Sent!",
        },
      });
    } else {
      // alert error
      dispatch({
        type: "SET_ALERT",
        payload: {
          type: "error",
          message: "Failed to report missed Session!",
        },
      });
      history.push({
        pathname: "/error",
        state: { errorId: new Date(), profileId },
      });
    }
  };

  const toggleTabs = async () => {
    setLoading(true);
    if (tab === 2) {
      await submitFeedback();
    }
    if (tab === 0 && survey.reason.id !== "A") {
      setTab(2);
    } else {
      setTab(tab + 1);
    }

    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const updateSurvey = (key, value) => setServey({ ...survey, [key]: value });
  const skipFeedback = async () => {
    setServey({ ...survey, feedback: null });
    await toggleTabs();
  };

  const STEPS = {
    0: <Step1 updateSurvey={updateSurvey} nextStep={toggleTabs} />,
    1: <PreTextReason updateSurvey={updateSurvey} nextStep={toggleTabs} />,
    2: (
      <Step2
        updateSurvey={updateSurvey}
        nextStep={toggleTabs}
        skipFeedback={skipFeedback}
        isThirdQuestion={!!survey.reasonText}
      />
    ),
    3:
      survey.reason && survey.reason.id === "A" ? (
        <ThankYou history={history} />
      ) : (
        <Investigating history={history} />
      ),
  };
  return (
    <div className={style.missedSession}>
      {loading ? <ThreeDots wrapperStyle={{ margin: "auto", display: "block" }} color="#fff" height={40} width={40} /> : STEPS[tab]}
    </div>
  );
};

MissedSession.propTypes = {
  history: PropTypes.object,
};

export default MissedSession;
