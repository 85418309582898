import { KeyResult } from "entities/OKR";
import { useAddKeyResult } from "hooks/mentee/okr/key_result/useAddKeyResult";
import { useDeleteKeyResult } from "hooks/mentee/okr/key_result/useDeleteKeyResult";
import { useToggleKeyResultStatus } from "hooks/mentee/okr/key_result/useToggleKeyResultStatus";
import ObjectiveListItem from "components/Molecules/ObjectiveListItem";
import ViewObjective from "components/Molecules/ViewObjective";

import styles from "./ObjectiveListItemFragment.module.css";

interface ObjectiveListItemProps {
  _id: string;
  title: string;
  due_date: string;
  status: string;
  key_results: KeyResult[];
  viewObjective: (objective_id: string) => void;
  editObjective: (objective_id: string) => void;
}

const ObjectiveListItemFragment = (props: ObjectiveListItemProps) => {
  const { mutate: addKeyResult } = useAddKeyResult(props._id);
  const { mutate: deleteKeyResult } = useDeleteKeyResult(props._id);
  const { mutate: toggleKeyResult } = useToggleKeyResultStatus(props._id);

  // console.log('ObjectiveListItem', props.key_results);

  return (
    <>
      <div className={styles.desktop}>
        <ObjectiveListItem
          {...props}
          addKeyResult={addKeyResult}
          deleteKeyResult={deleteKeyResult}
          toggleKeyResult={toggleKeyResult}
        />
      </div>
      <div className={styles.mobile}>
        <ViewObjective
          sidebar={false}
          {...props}
          sessions={[]}
          activity={[]}
          addKeyResult={addKeyResult}
          deleteKeyResult={deleteKeyResult}
          toggleKeyResult={toggleKeyResult}
        />
      </div>
    </>
  );
};

export default ObjectiveListItemFragment;
