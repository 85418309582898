import { useAuth0 } from "@auth0/auth0-react";
import { MentorCollection } from "entities/MentorCollection";
import { on } from "events";
import { useState } from "react";
import { API_URL, createHeaders } from "utils/api";

interface useCreateCollectionProps {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

const useCreateCollection = ({
  onSuccess,
  onError,
}: useCreateCollectionProps) => {
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const { getAccessTokenSilently } = useAuth0();

  const create = async (data: MentorCollection) => {
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/mentor_collection/new`, {
        method: "POST",
        mode: "cors",
        headers: createHeaders(token),
        body: JSON.stringify(data),
      });
      if (response.ok) {
        onSuccess && onSuccess();
        setError(undefined);
      } else {
        throw new Error("Failed to create collection");
      }
    } catch (error) {
      onError && onError(String(error));
      setError(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  return { create, is_loading, error };
};

export default useCreateCollection;
