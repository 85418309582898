import { createContext } from "react";
import { InterpreterFrom, createMachine } from "xstate";
import { MenuMachineContext, MenuEvent } from "./types";

const mobile_menu_machine = createMachine<MenuMachineContext, MenuEvent>({
  /** @xstate-layout N4IgpgJg5mDOIC5QFsD2AjAlgGzAfWTADsBXAOgGNtVZIBiAFQHkBxFgGQFEBtABgF1EoAA41MAF0yoiQkAA9EARgCsAdjIAmAMwAWLauUAaEAE9EAWkUA2MooCcyrYoAcW3q43OdOgL4-jaFi4BMTkqMLEjKwcPAKyorASUjJI8kpqmrr6RqaIGrzKZFp2Xk5WdtaqKlZ+ARg4+ISkZOGRAHIAggBqAJIsHQyxgqkJSdKyCgiKOuqOiho5ZghW6sWl1hUr1X7+IESoEHCygQ0hpPFikuOpk+bFZN5zC8ZLlrxaZOVzru5ant61EAnYJNchUGiQC6JK4pUCTLTKHQPLQIuzZF6IOy8IqqHR2Ow6eyVba7YGNUItCKwkCjGETRDOMjKRFWZwGDEILE4vEEolbZQ1HZAA */
  id: "mobile_menu",
  initial: "closed",
  on: {
    NAVIGATE: {
      actions: ["navigateTo"],
      target: "closed",
    },
  },
  states: {
    closed: {
      on: {
        TOGGLE: "open",
      },
    },
    open: {
      on: {
        TOGGLE: "closed",
      },
    },
  },
});

const MenuContext = createContext({
  menu_service: {} as InterpreterFrom<typeof mobile_menu_machine>,
});

export { mobile_menu_machine, MenuContext };