import styles from "./CaseStudy.module.css";
import Headshot from "./Images/bj-shannon.png";
import Button from "components/Atoms/Button";

const CaseStudy = () => {
  const goToTop = () =>
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  return (
    <div className={styles.container}>
      <h3>
        “The team is obsessed, it’s our best tool for employee retenetion.”
      </h3>
      <div className={styles.headshot}>
        <img src={Headshot} />
        <h4>BJ Shannon</h4>
        <p>Chief Customer Officer @ Wunderground</p>
      </div>
      <Button onClick={goToTop}>Get Access</Button>
    </div>
  );
};

export default CaseStudy;
