import { useQuery } from '@tanstack/react-query';
import { API_URL, createHeaders } from 'utils/api';
import { useAuth0 } from '@auth0/auth0-react';

export interface Stat {
    title: string;
    value: string | number;
    link?: string;
    help?: string;
}

const fetchPerformanceData = async (token: string, period: number): Promise<Stat[]> => {
    const response = await fetch(`${API_URL}/stats/mentor/performance?period=${period}`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
    });
    if (response.status !== 200) {
        const error = await response.json();
        throw new Error(error);
    }
    const { performance } = await response.json();
    return performance;
};

// Use the useQuery hook in your component
const usePerformanceStats = (period: number) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<Stat[]>({
        queryKey: ["mentor_performance", period],
        queryFn: async () => getAccessTokenSilently().then(token => fetchPerformanceData(token, period)),
    });
};


export default usePerformanceStats;