import React from "react";
import styles from "./ClaimPendingSessionSuccess.module.css";
import Avatar from "components/Atoms/Avatar";
import moment from "moment";
import Loading from "components/Molecules/Loading";
import { ThreeDots } from "react-loader-spinner";

export interface ClaimPendingSessionSuccessProps {
  is_loading: boolean;
  mentor: {
    avatar_url: string;
    name: string;
  };
  session_details: {
    duration: number;
    slot: number;
  };
  closeModal: () => void;
}

const ClaimPendingSessionSuccess = ({
  is_loading,
  mentor,
  session_details,
  closeModal,
}: ClaimPendingSessionSuccessProps) => {
  const session_datetime =
    moment(session_details.slot).format("ddd MMM D h:mma z") || "";

  return (
    <div
      data-testid="claim_pending_session_success"
      className={styles.container}
    >
      {is_loading ? (
        <ThreeDots
          wrapperStyle={{ margin: "auto", display: "block" }}
          color="#fff"
          height={20}
          width={42}
        />
      ) : (
        <>
          <div className={styles.mentor}>
            <Avatar
              src={mentor.avatar_url}
              name={mentor.name}
              loading={"lazy"}
              size={80}
            />
            <div className={styles.mentor_info}>
              <h3>{mentor.name}</h3>
              <div className={styles.details_container}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M7.99967 14.6667C4.31777 14.6667 1.33301 11.6819 1.33301 8.00004C1.33301 4.31814 4.31777 1.33337 7.99967 1.33337C11.6815 1.33337 14.6663 4.31814 14.6663 8.00004C14.6663 11.6819 11.6815 14.6667 7.99967 14.6667ZM7.99967 13.3334C10.9452 13.3334 13.333 10.9456 13.333 8.00004C13.333 5.05452 10.9452 2.66671 7.99967 2.66671C5.05415 2.66671 2.66634 5.05452 2.66634 8.00004C2.66634 10.9456 5.05415 13.3334 7.99967 13.3334ZM8.66634 8.00004H11.333V9.33337H7.33301V4.66671H8.66634V8.00004Z"
                    fill="#BFBFBF"
                  />
                </svg>
                <p>
                  {session_details.duration} min with {mentor.name}
                </p>
              </div>
              <div className={styles.details_container}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M5.99967 0.666626V1.99996H9.99967V0.666626H11.333V1.99996H13.9997C14.3679 1.99996 14.6663 2.29844 14.6663 2.66663V13.3333C14.6663 13.7015 14.3679 14 13.9997 14H1.99967C1.63149 14 1.33301 13.7015 1.33301 13.3333V2.66663C1.33301 2.29844 1.63149 1.99996 1.99967 1.99996H4.66634V0.666626H5.99967ZM13.333 7.33329H2.66634V12.6666H13.333V7.33329ZM4.66634 3.33329H2.66634V5.99996H13.333V3.33329H11.333V4.66663H9.99967V3.33329H5.99967V4.66663H4.66634V3.33329Z"
                    fill="#BFBFBF"
                  />
                </svg>
                <p>{session_datetime}</p>
              </div>
            </div>
          </div>
          <div className={styles.main_content}>
            <h2>Session Requested!</h2>
            <p>
              You payment was successful and your session with Nik has been
              requested. It should be confirmed within the next 48hrs.
            </p>
          </div>
          <button data-testid={"close_modal_button"} onClick={closeModal}>
            Go to dashboard
          </button>
        </>
      )}
    </div>
  );
};

export default ClaimPendingSessionSuccess;
