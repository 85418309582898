
import React from "react";
import SetHoursComponent from "./SetHours"
import { WeekDay, defaultWeeklyAvailability } from "entities/Mentor";
import useMyProfile from "hooks/useMyProfile";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import { AvailableWeek, isMentor } from "entities/Mentor";

interface SetHourProps { day: WeekDay; closeModal: () => void }

const SetHours = ({ day, closeModal }: SetHourProps) => {

    const { profile } = useMyProfile();
    const { update, is_loading } = useUpdateMentor();

    if (!isMentor(profile)) {
        throw Error("Only mentors can set available hours")
    }

    const availability =
        profile?.availability || defaultWeeklyAvailability;

    const updateAvailability = (availability: AvailableWeek) => {
        update({
            availability
        })
    }

    return <SetHoursComponent
        day={day}
        availability={availability}
        is_loading={is_loading}
        update={updateAvailability}
        closeModal={closeModal}
    />
}

export default SetHours;