import { useLocation, useNavigate } from "react-router-dom";

import OnboardingProgressComponent from "./OnboardingProgress";
import useBusiness from "hooks/mentee/useBusiness";

const ONBOARDING_STEPS = [
  {
    id: "personal",
    path: "personal",
    title: "User Profile",
    is_complete: false,
    is_current_step: false,
  },
  {
    id: "company",
    path: "company",
    title: "Company Profile",
    is_complete: false,
    is_current_step: false,
  },
  {
    id: "objective",
    path: "objective",
    title: "Objective",
    is_complete: false,
    is_current_step: false,
  },
];

const OnboardingProgress = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const changeStep = (path: string) => navigate(`/start/${path}`);
  const { user_is_admin } = useBusiness();
  // Modify the steps base on profile role.
  // Modify the completed sections based on fetched data.
  return (
    <OnboardingProgressComponent
      steps={ONBOARDING_STEPS.filter((step) => {
        if (!user_is_admin) {
          return step.id !== "company";
        }
        return true;
      }).map((step) => ({
        ...step,
        is_current_step: pathname.includes(step.path),
      }))}
      navigate={changeStep}
    />
  );
};

export default OnboardingProgress;
