import React, { useState } from "react";
import styles from "./BannerNotification.module.css";
import { CrossIcon } from "../../Icons";
import IconButton from "../IconButton";

interface BannerNotificationProps {
  isDisplayed: boolean;
  onDismiss?: () => void;
  children?: React.ReactNode;
}

const BannerNotification: React.FC<BannerNotificationProps> = ({
  isDisplayed,
  onDismiss,
  children,
}) => {
  const [show, setShow] = useState<boolean>(isDisplayed);

  const handleClose = () => {
    onDismiss && onDismiss();
    setShow(false);
  };

  return (
    <div
      data-testid="banner_notification"
      style={{ display: show ? "flex" : "none" }}
      className={styles.container}
    >
      <IconButton
        label="close button"
        icon={<img src={CrossIcon} alt="cross icon" />}
        onClick={handleClose}
        className={styles.close_button}
      />
      {children}
    </div>
  );
};

export default BannerNotification;
