import styles from "./Chip.module.css";

export interface IIconChipProps {
  id: string;
  size: number | string;
  content: {
    id: number;
    icon: string;
    text: string;
    isSelected: boolean;
  };
  onSelect: () => void;
}

const IconChip: React.FC<IIconChipProps> = ({
  id,
  size,
  content,
  onSelect,
}) => {
  return (
    <div data-testid={id} className={styles.iconChipBlock}>
      <div className={styles.iconContent}>
        <button
          data-testid={`button-${id}`}
          className={`${styles.iconChip} ${
            content.isSelected && styles.selected
          }`}
          style={{ width: size, height: size }}
          onClick={onSelect}
        >
          <img src={content.icon} alt="icon" width="" height="" />
        </button>
        <p className={styles.chipText}>{content.text}</p>
      </div>
    </div>
  );
};

export default IconChip;
