import * as Yup from "yup";
import { ProfileQueryResult } from "./Profile";
import { OKRQueryResult } from "./OKR";
import { MenteeQueryResult } from "./Mentee";
import { MentorQueryResult } from "./Mentor";

export enum ALLOWED_STATUS {
  REQUEST = "request",
  UPCOMING = "upcoming",
  RESCHEDULE = "reschedule",
  COMPLETED = "completed",
  EXPIRED = "expired",
  DECLINED = "declined",
  CANCELED = "canceled",
}

export const ReviewSchema = Yup.object({
  status: Yup.string(),
  nps: Yup.number(),
  best_quality: Yup.string(),
  public_feedback: Yup.string(),
  private_feedback: Yup.string(),
  created_at: Yup.string(),
});

const RescheduleEventMetadataSchema = Yup.object({
  ideal_proposed_time: Yup.string().required(),
  proposed_times: Yup.array().of(Yup.string().required()).required(),
  from: Yup.mixed().oneOf(["mentor", "mentee", "admin"]).required(),
  created_at: Yup.string().required(),
});

const RequestEventMetadataSchema = RescheduleEventMetadataSchema;

export const EventSchema = Yup.object({
  action: Yup.mixed<ALLOWED_STATUS>()
    .oneOf(Object.values(ALLOWED_STATUS))
    .required(),
  metadata: RequestEventMetadataSchema,
});

export const SessionSchema = Yup.object({
  to: Yup.string().required(),
  from: Yup.string().required(),
  slot: Yup.string().required(),
  ideal_proposed_time: Yup.string().required(),
  proposed_times: Yup.array().of(Yup.string().required()).required(),
  proposed_by: Yup.mixed<"mentor" | "mentee">().required(),
  reschedule_note: Yup.string(),
  duration: Yup.number().required(),
  okr: Yup.string().nullable(),
  title: Yup.string(),
  description: Yup.string(),
  attachments: Yup.array().of(Yup.object({})),
  createdAt: Yup.string().required(),
  cost: Yup.number().required(),
  location: Yup.string(),
  status: Yup.mixed<ALLOWED_STATUS>()
    .oneOf(Object.values(ALLOWED_STATUS))
    .default(ALLOWED_STATUS.REQUEST)
    .required(),
  silence: Yup.boolean().default(false),
  videoRoomLink: Yup.string(),
  video_room_id: Yup.string(),
  calendarEventId: Yup.string(),
  calendarEventLink: Yup.string(),
  mentee_review: ReviewSchema.notRequired(),
  requestExpiringReminderSent: Yup.boolean().default(false),
  upcomingRemindersSent: Yup.boolean().default(false),
  events: Yup.array().required().of(EventSchema.required()),
}).required();

export type Review = Yup.InferType<typeof ReviewSchema>;

export type Session = Yup.InferType<typeof SessionSchema>;

export type UpcomingSession = Yup.InferType<typeof SessionSchema> & {
  slot: string;
  type: ALLOWED_STATUS.UPCOMING;
};

export type SessionQueryResult = Session & { _id: string };

export type SessionQueryResultWithProfiles = SessionQueryResult & {
  to: MentorQueryResult;
  from: MenteeQueryResult;
};

export type SessionQueryResultsWithDetails = SessionQueryResultWithProfiles & {
  okr: OKRQueryResult;
};
