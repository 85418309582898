import { useAuth0 } from "@auth0/auth0-react";
import { useActor, useSelector } from "@xstate/react";
import { useContext, useEffect, useState } from "react";
import { MenteeQueryResult } from "entities/Mentee";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { API_URL, createHeaders } from "utils/api";
import { on } from "events";

interface UpdateMenteeHook {
  is_loading: boolean;
  error: string | undefined;
  update: (update: Partial<MenteeQueryResult>) => Promise<void>;
}

interface UpdateMenteeProps {
  onSuccess?: () => void;
  onError?: (error: string) => void;
}

const useUpdateMentee = ({
  onSuccess,
  onError,
}: UpdateMenteeProps): UpdateMenteeHook => {
  const { profile_service } = useContext(GlobalServiceContext);
  const { getAccessTokenSilently } = useAuth0();
  const [state, send] = useActor(profile_service);
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();

  const update = async (update: Partial<MenteeQueryResult>): Promise<void> => {
    try {
      const token = await getAccessTokenSilently();
      setIsLoading(true);
      const response = await fetch(`${API_URL}/mentee/update`, {
        method: "POST",
        mode: "cors",
        headers: createHeaders(token),
        body: JSON.stringify(update),
      });
      if (response.ok) {
        const data = await response.json();
        send({ type: "UPDATE_MENTEE_PROFILE", data });
        onSuccess && onSuccess();
      } else {
        setError("Failed to update mentee profile");
      }
    } catch (error) {
      setError("Failed to update mentee profile");
      onError && onError(String(error));
    } finally {
      setIsLoading(false);
    }
  };

  return { is_loading, error, update };
};

export default useUpdateMentee;
