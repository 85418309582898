import React from "react";

interface StripeMentorModalProps {
  user_data: {
    given_name: string;
  };
}

const MentorSuccessModal = ({ user_data }: StripeMentorModalProps) => {
  return (
    <div className="modal-content">
      <h1>Congratulations</h1>
      <p>
        Welcome to MentorPass {user_data.given_name}, we&apos;re excited to have
        you mentoring on the platform.
      </p>
      <p>
        Get started by completing your profile, that way potential mentees can
        find you.
      </p>
      <button
        onClick={() =>
          (window.location.href = "app.mentorpass.co/account/profile")
        }
      >
        To my Profile
      </button>
    </div>
  );
};

export default MentorSuccessModal;
