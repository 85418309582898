import { useAuth0 } from "@auth0/auth0-react";
import { QueryKey, UseQueryOptions, useQuery } from "@tanstack/react-query";
import { OKRQueryResult } from "entities/OKR";
import { API_URL, createHeaders } from "utils/api";

const fetchOKRById = async (token: string, okr_id: string): Promise<OKRQueryResult> => {
    const response = await fetch(`${API_URL}/okr/${okr_id}`, {
        headers: createHeaders(token),
        mode: "cors",
        method: "GET",
    });
    const data = await response.json();
    return data;
};

type SpreadableQueryOptions = Partial<UseQueryOptions<OKRQueryResult, Error, OKRQueryResult, QueryKey>>;

export const useObjective = (okr_id: string, options: SpreadableQueryOptions) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery<OKRQueryResult, Error>({
        queryKey: ['okr', okr_id],
        queryFn: async () => getAccessTokenSilently().then(token => fetchOKRById(token, okr_id)),
        ...options,
    });
};