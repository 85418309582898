import { useState } from "react";
import DropdownSelector from "components/Atoms/DropdownSelector";
import { CloseIcon } from "../../../Icons";
import styles from "./SetHours.module.css";
import { ThreeDots } from "react-loader-spinner";
import { AvailableDay, AvailableWeek, WeekDay } from "entities/Mentor";

const hours = {
  monday: [],
  tuesday: [],
  wednesday: [],
  thursday: [],
  friday: [],
  saturday: [],
  sunday: [],
};

const times = [
  { alt: "00:00", title: "12:00 AM", value: 0 },
  { alt: "00:30", title: "12:30 AM", value: 30 },
  { alt: "01:00", title: "1:00 AM", value: 60 },
  { alt: "01:30", title: "1:30 AM", value: 90 },
  { alt: "02:00", title: "2:00 AM", value: 120 },
  { alt: "02:30", title: "2:30 AM", value: 150 },
  { alt: "03:00", title: "3:00 AM", value: 180 },
  { alt: "03:30", title: "3:30 AM", value: 210 },
  { alt: "04:00", title: "4:00 AM", value: 240 },
  { alt: "04:30", title: "4:30 AM", value: 270 },
  { alt: "05:00", title: "5:00 AM", value: 300 },
  { alt: "05:30", title: "5:30 AM", value: 330 },
  { alt: "06:00", title: "6:00 AM", value: 360 },
  { alt: "06:30", title: "6:30 AM", value: 390 },
  { alt: "07:00", title: "7:00 AM", value: 420 },
  { alt: "07:30", title: "7:30 AM", value: 450 },
  { alt: "08:00", title: "8:00 AM", value: 480 },
  { alt: "08:30", title: "8:30 AM", value: 510 },
  { alt: "09:00", title: "9:00 AM", value: 540 },
  { alt: "09:30", title: "9:30 AM", value: 570 },
  { alt: "10:00", title: "10:00 AM", value: 600 },
  { alt: "10:30", title: "10:30 AM", value: 630 },
  { alt: "11:00", title: "11:00 AM", value: 660 },
  { alt: "11:30", title: "11:30 AM", value: 690 },
  { alt: "12:00", title: "12:00 PM", value: 720 },
  { alt: "12:30", title: "12:30 PM", value: 750 },
  { alt: "13:00", title: "1:00 PM", value: 780 },
  { alt: "13:30", title: "1:30 PM", value: 810 },
  { alt: "14:00", title: "2:00 PM", value: 840 },
  { alt: "14:30", title: "2:30 PM", value: 870 },
  { alt: "15:00", title: "3:00 PM", value: 900 },
  { alt: "15:30", title: "3:30 PM", value: 930 },
  { alt: "16:00", title: "4:00 PM", value: 960 },
  { alt: "16:30", title: "4:30 PM", value: 990 },
  { alt: "17:00", title: "5:00 PM", value: 1020 },
  { alt: "17:30", title: "5:30 PM", value: 1050 },
  { alt: "18:00", title: "6:00 PM", value: 1080 },
  { alt: "18:30", title: "6:30 PM", value: 1110 },
  { alt: "19:00", title: "7:00 PM", value: 1140 },
  { alt: "19:30", title: "7:30 PM", value: 1170 },
  { alt: "20:00", title: "8:00 PM", value: 1200 },
  { alt: "20:30", title: "8:30 PM", value: 1230 },
  { alt: "21:00", title: "9:00 PM", value: 1260 },
  { alt: "21:30", title: "9:30 PM", value: 1290 },
  { alt: "22:00", title: "10:00 PM", value: 1320 },
  { alt: "22:30", title: "10:30 PM", value: 1350 },
  { alt: "23:00", title: "11:00 PM", value: 1380 },
  { alt: "23:30", title: "11:30 PM", value: 1410 },
];

interface SetHoursProps {
  day: WeekDay;
  availability: AvailableWeek;
  is_loading: boolean;
  update: (value: AvailableWeek) => void;
  closeModal: () => void;
}

const SetHours = ({
  day,
  availability,
  is_loading,
  update,
  closeModal
}: SetHoursProps) => {

  const [addTime, setAddTime] = useState(false);

  const [newSlot, setNewSlot] = useState({
    start: { alt: "08:00", title: "8:00 AM", value: 480 },
    end: { alt: "17:00", title: "5:00 PM", value: 1020 },
  });

  const addAvailability = () => {
    if (!is_loading) {
      let editing_availability = availability || hours;
      let edit_day = editing_availability[day] || [];
      edit_day.push(newSlot);
      edit_day.sort((a, b) => a.start.value - b.start.value);
      editing_availability[day] = edit_day;
      update(editing_availability);
      setAddTime(false);
    }
  };

  const limitTimes = (position: "start" | "end") => {
    if (position === "start") {
      return newSlot.end
        ? times.filter((t) => t.value < newSlot.end.value)
        : times;
    } else {
      return newSlot.start
        ? times.filter((t) => t.value > newSlot.start.value)
        : times;
    }
  };

  const deleteSlot = (slot: AvailableDay) => {
    if (!is_loading) {
      let update_availability = availability || hours;
      let edit_day = update_availability[day] || [];
      const new_day = edit_day.filter((s) => {
        return (
          s.start.value !== slot.start.value || s.end.value !== slot.end.value
        );
      });
      update_availability[day] = new_day;
      update(update_availability);
    }
  };

  const onCancel = () => {
    setAddTime(!addTime);
  };

  return (
    <div
      className="modal-content"
      style={{
        width: "420px",
        padding: "40px 60px",
        // height: "fit-content",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <img src={CloseIcon} className="modal-close" onClick={closeModal} />
      <h3 style={{ textTransform: "capitalize", color: "#000" }}>
        {day} availability
      </h3>
      <div className="set-hours-content">
        {availability[day].length > 0 && (
          <div>
            <label>FROM</label>
            <label>TO</label>
          </div>
        )}
        <div className="available-times">
          {availability[day].map((slot, i) => (
            <div key={i}>
              <p>
                {slot.start.title} - {slot.end.title}
              </p>
              <img onClick={() => deleteSlot(slot)} src={CloseIcon} />
            </div>
          ))}
        </div>
        {addTime ? (
          <div className="add-time">
            <div>
              <DropdownSelector
                placeholder={newSlot.start.title}
                label="FROM"
                value={newSlot.start}
                options={limitTimes("start")}
                onSelect={(value) =>
                  setNewSlot({
                    ...newSlot,
                    start: value,
                  })
                }
                style={{ width: "130px" }}
              />
              <DropdownSelector
                placeholder={newSlot.end.title}
                label="TO"
                value={newSlot.end}
                options={limitTimes("end")}
                onSelect={(value) =>
                  setNewSlot({
                    ...newSlot,
                    end: value,
                  })
                }
                style={{ width: "130px" }}
              />
            </div>
            <div>
              <button
                disabled={is_loading}
                onClick={onCancel}
                className="btn-small black"
              >
                Cancel
              </button>
              <button onClick={addAvailability} className="btn-small">
                {is_loading ? (
                  <ThreeDots
                    wrapperStyle={{ marginTop: "-2px" }}
                    color="#FFF"
                    height={24}
                    width={32}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </div>
        ) : (
          <button
            onClick={() => {
              if (!is_loading) {
                setAddTime(!addTime);
              }
            }}
          // style={{ display: "flex", alignItems: "center" }}
          >
            {is_loading ? (
              <ThreeDots
                wrapperStyle={{
                  margin: "auto",
                  display: "block",
                  marginTop: "-2px",
                }}
                color="#FFF"
                height={24}
                width={32}
              />
            ) : (
              "Add Availability"
            )}
          </button>
        )}
      </div>
    </div>
  );
};

export default SetHours;
