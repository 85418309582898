/* eslint-disable react/prop-types */
import { BlackCrossIcon } from "../../../Icons";
import { DOMAINS, SKILLS } from "../../../../constants/domains";
import { Skill } from "entities/Mentor";

interface AddSkillProps {
  selected_skills: string[],
  closeModal: () => void;
  addSkill: (skill: Skill) => void;
}

const AddSkillComponent = ({ selected_skills, addSkill, closeModal }: AddSkillProps) => {

  const makeSkills = (): Skill[] =>
    DOMAINS.concat(SKILLS).map(skill => ({
      title: skill,
      value: skill.toLocaleLowerCase(),
    }));

  const selected = { color: "#fff", backgroundColor: "#5440f0" };

  return (
    <div className="modal-content">
      <div className="header">
        <div>
          <p className="account-title-black">Add Skills</p>
          <p className="modal-helper-text">
            Skills help mentees find you. Select from existing skills below or
            add a new skill to the list.
          </p>
        </div>
        <div className="action">
          <img src={BlackCrossIcon} alt="cross" onClick={closeModal} />
        </div>
      </div>

      <div className="skills-list">
        {makeSkills().map((o, i) => (
          <p
            className="skill-pill"
            style={selected_skills.includes(o.value) ? selected : {}}
            key={i}
            onClick={() => addSkill(o)}
          >
            {o.title}
          </p>
        ))}
      </div>
    </div>
  );
};

export default AddSkillComponent;
