import { z } from 'zod';

const FilestackUploadSchema = z.object({
  filename: z.string(),
  handle: z.string(),
  mimetype: z.string(),
  status: z.string(),
  source: z.string(),
  size: z.number(),
  uploadId: z.string(),
  url: z.string(),
});

const MultiSelectOptionSchema = z.object({
  title: z.string(),
  value: z.string(),
});

const BusinessSocialSchema = z.object({
  twitter: z.string(),
  linkedin: z.string(),
  instagram: z.string(),
});

const BusinessSchema = z.object({
  _id: z.string(),
  admin: z.string(),
  logo: FilestackUploadSchema.optional(),
  name: z.string(),
  description: z.string(),
  website: z.string(),
  teamSize: z.string(),
  industry: MultiSelectOptionSchema,
  revenue: MultiSelectOptionSchema,
  fundraising: z.boolean(),
  team: z.array(z.string()),
  funding: MultiSelectOptionSchema,
  attachment: FilestackUploadSchema.optional().nullable(),
  customer_persona: z.string(),
  has_stripe: z.boolean(),
  mission: z.string(),
  pain_points: z.string(),
  balance: z.number(),
});

export const BusinessUpdateSchema = z.object({
  name: z.string().default(""),
  website: z.string().default(""),
  mission: z.string().default(""),
  customer_persona: z.string().default(""),
  pain_points: z.string().default(""),
  industry: MultiSelectOptionSchema.default({
    title: "Select one",
    value: "none_selected"
  }),
  revenue: MultiSelectOptionSchema.default({
    title: "Select one",
    value: "none_selected"
  }),
  funding: MultiSelectOptionSchema.default({
    title: "Select one",
    value: "none_selected"
  }),
  fundraising: z.boolean().default(false),
  attachment: FilestackUploadSchema.optional().nullable(),
});



export type FilestackUpload = z.infer<typeof FilestackUploadSchema>;
export type BusinessIndustry = z.infer<typeof MultiSelectOptionSchema>;
export type BusinessRevenue = z.infer<typeof MultiSelectOptionSchema>;
export type BusinessFunding = z.infer<typeof MultiSelectOptionSchema>;
export type BusinessSocial = z.infer<typeof BusinessSocialSchema>;
export type Business = z.infer<typeof BusinessSchema>;
export type BusinessUpdate = z.infer<typeof BusinessUpdateSchema>;