import { ChangeEvent, ChangeEventHandler, FocusEventHandler } from "react";

import styles from "./TextArea.module.css";

interface TextAreaProps {
  name: string;
  label: string;
  value: string;
  error: string | boolean | undefined;
  placeholder?: string;
  handleChange: ChangeEventHandler<HTMLTextAreaElement>;
  handleBlur?: FocusEventHandler;
  dark?: boolean;
  rows?: number;
  style?: React.CSSProperties;
}

const TextArea: React.FC<TextAreaProps> = ({
  name,
  label,
  value,
  error,
  placeholder,
  handleChange,
  handleBlur,
  dark,
  rows = 4,
  style
}) => {
  const label_classes = error ? styles.error_label : dark ? "black" : "";
  const input_classes = error ? styles.error_input : dark ? "black" : "";
  return (
    <div data-testid="TextArea" className={styles.container} style={style}>
      <label className={label_classes}>{label}</label>
      <textarea
        data-hj-allow
        className={input_classes}
        name={name}
        rows={rows}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
      {error && (
        <p data-testid="TextArea_error" className={styles.error}>
          {error}
        </p>
      )}
    </div>
  );
};

export default TextArea;
