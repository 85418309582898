import PersonalComponent from "./Personal.component";
import useMyProfile from "hooks/useMyProfile";
import { isMentee } from "entities/Mentee";
import useUpdateMentee from "hooks/mentee/useUpdateMentee";
import { useAlert } from "@blaumaus/react-alert";
import { useNavigate } from "react-router";

const Personal = () => {
  const { profile } = useMyProfile();
  const alert = useAlert();
  const navigate = useNavigate();
  const { update, is_loading } = useUpdateMentee({
    onSuccess: () => {
      alert.success("Profile updated!");
      navigate("/start/company");
    },
    onError: () => alert.error("There was an error upading your profile"),
  });

  if (!isMentee(profile)) {
    throw Error("Profile is not a mentee");
  }

  return (
    <PersonalComponent
      profile={profile}
      update={update}
      is_loading={is_loading}
    />
  );
};

export default Personal;
