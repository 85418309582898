import { toFormikValidationSchema } from "zod-formik-adapter";
import { Formik, Form } from "formik";
import TextInput from "components/Atoms/TextInput";
import DropdownSelector from "components/Atoms/DropdownSelector";
import Checkbox from "components/Atoms/Checkbox";
import TextArea from "components/Atoms/TextArea";
import {
  BUSINESS_MODELS,
  FUNDRAISING_OPTIONS,
  REVENUE_OPTIONS,
} from "constants/options";
import { Grid, ThreeDots } from "react-loader-spinner";
import {
  Business,
  BusinessUpdate,
  BusinessUpdateSchema,
} from "entities/Business";

import Styles from "./Company.module.css";

function filterObjectBySchema<T extends object>(
  obj: T,
  schema: Record<string, boolean>
): Partial<T> {
  const filteredObj: Partial<T> = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key) && schema[key]) {
      filteredObj[key] = obj[key];
    }
  }
  return filteredObj;
}

interface ComponentProps {
  id: string;
  business?: Business;
  is_loading: boolean;
  is_updating: boolean;
  submit: (value: BusinessUpdate) => void;
}



const CompanyProfile = ({
  id,
  business,
  is_loading,
  is_updating,
  submit,
}: ComponentProps) => {
  const mapToCompany = (values: Business): BusinessUpdate => {
    const filted_values = filterObjectBySchema(values, {
      name: true,
      website: true,
      mission: true,
      customer_persona: true,
      pain_points: true,
      industry: true,
      revenue: true,
      funding: true,
      fundraising: true,
    });
    return BusinessUpdateSchema.parse(filted_values);
  };

  return (
    <div className="section" id={id}>
      <div className="section-content">
        <div className="section-header">
          <h3>Company</h3>
        </div>
      </div>
      <div className={Styles.form_container} data-testid="company-form">
        {!business || is_loading ? (
          <Grid
            wrapperStyle={{
              margin: "auto",
              display: "block",
              marginTop: "18px",
            }}
            color="#5440F0"
            height={32}
            width={32}
          />
        ) : (
          <Formik
            initialValues={mapToCompany(business)}
            validationSchema={toFormikValidationSchema(BusinessUpdateSchema)}
            onSubmit={(values: BusinessUpdate) => submit(values)}
          >
            {({
              values,
              errors,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form onSubmit={handleSubmit}>
                <TextInput
                  type="text"
                  name="name"
                  label="Name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors.name}
                  dark={true}
                />
                <TextInput
                  type="text"
                  name="website"
                  label="Website"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.website}
                  error={errors.website}
                  dark={true}
                />
                <DropdownSelector
                  label="Business Type"
                  placeholder={"Select industry"}
                  options={Object.values(BUSINESS_MODELS)}
                  onSelect={(value) => setFieldValue("industry", value)}
                  value={values.industry}
                  dark={true}
                  outline={false}
                />
                <DropdownSelector
                  label="Trailing 12 Months Revenue"
                  placeholder={"Select revenue"}
                  options={Object.values(REVENUE_OPTIONS)}
                  onSelect={(value) => setFieldValue("revenue", value)}
                  value={values.revenue}
                  dark={true}
                  outline={false}
                />
                <DropdownSelector
                  dark={true}
                  label="Lifetime Funding"
                  placeholder="Select One"
                  outline={false}
                  options={FUNDRAISING_OPTIONS}
                  value={values.funding}
                  onSelect={(value) => setFieldValue("funding", value)}
                />
                <Checkbox
                  id="fundraising"
                  name="fundraising"
                  label="Currently fundraising?"
                  error={errors.fundraising}
                  checked={values.fundraising}
                  handleChange={handleChange}
                  isDark
                />
                <TextArea
                  rows={6}
                  name="mission"
                  label="Mission"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.mission}
                  error={errors.mission}
                  dark={true}
                />
                <TextArea
                  rows={6}
                  name="customer_persona"
                  label="Ideal Customer Persona"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.customer_persona}
                  error={errors.customer_persona}
                  dark={true}
                />
                <TextArea
                  rows={6}
                  name="pain_points"
                  label="Key Challenges"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.pain_points}
                  error={errors.customer_persona}
                  dark={true}
                />
                <button disabled={is_updating} type="submit">
                  {is_updating ? (
                    <ThreeDots color="#FFF" height={18} width={24} />
                  ) : (
                    "Submit"
                  )}
                </button>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default CompanyProfile;
