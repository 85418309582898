import moment from "moment-timezone";
import styles from "./DeclineSession.module.css";
import TextArea from "components/Molecules/Inputs/TextArea/TextArea.component";
import AvatarPlaceholder from "images/avatar-placeholder.gif";
import { ProfileQueryResult } from "entities/Profile";
import { SessionQueryResult } from "entities/Session";
import { Grid } from "react-loader-spinner";
import Button from "components/Atoms/Button";

interface DeclineSessionProps {
  reason: string;
  is_loading: boolean;
  decline_pending: boolean;
  profile?: ProfileQueryResult;
  session?: SessionQueryResult;
  timezone: string;
  onUpdateReason: (reason: string) => void;
  declineSession: () => void;
}

const DeclineSession = ({
  reason,
  is_loading,
  decline_pending,
  profile,
  session,
  timezone,
  onUpdateReason,
  declineSession,
}: DeclineSessionProps) => {
  const date =
    (session && moment(session.ideal_proposed_time).tz(timezone)) || moment();
  return profile && session && !is_loading ? (
    <div className={styles.successPage}>
      <div className={styles.successAvatar}>
        <img
          className={styles.successAvatarImage}
          src={profile.profileImage.url || AvatarPlaceholder}
          alt={`${profile.given_name} Avatar`}
        />
      </div>
      <h2>Session Declined!</h2>
      <p>
        I&apos;m sure {profile.given_name} is disapointed that you won&apos;t be
        mentoring for {session.duration} minutes on{" "}
        {date.format("dddd, MMMM Do")}.
      </p>
      <TextArea
        value={reason}
        placeholder={""}
        label="WHY CAN'T YOU MAKE THE SESSION?"
        onChange={(e) => onUpdateReason(e.target.value)}
        dark
      />
      <Button
        variant="primary"
        loading={decline_pending}
        disabled={is_loading}
        className={styles.declineButton}
        onClick={async () => await declineSession()}
      >
        "Confirm Decline"
      </Button>
    </div>
  ) : (
    <div className={styles.loading_section}>
      <Grid
        wrapperStyle={{ margin: "auto", display: "block" }}
        color="#5440F0"
        height={42}
        width={42}
      />
    </div>
  );
};

export default DeclineSession;
