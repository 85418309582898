import PaywallComponent from "./Paywall.component";
import { useNavigate } from "react-router-dom";
import useDisplayPaywall from "hooks/mentee/useDisplayPaywall";
import PreferenceStorage from "services/Profile/Preference/Preference.storage";

const Paywall = () => {
  const display_paywall = useDisplayPaywall();
  const navigate = useNavigate();
  const plan_id = PreferenceStorage.getPlanId();
  const pending_session_exists = PreferenceStorage.getPendingSessionId();


  const goToCheckout = () => {
    if (plan_id) {
      const plan_id_query_params = new URLSearchParams({
        plan_id: encodeURIComponent(plan_id),
      }).toString()
      navigate(`/checkout_plus?${plan_id_query_params}`);
      return;
    } else {
      navigate("/checkout");
    }
  }

  return display_paywall && !pending_session_exists ? (
    <PaywallComponent goToCheckout={goToCheckout} />
  ) : null;
};

export default Paywall;
