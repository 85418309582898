import * as React from "react";
import { useSearchParams } from "react-router-dom";
import ProfilePreferenceStorage from "../../services/Profile/Preference/Preference.storage";
import { useAuth0 } from "@auth0/auth0-react";

const MenteeSignup = () => {
  const [search_params] = useSearchParams();
  const { loginWithRedirect } = useAuth0();
  const invite_code = search_params.get("invite_code");
  const mentor_plus = search_params.get("mentor_plus");
  const plan_id = search_params.get("plan_id");

  React.useEffect(() => {
    ProfilePreferenceStorage.setPreferenceMentee();
    // CANNOT use the Profile state service with an un-authed use.
    if (invite_code) {
      ProfilePreferenceStorage.setTeamInvite(invite_code);
    }
    // extract plan_id from search_params
    if(plan_id) {
      ProfilePreferenceStorage.setPlanId(plan_id);
    }

    if(mentor_plus == "true") {
      ProfilePreferenceStorage.setMentorPlusSignup();
    }

    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
      appState: {
        returnTo: "/loading_profile",
      },
    });
  }, []);

  return null;
};

export default MenteeSignup;
