import React from "react";
import classNames from "classnames/bind";

import styles from "./Chip.module.css";
import Square, { SquareProps } from "./Square.component";
import IconChip, { IIconChipProps } from "./IconChip.component";

interface ChipProps {
  id?: string;
  className?: string;
  isPrimary?: boolean;
  isSmall?: boolean;
  onClick?: () => void;
  children: React.ReactNode;
}

type ChipComponentType = {
  ({ className, isPrimary, isSmall, children }: ChipProps): JSX.Element;
  Square: React.FC<SquareProps>;
  Icon: React.FC<IIconChipProps>;
};

const cx = classNames.bind(styles);

const Chip: ChipComponentType = ({
  id,
  className,
  isPrimary,
  isSmall,
  children,
  onClick,
}) => {
  const chipStyles = cx({
    container: true,
    small: isSmall,
    primary: isPrimary,
    [className || ""]: !!className,
  });

  return (
    <span key={id} data-testid={id} className={chipStyles} onClick={onClick}>
      {children}
    </span>
  );
};

Chip.Square = Square;
Chip.Icon = IconChip;

export default Chip;
