import styles from "./Chip.module.css";

export interface SquareProps {
  size: string | number;
  content: {
    id: any;
    text: string | number;
    isSelected: boolean;
  };
  onSelect: (id: any) => void;
}

const Square: React.FC<SquareProps> = ({ size, content, onSelect }) => {
  return (
    <button
      data-testid="SquareChip_container"
      className={`${styles.square} ${content.isSelected && styles.selected}`}
      style={{ width: size, height: size }}
      onClick={onSelect.bind(null, content.id)}
    >
      {content.text}
    </button>
  );
};

export default Square;
