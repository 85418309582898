import React, { useState } from "react";
import styles from "./TagCloudInput.module.css";
import TextInput from "../../Atoms/TextInput/TextInput.component";
import Chip from "../../Atoms/Chip/Chip.component";
import { CrossIcon } from "../../Icons";
import { TextInputType } from "components/Atoms/TextInput/NewTextInput.wip";
import objectHash from "object-hash";

export interface TagCloudInputProps {
  title: string;
  tag_id: string;
  input_prompt: string;
  tooltip?: string;
  values: string[];
  tag_limit?: number;
  onChange: (values: string[]) => void;
}

const TagCloudInput = ({
  title,
  tag_id,
  input_prompt,
  tooltip,
  values,
  tag_limit,
  onChange,
}: TagCloudInputProps) => {
  const [current_tag, setCurrentTag] = useState<string>("");
  const [error, setError] = useState<string | undefined>(undefined);

  const onUpdateTag = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrentTag(event.target.value);
  };

  const onAdd = (value: string) => {
    if (tag_limit && values.length >= tag_limit) {
      setError(`You can only add up to ${tag_limit} ${title}.`);
      return;
    }

    if (values.length > 100) {
      setError(`${title} length is limited to 100 characters`);
      return;
    }

    onChange([...values, value]);
    setError(undefined);
  };

  const onRemove = (value: string) => {
    const new_values = values.filter((v) => v !== value);
    onChange(new_values);
    setError(undefined);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      onAdd(current_tag);
      setCurrentTag("");
    }
  };

  return (
    <div data-testid="tag_cloud_input" className={styles.container}>
      <TextInput
        placeholder={input_prompt}
        name={tag_id}
        label={title}
        type={TextInputType.TEXT}
        value={current_tag}
        tooltip={tooltip}
        error={undefined}
        handleChange={onUpdateTag}
        handleKeyDown={onKeyDown}
        dark={true}
      />
      <div className={styles.tag_container}>
        {values.map((value) => (
          <Chip
            id={value}
            key={objectHash(value)}
            className={styles.tag}
            onClick={() => onRemove(value)}
          >
            <div className={styles.tag_content}>
              {value}
              <img src={CrossIcon} />
            </div>
          </Chip>
        ))}
      </div>
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default TagCloudInput;
