const SET_PROFILES = "SET_PROFILES";
const FETCH_PROFILE_START = "FETCH_PROFILE_START";
const FETCH_PROFILE_FINISHED = "FETCH_PROFILE_START";
const SET_SELECTED_PROFILE = "SET_SELECTED_PROFILE";

const REGISTER_MENTEE_SUCCESS = "REGISTER_MENTEE_SUCCESS";
const REGISTER_MENTOR_SUCCESS = "REGISTER_MENTEE_SUCCESS";

const UPDATE_PROFILE_START = "UPDATE_PROFILE_START";
const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
const UPDATE_PROFILE_ERROR = "UPDATE_PROILE_ERROR";

const UPDATE_USERNAME_ERROR = "UPDATE_USERNAME_ERROR";

const FETCH_PROFILE_BY_SLUG_SUCCESS = "FETCH_PROFILE_BY_SLUG_SUCCESS";
const FETCH_REFERRING_MENTOR_BY_SLUG_SUCCESS =
	"FETCH_REFERRING_MENTOR_BY_SLUG_SUCCESS";

const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
const SET_PREVIEW_PROFILE = "SET_PREVIEW_PROFILE";

const initialState = {
	isLoading: false,
	error: null,
	profiles: [],
	loading: [],
	selectedProfile: null,
	previewLoading: false,
	previewProfile: null,
	username_error: null,
	referring_mentor: null,
};

const profileReducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_PROFILE_START:
			return {
				...state,
				isLoading: true,
				error: null,
			};
		case SET_PROFILES:
			return {
				...state,
				isLoading: false,
				error: null,
				profiles: action.payload,
			};
		case SET_SELECTED_PROFILE:
			return {
				...state,
				selectedProfile: action.payload,
			};
		case FETCH_PROFILE_FINISHED:
			return {
				...state,
				isLoading: false,
				error: null,
			};
		case UPDATE_PROFILE_START:
			return {
				...state,
				isLoading: true,
				loading: action.loading || [],
				error: null,
			};
		case UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				isLoading: false,
				loading: [],
				error: null,
				profiles: action.payload,
			};
		case UPDATE_PROFILE_ERROR:
			return {
				...state,
				isLoading: false,
				error: action.error,
				loading: [],
			};
		case UPDATE_USERNAME_ERROR:
			return {
				...state,
				isLoading: false,
				error: null,
				username_error: action.error,
				loading: [],
			};
		case SET_PREVIEW_PROFILE:
			return {
				...state,
				previewLoading: false,
				previewProfile: action.payload,
			};
		case FETCH_PROFILE_BY_SLUG_SUCCESS:
			return {
				...state,
				previewLoading: false,
				previewProfile: action.payload,
			};
		case FETCH_REFERRING_MENTOR_BY_SLUG_SUCCESS:
			return {
				...state,
				referring_mentor: action.payload,
			};
		case LOGOUT_SUCCESS:
			return {
				...initialState,
			};
		case REGISTER_MENTEE_SUCCESS:
			return {
				...state,
				profiles: [action.payload.profile],
				selectedProfile: `${action.payload.profile._id}`,
			};
		case REGISTER_MENTOR_SUCCESS:
			return {
				...state,
				profiles: [action.payload.profile],
				selectedProfile: `${action.payload.profile._id}`,
			};
		default:
			return state;
	}
};

export default profileReducer;
