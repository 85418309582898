import style from "../Profile.module.css";

interface IBannerProps {
  pitch: string;
}

const Banner: React.FC<IBannerProps> = ({ pitch }) => {
  return (
    <div className={style.banner}>
      <h3>{pitch}</h3>
    </div>
  );
};

export default Banner;
