import * as React from "react";
import ProfilePreferenceStorage from "../../services/Profile/Preference/Preference.storage";
import { useAuth0 } from "@auth0/auth0-react";

const MentorSignup = () => {
  const { loginWithRedirect } = useAuth0();

  React.useEffect(() => {
    ProfilePreferenceStorage.setPreferenceMentor();

    loginWithRedirect({
      authorizationParams: {
        screen_hint: "signup",
      },
      appState: {
        returnTo: "/",
      },
    });
  }, []);

  return null;
};

export default MentorSignup;
