import { MentorSessionQueryResult } from "entities/MentorSession";
import MentorSessionCard from "./MentorSessionCard";
import styles from "./MentorSessions.module.css";
import useAcceptSession from "hooks/mentor/session/useAcceptSession";
import useDeclineSession from "hooks/mentor/session/useDeclineSession";
import TimeSlotSelector from "../../Molecules/TimeSlotSelector/TimeSlotSelector";
import useMyProfile from "hooks/useMyProfile";
import { useNavigate } from "react-router-dom";
import posthog from "posthog-js";
import { ALLOWED_STATUS } from "entities/Session";
interface RequestedSessionProps {
  session: MentorSessionQueryResult;
}

const RequestedSession = (props: RequestedSessionProps) => {
  const { accept, is_loading: accept_session_loading } = useAcceptSession();
  const { decline, is_loading: decline_session_loading } = useDeclineSession();
  const { profile } = useMyProfile();
  const navigate = useNavigate();

  const handleAcceptSession = async (slot: string): Promise<void> => {
    accept(props.session._id, slot, () => {
      window.location.reload();
    });
  };

  const handleDeclineSession = async () => {
    decline(props.session._id, "", () => {
      window.location.reload();
    });
  };

  const handleRescheduleSession = async () => {
    posthog.capture("mentor_clicked_reschedule_session", {
      status: props.session.status,
      session_id: props.session._id,
    });
    navigate(`/reschedule/${props.session._id}`);
  };

  return (
    <MentorSessionCard
      session={props.session}
      actions={
        <div className={styles.requested_session_action_container}>
          {props.session.status === ALLOWED_STATUS.RESCHEDULE &&
          props.session.proposed_by !== "mentee" ? (
            <div className={styles.rescheduled_message}>
              <p>Waiting on a response from your mentee</p>
              <div className={styles.proposed_times}>
                <h4>Your proposed times:</h4>
                <ul>
                  {props.session.proposed_times.map((time, index) => (
                    <li key={index}>
                      {new Date(time).toLocaleString(undefined, {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        timeZoneName: "short",
                      })}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ) : (
            <TimeSlotSelector
              timezone={profile.timezone}
              inital_slot={props.session.ideal_proposed_time}
              slots={props.session.proposed_times}
              note={props.session.reschedule_note}
              accept_session_loading={accept_session_loading}
              confirmSession={handleAcceptSession}
              decline_session_loading={decline_session_loading}
              handleRescheduleSession={handleRescheduleSession}
              handleDeclineSession={handleDeclineSession}
            />
          )}
        </div>
      }
    />
  );
};

export default RequestedSession;
