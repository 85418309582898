import React from "react";
import styles from "./ProgressBar.module.css";

interface ProgressBarProps {
	progress: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ progress }) => {
	return (
		<div className={styles.container}>
			<div className={styles.fill} style={{ width: `${progress}%` }} />
		</div>
	);
};

export default ProgressBar;
