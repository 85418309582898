import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../utils/api";
import { ProfileQueryResult } from "entities/Profile";
import { useAuth0 } from "@auth0/auth0-react";
import { MenteeQueryResult } from "entities/Mentee";

const useMentees = () => {
  const [mentees, setMentees] = useState<MenteeQueryResult[]>([]);
  const [mentees_by_ids, setMenteesByIds] = useState<{
    [key: string]: MenteeQueryResult;
  }>({});
  const [refetch_id, setRefetchId] = useState<number>(0);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const { getAccessTokenSilently } = useAuth0();

  const refetch = () => setRefetchId(Math.random());

  useEffect(() => {
    const fetchMentees = async () => {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/mentee/all`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      });
      const data = await response.json();
      setMentees(data);
      setMenteesByIds(
        data.reduce(
          (
            acc: { [key: string]: ProfileQueryResult },
            mentee: ProfileQueryResult
          ) => {
            acc[mentee._id] = mentee;
            return acc;
          },
          {}
        )
      );
      setIsLoading(false);
    };
    fetchMentees();
  }, [refetch_id]);

  return { mentees, mentees_by_ids, is_loading, refetch };
};

export default useMentees;
