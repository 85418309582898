import React, { useEffect, useState } from "react";
import Header from "./sections/Header/CheckoutHeader.component";
import FAQ from "./sections/FAQ/FAQ.component";
import HowItWorks from "./sections/HowItWorks/HowItWorks.component";
import Logos from "./sections/Logos/Logos.component";
import PickPlan from "./sections/PickPlan/PickPlan.component";
import CaseStudy from "./sections/CaseStudy/CaseStudy.component";
import Testimonial from "./sections/Testimonial/Testimonial.component";
import CTAFooter from "../../../components/Molecules/CTAFooter";
import { withAuthenticationRequired } from "@auth0/auth0-react";

const Checkout = () => {
  const [referring_mentor_id, setRefferalId] = useState<string | undefined>();

  useEffect(() => {
    const referring_mentor = window.localStorage.getItem("referring_mentor");
    if (referring_mentor && referring_mentor.length > 1) {
      setRefferalId(referring_mentor);
    }
  }, []);

  return (
    <div>
      <Header />
      <PickPlan referring_mentor_id={referring_mentor_id} />
      <Testimonial />
      <Logos />
      <HowItWorks />
      <CaseStudy />
      <FAQ />
      <CTAFooter
        callToAction={() =>
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
        }
      />
    </div>
  );
};

export default withAuthenticationRequired(Checkout);
