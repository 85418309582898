import React from "react";
import Objective from "./Objective.component";
import { useCreateOKR } from "hooks/mentee/okr/useCreateOkr";
import { useNavigate } from "react-router";
import { ObjectiveValidation } from "components/Molecules/ModifyObjective/ModifyObjective";
import { useAlert } from "@blaumaus/react-alert";
import { ObjectiveUpdate } from "entities/OKR";

const ObjectiveContainer = () => {
  const navigate = useNavigate();
  const alert = useAlert();

  const { mutate } = useCreateOKR({
    onSuccess: () => {
      alert.success("Objective Created!");
      navigate("/");
    },
    onError: () => {
      alert.error("Something went wrong creating your Objective!");
    },
  });

  const saveObjective = (
    values: ObjectiveValidation,
    setSubmitting: (value: boolean) => void
  ) => {
    setSubmitting(true);
    const new_objective: ObjectiveUpdate = {
      ...values,
      attachments: [],
      due_date: values.due_date.value,
      subject: values.subject.value,
    };
    mutate(new_objective);
  };

  return <Objective saveObjective={saveObjective} />;
};

export default ObjectiveContainer;
