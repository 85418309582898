import React from "react";
import Company from "./Company.component";
import useBusiness from "hooks/mentee/useBusiness";
import { Grid } from "react-loader-spinner";
import useUpdateBusiness from "hooks/mentee/useUpdateBusiness";
import { u } from "msw/lib/glossary-de6278a9";
import { useNavigate } from "react-router";

const CompanyContainer = () => {
  const { business, is_loading } = useBusiness();
  const { update, is_loading: update_loading } = useUpdateBusiness();
  const navigate = useNavigate();

  const next = () => navigate("/start/objective");

  return !is_loading && business ? (
    <Company
      business={business}
      update={update}
      is_loading={update_loading}
      next={next}
    />
  ) : (
    <Grid
      color="#5440F0"
      width={42}
      height={42}
      wrapperStyle={{
        margin: "auto",
        marginTop: "10%",
      }}
    />
  );
};

export default CompanyContainer;
