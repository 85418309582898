export const SET_MODAL = "SET_MODAL";
export const CLOSE_MODAL = "CLOSE_MODAL";
export const SET_ALERT = "SET_ALERT";
export const GET_UPCOMING_SESSION_SUCCESS = "GET_UPCOMING_SESSION_SUCCESS";
export const GET_UPCOMING_SESSION_START = "GET_UPCOMING_SESSION_START";
export const GET_UPCOMING_SESSION_ERROR = "GET_UPCOMING_SESSION_ERROR";
export const GET_PAST_SESSION_START = "GET_PAST_SESSION_START";
export const GET_PAST_SESSION_SUCCESS = "GET_PAST_SESSION_SUCCESS";
export const GET_PAST_SESSION_ERROR = "GET_PAST_SESSION_ERROR";
export const GET_SESSION_REQUEST_START = "GET_SESSION_REQUEST_START";
export const GET_SESSION_REQUEST_SUCCESS = "GET_SESSION_REQUEST_SUCCESS";
export const GET_SESSION_REQUEST_ERROR = "GET_SESSION_REQUEST_ERROR";
