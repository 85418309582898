import React from "react";
import { pluralizer } from "utils/format";

interface RenewalDetailsProps {
  is_loading: boolean;
  days_until_sub_renews?: number;
}

const RenewalDetails = ({
  is_loading,
  days_until_sub_renews,
}: RenewalDetailsProps) => {
  return is_loading || days_until_sub_renews == undefined ? (
    null
  ) : (
    <p style={{ fontSize: "12px" }}>
      Subscription renews in {pluralizer(days_until_sub_renews, "day", "days")}{'.'}
    </p>
  );
};

export default RenewalDetails;
