import { useEffect, useState } from "react";
import { useInterpret } from "@xstate-ninja/react";
import { useAuth0, User } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import profileMachine from "./Profile.machine";
import { ProfileContext, SET_TEAM_INVITE_CODE_EVENT } from "./Profile.schema";
import ProfilePreferenceStorage from "./Preference/Preference.storage";
import {
  InvitedMenteeProfileCreationData,
  ProfileCreationData,
  getAdmin,
  getMentee,
  getMentor,
  getProfiles,
  requestCreateMentee,
  requestCreateMentor,
  requestJoinTeam,
  claimPendingSession
} from "./Profile.data";
import { identifyUser } from "./Identify";
import { usePostHog } from "posthog-js/react";
import processAuthClaims from "utils/processAuthClaims";
import ProfilePreference from "./Preference/Preference.helpers";
import { dispatchSignupEvent } from "./SignupRouting/SignupRouting";
import { PROFILE_TYPE } from "entities/Profile";

export type GetProfilePreferencesResults = {
  preference: PROFILE_TYPE;
  team_invite_code: string | undefined;
  pending_session_id: string | undefined;
  given_name: string | undefined;
  family_name: string | undefined;
};

const useProfileService = () => {
  const { user, isLoading, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const posthog = usePostHog();
  const location = useLocation();

  const ProfilePreferenceService = new ProfilePreference({
    PreferenceStorage: ProfilePreferenceStorage,
    getProfiles: getProfiles,
  });

  const createMentor = async (context: ProfileContext) => {
    const token = await getAccessTokenSilently();
    const profile_data: ProfileCreationData = {
      given_name: context?.user?.given_name,
      family_name: context?.user?.family_name,
    };
    await requestCreateMentor(token, profile_data);
  };

  const createMenteeOwner = async (context: ProfileContext) => {
    const token = await getAccessTokenSilently();
    const profile_data: ProfileCreationData = {
      given_name: context?.user?.given_name,
      family_name: context?.user?.family_name,
      pending_session_id: context?.signup_options?.pending_session_id,
    };
    await requestCreateMentee(token, profile_data);
  };

  const createTeamMember = async (context: ProfileContext) => {
    const token = await getAccessTokenSilently();
    if (!context?.signup_options?.team_invite_code) {
      throw Error("No invite code provided");
    }
    const profile_data: InvitedMenteeProfileCreationData = {
      given_name: context?.user?.given_name,
      family_name: context?.user?.family_name,
      invite_code: context?.signup_options?.team_invite_code,
    };
    await requestJoinTeam(token, profile_data);
  };

  // REGISTER THE MACHINE
  const profile_service = useInterpret(profileMachine, {
    actions: {
      setTeamInvite: (_: ProfileContext, event: SET_TEAM_INVITE_CODE_EVENT) =>
        ProfilePreferenceStorage.setTeamInvite(event.data),
      setProfilePreferenceMentee: ProfilePreferenceStorage.setPreferenceMentee,
      setProfilePreferenceMentor: ProfilePreferenceStorage.setPreferenceMentor,
      redirectOnSuccess: (context: ProfileContext) => {
        navigate(context.redirect_to);
      },
      goToCreateMenteeProfile: () => navigate("/loading_profile"),
      goToCreateMentorProfile: () => navigate("/loading_profile"),
      identifyUser: () =>
        getAccessTokenSilently().then((token) => identifyUser(token)),
      clearSignupOptions: () => { 
        ProfilePreferenceStorage.clearSignupOptions()
      },
    },
    services: {
      getProfilePreferences:
        async (): Promise<GetProfilePreferencesResults> => {
          const token = await getAccessTokenSilently();
          const preference = await ProfilePreferenceService.get(token);
          const team_invite_code = ProfilePreferenceStorage.getTeamInvite();
          const pending_session_id =
            ProfilePreferenceStorage.getPendingSessionId();
          const given_name = ProfilePreferenceStorage.getGivenName();
          const family_name = ProfilePreferenceStorage.getFamilyName();
          return {
            preference,
            team_invite_code,
            pending_session_id,
            given_name,
            family_name,
          };
        },
      getMentor: () => getAccessTokenSilently().then(getMentor),
      detirmineRegistrationType: (context) => async (callback) => {
        const token = await getAccessTokenSilently();
        const { profile } = await getMentee(token);
        dispatchSignupEvent(profile || undefined, context, callback);
      },
      getAdmin: () => getAccessTokenSilently().then(getAdmin),
      getMentee: () => getAccessTokenSilently().then(getMentee),
      createMentor,
      createTeamMember,
      createMenteeOwner,
      claimPendingSession: (context) => getAccessTokenSilently().then((token) => claimPendingSession(token, context)),
    },
    devTools: true,
  });

  useEffect(() => {
    if (!isLoading && user) {
      profile_service.send({
        type: "GOT_USER",
        data: user,
      });
      // Posthog identify required here because of hooks use.
      const { user_id, email, given_name, family_name } =
        processAuthClaims(user);

      posthog.identify(user_id, {
        email,
        name: `${given_name} ${family_name}`,
      });
    }
  }, [isLoading, user]);

  const [loading, setLoading] = useState<
    "mentee" | "mentor" | "general" | "ready"
  >("general");

  const getLoadingType = (
    matches: (value: any) => boolean
  ): "mentee" | "mentor" | "general" => {
    if (matches({ mentee: "loading" })) {
      return "mentee";
    }
    if (matches({ mentor: "loading" })) {
      return "mentor";
    }
    return "general";
  };

  useEffect(() => {

    const current_path = location.pathname;
    const search_params = new URLSearchParams(location.search);
    const redirect_to = `${current_path}?${search_params.toString()}`;

    profile_service.send({
      type: "SET_REDIRECT_TO",
      data: { redirect_to },
    });
  }, [location]);

  useEffect(() => {
    const subscription = profile_service.subscribe((state) => {
      const is_loading = [
        "init",
        "loading",
        { mentee: "loading" },
        { mentor: "loading" },
        { admin: "loading" },
      ].some(state.matches);
      setLoading(is_loading ? getLoadingType(state.matches) : "ready");
    });
    return subscription.unsubscribe;
  }, [profile_service]);

  return { loading, profile_service };
};

export default useProfileService;
