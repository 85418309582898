import * as filestack from "filestack-js";
import styles from "./ImageUpload.module.css";
import { AddPhoto } from "../../Icons";
import AvatarPlaceholder from "../../../images/avatar-placeholder.gif";

interface ImageUploadProps {
  call_to_action: string;
  image_url: string | null;
  onSuccess: (data: filestack.PickerFileMetadata) => void;
  onError: () => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({
  call_to_action,
  image_url,
  onSuccess,
  onError,
}) => {
  const FS = filestack.init(process.env.REACT_APP_FILESTACK_API as string);

  const picker_options: filestack.PickerOptions = {
    fromSources: [
      "local_file_system",
      "instagram",
      "facebook",
      "googledrive",
      "webcam",
    ],
    accept: ["image/*"],
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10mb
    onFileUploadFinished: (result: filestack.PickerFileMetadata) =>
      onSuccess(result),
    onFileUploadFailed: () => onError(),
    transformations: {
      circle: false,
      crop: {
        force: true,
        aspectRatio: 1,
      },
    },
  };

  const openFilestack = () => {
    FS.picker(picker_options).open();
  };

  return (
    <div className={styles.container} data-testid="image_upload">
      {!!image_url ? (
        <img
          className={styles.image}
          alt="account-avatar-img"
          src={image_url || AvatarPlaceholder}
        />
      ) : (
        <div style={{ padding: "16px" }}>
          <div className={styles.placeholder} onClick={openFilestack}>
            <img src={AddPhoto} alt="camera" />
          </div>

          {/* <p className={styles.title}>{title}</p> */}
          <button className={styles.uploadBtn} onClick={openFilestack}>
            {call_to_action}
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
