import { Skill } from "entities/Mentor";
import { CrossIcon, AddIcon, EmptyStar } from "../../Icons";
import InfoContainer from "../../Molecules/InfoContainer";

import Styles from "./SkillsPicker.module.css";

const exceedsLimitDefault = () => {
  console.log("Exceeded Limited");
};

interface SkillsPickerProps {
  options: Skill[];
  limit: number;
  exceedsLimit: () => void;
  lauchAddSkill: () => void;
  removeSkill: (skill: Skill) => void;
}

const SkillsPicker = ({
  options,
  limit,
  exceedsLimit = exceedsLimitDefault,
  lauchAddSkill,
  removeSkill,
}: SkillsPickerProps) => {
  const onAddSkill = () => {
    if (options.length < limit) {
      lauchAddSkill();
    } else {
      exceedsLimit();
    }
  };

  return options.length > 0 ? (
    <div data-testid="SkillsPicker" className={Styles.picker}>
      {options.map((o, i) => (
        <div className={Styles.pill} key={i}>
          <p>{o.title}</p>
          <img
            src={CrossIcon}
            onClick={() => removeSkill(o)}
            alt="remove skill"
          />
        </div>
      ))}
      <div>
        <img
          onClick={onAddSkill}
          className={Styles.add}
          src={AddIcon}
          alt="add skill"
        />
      </div>
    </div>
  ) : (
    <InfoContainer
      Icon={EmptyStar}
      helperText="Add skills to help potential mentees find you."
      buttonText="Add First Skills"
      onClick={onAddSkill}
    />
  );
};

export default SkillsPicker;
