import React from "react";
import { useActor } from "@xstate/react";
import style from "./SessionReview.module.css";
import { ThreeDots } from "react-loader-spinner";
import {
  SessionReviewContext,
  SessionReviewEvent,
} from "./SessionReview.machine";
import { SessionQueryResultWithProfiles } from "entities/Session";
import session_review_machine from "./SessionReview.machine";
import FirstStep from "./Steps/Step1/FirstStep";
import SecondStep from "./Steps/Step2/SecondStep";
import ThirdStep from "./Steps/Step3/ThirdStep";
import FourthStep from "./Steps/Step4/FourthStep";
import FifthStep from "./Steps/Step5/FifthStep";
import EndReview from "./Steps/EndReview/EndReview";
import { useInterpret } from "@xstate-ninja/react";

interface SessionReviewProps {
  fetchSession: (
    context: SessionReviewContext,
    event: SessionReviewEvent
  ) => Promise<SessionQueryResultWithProfiles>;
  createReview: (
    context: SessionReviewContext,
    event: SessionReviewEvent
  ) => Promise<void>;
  bookAnotherSession: (
    context: SessionReviewContext,
    event: SessionReviewEvent
  ) => void;
}

const SessionReview = ({
  fetchSession,
  createReview,
  bookAnotherSession,
}: SessionReviewProps) => {
  const session_review_service = useInterpret(session_review_machine, {
    actions: {
      bookAnotherSession,
    },
    services: {
      fetchSession,
      createReview,
    },
    devTools: true,
  });

  const [state, send] = useActor(session_review_service);

  const updateNPS = (nps: number) => {
    send({ type: "UPDATE_NPS", nps });
  };

  const updateBestQuality = (best_quality: string) => {
    send({ type: "UPDATE_BEST_QUALITY", best_quality });
  };

  const updatePublicFeedback = (public_feedback: string) => {
    send({ type: "UPDATE_PUBLIC_FEEDBACK", public_feedback });
  };

  const updatePrivateFeedback = (private_feedback: string) => {
    send({ type: "UPDATE_PRIVATE_FEEDBACK", private_feedback });
  };

  const nextStep = () => {
    send("NEXT");
  };

  return (
    <div className={style.sessionReview}>
      {state.matches("loading") && (
        <ThreeDots
          wrapperStyle={{ margin: "auto", display: "block" }}
          color="#fff"
          height={42}
          width={42}
        />
      )}
      {state.context.session !== null && (
        <>
          {state.matches("first_step") && (
            <FirstStep
              id="first_step"
              data={state.context.session}
              nextStep={nextStep}
            />
          )}
          {state.matches("second_step") && (
            <SecondStep
              id="second_step"
              data={state.context.session}
              updateNPS={updateNPS}
            />
          )}
          {state.matches("third_step") && (
            <ThirdStep
              id="third_step"
              data={state.context.session}
              updateBestQuality={updateBestQuality}
              nextStep={nextStep}
            />
          )}
          {state.matches("fourth_step") && (
            <FourthStep
              id="forth_step"
              data={state.context.session}
              updatePublicFeedback={updatePublicFeedback}
              nextStep={nextStep}
            />
          )}
          {state.matches("fifth_step") && (
            <FifthStep
              id="fith_step"
              data={state.context.session}
              updatePrivateFeedback={updatePrivateFeedback}
              nextStep={nextStep}
            />
          )}
          {state.matches("end_review") && (
            <EndReview
              id="review_complete"
              data={state.context.session}
              updateStep={nextStep}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SessionReview;
