import { useCallback } from "react";
import styles from "./Button.module.css";
import { ThreeDots } from "react-loader-spinner";

type DefaultButtonPropsType = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

interface IButtonProps extends DefaultButtonPropsType {
  variant?: "primary" | "dark";
  loading?: boolean;
}

const classes = {
  primary: styles.primary,
  dark: styles.dark,
};

const Button: React.FC<IButtonProps> = ({
  variant = "primary",
  loading,
  style,
  onClick,
  children,
  className,
  disabled,
  ...rest
}) => {
  const renderContent = useCallback(() => {
    return (
      <>
        {loading ? (
          <ThreeDots
            wrapperStyle={{
              margin: "auto",
              display: "block",
              marginTop: "-6px",
            }}
            color="#fff"
            height={32}
            width={32}
          />
        ) : (
          children
        )}
      </>
    );
  }, [children, loading]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (loading || !onClick) {
      return;
    }

    onClick(event);
  };

  const baseClass = variant !== undefined ? classes[variant] : styles.primary;

  return (
    <button
      className={`${!!disabled ? styles.disabled : baseClass} ${className}`}
      style={style}
      onClick={handleClick}
      data-testid="button_container"
      {...rest}
    >
      {renderContent()}
    </button>
  );
};

export default Button;
