import { useCallback } from "react";
import styles from "./TickCheckbox.module.css";
import classNames from "classnames";

interface TickCheckBoxProps {
  id: string;
  checked: boolean;
  onCheck?: () => void;
  disabled?: boolean;
  className?: string;
}

const classes = classNames.bind(styles);

const TickCheckbox: React.FC<TickCheckBoxProps> = ({
  id,
  checked,
  onCheck,
  disabled,
  className,
}) => {
  const active_classes = classes({
    round_checkbox: true,
    [className || ""]: !!className,
  });

  const handleClick = useCallback(() => onCheck && onCheck(), [onCheck]);

  return (
    <div className={`${styles.round_checkbox} ${className}`} onClick={handleClick}>
      <input
        style={{ height: "16px" }}
        data-testid={`tick_checkbox_${id}`}
        id={id}
        type="checkbox"
        name={id}
        checked={checked}
        defaultChecked={checked}
        disabled={disabled}
      />
      <label/>
    </div>
  );
};

export default TickCheckbox;
