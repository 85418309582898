import { PROFILE_TYPE } from "entities/Profile";

export const PROFILE_PREFERENCE_KEY = "profile_preference";
export const TEAM_INVITE_CODE_KEY = "team_invite_code";
export const PENDING_SESSION_ID = "pending_session";

interface PreferenceStorage {
  getPreference(): PROFILE_TYPE | undefined;
  setPreferenceMentor(): void;
  setPreferenceMentee(): void;
  clear(): void;
  getTeamInvite(): string | undefined;
  setTeamInvite(code: string): void;
}

class ProfilePreferenceStorage implements PreferenceStorage {
  getPreference(): PROFILE_TYPE | undefined {
    return (
      (window.localStorage.getItem(PROFILE_PREFERENCE_KEY) as PROFILE_TYPE) ||
      undefined
    );
  }

  setPreferenceMentor(): void {
    window.localStorage.setItem(PROFILE_PREFERENCE_KEY, PROFILE_TYPE.MENTOR);
  }

  setPreferenceMentee(): void {
    window.localStorage.setItem(PROFILE_PREFERENCE_KEY, PROFILE_TYPE.MENTEE);
  }

  clear(): void {
    window.localStorage.removeItem(PROFILE_PREFERENCE_KEY);
    window.localStorage.removeItem(TEAM_INVITE_CODE_KEY);
  }

  getTeamInvite(): string | undefined {
    return window.localStorage.getItem(TEAM_INVITE_CODE_KEY) || undefined;
  }

  setTeamInvite(code: string): void {
    window.localStorage.setItem(TEAM_INVITE_CODE_KEY, code);
  }

  setPendingSessionId(id: string): void {
    window.localStorage.setItem(PENDING_SESSION_ID, id);
  }

  getPendingSessionId(): string | undefined {
    return window.localStorage.getItem(PENDING_SESSION_ID) || undefined;
  }

  getGivenName(): string | undefined {
    return window.localStorage.getItem("given_name") || undefined;
  }

  setGivenName(name: string | null): void {
    if (!!name) {
      window.localStorage.setItem("given_name", name);
    }
  }

  setFamilyName(name: string | null): void {
    if (!!name) {
      window.localStorage.setItem("family_name", name);
    }
  }
  getFamilyName(): string | undefined {
    return window.localStorage.getItem("family_name") || undefined;
  }

  setMentorPlusSignup(): void {
    window.localStorage.setItem("mentor_plus_signup", "true");
  }

  getMentorPlusSignup(): boolean {
    return window.localStorage.getItem("mentor_plus_signup") === "true";
  }
  
  setPlanId(plan_id: string): void {
    window.localStorage.setItem("plan_id", plan_id);
  }

  getPlanId(): string | undefined {
    return window.localStorage.getItem("plan_id") || undefined;
  }

  clearSignupOptions(): void {
    // set family name, given name, and pending session id, and team invite to undefined
    window.localStorage.removeItem("given_name");
    window.localStorage.removeItem("family_name");
    window.localStorage.removeItem(PENDING_SESSION_ID);
    window.localStorage.removeItem(TEAM_INVITE_CODE_KEY);
  }
}

export type { PreferenceStorage };

export default new ProfilePreferenceStorage();
