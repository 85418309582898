import { useCallback, useState } from "react";
import Hash from "object-hash";
import ProgressBar from "../../Atoms/ProgressBar";
import moment from "moment";
import { RightChevron } from "../../Icons";
import KeyResultItem from "../KeyResult";
import AddKeyResultInput from "../../Atoms/AddKeyResultInput";
import { KeyResult } from "entities/OKR";

import styles from "./ObjectiveListItem.module.css";

interface ObjectiveListItemProps {
  _id: string;
  title: string;
  due_date: string;
  key_results: KeyResult[];
  addKeyResult: (key_result: KeyResult) => void;
  deleteKeyResult: (key_result_id: string) => void;
  toggleKeyResult: (key_result_id: string) => void;
  viewObjective: (objective_id: string) => void;
}

const ObjectiveListItem: React.FC<ObjectiveListItemProps> = ({
  _id,
  title,
  due_date,
  key_results,
  addKeyResult,
  deleteKeyResult,
  toggleKeyResult,
  viewObjective,
}) => {
  const [show_key_results, setShowKeyResults] = useState<boolean>(false);

  const toggleShowKeyResults = () => setShowKeyResults(!show_key_results);
  const due_date_formatted = moment(due_date).format("MMM Do, YYYY");

  const calculateProgress = (key_results: KeyResult[]) =>
    key_results.length > 0
      ? Math.round(
          (100 / key_results.length) *
            key_results.filter((key_result) => !!key_result?.completedAt).length
        )
      : 0;

  const makeKeyResults = (
    key_results: KeyResult[]
  ): React.ComponentProps<typeof KeyResultItem>[] =>
    key_results.map((key_result) => ({
      _id: key_result._id,
      title: key_result.title,
      completed: !!key_result.completedAt,
      deleteKeyResult: deleteKeyResult,
      toggleKeyResult: toggleKeyResult,
    }));

  const view = useCallback(() => viewObjective(_id), [_id]);

  return (
    <div data-testid="objective_list_item" className={styles.container}>
      <div className={styles.preview_container}>
        <h4 className={styles.title} onClick={view}>
          {title}
        </h4>
        <div key={Hash(key_results)} className={styles.progress}>
          <p>{calculateProgress(key_results)}%</p>
          <ProgressBar progress={calculateProgress(key_results)} />
        </div>
        <p className={styles.due_date}>{due_date_formatted}</p>
        <div
          data-testid="toggle_key_results"
          className={styles.toggle_key_results_button}
          onClick={toggleShowKeyResults}
        >
          <img
            src={RightChevron}
            style={{ rotate: show_key_results ? "270deg" : "90deg" }}
          />
        </div>
      </div>
      {show_key_results && (
        <>
          <div className={styles.divider} />
          <div
            key={Hash(key_results)}
            className={styles.key_result}
            data-testid="key_result_list"
          >
            {makeKeyResults(key_results).map((key_result) => (
              <KeyResultItem key={key_result._id} {...key_result} />
            ))}
            <AddKeyResultInput
              key={"add_key_resultinput"}
              addKeyResult={addKeyResult}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ObjectiveListItem;
