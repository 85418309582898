import MenteeSessionsDashboard from "components/Organisms/MenteeSessions";
import Header from "components/Organisms/Header";

import styles from "./MySessions.module.css";

const MySessions = () => {
  return (
    <>
      <Header>
        <h3>Sessions</h3>
      </Header>
      <div className={styles.contentContainer}>
        <MenteeSessionsDashboard />
      </div>
    </>
  );
};

export default MySessions;
