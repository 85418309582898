/* eslint-disable indent */
import styles from "./DurationSelection.module.css";
import LargeRadioButtons from "components/Molecules/LargeRadioButtons";

export interface DurationSelectionProps {
  timezone?: string;
  hourly_token_rate: number;
  durations_available: {
    half_hour: boolean;
    hour: boolean;
  };
  balance: number;
  session_length: number;
  setSessionDuration: (duration: number) => void;
}

const DurationSelection = ({
  hourly_token_rate,
  durations_available,
  balance,
  session_length,
  setSessionDuration,
}: DurationSelectionProps) => {
  return (
    <div className={styles.timePicker}>
      <h4>How long do you want to talk?</h4>
      <p
        style={{
          fontSize: "12px",
          margin: "8px 0px",
        }}
      >
        {typeof balance !== "number"
          ? "Loading account balance..."
          : `You have ${balance} credits remaining in your account.`}
      </p>
      <div className={styles.durationSelection}>
        <LargeRadioButtons
          checked={session_length === 30}
          name="halfHour"
          value={"30"}
          valueLabel="30 minutes"
          helperText={`This will cost ${
            hourly_token_rate / 2 || "Data Not Available"
          } credits`}
          style={{
            opacity: durations_available.half_hour ? 1 : 0.5,
          }}
          onRadioBtnClick={() =>
            durations_available.half_hour && setSessionDuration(30)
          }
        />
        <div style={{ width: "24px" }} />
        <LargeRadioButtons
          checked={session_length === 60}
          name="hour"
          value={"60"}
          valueLabel="60 minutes"
          helperText={`This will cost ${
            hourly_token_rate || "Data Not Available"
          } credits`}
          onRadioBtnClick={() =>
            durations_available.hour && setSessionDuration(60)
          }
          style={{
            opacity: durations_available.hour ? 1 : 0.5,
          }}
        />
      </div>
    </div>
  );
};

export default DurationSelection;
