import styles from "./Pill.module.css";

interface PillProps {
  children: React.ReactNode;
  onClick?: () => void;
  style?: React.CSSProperties;
}

const Pill: React.FC<PillProps> = ({ children, onClick, style }) => {
  return (
    <div
      data-testid="Pill"
      className={styles.pill}
      style={style}
      onClick={onClick}
    >
      <p style={{ fontSize: "14px", color: "#fff" }}>{children}</p>
    </div>
  );
};

export default Pill;
