import { useActor, useSelector } from "@xstate/react";
import { useContext, useEffect, useState } from "react";
import { Business } from "entities/Business";
import { MenteeQueryResult } from "entities/Mentee";
import { BUSINESS_EVENT } from "services/Business/Business.schema";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { ProfileContext } from "services/Profile/Profile.schema";
import { State } from "xstate";

type CompareProfile =  MenteeQueryResult | undefined;

const selectMenteeProfile = (state: State<ProfileContext>) => state.context.mentee;
const compareMenteeProfiles = (profile: CompareProfile, next_profile: CompareProfile) => profile?._id === next_profile?._id;


const useBusiness = () => {
  const globalServices = useContext(GlobalServiceContext);
  const profile = useSelector(globalServices.profile_service, selectMenteeProfile, compareMenteeProfiles);
  const [state, send] = useActor(globalServices.business_service);

  const business = useSelector(
    globalServices.business_service,
    (state) => state.context.business
  );
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const [refetch_id, setRefetchId] = useState<number>(0);
  const [user_is_admin, setUserIdAdmin] = useState<boolean>(false);

  const refetch = () => setRefetchId(Math.random());

  useEffect(() => {
    setIsLoading(["init", "loading"].some(state.matches));
  }, [state]);

  useEffect(() => {
    send(BUSINESS_EVENT.GET_BUSINESS);
  }, [refetch_id]);

  useEffect(() => {
    const isAdmin = (business: Business | undefined, mentee_profile: MenteeQueryResult | undefined ) => {
      return !!(business?.admin === mentee_profile?.user_id)
    }
    setUserIdAdmin(isAdmin(business, profile))
  }, [business, profile])

  return { business, refetch, is_loading, user_is_admin };
};

export default useBusiness;
