import React, { ChangeEventHandler, useState } from "react";
import style from "../../SessionReview.module.css";
import TextArea from "components/Atoms/TextArea";

interface FifthStepProps {
  id: string;
  data: {
    to: {
      given_name: string;
    };
  };
  nextStep: () => void;
  updatePrivateFeedback: (value: string) => void;
}

const FifthStep = ({
  id,
  data,
  updatePrivateFeedback,
  nextStep,
}: FifthStepProps) => {
  const [value, setValue] = useState("");
  const [error, setError] = useState<string | undefined>();

  const onChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setError(undefined);
    setValue(e.target.value);
  };

  const validateAndMove = () => {
    if (!value) {
      setError("Please add some feedback, otherwise click skip");
      return;
    }
    updatePrivateFeedback(value);
  };
  return (
    <div data-testid={id} className={style.fourthStep}>
      <p className={style.label}>Question 4/4</p>
      <p className={style.question}>
        Leave a private review for {data.to.given_name}
      </p>
      <p className={style.midCentered}>
        Help {data.to.given_name} improve their sessions by letting them know
        what did and didn’t work for you.
      </p>
      <div className={style.textAreaBox}>
        <TextArea
          label="FEEDBACK"
		  name="feedback"
          placeholder="Type your answer here..."
          handleChange={onChange}
          value={value}
          error={error}
          style={{ marginBottom: "5px" }}
          dark
        />
      </div>
      <button onClick={validateAndMove}>Complete Review</button>
      <button className={style.skipBtn} onClick={nextStep}>
        {" "}
        Skip
      </button>
    </div>
  );
};

export default FifthStep;
