import React from "react";
import styles from "./IconButton.module.css";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export interface IconButtonProps {
  id?: string;
  icon: string;
  styles?: React.CSSProperties;
  className?: string;
  action: () => void;
}

const IconButton: React.FC<IconButtonProps> = ({
  id,
  icon,
  className = "",
  styles,
  action,
}) => {
  const classes = cx({
    button: true,
    [className]: !!className,
  });

  return (
    <div data-testid={id} className={classes} onClick={action} style={styles}>
      <img src={icon} />
    </div>
  );
};

export default IconButton;
