import React, { ChangeEvent } from "react";
import Styles from "./CustomCreditPack.module.css";

interface CustomCreditPackProps {
  cost_per_credit: number;
  credit_quantity: number;
  is_selected: boolean;
  updateCreditQuantity: (value: number) => void;
  setSelected: () => void;
}

const CustomCreditPack = ({
  cost_per_credit,
  credit_quantity,
  is_selected,
  updateCreditQuantity,
  setSelected,
}: CustomCreditPackProps) => {

  const handleUpdate = (event: ChangeEvent<HTMLInputElement>) => {
    let parsed_int = Number.parseInt(event.target.value, 10);
    if (Number.isNaN(parsed_int)) {
      parsed_int = 0;
    };
    updateCreditQuantity(parsed_int);
  };

  const cpc_price = cost_per_credit / 100;

  const format_currency = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(cpc_price)

  const total_cost = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(credit_quantity * cpc_price);

  return (
    <div
      className={Styles.container}
      data-selected={is_selected}
      onClick={setSelected}
    >
      <div>
        <h4>Build your credit pack</h4>
        <div className={Styles.input_container}>
          <p className={Styles.credit_info}>
            / {format_currency} per credit
          </p>
          <input
            value={`${credit_quantity}`}
            type="number"
            min="0"
            onChange={handleUpdate}
          />
        </div>
      </div>
      <div className={Styles.cost}>
        <h3>{total_cost}</h3>
      </div>
    </div>
  );
};

export default CustomCreditPack;
