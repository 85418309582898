import { assign, createMachine } from 'xstate';
import { SessionQueryResultWithProfiles } from "entities/Session";

export interface SessionReviewContext {
  session: SessionQueryResultWithProfiles | null;
  review: {
    nps: null | number;
    best_quality: null | string;
    public_feedback: null | string;
    private_feedback: null | string;
  };
}

export type SessionReviewEvent =
  | { type: 'UPDATE_NPS'; nps: number }
  | { type: 'UPDATE_BEST_QUALITY'; best_quality: string }
  | { type: 'UPDATE_PUBLIC_FEEDBACK'; public_feedback: string }
  | { type: 'UPDATE_PRIVATE_FEEDBACK'; private_feedback: string }
  | { type: 'NEXT' };

const sessionReviewMachine = createMachine<SessionReviewContext, SessionReviewEvent>({
  /** @xstate-layout N4IgpgJg5mDOIC5SzrAlgewHYH0BOYAbmmAO4B0ANhgIYRpZQDEE2Y5DhGA1uyrOmz4iJCtToMoCThgDGNAC6YsAbQAMAXXUbEoAA4Z0S7LpAAPRACYArAGZyARksAWAJwAONbYevr1y5YANCAAnogOduTOAOwAbNYxaq7R0e6x7rYAvpnB-IK4BMRkVLT0jExgeHgYeOR6lIoAZjUAtuR5ysJFYqWS0lhc8saqmtqmBkbKphYINvZObp7evv5BoeFq1uTJ8c7OaZYZDtGu2bmonYWi5I1oeLAKOA9gekwAcgCiABoAKmNIIAmaGG00Q1li0XI0VszlsATcCOc1mCYQQx2cUK8DjUqUsrgc7gyWRyIA6QiuxRQsmwECeCheTAAqgAFAAiAEEfh8cG9mQBlf76QzAqYAmaxCHkQ7uVyuSypWzuaHRFGIdwOcjWNTatQOZzxHZqdxnUkXckiSlgalYWnPV6fX6CwHCkFitUBci2NT63W2ayuOHJVVo9XbOxJaKwuwOAmxE1kgoWigKAAWd1t9NeLI5XJwACEPnyfjgAIqM9kAGQAkj8AJpOoGu0AzbGRGKxGLOPWxNSxVzBiKuKWWeJ+glefYZeNmxPdG4YACueFTdIZ2c53OZjLz1YAwjgAGIfD6svPs3cAaQbLtFzfC0qhzks2MVML2sWDhMfstHahcnesacBEuJN5yXFc7Xeb4-k0cYbxMN00T8exbAhJxbFlGVvAHGUpS8JJZRHEcIiA-IumuW5GggzMmTZDccGZAAlKsADV6KPE8z0va9JgQu80XiDV3GsPVhOEmJoicYNfG2WVXAhE5bAw6IklIkC50o6iGQdGCdABRtb3McI5QxH13Esb0R2iVYB3kqJoz-LtomfGNnDU805zAG1yLIKDHVg-T4KwUFZi8SE4U8CJJMORUP3WBA0m-ZIFnHBI3JNLAMAgOBTATHzSDg3jgsQgBaOLURKrY5OqmrZWJc5gI865xDKKBCpFPijIQJ8B1sSEkW8Bw+pSdVn3c2cKLuB5Vz0dqmy6vZLClAlnIDJVYXiZF4uOLYLO8P9ZWVYTxvy9orRpGa5sM8VJOWtJ3CjHEHpVbbzPs7xvBsE4lksE6KWTNM8AzF4rs6mY5S2VIZViOEIgcPt3BwpaBqSEcvC1MaSTy-6wOXFNLsCoqQqGkSwy9PqJQ7BJbBwocVK9Xwkj2ESIT+0DNPxu1QeK-jjhHKI+sVaGXP7ba7N8BnrPE302c87z-u5kKLPlbYkQJAJzP9FwcMhensRfRUklZrGZ1OypqjwRXEMWu7VsVSNUL8AcvChEd-Dk3UY1+7JMiAA */
  id: 'session_review',
  initial: 'loading',
  context: {
    session: null,
    review: {
      nps: null,
      best_quality: null,
      public_feedback: null,
      private_feedback: null,
    }
  },
  states: {
    loading: {
      invoke: {
        src: 'fetchSession',
        onDone: {
          target: 'first_step',
          actions: assign({
            session: (context, event) => event.data,
          }),
        },
        onError: {
          target: 'error',
        },
      },
    },
    first_step: {
      on: {
        NEXT: 'second_step',
      },
    },
    second_step: {
      on: {
        UPDATE_NPS: {
          actions: assign({
            review: (context, event) => ({ ...context.review, nps: event.nps }),
          }),
          target: 'third_step',
        },
        NEXT: 'third_step',
      },
    },
    third_step: {
      on: {
        UPDATE_BEST_QUALITY: {
          actions: assign({
            review: (context, event) => ({ ...context.review, best_quality: event.best_quality }),
          }),
          target: 'fourth_step',
        },
      },
    },
    fourth_step: {
      on: {
        UPDATE_PUBLIC_FEEDBACK: {
          actions: assign({
            review: (context, event) => ({ ...context.review, public_feedback: event.public_feedback }),
          }),
          target: 'fifth_step',
        },
        NEXT: 'fifth_step',
      },
    },
    fifth_step: {
      on: {
        UPDATE_PRIVATE_FEEDBACK: {
          actions: assign({
            review: (context, event) => ({ ...context.review, private_feedback: event.private_feedback }),
          }),
          target: 'end_review',
        },
        NEXT: 'end_review',
      },
    },
    end_review: {
      invoke: {
        src: 'createReview',
      },
      on: {
        NEXT: {
          actions: ["bookAnotherSession"],
        }
      },
    },
    error: {
    },
  },
});

export default sessionReviewMachine;

export type SessionReviewMachine = typeof sessionReviewMachine;