import Button from "components/Atoms/Button";
import CreateMentorCollection from "components/Organisms/CreateMentorCollection";
import Header from "components/Organisms/Header";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import styles from "./MentorCollections.module.css";
import useMentorCollections from "hooks/admin/useMentorCollections";
import Select from "react-select";
import useMentees from "hooks/useMentees";

Modal.setAppElement("#root");

const MentorCollections = () => {
  const [create_mentor_open, setCreateMentorOpen] = useState<boolean>(false);
  const { mentees: mentor_collections, refetch } = useMentees();
  const closeCreateMentorModal = () => setCreateMentorOpen(false);

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Header>
        <h2>Mentor Collections</h2>
      </Header>
      <div className={styles.container}>
        <Button onClick={() => setCreateMentorOpen(true)}>
          Create Collection
        </Button>
        <div>
          <Select
            options={mentor_collections}
            getOptionLabel={(option) => option.given_name}
            getOptionValue={(option) => option._id}
          />
        </div>
      </div>
      <Modal
        isOpen={create_mentor_open}
        onRequestClose={closeCreateMentorModal}
        className={styles.create_collection_modal}
        overlayClassName={styles.overlay}
      >
        <CreateMentorCollection onSuccess={closeCreateMentorModal} />
      </Modal>
    </>
  );
};

export default MentorCollections;
