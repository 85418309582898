import React from "react";
import { Formik } from "formik";
import styles from "./CreateProfile.module.css";
import CheckBox from "components/Atoms/Checkbox";
import AltTextInput from "components/Atoms/AltTextInput";
import { AdditionalDetailsValidationSchema } from "./Schemas";
import { ThreeDots } from "react-loader-spinner";
import { errorOrUndefined } from "utils/format";

interface AdditionalDetailsFormProps {
  updateAdditionalDetails: (
    values: any,
    setSubmitting: (isSubmitting: boolean) => void
  ) => void;
}

const AdditionalDetailsForm = ({
  updateAdditionalDetails,
}: AdditionalDetailsFormProps) => {
  return (
    <>
      <h3 id="about-you" style={{ color: "#000", textAlign: "center" }}>
        About you
      </h3>
      <Formik
        initialValues={{
          name: "",
          terms_conditions_accepted: false,
        }}
        validationSchema={AdditionalDetailsValidationSchema}
        onSubmit={(values, { setSubmitting }) =>
          updateAdditionalDetails(values, setSubmitting)
        }
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className={styles.form}>
            <AltTextInput
              id="name"
              name="name"
              value={values.name}
              label="Your name"
              type="text"
              error={errorOrUndefined(errors?.name, touched?.name)}
              placeholder=""
              handleBlur={handleBlur}
              handleChange={handleChange}
            />
            <div className={styles.options_container}>
              <CheckBox
                id="terms_conditions_accepted"
                name="terms_conditions_accepted"
                checked={values.terms_conditions_accepted}
                error={errorOrUndefined(
                  errors?.terms_conditions_accepted,
                  touched?.terms_conditions_accepted
                )}
                label={
                  <p
                    style={{
                      fontSize: "16px",
                      marginLeft: "4px",
                      marginTop: "-2px",
                    }}
                  >
                    Click here to accept our{" "}
                    <a
                      style={{
                        textDecoration: "underline",
                      }}
                      href="https://www.mentorpass.co/privacy-terms"
                      target="_blank"
                      rel="noreferrer"
                    >
                      terms and conditions.
                    </a>
                  </p>
                }
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </div>
            <button type="submit" className={styles.submit_button}>
              {isSubmitting ? (
                <ThreeDots
                  // style={{ marginRight: "2px" }}
                  color="#FFF"
                  height={18}
                  width={24}
                />
              ) : (
                "Continue"
              )}
            </button>
          </form>
        )}
      </Formik>
    </>
  );
};

export default AdditionalDetailsForm;
