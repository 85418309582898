import { useAuth0 } from "@auth0/auth0-react";
import useNotification from "antd/es/notification/useNotification";
import useNotificationDismissal from "hooks/ui/useNotificationDismissal";
import useMyProfile from "hooks/useMyProfile";
import useProfile from "hooks/useProfile";
import { useEffect, useState } from "react";
import { API_URL, createHeaders } from "utils/api";

type GetOnboardingRequired = (
  token: string,
  onSuccess: (value: boolean) => void,
  onFailure: (error: string) => void
) => Promise<void>;

const useMenteeOnboardingRequired = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { profile } = useMyProfile();
  const [onboarding_required, setOnboardingRequired] = useState<boolean>(false);
  const { dismissed, dismissNotification } = useNotificationDismissal(
    "mentee_onboarding_required_dismissed"
  );

  useEffect(() => {
    const getOnboardingRequired: GetOnboardingRequired = async (
      token,
      onSuccess,
      onFailure
    ) => {
      const response = await fetch(`${API_URL}/mentee/onboarding-required`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      });
      if (!response.ok) {
        onFailure("Failed to fetch onboarding required");
      }
      const { onboarding_required } = await response.json();
      onSuccess(onboarding_required);
    };

    const onSuccess = (value: boolean) => setOnboardingRequired(value);
    const onFailure = (error: string) => {
      console.error(error);
    };

    if (!dismissed && profile?._id && profile?.type === "mentee") {
      getAccessTokenSilently().then((token) =>
        getOnboardingRequired(token, onSuccess, onFailure)
      );
    }
  }, []);

  return {
    onboarding_required: onboarding_required && !dismissed,
    dismissOnboardingRequired: dismissNotification,
  };
};

export default useMenteeOnboardingRequired;
