import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { createHeaders, API_URL } from "../../../../../utils/api";
import GoogleCalendarComponent from "./GoogleCalendar.component";
import { useAuth0 } from "@auth0/auth0-react";
import useMyProfile from "hooks/useMyProfile";
import { useAppSelector } from "hooks/redux";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import { MentorQueryResult } from "entities/Mentor";
import { MenteeQueryResult } from "entities/Mentee";

const GoogleCalendar = ({ id }: { id: string }) => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const [loadingAuthLink, setLoadingAuthLink] = useState(false);
  const [loadingCalendars, setLoadingCalendars] = useState(false);
  const [calendars, setCalendars] = useState(null);

  const { profile } = useMyProfile();

  const isMentor = (profile: MentorQueryResult | MenteeQueryResult): profile is MentorQueryResult =>  {
    return profile.type === "mentor";
  };

  if(!isMentor(profile)) {
    throw new Error("Google Calendar is only for mentors.");
  }

  const { update } = useUpdateMentor();

  const displayError = () =>
    dispatch({
      type: "SET_ALERT",
      payload: {
        type: "error",
        message: `There was an error. Contact support.`,
      },
    });

  const disconnectCalendar = async () => {
    console.log("disconnectCalendar");
    setLoadingCalendars(true);
    try {
      const token = await getAccessTokenSilently();
      // fetch link from API.
      const response = await fetch(`${API_URL}/user/google/disconnect`, {
        method: "POST",
        mode: "cors", // no-cors, *cors, same-origin
        // credentials: 'same-origin',
        headers: createHeaders(token),
      })

      if (response.status !== 200) {
        response.json().then((json) => {
          displayError();
          console.error(json.error);
        });
      } else {
        setLoadingCalendars(false);
      }

    } catch (error) {
      console.error(error);
      setLoadingCalendars(false);
      displayError();
    }
  };

  // open google auth
  const openGoogleAuth = async () => {
    setLoadingAuthLink(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/user/google/create-oauth-link`, {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      })
      if (response.status !== 200) {
        response.json().then((json) => {
          displayError();
          console.error(json.error);
        });
      } else {
        response.json().then((json) => {
          window.location.href = json.oauthUrl;
        });
      }
    } catch (error) {
      console.error(error);
      setLoadingAuthLink(false);
      displayError();
    }
  };

  const getCalendars = async () => {
    try {
      const token = await getAccessTokenSilently();
      setLoadingCalendars(true);
      const response = await fetch(`${API_URL}/profile/calendars`, {
        method: "GET",
        mode: "cors", // no-cors, *cors, same-origin
        // credentials: 'same-origin',
        headers: createHeaders(token),
      })
      if (response.status !== 200) {
        response.json().then((json) => {
          console.error(json);
          displayError();
          setLoadingCalendars(false);
        });
      } else {
        response.json().then((json) => {
          setCalendars(json.calendars);
          setLoadingCalendars(false);
        });
      }
    } catch (error) {
      console.error(error);
      displayError();
      setLoadingCalendars(false);
    }
  };

  const selectCalToAvoid = (id: string) => {
    // patch the profile
    let calendarsToAvoid =
      (profile.google && profile.google.calendarsToAvoid) || [];

    if (calendarsToAvoid.includes(id)) {
      // remove calendar if from array in place
      calendarsToAvoid.splice(calendarsToAvoid.indexOf(id), 1);
    } else {
      calendarsToAvoid.push(id);
    }

    const patch = {
      google: {
        ...profile.google,
        calendarsToAvoid
      }
    };
    update(patch)
  };

  useEffect(() => {
    if (profile?.calendar_connected) {
      getCalendars();
    }
  }, [profile]);

  return (
    <GoogleCalendarComponent
      componentId={id}
      accountConnected={!!profile?.calendar_connected}
      loadingAuthLink={loadingAuthLink}
      loadingCalendars={loadingCalendars}
      calendars={calendars || []}
      profile={profile}
      openGoogleAuth={openGoogleAuth}
      calendarsToAvoid={
        (profile.google && profile.google.calendarsToAvoid) || []
      }
      selectCalToAvoid={selectCalToAvoid}
      disconnectCalendar={disconnectCalendar}
    />
  );
};

export default GoogleCalendar;
