import React, { useMemo } from "react";
import BookingCalendarComponent from "./BookingCalendar.component";
import { useDispatch, useSelector } from "react-redux";
import { setBookingDate } from "../../../../../../store/dux/booking/booking.actions";
import { useAuth0 } from "@auth0/auth0-react";
import processAuthClaims from "utils/processAuthClaims";

const BookingCalendar = () => {
	const dispatch = useDispatch();

	const availability = useSelector((state: any) => state.booking.availability);

	const { user } = useAuth0();

	const { timezone } = useMemo(() => processAuthClaims(user), [user]);

	const selected_iso_date = useSelector((state: any) => state.booking.date);

	const is_loading = useSelector(
		(state: any) =>
			state.booking.availability_is_loading || state.modalReducer.isLoading
	);

	const onSelectCalendarDay = (date: string) => {
		dispatch(setBookingDate(date));
		// scroll to time selection after 0.5s
		setTimeout(() => {
			const time_selection_input =
				window.document.getElementById("booking-time");
			time_selection_input?.scrollIntoView({ behavior: "smooth" });
		}, 350);
	};

	return (
		<BookingCalendarComponent
			availability={availability}
			user_timezone={timezone}
			selected_iso_date={selected_iso_date}
			is_loading={is_loading}
			onSelectCalendarDay={onSelectCalendarDay}
		/>
	);
};

export default BookingCalendar;
