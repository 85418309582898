import DropdownSelector from "components/Atoms/DropdownSelector";

import styles from "./DropDownVariableInput.module.css";

interface DropdownVariableInputProps {
  id: string;
  label: string;
  options: Array<{
    title: React.ReactNode;
    value: string;
  }>;
  selected: string;
  onUpdate: (e: any) => void;
  onSelect: (e: any) => void;
  value: any;
  style?: any;
}

const DropdownVariableInput: React.FC<DropdownVariableInputProps> = ({
  id,
  label,
  options,
  selected,
  onSelect,
  value,
  onUpdate,
}) => {
  const options_by_value = options.reduce(
    (acc, cur) => {
      acc[cur.value] = cur;
      return acc;
    },
    {} as Record<
      string,
      {
        title: React.ReactNode;
        value: string;
      }
    >
  );

  return (
    <div data-testid={id} className={styles.container}>
      <DropdownSelector
        id={`${id}_dropdown`}
        label={label}
        options={options}
        onSelect={onSelect}
        className={styles.dropdown}
        listClassName={styles.options}
        itemClassName={styles.item}
        buttonClassName={styles.button}
        labelClassName={styles.label}
        value={options_by_value[selected]}
        disabledDefaultOutline
        dark
      />
      <input
        className={styles.text_input}
        type="text"
        value={value}
        onChange={onUpdate}
      />
    </div>
  );
};

export default DropdownVariableInput;
