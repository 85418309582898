import { useActor } from "@xstate/react";
import { useContext } from "react";
import { GlobalServiceContext } from "services/GlobalServiceProvider";

const useDisplayPaywall = (): boolean => {
  const { business_service } = useContext(GlobalServiceContext);
  const [state] = useActor(business_service);

  return state.matches("requires_purchase");

};

export default useDisplayPaywall;
