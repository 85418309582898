import { useEffect } from "react";

import styles from "./TextArea.module.css";

interface ITextAreaProps {
  id?: string;
  value: string;
  error?: string | boolean;
  placeholder?: string;
  label?: string;
  style?: React.CSSProperties;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  dark?: boolean;
  helper?: string;
  buttonText?: string;
  onButtonClick?: () => void;
  onIconClick?: () => void;
  Icon?: string;
  SecIcon?: string;
  blue?: boolean;
  autofocus?: boolean;
  submitOnEnter?: boolean;
}

const TextArea: React.FC<ITextAreaProps> = ({
  id = String(Math.floor(Math.random() * Date.now())),
  value,
  error,
  placeholder,
  label,
  style,
  onChange,
  dark,
  helper,
  buttonText = "",
  onButtonClick,
  onIconClick,
  Icon,
  SecIcon,
  blue,
  autofocus,
  submitOnEnter = true,
}) => {
  let classes = `${styles.textArea} `;
  let textPadding = "";
  classes += dark ? "black" : "";
  classes += error ? " error" : "";
  const actionStyle = Icon && buttonText ? { marginLeft: "5px" } : {};

  if (Icon && buttonText) {
    textPadding += " input-btn-icon-padding";
  } else if (Icon && SecIcon) {
    textPadding += " input-two-icon-padding";
  } else if (Icon && !buttonText) {
    textPadding += " input-icon-padding";
  } else if (!Icon && buttonText) {
    textPadding += " input-btn-padding";
  }

  useEffect(() => {
    if (autofocus) {
      const d = document.getElementById(id);
      if (d) focus();
    }
  }, [autofocus, id]);

  const handleKeydown = (event: React.KeyboardEvent) => {
    if (submitOnEnter) {
      if (event.key === "Enter") {
        if (onButtonClick) {
          onButtonClick();
        }
      }
    }
  };

  return (
    <div data-testid="TextArea" className="input-main">
      <div className="input-container" style={style}>
        <label
          className={`${dark ? styles.darkLabel : ""} ${blue ? "blue" : ""}`}
          htmlFor={id}
        >
          {label}
        </label>
        <textarea
          id={id}
          style={{
            border: error ? "1px solid var(--error-colour)" : undefined,
          }}
          className={classes + textPadding}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={handleKeydown}
        >
          {value}
        </textarea>

        <div className="input-action-textarea">
          {buttonText.trim() && buttonText.length > 0 ? (
            <button
              style={actionStyle}
              className="input-button"
              onClick={onButtonClick}
            >
              {buttonText}
            </button>
          ) : null}

          {Icon && (
            <img
              src={Icon}
              alt="EyeIcon"
              className="input-icon"
              onClick={onIconClick}
            />
          )}
        </div>
      </div>

      {helper && <p>*{helper}</p>}
      {error && typeof error === "string" && (
        <p style={{ color: "red" }}>{error}</p>
      )}
    </div>
  );
};

export default TextArea;
