import EditHighlightModal from './EditHighlight';
import useUpdateMentor from 'hooks/mentor/account/useUpdateMentor';
import useMyProfile from 'hooks/useMyProfile';
import { Highlight, isMentor } from 'entities/Mentor';

const EditHighlight = ({ previous_highlight, closeModal }: { previous_highlight: Highlight, closeModal: () => void }) => {
    const { update } = useUpdateMentor();
    const { profile } = useMyProfile();

    const updateHighlight = async (highlight: Highlight) => {

        if (!isMentor(profile)) {
            throw Error("Only mentors can edit highlights")
        }

        let highlights = profile?.highlights || [];
        highlights = highlights.filter((h) => h.title !== previous_highlight.title);
        highlights.push(highlight);

        await update({ highlights });
        closeModal();
    };

    return <EditHighlightModal previous_highlight={previous_highlight} updateHighlight={updateHighlight} closeModal={closeModal} />;
};

export default EditHighlight;
