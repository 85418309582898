import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import styles from "./MenteeNoSessionResults.module.css";

interface IMenteeNoSessionResultsProps {
  title: string;
}

const MenteeNoSessionResults: React.FC<IMenteeNoSessionResultsProps> = ({
  title,
}) => {
  const navigate = useNavigate();

  const navigateToHome = () => {
    navigate("/");
  };

  return (
    <div data-testid="MenteeNoSessionResults" className={styles.container}>
      <p className={styles.title}>{title}</p>
      <button
        onClick={navigateToHome}
        className={classNames(["btn-dark", styles.button])}
      >
        Book a session
      </button>
    </div>
  );
};

export default MenteeNoSessionResults;
