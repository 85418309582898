import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";

interface Stat {
  title: string;
  value: string;
}

const useTeamStatistics = () => {
  const [stats, setStats] = useState<Stat[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [refetch_id, setRefetchId] = useState<number>(0);
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const { getAccessTokenSilently } = useAuth0();

  const refetch = () => setRefetchId(Math.random());

  useEffect(() => {
    const getSatistics = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(
          `${API_URL}/team/stats`,
          {
            headers: createHeaders(token),
          }
        );
        const data = await response.json();
        setStats(data);
      } catch (error) {
        setError(String(error));
      } finally {
        setIsLoading(false);
      }
    };
    getSatistics();
  }, [refetch_id]);

  return { stats, is_loading, refetch, error };
};

export default useTeamStatistics;
