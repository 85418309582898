import { IMenuItem } from "./types";

import {
  Settings,
  SettingsActive,
  MySession,
  MySessionActive,
  Dashboard,
  DashboardActive,
  Explore,
  ExploreActive,
  Objectives,
  ObjectivesActive,
  Team,
  TeamActive,
  VideoActive,
  VideoInactive,
  Message,
  MessageInactive,
} from "components/Icons";

export enum NavType {
  ACCOUNT = "ACCOUNT",
  NAVIGATION = "NAVIGATION",
}

export const NAV: IMenuItem[] = [
  {
    activeIcon: SettingsActive,
    icon: Settings,
    text: "Account",
    id: "account",
    type: NavType.ACCOUNT,
  },
];

const COMMUNITY: IMenuItem = {
  activeIcon: Message,
  icon: MessageInactive,
  text: "Community",
  id: "community",
  type: NavType.NAVIGATION,
}

export const MENUS: Record<
  "mentor" | "mentee_team_member" | "mentee_admin" | "admin",
  IMenuItem[]
> = {
  mentor: [
    {
      activeIcon: DashboardActive,
      icon: Dashboard,
      text: "Dashboard",
      id: "dashboard",
      type: NavType.NAVIGATION,
    },
    COMMUNITY,
    {
      activeIcon: VideoActive,
      icon: VideoInactive,
      text: "Video",
      id: "video",
      type: NavType.NAVIGATION,
    },
    ...NAV,
  ],
  mentee_team_member: [
    {
      activeIcon: ExploreActive,
      icon: Explore,
      text: "Discover",
      id: "",
      type: NavType.NAVIGATION,
    },
    COMMUNITY,
    {
      activeIcon: ObjectivesActive,
      icon: Objectives,
      text: "Objectives",
      id: "objectives",
      type: NavType.NAVIGATION,
    },
    {
      activeIcon: MySessionActive,
      icon: MySession,
      text: "Sessions",
      id: "my-sessions",
      type: NavType.NAVIGATION,
    },
    {
      activeIcon: VideoActive,
      icon: VideoInactive,
      text: "Video",
      id: "video",
      type: NavType.NAVIGATION,
    },
    ...NAV,
  ],
  mentee_admin: [
    {
      activeIcon: ExploreActive,
      icon: Explore,
      text: "Discover",
      id: "",
      type: NavType.NAVIGATION,
    },
    COMMUNITY,
    {
      activeIcon: MySessionActive,
      icon: MySession,
      text: "Sessions",
      id: "my-sessions",
      type: NavType.NAVIGATION,
    },
    {
      activeIcon: ObjectivesActive,
      icon: Objectives,
      text: "Objectives",
      id: "objectives",
      type: NavType.NAVIGATION,
    },
    {
      activeIcon: VideoActive,
      icon: VideoInactive,
      text: "Video",
      id: "video",
      type: NavType.NAVIGATION,
    },
    {
      activeIcon: TeamActive,
      icon: Team,
      text: "Team",
      id: "team",
      type: NavType.NAVIGATION,
    },
    ...NAV,
  ],
  admin: [
    // {
    //   activeIcon: ExploreActive,
    //   icon: Explore,
    //   text: "Mentor Browser",
    //   id: "",
    //   type: NavType.NAVIGATION,
    // },
    // {
    //   activeIcon: ObjectivesActive,
    //   icon: Objectives,
    //   text: "Mentee Tools",
    //   id: "mentee-tools",
    //   type: NavType.NAVIGATION,
    // },
    COMMUNITY,
    {
      activeIcon: ObjectivesActive,
      icon: Objectives,
      text: "Collections",
      id: "admin/mentor_collections",
      type: NavType.NAVIGATION,
    },
    ...NAV,
  ],
};
