import React from "react";
import profileVideoBG from "../../../../../images/profile-video-bg.png";
import { PlayIcon } from "../../../../../components/Icons";
interface ProfileVideoProps {
  profile_name: string;
  playVideo: () => void;
  closeModal: () => void
}

const ProfileVideo = ({ profile_name, playVideo, closeModal }: ProfileVideoProps) => {
  return (
    <div className="profile-video-preview" onClick={playVideo}>
      <img src={profileVideoBG} alt="profile-video-bg" />
      <span>
        <h3 style={{ marginTop: "4px" }}>Get to know {profile_name}</h3>
        <img src={PlayIcon} alt="play-icon" />
      </span>
    </div>
  );
};

export default ProfileVideo;
