import React, { useState } from "react";
import { CrossIcon } from "../../../Icons";
import style from "./CreditAvatar.module.css";
import CreditPackCheckout from "../../CreditPackCheckout/CreditPackCheckout.component";
import { ThreeDots } from "react-loader-spinner";

interface CreditAvatarProps {
  credit_balance?: number;
  credits_loading: boolean;
  is_mobile: boolean;
}

const CreditAvatar = ({
  credit_balance,
  credits_loading,
  is_mobile,
}: CreditAvatarProps) => {
  const [credit_window_open, setCreditWindow] = useState(false);
  const toggleCreditWindow = () => setCreditWindow(!credit_window_open);
  
  return (
    <div className={credit_window_open ? style.creditAvatarDesktop : ""}>
      {credit_window_open ? (
        <div className={style.creditAvatarPosition}>
          <button
            className={style.active_round_btn}
            onClick={toggleCreditWindow}
          >
            <img src={CrossIcon} alt="cross-icon" height="12px" width="12px" />
          </button>
          <div className={style.avatarWindow}>
            <CreditPackCheckout
              id={"credit_pack_checkout"}
              toggleCreditWindow={toggleCreditWindow}
            />
          </div>
        </div>
      ) : !credits_loading ? (
        <button className={style.round_btn} onClick={toggleCreditWindow}>
          {credit_balance || 0}
        </button>
      ) : (
        !is_mobile && <button disabled className={style.loading_button} />
      )}
    </div>
  );
};

export default CreditAvatar;
