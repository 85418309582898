import Header from "components/Organisms/Header";
import styles from "./TeamManagment.module.css";
import InviteTeammember from "./components/InviteTeamMember.component";
import Team from "./components/Team.component";
import TeamStatistics from "./components/TeamStatistics.component";

const TeamManagmentScreen = () => {
  return (
    <>
      <Header>
        <h3>Team Managment</h3>
      </Header>
      <div className={styles.content_container}>
        <TeamStatistics />
        <InviteTeammember />
        <Team />
      </div>
    </>
  );
};

export default TeamManagmentScreen;
