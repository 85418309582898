import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";

const useRemoveTeamMember = ({ onRemoved }: { onRemoved: () => void }) => {
  const [error, setError] = useState<string | undefined>();
  const [is_loading, setIsLoading] = useState<boolean>(false);
  const { getAccessTokenSilently } = useAuth0();

  const remove = async (profile_id: string) => {
    try {
      setIsLoading(true);
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/team/remove/${profile_id}`, {
        headers: createHeaders(token),
      });
      if (!response.ok) {
        throw Error("Failed to remove team member.");
      }
    } catch (error) {
      setError(String(error));
    } finally {
      setIsLoading(false);
      onRemoved();
    }
  };
  return { remove, is_loading, error };
};

export default useRemoveTeamMember;
