import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "@blaumaus/react-alert";
import { MentorQueryResult } from "entities/Mentor";

const useMentor = ({ slug }: { slug?: string }) => {
  const [mentor, setMentor] = useState<MentorQueryResult | undefined>();
  const [refetch_id, setRefetchId] = useState<number>(0);
  const [error, setError] = useState<string | undefined>();
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();

  const refetch = () => setRefetchId(Math.random());

  useEffect(() => {
    const getMentor = async () => {
      try {
        const token = await getAccessTokenSilently();
        const response = await fetch(`${API_URL}/mentor/byslug/${slug}`, {
          method: "GET",
          mode: "cors",
          headers: createHeaders(token),
        });
        const data = await response.json();
        setMentor(data);
        setError(undefined);
      } catch (error) {
        setError(String(error));
      } finally {
        setIsLoading(false);
      }
    };
    if (!!slug) {
      getMentor();
    }
  }, [refetch_id, slug]);

  return { mentor, is_loading, refetch, error };
};

export default useMentor;
