/* eslint-disable indent */
import React, { useEffect, useState, useRef } from "react";
import styles from "./MenteeProfile.module.css";
import * as filestack from "filestack-js";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "components/Atoms/TextInput";
import AvatarPlaceholder from "../../../../../images/avatar-placeholder.gif";
import useUpdateMentee from "hooks/mentee/useUpdateMentee";
//@ts-ignore
import { useAlert } from "@blaumaus/react-alert";
import { ThreeDots } from "react-loader-spinner";
import useMyProfile from "hooks/useMyProfile";
import { createImageOptions } from "utils/upload.options";
// eslint-disable-next-line no-undef
const FS = filestack.init(String(process.env.REACT_APP_FILESTACK_API));

interface ProfileFields {
  headline: string;
  social: {
    linkedin?: string;
    twitter?: string;
    instagram?: string;
  };
}

const ProfileSchema = Yup.object().shape({
  headline: Yup.string().required("Title is required"),
  social: Yup.object().shape({
    linkedin: Yup.string().url("Must be a valid URL").nullable(),
    twitter: Yup.string().url("Must be a valid URL").nullable(),
    instagram: Yup.string().url("Must be a valid URL").nullable(),
  }),
});

interface ProfileSettingsProps {
  id: string;
}

const MenteeProfileForm = ({ id }: ProfileSettingsProps) => {
  const { profile } = useMyProfile();
  const alert = useAlert();

  const { update, is_loading } = useUpdateMentee({
    onError: (error) => alert.error(error),
  });

  const initialValues: ProfileFields = {
    headline: profile.headline,
    social: {
      linkedin: profile.social?.linkedin || "",
      twitter: profile.social?.twitter || "",
      instagram: profile.social?.instagram || "",
    },
  };

  const onSubmit = async (values: ProfileFields) => {
    // process values via schmea validation
    if (!profile) return null;
    console.log("Form values:", values);
    await update(values);
  };

  const uploadImage = () => {
    const image_options = createImageOptions((image_data) => {
      update({
        profileImage: image_data,
      });
    });
    FS.picker(image_options).open();
  };

  const removeProfileImage = () => {
    update({
      profileImage: {
        url: null,
      },
    });
  };

  return (
    <div className="section" id={id}>
      <div className="section-content">
        <div className="section-header">
          <h3>Profile</h3>
        </div>
        <div className="profile-section-media">
          <div className="profile-photo">
            <div className="media-header">
              <p className="section-label">Photo</p>
              <button
                className="input-button"
                onClick={
                  profile?.profileImage?.url
                    ? () => removeProfileImage()
                    : uploadImage
                }
              >
                {profile?.profileImage?.url ? "Remove Photo" : "Upload"}
              </button>
            </div>
            <div className="media-content">
              <img
                className="account-avatar-img"
                alt="account-avatar-img"
                src={profile?.profileImage?.url || AvatarPlaceholder}
              />
            </div>
          </div>
        </div>
        <hr className="border-black" />
        <Formik
          initialValues={initialValues}
          validationSchema={ProfileSchema}
          onSubmit={onSubmit}
        >
          {({ values, errors, handleChange, handleBlur }) => (
            <Form className={styles.profile_form}>
              <TextInput
                name="headline"
                label="Title"
                type="text"
                value={values.headline}
                error={errors["headline"]}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextInput
                name="social.linkedin"
                label="LinkedIn"
                type="text"
                value={values.social?.linkedin || ""}
                error={errors.social?.linkedin}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextInput
                name="social.twitter"
                label="Twitter"
                type="text"
                value={values.social.twitter || ""}
                error={errors.social?.twitter}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <TextInput
                name="social.instagram"
                label="Instagram"
                type="text"
                value={values.social.instagram || ""}
                error={errors.social?.instagram}
                handleChange={handleChange}
                handleBlur={handleBlur}
                dark={true}
              />
              <button type="submit">
                {is_loading ? (
                  <ThreeDots color="#FFF" height={18} width={24} />
                ) : (
                  "Save"
                )}
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default MenteeProfileForm;
