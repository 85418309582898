const initialState = {
	isLoading: false,
	notifications: [],
	headerMessages: [],
};

const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case "ADD_HEADER_MESSAGE":
			return {
				...state,
				headerMessages: [action.payload, ...state.headerMessages],
			};
		case "SUCCESS_FETCH_HEADER_MESSAGES":
			return {
				...state,
				headerMessages: [...state.headerMessages, ...action.payload.messages],
			};
		case "SUCCESS_ACKNOWLEGED_HEADER_MESSAGE":
			return {
				...state,
				headerMessages: state.headerMessages.filter(
					(m) => m._id !== action.payload._id
				),
			};
		default:
			return state;
	}
};

export default notificationReducer;
