import React, { useState } from "react";
import AddContentComponent from "./AddContent";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import useMyProfile from "hooks/useMyProfile";
import { Content } from "entities/Mentor";
import isMentorProfile from "utils/isMentorProfile";


const AddContent = ({ closeModal }: { closeModal: () => void }) => {

    const { profile } = useMyProfile();
    const { update } = useUpdateMentor();

    const [newContent, setNewContent] = useState<Content>({
        title: "",
        description: "",
        url: "",
    });

    const saveContent = async () => {
        let content = [newContent];

        if(!isMentorProfile(profile)) {
            throw Error("Only mentors can add content to their profiles.");
        }

        if (profile?.content) {
            content = content.concat(profile.content);
        }
        update({ content });
        closeModal();
    };

    return <AddContentComponent
        saveContent={saveContent}
        closeModal={closeModal}
        setNewContent={setNewContent}
        newContent={newContent}
    />
}

export default AddContent;