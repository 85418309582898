import React, { useState } from "react";
import CreateMentorCollectionComponent from "./CreateMentorCollection";
import styles from "./CreateMentorCollection.module.css";
import { MentorCollection } from "entities/MentorCollection";
import useCreateCollection from "hooks/admin/useCreateCollection";
import { useAlert } from "@blaumaus/react-alert";

interface CreateMentorCollectionProps {
  onSuccess: () => void;
}

const CreateMentorCollection = ({ onSuccess }: CreateMentorCollectionProps) => {
  const alert = useAlert();

  const { create, is_loading } = useCreateCollection({
    onSuccess: () => {
      alert.success("Collection created!");
      onSuccess();
    },
    onError: (error) => alert.error(error),
  });

  return (
    <CreateMentorCollectionComponent
      id={"CreateMentorCollection"}
      createCollection={create}
      is_loading={is_loading}
    />
  );
};

export default CreateMentorCollection;
