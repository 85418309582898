import Button from "components/Atoms/Button";

import styles from "./CTAFooter.module.css";

interface CTAFooterProps {
  callToAction: () => void;
}

const CTAFooter: React.FC<CTAFooterProps> = ({ callToAction }) => {
  return (
    <div data-testid="CTAFooter" className={styles.container}>
      <div>
        <div className={styles.info}>
          <h2>Reach your greatest potential.</h2>
          <p>Get access to the #1 digital mentorship experience.</p>
          <Button onClick={callToAction}>Get my MentorPass</Button>
        </div>
      </div>
    </div>
  );
};

export default CTAFooter;
