import { createRoot } from "react-dom/client";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { Provider } from "react-redux";
import "./styles/index.css";
import App from "./App";
import { store } from "./store";
import ErrorBoundary from "./hoc/errorBoundary";
import { BrowserRouter } from "react-router-dom";
import { PostHogProvider } from "posthog-js/react";

const IS_DEV = process.env.REACT_APP_ENVIRONMENT !== "PRODUCTION";

const posthog_options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_API_HOST, // Routed via proxy
  ui_host: process.env.REACT_APP_PUBLIC_POSTHOG_UI_HOST,
  autocapture: false,
  disable_session_recording: IS_DEV,
  opt_out_capturing_by_default: IS_DEV,
};

if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
  LogRocket.init("oabdtg/mentorpass");
  setupLogRocketReact(LogRocket);
}

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <div className="App">
    <PostHogProvider
      apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
      options={posthog_options}
    >
      <ErrorBoundary>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </ErrorBoundary>
    </PostHogProvider>
  </div>
);

export {};
