import { createHeaders, API_URL } from "../../../utils/api";
import { BookingAction } from "./booking.reducer";
import { Dispatch } from "redux";
import { SessionRequestDto } from "./booking.dto";

export const getMentorBookingAvailabilty = (
  token: string,
  profile_slug: string,
  duration: number
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: BookingAction.GET_MENTOR_AVALIBILITY_START });
    return fetch(
      `${API_URL}/session/get-availability?mentor_slug=${profile_slug}&duration=${duration}`,
      {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      }
    )
      .then((response) => {
        if (response.status !== 200) {
          return response.json().then(({ error }) => {
            throw Error(error);
          });
        }
        return response.json();
      })
      .then((results) => {
        dispatch({
          type: BookingAction.GET_MENTOR_AVALIBILITY_SUCCESS,
          payload: results,
        });
      })
      .catch((error) => {
        console.error(error);
        dispatch({
          type: BookingAction.GET_MENTOR_AVALIBILITY_ERROR,
          payload: error,
        });
      });
  };
};

export const requestSession = (
  token: string,
  booking: SessionRequestDto,
  goTo: () => void
) => {
  return (dispatch: Dispatch) => {
    dispatch({ type: BookingAction.REQUEST_SESSION_START });
    const id = `${booking.to}-${booking.from}-${Date.now()}`;
    return fetch(`${API_URL}/session/${id}`, {
      method: "POST",
      mode: "cors",
      headers: createHeaders(token),
      body: JSON.stringify({ booking }),
    })
      .then((response) => {
        if (response.status === 500) {
          return response.json().then(({ error }) => {
            throw Error(error);
          });
        }
        goTo();
      })
      .catch((error) => {
        dispatch({
          type: "SET_ALERT",
          payload: {
            type: "error",
            message: "Something went wrong! We couldn't request the session",
          },
        });
      });
  };
};
