import { Attachment } from "entities/OKR";
import { PickerFileMetadata, PickerOptions } from "filestack-js";



export const createImageOptions = (updateImageData: (image: PickerFileMetadata) => void): PickerOptions => ({
    fromSources: [
        "local_file_system",
        "instagram",
        "facebook",
        "googledrive",
        "webcam",
    ],
    accept: ["image/*"],
    maxFiles: 1,
    maxSize: 10 * 1024 * 1024, // 10mb
    onFileUploadFinished: (result: PickerFileMetadata) => {
        if (result) {
            updateImageData(result);
        } else {
            throw Error("Failed to upload image");
        }
    },
    transformations: {
        circle: false,
        crop: {
            force: true,
            aspectRatio: 1,
        },
    },
});

export const createVideoOptions = (updateVideoData: (video: PickerFileMetadata) => void): PickerOptions => ({
    fromSources: [
        "local_file_system",
        "instagram",
        "facebook",
        "googledrive",
        "onedrive",
    ],
    accept: ["video/*"],
    onFileUploadFinished: (result: PickerFileMetadata) => {
        if (result) {
            updateVideoData(result);
        } else {
            throw Error("Failed to upload video");
        }
    },
});

export const createAttachmentOptions = (onSuccess: (attachment: PickerFileMetadata) => void, onError: (message: string) => void): PickerOptions => ({
    fromSources: [
        "local_file_system",
        "instagram",
        "facebook",
        "googledrive",
        "onedrive",
    ],
    accept: ["image/*",
        "video/*",
        "audio/*",
        "text/*"],
    onFileUploadFinished: (result: PickerFileMetadata) => {
        if (result) {
            console.log("result", result);
            onSuccess(result);
        } else {
            throw Error("Failed to upload attachment");
        }
    },
    onFileUploadFailed: (file, error) => {
        console.error(error)
        onError("Failed to upload attachment!");
    }
});