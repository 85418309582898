import HeaderWrapper from "components/Organisms/Header";

const Header = ({
  profileImage,
  username,
}: {
  profileImage: string;
  username: string;
}) => (
  <HeaderWrapper
    children={
      <>
        <div
          className="profile-img"
          style={{ marginRight: "24px", marginTop: "8px" }}
        >
          <img src={profileImage} alt="profile-img" width="48px" />
        </div>
        <h3 className="mobile-only" style={{ marginTop: "15px" }}>
          My Account
        </h3>
        <div className="account-header-content">
          <p
            style={{ marginTop: "12px" }}
            className="account-title"
          >{`${username}'s Account`}</p>
          {/* <p className="header-date">Joined July 14, 2020</p> */}
        </div>
      </>
    }
  />
);

export default Header;
