import React, { useState } from "react";
import style from "../../SessionReview.module.css";
import Chip from "components/Atoms/Chip";

const RATING_BLOCK = [
  { id: 1, text: 1, isSelected: false },
  { id: 2, text: 2, isSelected: false },
  { id: 3, text: 3, isSelected: false },
  { id: 4, text: 4, isSelected: false },
  { id: 5, text: 5, isSelected: false },
  { id: 6, text: 6, isSelected: false },
  { id: 7, text: 7, isSelected: false },
  { id: 8, text: 8, isSelected: false },
  { id: 9, text: 9, isSelected: false },
  { id: 10, text: 10, isSelected: false },
];

interface SecondStepProps {
  id: string;
  data: {
    to: {
      given_name: string;
    };
  };
  updateNPS: (value: number) => void;
}

const SecondStep = ({ id, data, updateNPS }: SecondStepProps) => {
  const [rating, setRating] = useState(RATING_BLOCK);

  const onSelect = (id: number) => {
    const updated_rating = RATING_BLOCK.map((rating) => {
      if (rating.id === id) {
        return { id, text: id, isSelected: !rating.isSelected };
      } else return rating;
    });
    setRating(updated_rating);
    updateNPS(RATING_BLOCK[id - 1].text);
  };

  return (
    <div data-testid={id} className={style.secondStep}>
      <p className={style.label}>Question 1/4</p>
      <p className={style.question}>
        How likely are you to recommend {data.to.given_name} to another founder?{" "}
      </p>
      <div className={style.numBlocks}>
        {rating.map((num) => (
          <Chip.Square
            key={`testId-${num.id}`}
            content={num}
            onSelect={onSelect}
            size={"60px"}
          />
        ))}
      </div>
      <div className={style.ratingLabel}>
        <p>Not Likely</p>
        <p>Somewhat Likely</p>
        <p>Very Likely</p>
      </div>
    </div>
  );
};

export default SecondStep;
