import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createHeaders, API_URL } from "../../../../utils/api";
import DeclineSessionComponent from "./DeclineSession.component";
import useSession from "hooks/useSession";
//@ts-ignore
import { useAlert } from "@blaumaus/react-alert";
import { useAuth0 } from "@auth0/auth0-react";
import processAuthClaims from "utils/processAuthClaims";

const DeclineSession = () => {
  const { session_id } = useParams();
  const [reason, setReason] = useState<string>("");
  const [decline_pending, setIsDeclining] = useState(false);
  const alert = useAlert();
  const navigate = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const { user } = useAuth0();

  const { timezone } = useMemo(() => processAuthClaims(user), [user]);

  if (!timezone) {
    throw Error("Users timezone is not set");
  }

  if (!session_id) {
    throw Error("Session id is not set");
  }

  const {
    session,
    is_loading,
    error: error_fetching_session,
  } = useSession({ id: session_id });

  useEffect(() => {
    alert.error("Something went wrong! Please contact support.");
  }, [error_fetching_session]);

  const declineSession = async () => {
    setIsDeclining(true);
    try {
      const token = await getAccessTokenSilently();
      const response = await fetch(`${API_URL}/session/decline/${session_id}`, {
        method: "POST",
        mode: "cors",
        headers: createHeaders(token),
        body: JSON.stringify({
          reason,
        }),
      });
      if (response.ok) {
        alert.success("Declined Session");
        navigate("/dashboard");
      } else {
        alert.error("Something went wrong! Please contact support.");
      }
    } catch (error) {
      console.error(error);
      alert.error("Something went wrong! Please contact support.");
    } finally {
      setIsDeclining(false);
    }
  };

  return (
    <DeclineSessionComponent
      profile={session && session.from}
      session={session}
      declineSession={declineSession}
      reason={reason}
      is_loading={is_loading}
      decline_pending={decline_pending}
      timezone={timezone}
      onUpdateReason={(value: string) => setReason(value)}
    />
  );
};

export default DeclineSession;
