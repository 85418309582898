import { ProfileQueryResult } from "entities/Profile";

import style from "../Profile.module.css";

type IDType =
  | "sunday"
  | "monday"
  | "tuesday"
  | "wednesday"
  | "thursday"
  | "friday"
  | "saturday";

const days: Array<{ title: string; id: IDType }> = [
  { title: "Sunday", id: "sunday" },
  { title: "Monday", id: "monday" },
  { title: "Tuesday", id: "tuesday" },
  { title: "Wednesday", id: "wednesday" },
  { title: "Thursday", id: "thursday" },
  { title: "Friday", id: "friday" },
  { title: "Saturday", id: "saturday" },
];

interface IAvailabilityTabsProps {
  availability: ProfileQueryResult["availability"];
}

const AvailabilityTabs: React.FC<IAvailabilityTabsProps> = ({
  availability,
}) => {
  return (
    <>
      <h3>Availability</h3>
      <div className={style.availabilityTabs}>
        {days.map((d, i) => {
          return (
            <div
              key={`testId-${i}`}
              className={`${style.chip} ${
                (availability[d.id] as any).length > 0
                  ? style.hasAvailabilityHours
                  : null
              }`}
            >
              <h4>{d.title}</h4>
            </div>
          );
        })}
      </div>
      <p className={style.notice}>
        *Availability on these days may change based on appointments booked by
        other mentees.
      </p>
    </>
  );
};

export default AvailabilityTabs;
