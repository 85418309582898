import { useEffect } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import MentorCollections from "./MentorCollections/MentorCollections.screen";

const AdminRoutes = () => {
  return (
    <div>
      <Routes>
        <Route path="/mentor_collections" element={<MentorCollections/>} />
      </Routes>
    </div>
  );
};

export default AdminRoutes;