import { addDays, format } from "date-fns";

export const createDueDateTitle = (days: number, future_date: Date) =>
    `${days} days - ${format(future_date, "EEEE do, MMMM")}`;

export const generateDueDates = () => {
    const today = new Date();

    const durations = [15, 30, 45, 60, 90, 180];
    const result = durations.map((days) => {
        const future_date = addDays(today, days);
        const title = createDueDateTitle(days, future_date);
        const value = format(future_date, "yyyy-MM-dd'T'HH:mm:ss'Z'"); // ISO 8601 datetime format
        return { title, value };
    });

    return result;
}
