import { User } from "@auth0/auth0-react";
import { ProfileQueryResult } from "../../entities/Profile";
import { MentorQueryResult } from "entities/Mentor";
import { MenteeQueryResult } from "entities/Mentee";

interface Business {
  name: string;
  admin: string;
}

export interface ProfileContext {
  user?: User;
  mentor?: MentorQueryResult;
  mentee?: MenteeQueryResult;
  admin?: ProfileQueryResult;
  signup_options: {
    team_invite_code?: string;
    mentor_invite_code?: string;
    pending_session_id?: string;
  };
  redirect_to: string;
  user_settings: {
    has_community_access: boolean;
  }
}

export enum ProfileEventType {
  SWITCH_TO_MENTEE = "SWITCH_TO_MENTEE",
  SWITCH_TO_MENTOR = "SWITCH_TO_MENTOR",
  SWITCH_TO_ADMIN = "SWITCH_TO_ADMIN",
}

// EVENTS
export type UPDATE_MENTEE_PROFILE = {
  type: "UPDATE_MENTEE_PROFILE";
  data: {
    profile: MenteeQueryResult;
  };
};

export type UPDATE_MENTOR_PROFILE = {
  type: "UPDATE_MENTOR_PROFILE";
  data: {
    profile: MentorQueryResult;
  };
};

export type GOT_MENTEE_PROFILE_EVENT = {
  type: "GOT_MENTEE_PROFILE";
  data: {
    business: Business;
    profile: MenteeQueryResult;
  };
};

export type GOT_MENTOR_PROFILE_EVENT = {
  type: "GOT_MENTOR_PROFILE";
  data: {
    profile: MentorQueryResult;
  };
};

export type SET_DETAILS_EVENT = {
  type: "SET_DETAILS";
  data: {
    given_name: string;
    family_name: string;
  };
};

export type GOT_USER_EVENT = {
  type: "GOT_USER";
  data: User;
};

export type SET_TEAM_INVITE_CODE_EVENT = {
  type: "SET_TEAM_INVITE_CODE";
  data: string;
};

export type SET_PROFILE_PREFERENCE_MENTEE_EVENT = {
  type: "SET_PROFILE_PREFERENCE_MENTEE";
};

export type SET_PROFILE_PREFERENCE_MENTOR_EVENT = {
  type: "SET_PROFILE_PREFERENCE_MENTOR";
};

export type SWITCH_TO_MENTEE_EVENT = {
  type: ProfileEventType.SWITCH_TO_MENTEE;
};

export type SWITCH_TO_MENTOR_EVENT = {
  type: ProfileEventType.SWITCH_TO_MENTOR;
};

export type SWITCH_TO_ADMIN_EVENT = {
  type: ProfileEventType.SWITCH_TO_ADMIN;
};

export type GET_PROFILE_EVENT = {
  type: "GET_PROFILE";
};

export type OWNER_READY = {
  type: "OWNER_READY";
  data: {
    profile: MenteeQueryResult;
  };
};

export type TEAM_MEMBER_READY = {
  type: "TEAM_MEMBER_READY";
  data: {
    profile: MenteeQueryResult;
  };
};

export type TEAM_MEMBER_DETAILS_REQUIRED = {
  type: "TEAM_MEMBER_DETAILS_REQUIRED";
};

export type TEAM_MEMBER_INVITED = {
  type: "TEAM_MEMBER_INVITED";
};

export type OWNER_DETAILS_REQUIRED = {
  type: "OWNER_DETAILS_REQUIRED";
};

export type CREATE_OWNER = {
  type: "CREATE_OWNER";
};

export type CLAIM_PENDING_SESSION = {
  type: "CLAIM_PENDING_SESSION";
  data: {
    
  };
}

export type SET_REDIRECT_TO = {
  type: "SET_REDIRECT_TO",
  data: {
    redirect_to: string;
  }
}

export type ProfileEvent =
  | GOT_MENTEE_PROFILE_EVENT
  | UPDATE_MENTEE_PROFILE
  | GOT_MENTOR_PROFILE_EVENT
  | UPDATE_MENTOR_PROFILE
  | SET_DETAILS_EVENT
  | GOT_USER_EVENT
  | SET_TEAM_INVITE_CODE_EVENT
  | SET_PROFILE_PREFERENCE_MENTEE_EVENT
  | SET_PROFILE_PREFERENCE_MENTOR_EVENT
  | SWITCH_TO_MENTEE_EVENT
  | SWITCH_TO_MENTOR_EVENT
  | SWITCH_TO_ADMIN_EVENT
  | GET_PROFILE_EVENT
  | OWNER_READY
  | TEAM_MEMBER_READY
  | TEAM_MEMBER_DETAILS_REQUIRED
  | TEAM_MEMBER_INVITED
  | OWNER_DETAILS_REQUIRED
  | CREATE_OWNER
  | CLAIM_PENDING_SESSION 
  | SET_REDIRECT_TO;
