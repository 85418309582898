import { caseCreatorHOC } from "../helpers";
const typeMaker = caseCreatorHOC(ENTITY);

import {
	ENTITY,
	SEARCH_PROFILES,
	CLICK_PROFILE,
	FOLLOW_MENTOR,
	CLICK_BOOK_SESSION,
} from "./ui.types";

export const searchForMentor = () => ({
	type: typeMaker(SEARCH_PROFILES),
	payload: {},
});

export const clickMentorProfile = () => ({
	type: typeMaker(CLICK_PROFILE),
	payload: {},
});

export const clickBookSession = ({ mentor_profile }) => ({
	type: typeMaker(CLICK_BOOK_SESSION),
	payload: {
		mentor_profile,
	},
});
