import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import ReactPixel from "react-facebook-pixel";
import { useLocation } from "react-router-dom";

/**
 * This Wrapper is exculsiely for the Facebook Pixel
 * CAUTION: This is only to be used for the page view event. 
 * All other events should be dispatched via PostHog and will be sent to Facebook Pixel via RudderStack and the  
 */

const FACEBOOK_PIXEL = process.env.REACT_APP_FACEBOOK_PIXEL_ID || "";

const AnalyticsWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { user } = useAuth0();

  const FB_PIXLE_CONFIG = {
    autoConfig: true,
    debug: false,
  };

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
      if (user) {
        const email = user?.email;
        ReactPixel.init(
          FACEBOOK_PIXEL,
          {
            em: email as string,
            fn: user?.given_name || "",
            ln: user?.family_name || "",
            ct: "",
            country: "",
            db: "",
            ge: "",
            ph: "",
            st: "",
            zp: "",
          },
          FB_PIXLE_CONFIG
        );
      } else {
        ReactPixel.init(FACEBOOK_PIXEL, undefined, FB_PIXLE_CONFIG);
      }
    }
  }, [user]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
      ReactPixel.pageView();
    }
  }, [location]);

  return <>{children}</>;
};

export default AnalyticsWrapper;
