import React from "react";
import styles from "../TeamManagment.module.css";
import { ThreeDots } from "react-loader-spinner";
import { TeamMember } from "../TeamManagment.schema";
import useRemoveTeamMember from "hooks/team/useRemoveTeamMember";

interface ConfirmRemoveTeamMemberProps {
  team_member: TeamMember;
  onRemoved: () => void;
  closeModal: () => void;
}

const RemoveTeamMember = ({
  team_member,
  onRemoved,
  closeModal,
}: ConfirmRemoveTeamMemberProps) => {
  const { remove, is_loading, error } = useRemoveTeamMember({ onRemoved });
  const { avatar_image, name, profile_id } = team_member;

  const removeTeamMember = () => remove(profile_id);

  return (
    <div className={styles.confirmRemoveContainer}>
      <div className={styles.confirmRemoveContent}>
        <img src={avatar_image} />
        <h3>Remove {name}?</h3>
        <p>This cannot be undone.</p>
        <button
          style={{ paddingTop: is_loading ? "4px" : "12px" }}
          disabled={is_loading}
          onClick={removeTeamMember}
        >
          {is_loading ? (
            <ThreeDots wrapperStyle={{ margin: "auto", display: "block" }} color="#fff" height={32} width={32} />
          ) : (
            "Yes, remove user "
          )}
        </button>
        <p onClick={closeModal}>Cancel</p>
      </div>
    </div>
  );
};

export default RemoveTeamMember;
