import { useState, useEffect, useContext } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import { useAlert } from "@blaumaus/react-alert";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const useFavourites = () => {
	const alert = useAlert();
	const { getAccessTokenSilently } = useAuth0();
	const queryClient = useQueryClient();

	const fetchFavourites = async () => {
		const token = await getAccessTokenSilently();
		const response = await fetch(`${API_URL}/profile/favourites`, {
			method: "GET",
			mode: "cors",
			headers: createHeaders(token),
		});
		if (!response.ok) {
			throw Error("Failed to fetch mentors you follow");
		}
		const { favourites } = await response.json();
		return favourites;
	};

	const toggleFavourite = async (mentor_profile_id: string) => {
		const token = await getAccessTokenSilently();
		const response = await fetch(`${API_URL}/profile/toggle-favourite-mentor/${mentor_profile_id}`, {
			method: "POST",
			mode: "cors",
			headers: createHeaders(token),
		});
		if (!response.ok) {
			throw Error("Failed to follow Mentor");
		}
	};

	const {
		data: favourites,
		isFetched,
		refetch,
		isLoading,
	} = useQuery({
		queryKey: ["favourites"],
		queryFn: fetchFavourites,
		staleTime: 1000 * 60 * 5, // 5 minutes
		placeholderData: [],
	});

	const toggleMutation = useMutation(toggleFavourite, {
		onMutate: async (mentor_profile_id) => {
			// cancel outgoing refetch
			await queryClient.cancelQueries(["favourites"]);
			const previous_favourites = queryClient.getQueryData(["favourites"]) as string[];

			// Toggele the favourite
			let new_favorites = previous_favourites;
			if (previous_favourites.includes(mentor_profile_id)) {
				new_favorites = new_favorites.filter((id) => id !== mentor_profile_id);
			} else {
				new_favorites.push(mentor_profile_id);
			}

			queryClient.setQueryData(["favourites"], () => new_favorites);
			return { previous_favourites, mentor_profile_id };
		},
		onError: async (error, variables, context) => {
			alert.error("Failed to favourite mentor");
			queryClient.setQueryData(["favourites"], context?.previous_favourites || []);
			console.error(error);
		},
		onSettled: async () => {
			queryClient.invalidateQueries(["favourites"]);
		},
	});

	return { favourites, toggle: toggleMutation.mutate, refetch, is_loading: isLoading, ready: isFetched };
};

export default useFavourites;
