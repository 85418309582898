import { API_URL, createHeaders } from "utils/api";
import { ProfileQueryResult } from "entities/Profile";
import { MenteeQueryResult } from "entities/Mentee";
import { ProfileContext } from "./Profile.schema";

export interface ProfileCreationData {
  given_name?: string;
  family_name?: string;
  invite_code?: string;
  pending_session_id?: string;
}

export type MenteeProfileCreationData = ProfileCreationData & {
  pending_session_id?: string;
};

export type InvitedMenteeProfileCreationData = ProfileCreationData & {
  invite_code: string;
};

export const requestCreateMentor = async (
  token: string,
  profile_data: ProfileCreationData
): Promise<void> => {
  const response = await fetch(`${API_URL}/webhooks/auth/mentor_signup`, {
    method: "POST",
    headers: createHeaders(token),
    body: JSON.stringify(profile_data),
  });
  if (!response.ok) {
    const data = await response.json();
    throw Error(data.message);
  }
};

export const requestCreateMentee = async (
  token: string,
  profile_data: MenteeProfileCreationData
): Promise<void> => {
  const response = await fetch(`${API_URL}/webhooks/auth/mentee_signup`, {
    method: "POST",
    headers: createHeaders(token),
    body: JSON.stringify(profile_data),
  });
  if (!response.ok) {
    const data = await response.json();
    throw Error(data.message);
  }
};

export const requestJoinTeam = async (
  token: string,
  profile_data: InvitedMenteeProfileCreationData
): Promise<void> => {
  if (!profile_data.invite_code) throw Error("No invite code provided");
  const response = await fetch(`${API_URL}/webhooks/auth/mentee_invited`, {
    method: "POST",
    headers: createHeaders(token),
    body: JSON.stringify(profile_data),
  });
  if (!response.ok) {
    const data = await response.json();
    throw Error(data.message);
  }
};

export const getProfiles = async (
  token: string
): Promise<ProfileQueryResult[]> => {
  const response = await fetch(`${API_URL}/user/me`, {
    method: "GET",
    mode: "cors", // no-cors, *cors, same-origin
    // credentials: 'same-origin',
    headers: createHeaders(token),
  });
  const json = await response.json();
  const { profiles } = json;
  return profiles;
};

export const getMentor = async (
  token: string
): Promise<{ profile: ProfileQueryResult | null }> => {
  const response = await fetch(`${API_URL}/mentor`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  });
  // Mentor profile dosen't exist
  if (response.status === 404) {
    return { profile: null };
  }
  if (response.status == 500) {
    throw Error("Server error");
  }
  const { profile } = await response.json();
  return { profile };
};

export const getMentee = async (
  token: string
): Promise<{ profile: MenteeQueryResult | null }> => {
  const response = await fetch(`${API_URL}/profile/mentee`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  });
  const { profile } = await response.json();
  return { profile };
};

export const getAdmin = async (
  token: string
): Promise<{ profile: ProfileQueryResult | null }> => {
  const response = await fetch(`${API_URL}/profile/admin`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  });
  const { profile } = await response.json();
  return { profile };
};

export const claimPendingSession = async (
  token: string,
  profile: ProfileContext
): Promise<void> => {
  const response = await fetch(
    `${API_URL}/one_page_checkout/claim_pending_session`,
    {
      method: "POST",
      headers: createHeaders(token),
      body: JSON.stringify({
        pending_session_id: profile.signup_options.pending_session_id,
      }),
    }
  );
  if (!response.ok) {
    const data = await response.json();
    throw Error(data.message);
  }
};
