import { useState } from "react";
import PropTypes from "prop-types";
import TextArea from "components/Molecules/Inputs/TextArea/TextArea.component";
import style from "../MissedSession.module.css";

const Step1 = ({ updateSurvey, nextStep, skipFeedback, isThirdQuestion }) => {
  const [feedback, setFeedback] = useState("");
  const onChange = (e) => {
    setFeedback(e.target.value);
    updateSurvey("feedback", e.target.value);
  };

  return (
    <div className={style.step}>
      <p className={style.label}>Question {isThirdQuestion ? " 3" : " 2"}</p>
      <p className={style.title}>Why didn’t your session happen?</p>
      <p className={style.description}>
        {isThirdQuestion
          ? "Let us know why you think that this error happened so we can prevent it from happeneing in the future."
          : "Help us improve future sessions by giving us insight as to why this session didn’t happen."}
      </p>
      <div className={style.content}>
        <TextArea
          id="feedback"
          label="FEEDBACK"
          placeholder="Type your answer here..."
          onChange={onChange}
          rows="8"
          cols="33"
          value={feedback}
          dark
        />
      </div>
      <button
        onClick={async () => {
          await nextStep();
        }}
      >
        Continue
      </button>
      <button
        onClick={async () => {
          await skipFeedback();
        }}
        className={style.skipBtn}
      >
        Skip
      </button>
    </div>
  );
};

Step1.propTypes = {
  updateSurvey: PropTypes.func,
  nextStep: PropTypes.func,
  skipFeedback: PropTypes.func,
  isThirdQuestion: PropTypes.bool,
};

export default Step1;
