import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import {
  SessionReviewContext,
  SessionReviewEvent,
} from "./SessionReview.machine";
import { API_URL, createHeaders } from "utils/api";
import { useNavigate, useParams } from "react-router-dom";
import { SessionQueryResultWithProfiles } from "entities/Session";
import SessionReviewComponent from "./SessionReview.component";
import { useAlert } from "@blaumaus/react-alert";
import { usePostHog } from "posthog-js/react";

const SessionReview = () => {
  const { getAccessTokenSilently } = useAuth0();
  const { session_id } = useParams();
  const navigate = useNavigate();
  const alert = useAlert();
  const posthog = usePostHog();

  const fetchSession = async (
    context: SessionReviewContext,
    event: SessionReviewEvent
  ): Promise<SessionQueryResultWithProfiles> => {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/session/${session_id}`, {
      method: "GET",
      mode: "cors",
      headers: createHeaders(token),
    });
    if (response.status !== 200) {
      alert.error("Failed to fetch session! Please contact support.");
      throw new Error("Failed to fetch session");
    }
    const data = await response.json();
    return data;
  };

  const createReview = async (
    context: SessionReviewContext,
    event: SessionReviewEvent
  ): Promise<void> => {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/session/review/${session_id}`, {
      method: "POST",
      mode: "cors",
      headers: createHeaders(token),
      body: JSON.stringify({ review: context.review }),
    });
    if (response.status !== 200) {
      alert.error("Failed to create review! Please contact support.");
      throw new Error("Failed to create review");
    }
  };

  const bookAnotherSession = (
    context: SessionReviewContext,
    event: SessionReviewEvent
  ): void => {
    const slug = context.session?.to.slug;
    posthog.capture("clicked_book_again_post_review", {
      slug: slug,
    });
    navigate(`/profile/${slug}/book-session`);
  };

  return (
    <SessionReviewComponent
      fetchSession={fetchSession}
      createReview={createReview}
      bookAnotherSession={bookAnotherSession}
    />
  );
};

export default SessionReview;
