import { useState, useEffect } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import z from "zod";

export const AddTeamMemberPayloadSchema = z.object({
    given_name: z.string(),
    family_name: z.string(),
    email: z.string(),
})

export type AddTeamMemberPayload = z.infer<typeof AddTeamMemberPayloadSchema>;

const useAddTeamMember = ({ onSuccess }: { onSuccess: () => void }) => {
    const [error, setError] = useState<string | undefined>();
    const [is_loading, setIsLoading] = useState<boolean>(false);
    const { getAccessTokenSilently } = useAuth0();

    const add = async (values: AddTeamMemberPayload) => {
        try {
            AddTeamMemberPayloadSchema.parse(values);
            setIsLoading(true);
            const token = await getAccessTokenSilently();
            const response = await fetch(`${API_URL}/team/invite/`, {
                method: "POST",
                mode: "cors", // no-cors, *cors, same-origin
                // credentials: 'same-origin',
                headers: createHeaders(token),
                body: JSON.stringify({
                    given_name: values.given_name,
                    family_name: values.family_name,
                    email: values.email.toLowerCase(),
                }),
            });
            if (!response.ok) {
                throw Error("Failed to add team member.");
            }
            onSuccess();
        } catch (error) {
            setError(String(error));
        } finally {
            setIsLoading(false);
        }
    };
    return { add, is_loading, error };
};

export default useAddTeamMember;