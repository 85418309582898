import { createMachine, assign, raise, Sender } from "xstate";
import {
  CreditPackContext,
  CreditPackStateSchema,
  CreditPackEvent,
  Offer,
  UpdateCustomQuantityEvent,
  OFFER_TITLE,
  OFFER_TRANSITION_EVENT,
} from "./schema";
import { calculateCustomRate } from "./helpers";

export const DEFAULT_OFFERS: Offer[] = [
  {
    _id: 0,
    quantity_of_credits: 30,
    cost_per_credit: 1200,
  },
  {
    _id: 1,
    quantity_of_credits: 60,
    cost_per_credit: 1200,
  },
  {
    _id: 2,
    quantity_of_credits: 90,
    cost_per_credit: 1200,
  },
];

const INITAL_VALUES: CreditPackContext = {
  offers: DEFAULT_OFFERS,
  rate: 1200,
  default_rate: DEFAULT_OFFERS[1].cost_per_credit,
  credit_quantity: DEFAULT_OFFERS[1].quantity_of_credits,
  custom_quantity: 0,
  payment_method: undefined,
  days_until_sub_renews: undefined,
};

const OFFER_INDEX_MAP = {
  [OFFER_TITLE.OFFER_1]: 0,
  [OFFER_TITLE.OFFER_2]: 1,
  [OFFER_TITLE.OFFER_3]: 2,
};

const changeOffer = (offer_target: OFFER_TITLE, context: CreditPackContext) => {
  if (offer_target in OFFER_INDEX_MAP) {
    const offer_index =
      OFFER_INDEX_MAP[offer_target as keyof typeof OFFER_INDEX_MAP];
    const offer = context.offers[offer_index];
    return {
      rate: offer.cost_per_credit,
      credit_quantity: offer.quantity_of_credits,
    };
  } else {
    const custom_rate = calculateCustomRate(
      context.custom_quantity,
      context.offers,
      context.default_rate
    );
    return {
      rate: custom_rate,
      credit_quantity: context.custom_quantity,
    };
  }
};

const goToOfferOne = {
  target: "offer_1",
  actions: assign((context: CreditPackContext) =>
    changeOffer(OFFER_TITLE.OFFER_1, context)
  ),
};

const goToOfferTwo = {
  target: "offer_2",
  actions: assign((context: CreditPackContext) =>
    changeOffer(OFFER_TITLE.OFFER_2, context)
  ),
};

const goToOfferThree = {
  target: "offer_3",
  actions: assign((context: CreditPackContext) =>
    changeOffer(OFFER_TITLE.OFFER_3, context)
  ),
};

const goToCustomOffer = {
  target: "offer_custom",
  actions: assign((context: CreditPackContext) =>
    changeOffer(OFFER_TITLE.CUSTOM_OFFER, context)
  ),
};

const credit_pack_machine = createMachine<
  CreditPackContext,
  CreditPackEvent,
  CreditPackStateSchema
>({
  id: "credit_pack",
  initial: "loading_offers",
  context: INITAL_VALUES,
  states: {
    loading_offers: {
      invoke: {
        src: "getOffers",
        onError: {
          target: "loading_offers",
          actions: "errorFetchingOffers",
        },
      },
      on: {
        GOT_OFFERS: {
          target: "offers_ready",
          actions: assign((_, event) => ({
            offers: event.offers,
            default_rate: event.default_rate,
            rate: event.offers[1].cost_per_credit, // default to offer 2
          })),
        },
        SET_PURCHASE_NOT_ALLOWED: {
          target: "purchase_not_allowed",
          actions: assign({
            offers: DEFAULT_OFFERS,
          }),
        },
      },
    },
    purchase_not_allowed: {
      type: "final",
    },
    offers_ready: {
      type: "parallel",
      on: {
        SET_PURCHASE_NOT_ALLOWED: {
          target: "purchase_not_allowed",
          actions: assign({
            offers: DEFAULT_OFFERS as Offer[],
          }),
        },
      },
      states: {
        subscription_status: {
          states: {
            loading: {
              invoke: {
                src: "getDaysUntilSubscriptionRenewal",
                onDone: {
                  target: "ready",
                  actions: assign((_, event) => ({
                    days_until_sub_renews: event.data || undefined,
                  })),
                },
              },
            },
            ready: {
              on: {
                GET_SUBSCRIPTION_STATUS: "loading",
              },
            },
          },
          initial: "loading",
        },
        payment_method: {
          states: {
            loading: {
              invoke: {
                src: "getPaymentMethod",
                onDone: {
                  target: "ready",
                  actions: assign((_, event) => ({
                    payment_method: event.data,
                  })),
                },
              },
              on: {
                GOT_PAYMENT_METHOD: "ready",
              },
            },
            ready: {
              on: {
                UPDATE_PAYMENT_METHOD: "fetching_update_link",
              },
            },
            fetching_update_link: {
              invoke: {
                src: "getStripePortalLink",
                onDone: {
                  actions: "redirectToStripePaymentUpdate",
                },
                onError: {
                  actions: "errorFetchingStripePortalLink",
                },
              },
            },
          },
          initial: "loading",
        },
        credit_offers: {
          states: {
            offer_1: {
              on: {
                SELECT_OFFER_2: goToOfferTwo,
                SELECT_OFFER_3: goToOfferThree,
                SELECT_OFFER_CUSTOM: goToCustomOffer,
                CHECKOUT: "#credit_pack.purchasing.checkout_offer_1",
              },
            },
            offer_2: {
              on: {
                SELECT_OFFER_1: goToOfferOne,
                SELECT_OFFER_3: goToOfferThree,
                SELECT_OFFER_CUSTOM: goToCustomOffer,
                CHECKOUT: "#credit_pack.purchasing.checkout_offer_2",
              },
            },

            offer_3: {
              on: {
                SELECT_OFFER_2: goToOfferTwo,
                SELECT_OFFER_1: goToOfferOne,
                SELECT_OFFER_CUSTOM: goToCustomOffer,
                CHECKOUT: "#credit_pack.purchasing.checkout_offer_3",
              },
            },
            offer_custom: {
              entry: assign((context) => {
                return {
                  credit_quantity: context.custom_quantity,
                };
              }),
              on: {
                SELECT_OFFER_1: goToOfferOne,
                SELECT_OFFER_2: goToOfferTwo,
                SELECT_OFFER_3: goToOfferThree,
                UPDATE_CUSTOM_QUANTITY: {
                  actions: assign(
                    (
                      context: CreditPackContext,
                      event: UpdateCustomQuantityEvent
                    ) => {
                      const custom_rate = calculateCustomRate(
                        event.custom_quantity,
                        context.offers,
                        context.default_rate
                      );
                      return {
                        rate: custom_rate,
                        custom_quantity: event.custom_quantity,
                        credit_quantity: event.custom_quantity,
                      };
                    }
                  ),
                },
                CHECKOUT: "#credit_pack.purchasing.checkout_offer_custom",
              },
            },
          },
          initial: "offer_2",
        },
        offer_custom_tab: {
          initial: "closed",
          states: {
            closed: {
              on: {
                TOGGLE_CUSTOM_OFFER_TAB: {
                  target: "open",
                  actions: raise(OFFER_TRANSITION_EVENT.SELECT_OFFER_CUSTOM),
                },
              },
            },
            open: {
              on: {
                TOGGLE_CUSTOM_OFFER_TAB: {
                  target: "closed",
                  actions: raise(OFFER_TRANSITION_EVENT.SELECT_OFFER_2),
                },
              },
            },
          },
        },
      },
    },
    purchasing: {
      states: {
        checkout_offer_1: {
          invoke: {
            src: "checkoutOfferOne",
            onError: {
              target: `#credit_pack.offers_ready.credit_offers.offer_1`,
            },
          },
          on: {
            CHECKOUT_SUCCESS:
              "#credit_pack.checkout_complete.checkout_success_offer_1",
          },
        },
        checkout_offer_2: {
          invoke: {
            src: "checkoutOfferTwo",
            onError: {
              target: `#credit_pack.offers_ready.credit_offers.offer_2`,
            },
          },
          on: {
            CHECKOUT_SUCCESS:
              "#credit_pack.checkout_complete.checkout_success_offer_2",
          },
        },
        checkout_offer_3: {
          invoke:{
            src: "checkoutOfferThree",
            onError: {
              target: `#credit_pack.offers_ready.credit_offers.offer_3`,
            },
          },
          on: {
            CHECKOUT_SUCCESS:
              "#credit_pack.checkout_complete.checkout_success_offer_3",
          },
        },
        checkout_offer_custom: {
          invoke: {
            src: "checkoutCustomOffer",
            onError: {
              target: `#credit_pack.offers_ready.credit_offers.offer_custom`,
            },
          },
          on: {
            CHECKOUT_SUCCESS:
              "#credit_pack.checkout_complete.checkout_success_offer_custom",
          },
        },
      },
    },
    checkout_complete: {
      entry: ["refetchBusiness"],
      states: {
        checkout_success_offer_1: {
          type: "final",
        },
        checkout_success_offer_2: {
          type: "final",
        },
        checkout_success_offer_3: {
          type: "final",
        },
        checkout_success_offer_custom: {
          type: "final",
        },
      },
      on: {
        RESET: {
          target: "loading_offers",
          actions: assign(INITAL_VALUES),
        },
      },
    },
  },
});

export default credit_pack_machine;
