import React, { useEffect, useState } from "react";
import ClaimPendingSessionSuccessComponent from "./ClaimPendingSessionSuccess";
import { useAuth0 } from "@auth0/auth0-react";
import { API_URL, createHeaders } from "utils/api";

const ClaimPendingSessionSuccess = ({
  closeModal,
  session_id,
}: {
  closeModal: () => void;
  session_id: string;
}) => {
  const [is_loading, setIsLoading] = useState<boolean>(true);
  const [pending_session, setPendingSessionData] = useState<{
    mentor: {
      avatar_url: string;
      name: string;
    };
    session_details: {
      duration: number;
      slot: number;
    };
  }>({
    mentor: {
      avatar_url: "",
      name: "",
    },
    session_details: {
      duration: 0,
      slot: 0,
    },
  });
  const { getAccessTokenSilently } = useAuth0();

  const fetchPendingSession = async (pending_session_id: string) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(
      `${API_URL}/one_page_checkout/pending_session/${pending_session_id}`,
      {
        method: "GET",
        mode: "cors",
        headers: createHeaders(token),
      }
    );

    if (!response.ok) {
      throw Error("Failed to fetch pending session.");
    }

    const { session, mentor } = await response.json();

    return {
      session,
      mentor,
    };
  };

  useEffect(() => {
    if (pending_session) {
      fetchPendingSession(session_id).then(({ session, mentor }) => {
        setPendingSessionData({
          session_details: session,
          mentor,
        });
        setIsLoading(false);
      });
    }
  }, [session_id]);

  return (
    <ClaimPendingSessionSuccessComponent
      is_loading={is_loading}
      mentor={pending_session.mentor}
      session_details={pending_session.session_details}
      closeModal={closeModal}
    />
  );
};

export default ClaimPendingSessionSuccess;
