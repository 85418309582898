import { differenceInCalendarDays } from "date-fns";

import { OKRQueryResult } from "entities/OKR";
import { ObjectiveValidation } from "components/Molecules/ModifyObjective/ModifyObjective";
import { createDueDateTitle } from "components/Molecules/ModifyObjective/ModifyObjective.helpers";

// Take a response and format such that it can be consumed by Formik
export const formatObjectiveForEditView = (
  objective: OKRQueryResult
): ObjectiveValidation => {
  const days_until_due = differenceInCalendarDays(
    new Date(objective.due_date),
    new Date()
  );
  return {
    title: objective.title,
    due_date: {
      title: createDueDateTitle(
        days_until_due,
        new Date(objective.due_date || new Date().toISOString())
      ),
      value: objective.due_date,
    },
    subject: {
      title: objective.subject,
      value: objective.subject,
    },
    status: objective.status,
    key_results:
      (objective.key_results &&
        objective.key_results.map((key_result) => ({
          _id: key_result._id,
          title: key_result.title,
          completedAt: key_result.completedAt,
          createdAt: key_result.createdAt,
        }))) ||
      [],
    attachments: objective?.attachments || [],
  };
};
