import { useState } from "react";
import { Alert } from "antd";
import InfoContainer from "components/Molecules/InfoContainer";
import ProfileHighlight from "components/Molecules/ProfileHighlight";
import VerticalList from "components/Organisms/VerticalList";
import SkillsPicker from "components/Organisms/SkillsPicker";
import { Trophy, Floppy } from "components/Icons";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import useMyProfile from "hooks/useMyProfile";
import { ModalNames, useModal } from "components/Organisms/modals/ModalProvider";
import { isMentor, Skill, Highlight, Content } from "entities/Mentor";


const Experience = ({ id }: { id: string }) => {
  const { is_loading, update } = useUpdateMentor();
  const { profile } = useMyProfile();
  const [exceedLimit, setExceedLimit] = useState(false);
  const { openModal } = useModal();


  if (!isMentor(profile)) {
    throw Error("Only Mentors can update their experience")
  }

  const deleteHighlight = (highlight_title: string) => {
    let remaining_highlights = (profile?.highlights || []) as Highlight[];
    remaining_highlights = remaining_highlights.filter(
      (h: Highlight) => h.title != highlight_title
    );
    update({ highlights: remaining_highlights });
  };

  const updateHighlightsOrder = (highlights: Highlight[]) => {
    update({ highlights });
  };

  const deleteContent = (content_title: string) => {
    if (!is_loading) {
      let remaining_content = (profile?.content || []) as Content[];
      remaining_content = remaining_content.filter(
        (c: Content) => c.title !== content_title
      );
      update({ content: remaining_content });
    }
  };

  const updateContentOrder = (content: Content[]) => update({ content });

  const removeSkill = (skill: Skill) => {
    setExceedLimit(false);
    const skills = profile.skills.filter((s: Skill) => s.value !== skill.value);
    update({ skills });
  };

  const addSkill = () => openModal({ name: ModalNames.ADD_SKILL })

  const addContent = () =>
    openModal({ name: ModalNames.ADD_CONTENT })

  const addHighlight = () => openModal({ name: ModalNames.ADD_HIGHLIGHT })

  const editContent = (content: Content) => openModal({
    name: ModalNames.EDIT_CONTENT,
    data: content
  })

  const editHighlight = (highlight: Highlight) => openModal({
    name: ModalNames.EDIT_HIGHLIGHT,
    data: highlight
  })

  return (
    <div className="section" id={id}>
      <div className="section-content">
        <div className="section-header">
          <h3>Experience</h3>
        </div>
        <div className="section-header">
          <h4>Skill</h4>
        </div>
        <SkillsPicker
          removeSkill={removeSkill}
          lauchAddSkill={addSkill}
          options={profile.skills || []}
          limit={5}
          exceedsLimit={() => setExceedLimit(true)}
        />
        {exceedLimit && (
          <Alert type="error" message="There is a max limit of five skills." />
        )}

        <hr className="border-black" />
        {profile.highlights && profile.highlights.length > 0 ? (
          <>
            <div className="section-header">
              <h4>Highlights</h4>
              <button className="btn-small" onClick={addHighlight}>
                Add Highlight
              </button>
            </div>
            <VerticalList
              defaultItems={profile.highlights.map((h) => ({
                id: h.title,
                value: h,
                content: (
                  <ProfileHighlight
                    onSubmit={editHighlight}
                    onDelete={deleteHighlight}
                    data={h}
                  />
                ),
              }))}
              onUpdate={updateHighlightsOrder}
            />
          </>
        ) : (
          <InfoContainer
            Icon={Trophy}
            label="Highlights"
            helperText="Add career highlights to let clients know what makes you, you."
            buttonText="Add First Highlight"
            onClick={addHighlight}
          />
        )}

        <hr className="border-black" />
        {profile.content && profile.content.length > 0 ? (
          <>
            <div className="section-header">
              <h4>Publications</h4>
              <button className="btn-small" onClick={addContent}>
                Add Publication
              </button>
            </div>
            <VerticalList
              defaultItems={profile.content.map((h) => ({
                id: h.title as string,
                value: h,
                content: (
                  <ProfileHighlight
                    onSubmit={editContent}
                    onDelete={deleteContent}
                    data={h as Highlight}
                  />
                ),
              }))}
              onUpdate={updateContentOrder}
            />
          </>
        ) : (
          <InfoContainer
            Icon={Floppy}
            label="Publications"
            helperText="Journal article, blog post, youtube video, or killer Tweet. Add a link here to showcase it on your mentor profile."
            buttonText="Add First Publication"
            onClick={addContent}
          />
        )}
      </div>
    </div>
  );
};

export default Experience;
