import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  getMentorBookingAvailabilty,
} from "../../../../../store/dux/booking/booking.thunks";
import { useAuth0 } from "@auth0/auth0-react";
import useMentor from "hooks/useMentor";
import useMyProfile from "hooks/useMyProfile";
import { useAppSelector } from "hooks/redux";
import useObjectiveService from "../../Objectives/service/useObjectivesService";
import { PROFILE_TYPE } from "entities/Profile";
import BookSessionComponent from "./BookSession.component";
import useSwitchProfile from "hooks/user/useChangeProfile";

enum ERROR_MESSAGES {
  SLOT = "Please select a valid Session Time",
  DURATION = "Please select a valid Session Length",
  OKR = "Please select, or create a new OKR",
  DESCRIPTION = "Please add infomation about your problem or desired outcome.",
  TITLE = "Please add a Session title",
  COST = "You don't have enough credits remaining for this session.",
}

const BookSession = () => {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { slug: mentor_slug } = useParams<Record<string, string | undefined>>();
  const { switchTo } = useSwitchProfile();

  if (!mentor_slug) {
    throw Error("Mentor slug is required!");
  }

  const { mentor } = useMentor({ slug: mentor_slug });

  const booking = useAppSelector((state) => state.booking);

  const { profile } = useMyProfile();

  // the the profile is not mentee then switch to mentee
  if (!!profile && profile.type !== PROFILE_TYPE.MENTEE) {
    switchTo(PROFILE_TYPE.MENTEE);
  }

  const {
    duration,
    date: selected_iso_date,
    availability_is_loading,
    session_request_is_submitting,
  } = booking;

  // If the user can from checkout set the profile type to mentee
  useEffect(() => {
    if (!!profile && profile.type !== PROFILE_TYPE.MENTEE) {
      switchTo(PROFILE_TYPE.MENTEE);
    }
  }, []);

  useEffect(() => {
    if (mentor_slug) {
      getAccessTokenSilently().then((token) =>
        dispatch(getMentorBookingAvailabilty(token, mentor_slug, duration))
      );
    }
  }, [duration, mentor_slug]);

  const objective_service = useObjectiveService();

  return (
    <BookSessionComponent
      mentor={mentor}
      mentor_slug={mentor_slug}
      selected_iso_date={selected_iso_date}
      request_submitting={session_request_is_submitting}
      availability_is_loading={availability_is_loading}
      objective_service={objective_service}
    />
  );
};

export default BookSession;
