import { BlueChevron, InfoIcon } from "../../Icons";

import styles from "./PerformanceLineItem.module.css";

interface LineItemProps {
  title: string;
  value: string | number;
  help?: string;
  onClick?: () => void;
}

const LineItem: React.FC<LineItemProps> = ({ title, value, help, onClick }) => (
  <li data-testid="LineItem" className={styles.lineItem}>
    <div className={styles.lineItemTitle}>
      <p>{title}</p>
      {help && (
        <div className={styles.helpContainer}>
          <img src={InfoIcon} alt={`${title} help toolip`} />
          <p className={styles.toolTip}>{help}</p>
        </div>
      )}
    </div>
    <div className={styles.stat}>
      <p>{value}</p>
      {onClick && (
        <img
          src={BlueChevron}
          className={styles.lineItemIcon}
          onClick={onClick}
          alt="More Details"
        />
      )}
    </div>
  </li>
);

export default LineItem;
