import { useContext, useEffect } from "react";
import CreditPackCheckoutFragment from "./CreditPackCheckoutFragment";
import {
  CreditPackContext,
  CreditPackEvent,
  CreditPackState,
  OFFER_TITLE,
  OFFER_TRANSITION_EVENT,
} from "./schema";
import {
  getPaymentMethod,
  getStripePortalLink,
  getDaysUntilSubscriptionRenewal,
  getOffers,
  purchaseCreditPack,
  calculateCustomRate,
} from "./helpers";
import { useActor } from "@xstate/react";
import { useInterpret } from "@xstate-ninja/react";
import credit_pack_machine from "./CreditPack.machine";
import { useAlert } from "@blaumaus/react-alert";
import { Sender } from "xstate";
import { BUSINESS_EVENT } from "services/Business/Business.schema";
import { useAuth0 } from "@auth0/auth0-react";
import { GlobalServiceContext } from "services/GlobalServiceProvider";

const CreditPackCheckout = ({ id, toggleCreditWindow }: { id: string, toggleCreditWindow: () => void }) => {
  const alert = useAlert();
  const { getAccessTokenSilently } = useAuth0();
  const { business_service } = useContext(GlobalServiceContext);
  const [_, sendToBusinessService] = useActor(business_service);

  const errorFetchingOffers = async () =>
    alert.error("Error fetching offers. Please try again later.");

  const credit_pack_service = useInterpret(credit_pack_machine, {
    services: {
      getOffers: () => (callback) => {
        getAccessTokenSilently().then((token) =>
          getOffers(token).then((offers) => {
            callback({
              type: "GOT_OFFERS",
              offers: offers.offers,
              default_rate: offers.default_rate,
            });
          })
        );
      },
      getStripePortalLink: () =>
        getAccessTokenSilently().then(getStripePortalLink),
      getPaymentMethod: () => getAccessTokenSilently().then(getPaymentMethod),
      getDaysUntilSubscriptionRenewal: () =>
        getAccessTokenSilently().then(getDaysUntilSubscriptionRenewal),
      checkoutOfferOne:
        (context: CreditPackContext) => (callback: Sender<CreditPackEvent>) => {
          const offer = context.offers[0]; // offer 1
          const quantity = offer.quantity_of_credits;
          const rate = offer.cost_per_credit;
          getAccessTokenSilently().then((token) =>
            purchaseCreditPack({
              token,
              quantity,
              rate,
            }).then(() => {
              callback({
                type: "CHECKOUT_SUCCESS",
              });
            })
          );
        },
      checkoutOfferTwo:
        (context: CreditPackContext) => (callback: Sender<CreditPackEvent>) => {
          const offer = context.offers[1]; // offer 2
          const quantity = offer.quantity_of_credits;
          const rate = offer.cost_per_credit;
          getAccessTokenSilently().then((token) =>
            purchaseCreditPack({
              token,
              quantity,
              rate,
            }).then(() => {
              callback({
                type: "CHECKOUT_SUCCESS",
              });
            })
          );
        },
      checkoutOfferThree:
        (context: CreditPackContext) => (callback: Sender<CreditPackEvent>) => {
          const offer = context.offers[2]; // offer 3
          const quantity = offer.quantity_of_credits;
          const rate = offer.cost_per_credit;
          getAccessTokenSilently().then((token) =>
            purchaseCreditPack({
              token,
              quantity,
              rate,
            }).then(() => {
              callback({
                type: "CHECKOUT_SUCCESS",
              });
            })
          );
        },
      checkoutCustomOffer:
        (context: CreditPackContext) => (callback: Sender<CreditPackEvent>) => {
          const quantity = context.custom_quantity;
          const rate = calculateCustomRate(
            context.custom_quantity,
            context.offers,
            context.default_rate
          );
          getAccessTokenSilently().then((token) =>
            purchaseCreditPack({
              token,
              quantity,
              rate,
            }).then(() => {
              callback({
                type: "CHECKOUT_SUCCESS",
              });
            })
          );
        },
    },
    actions: {
      errorFetchingOffers,
      refetchBusiness: () =>
        setTimeout(
          () =>
            sendToBusinessService({
              type: BUSINESS_EVENT.GET_BUSINESS,
            }),
          6000
        ),
      redirectToStripePaymentUpdate: (_, event) => {
        if ("data" in event) {
          const w = window.open(event.data, "_blank");
          if (w) {
            w.focus();
          }
        }
      },
      errorFetchingStripePortalLink: () => {
        alert.error(
          "Error fetching Stripe portal link. Please contact support."
        );
      },
    },
    devTools: true,
  });

  const [state, send] = useActor(credit_pack_service);

  // CHANGE TO USE GLOABAL PROFILE....

  const globalServices = useContext(GlobalServiceContext);
  const [profile_state] = useActor(globalServices.profile_service);

  useEffect(() => {
    // This should be sufficent, if the profile type changes the component will need to re-render
    if (!profile_state.matches("mentee.owner")) {
      send({ type: "SET_PURCHASE_NOT_ALLOWED" });
    }
  }, [profile_state]);

  const custom_offer_open = !!state.matches<any>(
    "offers_ready.offer_custom_tab.open"
  );

  const toggleCustomOfferTab = () => send({ type: "TOGGLE_CUSTOM_OFFER_TAB" });

  const isSelectedOffer = (offer_id: OFFER_TITLE): boolean =>
    !!state.matches<any>(`offers_ready.credit_offers.${offer_id}`);

  const selectOffer = (offer_event: OFFER_TRANSITION_EVENT) =>
    send({
      type: offer_event,
    });

  const checkout = () => send({ type: "CHECKOUT" });

  const setCustomQuantity = (quantity: number) =>
    send({ type: "UPDATE_CUSTOM_QUANTITY", custom_quantity: quantity });

  const getTopLevelState = (
    state: string | Record<string, unknown>
  ): CreditPackState =>
    (typeof state === "string"
      ? state
      : Object.keys(state)[0]) as CreditPackState;

  const updateBillingDetails = () => send({ type: "UPDATE_PAYMENT_METHOD" });

  const UI_STATE = getTopLevelState(state.value);

  return (
    <CreditPackCheckoutFragment
      id={id}
      context={state.context}
      ui_state={UI_STATE}
      custom_offer_open={custom_offer_open}
      toggleCustomOfferTab={toggleCustomOfferTab}
      isSelectedOffer={isSelectedOffer}
      selectOffer={selectOffer}
      setCustomQuantity={setCustomQuantity}
      updateBillingDetails={updateBillingDetails}
      checkout={checkout}
      toggleCreditWindow={toggleCreditWindow}
    />
  );
};

export default CreditPackCheckout;
