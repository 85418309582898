import { useActor } from "@xstate/react";
import { useContext, useEffect, useState } from "react";
import { Business } from "entities/Business";
import { BUSINESS_EVENT } from "services/Business/Business.schema";
import { GlobalServiceContext } from "services/GlobalServiceProvider";

const useUpdateBusiness = () => {
    const { business_service } = useContext(GlobalServiceContext);
    const [state, send] = useActor(business_service);
    const [is_loading, setIsLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(state.matches("update"));
    }, [state]);

    const update = (update: Partial<Business>) => {
        send({
            type: BUSINESS_EVENT.UPDATE_BUSINESS,
            data: {
                update
            }
        })
    }

    return { is_loading, update };
};

export default useUpdateBusiness;
