import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const SignupLoginRedirect = ({
  beforeSignup,
  signup,
}: {
  beforeSignup?: () => void;
  signup: boolean;
}) => {
  const { loginWithRedirect } = useAuth0();

  useEffect(() => {
    // Pre login/signup redirect Logic
    beforeSignup && beforeSignup();
    loginWithRedirect({
      authorizationParams: {
        screen_hint: signup ? "signup" : "login",
      },
      appState: {
        returnTo: signup ? `/loading_profile` : `/`,
      },
    });
  }, []);

  return null;
};

export default SignupLoginRedirect;
