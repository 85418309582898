import { useState } from "react";
import { useDispatch } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";

import {
  LinkShare,
  TwitterShare,
  LinkedInShare,
  FacebookShare,
  EmailShare,
} from "components/Icons";

import useMyProfile from "hooks/useMyProfile";

import styles from "../MentorDashboardMarketingHub.module.css";

interface IProps {
  affiliate_token: string | null;
}

const MarketingHub: React.FC<IProps> = ({ affiliate_token }) => {
  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);

  const { profile } = useMyProfile();

  const copy = `I’m excited to be offering my advisory services on @MentorPass! Join to get personalized advice from me and 100s of other world-class startup advisors.`;
  const title =
    "I’m excited to be offering my advisory services on @MentorPass!";
  const body = copy;

  const alert = (message: string) =>
    dispatch({
      type: "SET_ALERT",
      payload: {
        message,
        type: "success",
      },
    });

  const copyToClipboard = () => {
    setCopied(true);
    alert("Copied to clipboard");
  };

  const createURIEncodedProfileLink = () => {
    return encodeURIComponent(createProfileLink());
  };

  const createProfileLink = () => {
    if (affiliate_token) {
      return `https://mentorpass.co/me/${profile.slug}?via=${affiliate_token}`;
    } else {
      return `https://mentorpass.co/me/${profile.slug}`;
    }
  };

  const shareToLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/shareArticle?mini=true&url=${createURIEncodedProfileLink()}&title=&summary=${copy}&source=`,
      "_blank"
    );
    alert("Share on LinkedIn");
  };

  const shareToTwitter = () => {
    window.open(
      `https://twitter.com/intent/tweet?url=${createURIEncodedProfileLink()}&text=${copy}`,
      "_blank"
    );
    alert("Share on Twitter");
  };

  const shareToFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=${createURIEncodedProfileLink()}`,
      "_blank"
    );
    alert("Share on Facebook");
  };

  const shareToEmail = () => {
    window.open(
      `mailto:info@example.com?&subject=${title}&body=${body}`,
      "_blank"
    );
    alert("Send in Email");
  };

  return (
    <div className={styles.subContainer}>
      <div>
        <h3>Marketing Hub</h3>
        <p>Links designed to drive traffic to your profile.</p>
        <div className={styles.marketing_container}>
          <img
            className={styles.openGraph}
            src={
              profile?.profileImage?.url ||
              "https://storage.googleapis.com/marketing-assets.mentorpass.co/meta/mentorpass_opengraph.png"
            }
          />
          <div className={styles.shareContainer}>
            <p>Share via:</p>
            <button className={styles.socialButton} onClick={shareToLinkedIn}>
              <img src={LinkedInShare} className={styles.shareIcon} />{" "}
              <p>LinkedIn</p>
            </button>
            <button className={styles.socialButton} onClick={shareToTwitter}>
              <img src={TwitterShare} className={styles.shareIcon} />
              <p>Twitter</p>
            </button>
            <button className={styles.socialButton} onClick={shareToFacebook}>
              <img src={FacebookShare} className={styles.shareIcon} />
              <p>Facebook</p>
            </button>
            <button className={styles.socialButton} onClick={shareToEmail}>
              <img src={EmailShare} className={styles.shareIcon} />
              <p>Email</p>
            </button>
            <CopyToClipboard
              text={createProfileLink()}
              onCopy={copyToClipboard}
            >
              <button className={styles.socialButton}>
                <>
                  <img src={LinkShare} className={styles.shareIcon} />
                  <p>{copied ? "Copied" : "Link"}</p>
                </>
              </button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MarketingHub;
