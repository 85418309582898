import React from "react";
import styles from "../TeamManagment.module.css";
import useTeamStatistics from "hooks/team/useTeamSatistics";
import { Grid, ThreeDots } from "react-loader-spinner";
import useStripeCustomerPortal from "hooks/mentee/useStripeCustomerPortal";

const TeamStatistics = () => {
  const {
    stats,
    is_loading: loading_stats,
    error: stats_error,
  } = useTeamStatistics();

  const {
    goToCustomerPortal,
    is_loading: stripe_portal_loading,
    error: stripe_portal_error,
  } = useStripeCustomerPortal();

  return (
    <div className={styles.team_stats}>
      <div className={styles.team_stats_header}>
        <h4>Team Plan</h4>
        <button disabled={stripe_portal_loading} onClick={goToCustomerPortal}>
          {stripe_portal_loading ? (
            <ThreeDots wrapperStyle={{ margin: "auto", display: "block" }} color="#fff" height={22} width={32} />
          ) : (
            "Upgrade"
          )}
        </button>
      </div>
      <div className={styles.stats_container}>
        {!loading_stats && stats.length > 0 ? (
          stats.map((stat, index) => (
            <div key={`stat-${index}`} className={styles.stat}>
              <h2>{stat.value}</h2>
              <p>{stat.title}</p>
            </div>
          ))
        ) : (
          <Grid wrapperStyle={{ margin: "auto", display: "block" }} color="#5440F0" height={42} width={42} />
        )}
      </div>
    </div>
  );
};

export default TeamStatistics;
