/* eslint-disable react/prop-types */
import moment from "moment-timezone";
import Styles from "./UpdateTimezoneAlert.module.css";

interface UpdateTimezoneAlert {
  data: {
    browser_tz: string;
    profile_tz: string;
  };
  update: (timezone: string) => void;
  closeModal: () => void;
}

const UpdateTimezoneAlertComponent = ({
  data,
  update,
  closeModal,
}: UpdateTimezoneAlert) => {
  const setTimezone = () => {
    const timezone = moment.tz.guess();
    update(timezone);
    closeModal();
  };

  return (
    <div className={`modal-content ${Styles.updateTimezoneAlert}`}>
      <h3 style={{ color: "#000", textAlign: "center" }}>
        Timezone change detected
      </h3>
      <p style={{ color: "#66686A", textAlign: "center", fontSize: "14px" }}>
        Your browser says your timezone is {data.browser_tz}, but your profile
        says {data.profile_tz}.
      </p>
      <button
        style={{ width: "180px", margin: "0px auto" }}
        onClick={setTimezone}
      >
        Update timezone
      </button>
      <div onClick={closeModal}>
        <p style={{ textAlign: "center" }}>Keep current timezone</p>
      </div>
    </div>
  );
};

export default UpdateTimezoneAlertComponent;
