import React from "react";
import UserNotificationComponent from "./UserNotification";
import useMenteeOnboardingRequired from "hooks/mentee/useMenteeOnboardingRequired";
import { useNavigate } from "react-router-dom";
import objectHash from "object-hash";

const UserNotification = () => {
  const navigate = useNavigate();
  const { onboarding_required, dismissOnboardingRequired } =
    useMenteeOnboardingRequired();

  return (
    <UserNotificationComponent
      mentee_onboarding={{
        display: onboarding_required,
        onClick: () => {
          dismissOnboardingRequired();
          navigate("/onboarding/mentee");
        },
        dismiss: dismissOnboardingRequired,
      }}
    />
  );
};

export default UserNotification;
