import React, { useContext } from "react";
import { BlueStar, MentorpassIcon } from "../../../../../components/Icons";
import AvatarPlaceholder from "../../../../../images/avatar-placeholder.gif";
import TruncatedText from "../../../../../components/Molecules/TruncatedText/TruncatedText.component";
import style from "../Profile.module.css";
import { relativeNav } from "../../../../../utils/nav";
import { useDispatch } from "react-redux";
import { clickBookSession } from "../../../../../store/dux/ui/ui.actions";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import { MentorQueryResult } from "entities/Mentor";
interface SessionCounterProps {
  session_count: number;
}

const SessionCounter = ({ session_count }: SessionCounterProps) => {
  return (
    <div className="session-counter">
      <img src={BlueStar} alt="blue-star" />
      <p>
        <span>{session_count}</span> credits
      </p>
    </div>
  );
};
interface Pill {
  title: string;
}

const Pill = ({ title }: Pill) => (
  <div className="pill">
    <p>{title}</p>
  </div>
);

interface Skill {
  title?: string;
  value?: string;
}

const SkillsBar = ({ skills }: { skills: Skill[] }) => {
  // Truncate skills to 8 for naughty mentors that somehow got more..
  const skills_list = skills.length > 8 ? skills.slice(0, 8) : skills;

  return (
    <div className="skills-pill-container">
      {skills_list.map(
        (s, i) => s.title && <Pill key={`testId-${i}`} title={s.title} />
      )}
    </div>
  );
};

interface ProfileIntroductionProps {
  is_favourite: boolean;
  loading_favourites: boolean;
  toggleFavourite: () => void;
  profile: MentorQueryResult;
  bookSession: () => void;
  preview: boolean;
}

const ProfileIntroduction = ({
  profile,
  preview,
  toggleFavourite,
  is_favourite,
  loading_favourites,
}: ProfileIntroductionProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const bookSessionButton = () => {
    if (preview) {
      navigate("/account");
    } else {
      dispatch(clickBookSession({ mentor_profile: profile._id }));
      relativeNav(navigate, "book-session");
    }
  };

  const rate =
    profile.type === "mentor" && profile.hourlyRate.tokens
      ? profile.availabilityDetails.halfHour
        ? profile.hourlyRate.tokens / 2
        : profile.hourlyRate.tokens
      : false;

  const rating = profile?.reviews
    ? profile.reviews.reduce((acc, cur) => (acc += (cur.rating / 2)), 0.0) /
      profile.reviews.length
    : null;

  return (
    <div className={style.profileIntroduction}>
      <div className="profile-header">
        <div className="profile-avatar">
          <img
            className="profile-avatar-img"
            src={
              profile.profileImage?.url
                ? profile.profileImage.url
                : AvatarPlaceholder
            }
            alt="profile-avatar"
          />
          <div className="avatar-mp-cube">
            <img src={MentorpassIcon} alt="mentor-pass" />
          </div>
        </div>
      </div>
      <div className="profile-header-content">
        <button
          className={`${style.follow_btn} btn-small btn-dark`}
          id="follow_btn"
          onClick={toggleFavourite}
        >
          {loading_favourites ? (
            <ThreeDots
              // style={{ marginRight: "2px" }}
              color="#FFF"
              height={18}
              width={24}
            />
          ) : is_favourite ? (
            "Unfollow"
          ) : (
            "Follow"
          )}
        </button>
        <div className={style.introTop}>
          <div className="profile-intro-title">
            <h2 style={{ color: "#fff" }}>
              {profile.given_name} {profile.family_name}
            </h2>
            <p className={style.headline}>{profile.headline}</p>
            <div className={style.ratings}>
              {!!profile?.reviews && !!rating && (
                <>
                  <img src={BlueStar} />
                  <p>{Number(rating).toFixed(1)}</p>
                  <span className={style.noRating}>
                    ({profile.reviews.length})
                  </span>
                  <span className={style.dot} />
                </>
              )}
              <p>{rate} credits</p>
            </div>
          </div>
          {/* {rate && <SessionCounter sessions={rate} />} */}
        </div>

        <div className={style.bio}>
          <TruncatedText
            text={profile.bio}
          />
        </div>
        {/* {profile?.charity && (
          <div className={style.charity}>
            <CharityBanner 
              mentor_name={profile.given_name}
              charity_name={profile.charity.name}
              percentage={profile.charity.percentage}
              link={profile.charity.link}
            />
          </div>
        )} */}
        <div className={style.stickToBottom}>
          <SkillsBar skills={profile?.skills || []} />
          <div className="profile-action-btns">
            <button
              id="book_session_btn"
              className={style.button}
              onClick={bookSessionButton}
            >
              {preview ? "Edit Your Profile" : "Book Session"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileIntroduction;
