import { TickSign } from "components/Icons";
import style from "../../SessionReview.module.css";
import AvatarPlaceholder from "images/avatar-placeholder.gif";
import NotificationPin from "components/Atoms/NotificationPin";
import Avatar from "components/Atoms/Avatar";

interface EndReviewProps {
  id: string;
  data: {
    to: {
      profileImage: {
        url?: string | null;
      };
    };
  };
  updateStep: () => void;
}

const EndReview = ({ id, data, updateStep }: EndReviewProps) => {
  return (
    <div data-testid={id} className={style.endReview}>
      <div className={style.avatarSection}>
        <NotificationPin
          borderWidth={0}
          size={40}
          isActive
          top={-20}
          right={-20}
          icon={TickSign}
        >
          <Avatar
            src={data.to.profileImage.url || AvatarPlaceholder}
            rounded="var(--corner-radius)"
            name="profile"
            size={160}
          />
        </NotificationPin>
      </div>
      <h2 className={style.reviewCompleteHeading}>Review Complete!</h2>
      <div className={style.thanks}>
        <p>
          Thank you for taking the time to help make MentorPass a better
          community for entrepreneurs to learn and grow.
        </p>
      </div>
      <button onClick={updateStep}>Book another session</button>
    </div>
  );
};

export default EndReview;
