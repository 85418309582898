import styles from "./RoundTick.module.css";

interface RoundTickProps {
  id: string;
  checked: boolean;
  onClick?: () => void;
  className?: string;
}

const RoundTick = ({ id, checked, onClick, className }: RoundTickProps) => {
  const classes = !!className ? `${styles.round} ${className}` : styles.round;

  const handleClick = () => onClick && onClick();

  return (
    <div className={classes}>
      <input
        type="checkbox"
        checked={checked}
        id={`checkbox_${id}`}
        data-testid={`checkbox_${id}`}
        onChange={handleClick}
      />
      <label htmlFor={`checkbox_${id}`}></label>
    </div>
  );
};

export default RoundTick;
