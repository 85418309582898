import React, { useState, useEffect } from "react";
import moment, { Moment } from "moment";
import styles from "./SessionSelector.module.css";
import { CalendarIcon } from "../../../../../../components/Icons";

interface SesssionSelectorProps {
  ideal_proposed_time?: string;
  selected_date?: string;
  proposed_datetimes: string[];
  duration: number;
  availability: Moment[];
  onClickTimeslot: (date: string) => void;
  selectAllTimeSlot: () => void;
  clearAllTimeSlot: () => void;
}

export const SessionSelectorComponent = ({
  ideal_proposed_time,
  selected_date,
  proposed_datetimes,
  duration,
  availability,
  onClickTimeslot,
  selectAllTimeSlot,
  clearAllTimeSlot,
}: SesssionSelectorProps) => {
  const handleSelect = (datetime: string) => onClickTimeslot(datetime);
  const date = moment(selected_date);
  return (
    <div className={styles.container}>
      <h4>When do you want to talk?</h4>
      {selected_date && (
        <h5 style={{ margin: "0px 0px 8px 0px" }}>{date.format("LL")}</h5>
      )}
      <div className={styles.availability}>
        {availability.length > 0 ? (
          availability.map((slot, index) => {
            const slot_iso = slot.toISOString();
            const is_proposed = proposed_datetimes.includes(slot_iso);
            const is_ideal = slot_iso === ideal_proposed_time;
            const class_names = [
              styles.slot,
              is_proposed ? styles.slotActive : "",
              is_ideal ? styles.slotPreferred : "",
            ]
              .filter(Boolean)
              .join(" ");

            return (
              <div
                key={`av-${index}`}
                className={class_names}
                onClick={() => handleSelect(slot_iso)}
              >
                {slot.format("LT")} -{" "}
                {slot.clone().add("minutes", duration).format("LT")}
              </div>
            );
          })
        ) : (
          <div className={styles.noSlotsContainer}>
            <img className={styles.calIcon} src={CalendarIcon} />
            <p
              style={{
                margin: "auto",
                textAlign: "center",
                marginTop: "5px",
              }}
            >
              Select an available date to view available times.
            </p>
          </div>
        )}
      </div>
      <div className={styles.legend}>
        <div className={styles.legend_container}>
          <div style={{ background: "#5440F0" }} />
          <p>Preferred</p>
        </div>
        <div className={styles.legend_container}>
          <div
            style={{
              border: "1px solid #5440F0",
              background: "#1A1C1D",
            }}
          />
          <p>Alternate</p>
        </div>
      </div>
      <span className={styles.select_clear_container}>
        <p className={styles.clear_select_button} onClick={selectAllTimeSlot}>
          select all
        </p>{" "}
        |{" "}
        <p className={styles.clear_select_button} onClick={clearAllTimeSlot}>
          clear all
        </p>
      </span>
    </div>
  );
};

export default SessionSelectorComponent;
