import * as yup from "yup";
import AdditionalDetailsForm from "./AdditionalDetailsForm.component";

export const AdditionalDetailsValidationSchema = yup.object().shape({
  name: yup.string().required("A name is required."),
  terms_conditions_accepted: yup
    .bool()
    .oneOf([true], "You must agree to the terms & conditions."),
});

export type AdditionalDetailsFormSubmission =
  yup.InferType<typeof AdditionalDetailsValidationSchema>;
