import React, { useContext } from "react";
import HeaderComponent from "./Header.component";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { useActor } from "@xstate/react";
import { MenuContext } from "../SideMenu/MobileMenu.machine";

type HeaderProps = {
  children: React.ReactNode;
};

const Header = ({ children }: HeaderProps): JSX.Element => {
  const globalServices = useContext(GlobalServiceContext);
  const [state] = useActor(globalServices.profile_service);
  const menuContext = useContext(MenuContext);
  const [_, sendToMenu] = useActor(menuContext.menu_service);

  const toggleMobileMenu = () => sendToMenu("TOGGLE");

  return (
    <HeaderComponent
      isMentee={state.matches("mentee")}
      toggleMobileMenu={toggleMobileMenu}
    >
      {children}
    </HeaderComponent>
  );
};

export default Header;
