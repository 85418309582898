/**
Returns a formatted string with count and the appropriate singular or plural label.
@param {number} count - The count value to be formatted.
@param {string} singular - The singular label for the count.
@param {string} plural - The plural label for the count.
@returns {string} Formatted count string with the appropriate label.
@example
// returns "1 item"
pluralizer(1, "item", "items");
*/
export const pluralizer = (count: number, singular: string, plural: string) => {
  return Math.abs(count) !== 1 ? `${count} ${plural}` : `${count} ${singular}`;
}

/**
 * Returns either a error string or unedfined, useful for Formik error fields.
 * @param error 
 * @param touched 
 * @returns 
 */
export const errorOrUndefined = (error?: string, touched?: boolean) => (error && touched) ? error : undefined;
