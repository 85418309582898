import React from "react";
import styles from "../BookSession.module.css";
import AvatarPlaceholder from "images/avatar-placeholder.gif";
import { Globe } from "components/Icons";
import { Grid } from "react-loader-spinner";
import { ProfileQueryResult } from "entities/Profile";

interface MentorContentProps {
  mentor?: ProfileQueryResult;
  is_loading: boolean;
}

const MentorContent = ({ mentor, is_loading }: MentorContentProps) => {
  return (
    <div className={styles.mentorContent}>
      {!mentor || is_loading ? (
        <Grid wrapperStyle={{ margin: "auto", display: "block" }}
          color="#5440F0"
          height={32}
          width={32}
        />
      ) : (
        <>
          <img
            className={styles.mentorAvatar}
            src={
              (mentor.profileImage && mentor.profileImage.url) ||
              AvatarPlaceholder
            }
            alt={`${mentor.given_name}'s profile`}
          />
          <div>
            <h4 style={{ color: "#fff" }}>
              {mentor.given_name} {mentor.family_name}
            </h4>
            <p
              style={{
                fontSize: "12px",
                marginTop: "4px",
                color: "#fff",
              }}
            >
              {mentor.headline}
            </p>
            <div className={styles.timezoneContainer}>
              <img
                src={Globe}
                className={styles.timezoneGlobe}
                alt="Globe timzone indicator"
              />
              <p className={styles.timezoneText}>
                Located in {mentor.timezone}
              </p>
            </div>
            <p className={styles.mentorBio}>{mentor.bio}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default MentorContent;
