import { createMachine, assign } from "xstate";
import { fetchBusiness } from "./Business.data";
import {
  BusinessContext,
  BusinessEvent,
  BusinessMachineStates,
  UPDATE_BUSINESS_EVENT,
} from "./Business.schema";

const business_machine = createMachine<
  BusinessContext,
  BusinessEvent,
  BusinessMachineStates
>({
  /** @xstate-layout N4IgpgJg5mDOIC5QCMCusCWA7OsB02GALgMQCqAygKIBKA+jVQIIAiAmgNoAMAuoqAAcA9piIYhWfiAAeiAIwAmAGwAaEAE9ECuQGY8CgBwBOE6bNGlAX0tq0mHLHwAbIQEMI2KHgBmYIgGMAC08SCAkwAiwANyEAawi7bFw8F3dPHz8gzwRsGP9XMQluHmKpYVFxSSQZRABWAHYAFjwuHUVatU0EBVqFPANdRp0m8wtrW3QkxxS3DywvKNcnDAgCkLCcSJj4vESHZ1n0xeXVsXmc6KF8wqxi0ury4kqpWQRaowM8OS4hxoVtRr1AyNOSdRAgvoGBRcLi1AxNRpKLgGYHjEB7ZKpOYLJYrNbzULhLZxBKTfYzNLzPDHPFnKAXPJrIq8DhyPgPERPCQveS1Lh4EwKHTGeE6QH-HRghBA2pfWpKAzDRVGf5GRpojHTVACU5gQmbXIk3Zk5La3UMq5M24s3hlTk3HkIHRGPQquTwxqjNVS3r1PD1JRquRGORDJRyYMak3TABOYHc6hIAHEqAAVOgAIUoAEkAHJUCgUe6Ce3PaqvUN9bTwjoaLRKPRAr1mKw2dHR-BxhPkAAKLCYqaomZz+cLxZAjwd5fB0P0-0RCMaXA+OlUdYQDaMc6USnqEaUCkRCij9mScYAjqgMHHYHQBKgY0FXLA9Sn01mKHmC0XbRyKtzpw3BQpRDWVAwPPkYSgrg5GsNssCECA4CkTV4D-LkqlAV4AFo1y6AMWmdZsTFbCZT2mQgiDtf9MJqBA-ilbRG2MYixjbVCKWxaiMMdHRkTlCCpTkBs8FqIjiNI9tyIOSkvF8AJgnmbipywxAdHlASekY2o5EI1i2LIqYZOxalcVOTxlLLVTpUDfp6lrLoel01p9MkjizQKMBLIA6y9yUfRARMRoDFqHT6gUIwpR3XTVwaLhtD5HQnJPIy8C7CAuhLGjHQY9ckT9EELBrFLyQvK8bzvB8nxfbzaNeJd+XeYYHMQIx3jwcMIuEncet3ODLCAA */
  id: "business",
  initial: "init",
  context: {
    business: undefined,
    pending_update: undefined,
  },
  states: {
    init: {
      on: {
        USER_READY: "loading",
      },
    },
    loading: {
      initial: "fetching",
      states: {
        fetching: {
          invoke: {
            src: "fetchBusiness",
            onDone: {
              target: "validating",
              actions: assign((_, event) => ({
                business: event.data.business,
              })),
            },
          },
        },
        validating: {
          invoke: {
            src: "validateSubscription",
            onDone: [
              {
                cond: (_, event) => event.data.is_valid,
                target: "#business.ready",
              },
              {
                target: "#business.requires_purchase",
              },
            ],
          },
        },
      },
    },
    update: {
      invoke: {
        src: "updateBusiness",
        onDone: {
          target: "ready",
          actions: assign((_, event) => ({
            business: event.data.business,
          })),
        },
      },
    },
    ready: {
      on: {
        GET_BUSINESS: "loading",
        UPDATE_BUSINESS: {
          target: "update",
          actions: assign((_, event: UPDATE_BUSINESS_EVENT) => ({
            pending_update: event.data.update,
          })),
        },
      },
    },
    requires_purchase: {
      on: {
        GET_BUSINESS: "loading",
      },
    },
  },
});

export default business_machine;
