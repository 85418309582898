import z from "zod";

export const AppMetadataSchema = z.object({
    id: z.string(),
    affiliate_id: z.string().optional(),
    stripe_connected: z.string().optional(),
    profile_created: z.boolean().optional(),
});

export const UserMetadataSchema = z.object({
    timezone: z.string().nullable(),
})

export type UserMetadata = z.infer<typeof UserMetadataSchema>;

export type AppMetadata = z.infer<typeof AppMetadataSchema>;