/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import moment, { Moment } from "moment-timezone";
import mayLoad from "../../../../../../hoc/mayLoad";

import {
  getFirstDayOfMonthGivenOffset,
  generateCalendarDays,
  numberOfCalendarDaysInMonth,
  range,
} from "./ReschedullingCalendar.helpers";

import { BlueChevron, Globe } from "components/Icons";
import CalendarWeek from "components/Molecules/CalendarWeek";
import { CalednarDayInfo } from "components/Atoms/CalendarDay";

import styles from "./ReschedullingCalendar.module.css";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks/redux";

interface ReschedullingCalendarProps {
  availability: any[];
  user_timezone: string;
  selected_iso_date: string;
  is_loading: boolean;
  onSelectCalendarDay: (date: string) => void;
}

const ReschedullingCalendar: React.FC<ReschedullingCalendarProps> = ({
  availability,
  user_timezone,
  selected_iso_date,
  is_loading,
  onSelectCalendarDay,
}) => {
  const [month, setMonth] = useState<CalednarDayInfo[] | undefined>([]);
  const [calendar_month_offset, setMonthOffset] = useState(0);
  const [number_of_weeks_in_calendar_month, setNumberOfWeeksInCalendarMonth] =
    useState(5);
  const [startDate, setStartDate] = useState(moment().local().startOf("month"));

  const raw_sessions = availability || [];

  const init = (calendar_month_offset: number) => {
    // map all timeslots to the users local timezone.
    const session_slots: Moment[] = raw_sessions.map((slot: any) =>
      moment(slot).tz(user_timezone)
    );

    const today: Moment = moment().tz(user_timezone);

    // get the first of the month
    const first_day_of_month: Moment = getFirstDayOfMonthGivenOffset(
      calendar_month_offset,
      today
    );

    setStartDate(first_day_of_month);

    // create the calendar day info data.
    const month: CalednarDayInfo[] = generateCalendarDays(
      today,
      first_day_of_month,
      session_slots
    );

    // calculate number of weeks in calander month
    const first_of_the_month_iso: number = first_day_of_month.isoWeekday() % 7;
    const number_of_days_in_month = first_day_of_month.daysInMonth();

    const number_of_days_in_calendar_month = numberOfCalendarDaysInMonth(
      first_of_the_month_iso,
      number_of_days_in_month
    );
    setNumberOfWeeksInCalendarMonth(number_of_days_in_calendar_month / 7);
    setMonth(month);
  };

  const incrementMonth = (no: number) => setMonthOffset(no);

  useEffect(() => {
    if (!is_loading) {
      init(calendar_month_offset);
    }
  }, [availability, calendar_month_offset, is_loading]);

  const titles = ["Su", "M", "Tu", "W", "Th", "F", "Sa"];

  const tz = moment.tz(user_timezone).format("Z z");

  const navigate = useNavigate();

  const mentee_request_slots = useAppSelector(
    (state) => state.booking.mentee_request_slots
  );

  const handleSelectCalendarDay = (date: string) => {
    onSelectCalendarDay(date);
    setTimeout(() => {
      const time_selection_input =
        window.document.getElementById("booking-time");
      time_selection_input?.scrollIntoView({ behavior: "smooth" });
    }, 350);
  };

  return (
    <div className={styles.sessionCalendarMonth}>
      <h4 style={{ marginBottom: "8px" }}>What day would you like to talk?</h4>
      <div className={styles.timezoneContainer}>
        <img src={Globe} />{" "}
        <p>
          Reschedulling call in {tz}{" "}
          <span
            className={styles.changeLink}
            onClick={() => navigate("/account")}
          >
            (change)
          </span>
        </p>
      </div>
      <div className={styles.sessionCalendarMonthNav}>
        <img
          src={BlueChevron}
          style={
            calendar_month_offset === 0
              ? {
                  visibility: "hidden",
                }
              : {
                  transform: "rotate(90deg)",
                  height: "18px",
                  width: "18px",
                  marginTop: "8px",
                  marginLeft: "12px",
                }
          }
          onClick={() => incrementMonth(calendar_month_offset - 1)}
          alt="Left Chevron Icon"
        />
        <h5 style={{ margin: "8px auto", textAlign: "center" }}>
          {startDate.format("MMMM YYYY")}
        </h5>
        <img
          src={BlueChevron}
          style={
            calendar_month_offset === 2
              ? {
                  visibility: "hidden",
                }
              : {
                  transform: "rotate(270deg)",
                  height: "18px",
                  width: "18px",
                  marginTop: "6px",
                  marginRight: "12px",
                }
          }
          onClick={() => incrementMonth(calendar_month_offset + 1)}
          alt="Right Chevron Icon"
        />
      </div>
      <div className={styles.sessionCalendarMonthHeader}>
        {titles.map((d) => (
          <h5 key={d} className={styles.dayTitle}>
            {d}
          </h5>
        ))}
      </div>
      <div>
        {range(number_of_weeks_in_calendar_month).map((w) => {
          const results = month
            ? month.filter((day, index) => Math.floor(index / 7) === w)
            : [];
          return (
            <CalendarWeek
              key={w}
              week={results}
              onSelect={handleSelectCalendarDay}
              selected_iso_date={selected_iso_date}
              mentee_request_slots={mentee_request_slots}
            />
          );
        })}
      </div>
    </div>
  );
};

export default mayLoad(
  ReschedullingCalendar,
  null,
  styles.sessionCalendarMonth,
  {
    paddingTop: "150px",
  }
);
