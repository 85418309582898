import { Widget } from "@typeform/embed-react";
import styles from "./FreemiumOnboarding.module.css";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";
import MPLogo from "../../../../images/mp-logo.svg";
import useMyProfile from "hooks/useMyProfile";

const POST_ONBOARDING_REDIRECT = "/?modal=one_page_onboarding_complete";

const FreemiumOnboardingScreen = () => {
  const ONBOARDING_TYPEFORM_ID = process.env.REACT_APP_ONBOARDING_TYPEFORM_ID as string;

  const navigate = useNavigate();
  const [completed, setComplete] = useState<boolean>(false);
  const { profile } = useMyProfile();
  
  return (
    <div className={styles.container}>
      {completed ? (
        <div className={styles.completed}>
          <img src={MPLogo} />
          <h2>Thank you for completing your onboarding</h2>
          <ul>
            <li>You will recieve futher instructions by email shortly.</li>
            <li>Your mentor has 72hrs to accept your request. </li>
            <li>
              {" "}
              If for any reason your mentor cannot not accept a session at your
              requested date and time, you are entitled to a refund, or may
              request to reschedule.{" "}
            </li>
          </ul>
          <div>
            <button
              onClick={() => {
                navigate(POST_ONBOARDING_REDIRECT);
              }}
            >
              Browse mentors
            </button>
          </div>
        </div>
      ) : (
        <Widget
          id={ONBOARDING_TYPEFORM_ID}
          hidden={{
            user_id: profile.user_id,
            name: `${profile.given_name} ${profile.family_name}`,
          }}
          className={styles.container}
          onSubmit={() => {
            setComplete(true);
          }}
          onClose={() => {
            navigate(POST_ONBOARDING_REDIRECT);
          }}
        />
      )}
    </div>
  );
};

export default FreemiumOnboardingScreen;
