import { memo, useCallback } from "react";
import styles from "./KeyResult.module.css";
import TrashConfirmationButton from "../../Atoms/TrashConfirmationButton";

export interface KeyResultProps {
  _id: string;
  title: string;
  completed: boolean;
  deleteKeyResult: (key_result_id: string) => void;
  toggleKeyResult: (key_result_id: string) => void;
}

const KeyResult = ({
  _id,
  title,
  completed,
  deleteKeyResult,
  toggleKeyResult,
}: KeyResultProps) => {
  const removeKeyResult = useCallback(() => deleteKeyResult(_id), [_id]);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div
          data-testid="key-result"
          className={styles.round_checkbox}
          onClick={() => toggleKeyResult(_id)}
        >
          <input
            data-testid="toggle"
            id={_id}
            type="checkbox"
            name={title}
            defaultChecked={completed}
          />
          <label htmlFor={_id}></label>
        </div>
        <p>{title}</p>
      </div>
      <TrashConfirmationButton confirm={removeKeyResult} />
    </div>
  );
};

export default memo(KeyResult);
