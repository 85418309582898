import { Moment } from "moment-timezone";
import { CalednarDayInfo } from "../../../../../../components/Atoms/CalendarDay/CalendarDay.component";

export const range = (size: number): ReadonlyArray<number> => {
	return [...Array(size).keys()];
};

export const getFirstDayOfMonthGivenOffset = (
	number_of_months_offset: number,
	today: Moment
): Moment => {
	const first_of_the_month = today.clone().startOf("month");
	if (number_of_months_offset) {
		return first_of_the_month.add(number_of_months_offset, "month");
	}
	return first_of_the_month;
};

export const createCalendarDayInfo = (
	day_increment: number,
	today: Moment,
	first_day_of_month: Moment,
	first_of_the_month_iso: number,
	number_of_days_in_month: number,
	session_slots: Moment[]
): CalednarDayInfo => {
	if (
		first_of_the_month_iso <= day_increment &&
		day_increment < number_of_days_in_month + first_of_the_month_iso
	) {
		const date = first_day_of_month
			.clone()
			.add(day_increment - first_of_the_month_iso, "day");

		const month = date.month();
		const date_day = date.date();

		const availability = session_slots.filter(
			(slot) => slot.month() === month && slot.date() === date_day
		);
		const day = {
			title: String(date_day),
			iso_date: date.toISOString(true),
			disabled:
				// if date isn't this calendar month
				// (date.year() === fom.year() && date.month() !== fom.month()) ||
				// or this year but in the past
				today.year() === first_day_of_month.year() && // same year
				today.month() === month && // month is this month
				date_day < today.date(), // date is before fom.
			has_availability: availability.length > 0,
		};
		return day;
	}
	return {
		title: "",
		iso_date: "",
		has_availability: false,
		disabled: true,
	};
};

export const numberOfCalendarDaysInMonth = (
	first_of_the_month_iso: number,
	number_of_days_in_month: number
): number => {
	return (
		number_of_days_in_month +
		first_of_the_month_iso +
		(7 - ((number_of_days_in_month + first_of_the_month_iso) % 7))
	);
};

export const generateCalendarDays = (
	today: Moment,
	first_of_the_month: Moment,
	session_slots: Moment[]
): CalednarDayInfo[] => {
	const first_of_the_month_iso: number = first_of_the_month.isoWeekday() % 7;
	const number_of_days_in_month = first_of_the_month.daysInMonth();

	const number_of_days_in_calendar_month = numberOfCalendarDaysInMonth(
		first_of_the_month_iso,
		number_of_days_in_month
	);

	const month: CalednarDayInfo[] = range(number_of_days_in_calendar_month).map(
		(day_increment) =>
			createCalendarDayInfo(
				day_increment,
				today,
				first_of_the_month,
				first_of_the_month_iso,
				number_of_days_in_month,
				session_slots
			)
	);

	return month;
};
