import { useActor, useSelector } from "@xstate/react";
import { useContext, useEffect, useMemo, useState } from "react";
import { MenteeQueryResult } from "entities/Mentee";
import { MentorQueryResult } from "entities/Mentor";
import { GlobalServiceContext } from "services/GlobalServiceProvider";
import { PROFILE_TYPE } from "entities/Profile";

export const isMentor = (
  profile: MentorQueryResult | MenteeQueryResult
): profile is MentorQueryResult => {
  return profile.type === "mentor";
};

export const isMentee = (
  profile: MentorQueryResult | MenteeQueryResult
): profile is MenteeQueryResult => {
  return profile.type === "mentee";
};

const useMyProfile = () => {
  const { profile_service } = useContext(GlobalServiceContext);

  const [state] = useActor(profile_service);

  const profile = useSelector(profile_service, (state) => {
    if (state.matches("mentor")) {
      return state.context.mentor;
    }
    if (state.matches("mentee")) {
      return state.context.mentee;
    }
    if (state.matches("admin")) {
      return state.context.admin;
    }
  }) as MentorQueryResult | MenteeQueryResult;

  const type = useMemo(() => {
    if (state.matches("mentor")) {
      return PROFILE_TYPE.MENTOR;
    }
    if (state.matches("mentee")) {
      return PROFILE_TYPE.MENTEE;
    }
    if (state.matches("admin")) {
      return PROFILE_TYPE.ADMIN;
    }
    return undefined;
  }, [state]);

  return { profile, type };
};

export default useMyProfile;
