import React, { useState } from "react";
import AddHighlightModal from "./AddHighlight";
import useMyProfile from "hooks/useMyProfile";
import useUpdateMentor from "hooks/mentor/account/useUpdateMentor";
import { Highlight } from "entities/Mentor";
import isMentorProfile from "utils/isMentorProfile";

const AddHighlight = ({ closeModal }: { closeModal: () => void }) => {

    const { profile } = useMyProfile();
    const { update } = useUpdateMentor();

    const [highlight, setHightlight] = useState<Highlight>({
        title: "",
        description: "",
    });

    const saveHighlight = async () => {
        let highlights = [highlight];

        if (!isMentorProfile(profile)) {
            throw Error("Only a mentor can add highlights to their profile.")
        }
        
        if (profile?.highlights) {
            highlights = highlights.concat(profile.highlights);
        }
        await update({ highlights });
        closeModal();
    };


    return <AddHighlightModal highlight={highlight} setHighlight={setHightlight} saveHighlight={saveHighlight} closeModal={closeModal} />
}

export default AddHighlight;