import { createContext } from "react";
import { InterpreterFrom } from "xstate";
import useProfileService from "./Profile/useProfileService";
import useBusinessService from "./Business/useBusinessService";
import business_machine from "./Business/Business.machine";
import profile_machine from "./Profile/Profile.machine";
import Loading from "components/Molecules/Loading";

export const GlobalServiceContext = createContext({
  profile_service: {} as InterpreterFrom<typeof profile_machine>,
  business_service: {} as InterpreterFrom<typeof business_machine>,
});

export const ProfileStateProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { profile_service, loading } = useProfileService();
  const { business_service } = useBusinessService(profile_service);

  return (
    <GlobalServiceContext.Provider
      value={{ profile_service, business_service }}
    >
      {loading !== "ready" ? <Loading type={loading} /> : children}
    </GlobalServiceContext.Provider>
  );
};
