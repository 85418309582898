import React, { useEffect, useRef, useState } from "react";
import styles from "./AddKeyResult.module.css";
import ObjectId from "bson-objectid";
import { KeyResult } from "entities/OKR";

interface AddKeyResultProps {
	addKeyResult: (key_result: KeyResult) => void;
	autoFocus?: boolean;
	style?: React.CSSProperties;
	disable_submit_on_enter?: boolean;
}

const AddKeyResultInput = ({ style, autoFocus = false, addKeyResult, disable_submit_on_enter = false }: AddKeyResultProps) => {
	const [key_result_title, setKeyResult] = useState<string>("");
	const input_ref = useRef<HTMLInputElement>(null);
	const submit = () => {
		if(key_result_title === "") return;
		const id = new ObjectId();
		addKeyResult({
			_id: id.toHexString(),
			title: key_result_title,
			completedAt: null,
			createdAt: new Date().toISOString(),
		});
		setKeyResult("");
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter" && !disable_submit_on_enter) {
			submit();
		}
	};

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setKeyResult(event.target.value);
	};

	useEffect(() => {
		if (input_ref.current && autoFocus) {
			input_ref.current.focus();
		}
	});

	return (
		<div className={styles.container} style={style}>
			<input
				ref={input_ref}
				data-testid="add-key-result-input"
				className={styles.input}
				value={key_result_title}
				onChange={handleChange}
				onKeyDown={handleKeyDown}
				placeholder="Type a key result to add..."
			/>
			<span
				data-testid="add-key-result-button"
				className={styles.button_plus}
				onClick={submit}
			/>
		</div>
	);
};

export default AddKeyResultInput;
