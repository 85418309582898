import { useState } from "react";
import { API_URL, createHeaders } from "../../utils/api";
import { useAuth0 } from "@auth0/auth0-react";
import { UserMetadata, UserMetadataSchema } from "entities/User";

interface UseUpdateUserMetadata {
    onSuccess?: () => void;
}

const useUpdateUserMetadata = ({ onSuccess }: UseUpdateUserMetadata) => {
    const { getAccessTokenSilently } = useAuth0();
    const [error, setError] = useState<string | null>(null);
    const [is_loading, setIsLoading] = useState<boolean>(true);

    const update = async (user_metadata: Partial<UserMetadata>) => {
        try {
            const metadata = UserMetadataSchema.parse(user_metadata);
            const token = await getAccessTokenSilently();
            setIsLoading(true);
            const response = await fetch(`${API_URL}/user/metadata`, {
                method: "PATCH",
                headers: createHeaders(token),
                body: JSON.stringify(metadata),
            });
            if (!response.ok) {
                throw Error("Failed to update user metadata.");
            }
            onSuccess && onSuccess();
        } catch (error) {
            console.log(error);
            setError(String(error));
        } finally {
            setIsLoading(false);
        }
    }

    return { error, is_loading, update };
};

export default useUpdateUserMetadata;
