import { createHeaders, API_URL } from "../../utils/api";
// @ts-ignore
import _refiner from "refiner-js";
import LogRocket from "logrocket";
import { usePostHog } from "posthog-js/react";

interface IdentityProperties {
  user_id: string;
  given_name: string;
  family_name: string;
  email: string;
  intercom_hash: string;
}

const identify = ({
  user_id,
  given_name,
  family_name,
  email,
  intercom_hash,
}: IdentityProperties) => {
  try {
    if (process.env.REACT_APP_ENVIRONMENT === "PRODUCTION") {
      // Identify with Segment
      window.Intercom("boot", {
        app_id: "yq8cmpr9", // APP ID,
        email,
        user_id,
        user_hash: intercom_hash,
      });
      // Itentify for Google Analytics
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        userId: user_id,
      });
      // Identify in LogRocket
      LogRocket.identify(user_id, {
        name: `${given_name} ${family_name}`,
        email,
      });
      // Identify user with refiner Survey Tool
      _refiner("setProject", "51225b10-d28f-11eb-8bc7-79ec63d2cfa1");
      _refiner("identifyUser", {
        id: user_id,
        email,
        name: `${given_name} ${family_name}`,
      });
    }
  } catch (error) {
    console.error("There was an error initalizing analytics tools");
    console.error(error);
  }
};

export const identifyUser = async (token: string) => {
  try {
    const response = await fetch(`${API_URL}/user/identify`, {
      method: "GET",
      mode: "cors",
      headers: createHeaders(token),
    });
    const identity = await response.json();
    identify(identity);
  } catch (error) {
    console.error("There was an error identifying the user");
    console.error(error);
  }
};
