import { useState } from "react";
import styles from "./Agenda.module.css";
import Button from "components/Atoms/Button";
import DropdownSelector from "components/Atoms/DropdownSelector";

interface AgendaProps {
  description: string;
  mentee_objective_options: {
    _id: string;
    title: string;
  }[];
  updateDescription: (description: string) => void;
  updateObjective: (objective_id: string) => void;
  openCreateObjective: () => void;
}

const Agenda = ({
  mentee_objective_options,
  description,
  updateDescription,
  updateObjective,
  openCreateObjective,
}: AgendaProps) => {
  const [objective, setObjective] = useState<
    { value: string; title: string } | undefined
  >();

  const handleSelectObjective = (option: { value: string; title: string }) => {
    console.log("selected objective: ", option);
    setObjective(option);
    updateObjective(option.value);
  };

  const options = [
    ...mentee_objective_options.map((objective) => ({
      value: objective._id,
      title: objective.title,
    })),
    {
      value: "Create Objective",
      title: (
        <Button
          variant="dark"
          onClick={openCreateObjective}
          style={{ width: "100%" }}
        >
          Create Objective
        </Button>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <label className={`${styles.label} black`}>
        What is the agenda for this call?
      </label>
      <textarea
        style={{ marginTop: "4px", padding: "12px" }}
        className="black"
        value={description}
        onChange={(e) => updateDescription(String(e.target.value))}
        id="description"
        data-testid="description"
        placeholder={`Please list the talking points and questions that you'd like to discuss.`}
        autoFocus
        rows={5}
      />
      <DropdownSelector
        id="choose-objective"
        label="Choose an objective"
        placeholder="Select from your objectives"
        options={options}
        onSelect={handleSelectObjective}
        value={objective}
        dark={true}
        outline={false}
      />
    </div>
  );
};

export default Agenda;
