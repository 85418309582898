// FIXME: This component is a molecule that is dependent on another molecules, which is not permissible for Atomic Design Pattern
import moment from "moment";

import { PencilIcon } from "../../Icons";
import KeyResultItem from "../KeyResult";
import AddKeyResultInput from "../../Atoms/AddKeyResultInput";
import ProgressBar from "../../Atoms/ProgressBar";
import { KeyResult } from "../../../entities/OKR";

import styles from "./ViewObjective.module.css";

interface ViewObjectiveProps {
  _id: string;
  title: string;
  status: string;
  due_date: string;
  key_results: KeyResult[];
  sessions: {}[];
  activity: {}[];
  sidebar?: boolean;
  addKeyResult: (key_result: KeyResult) => void;
  deleteKeyResult: (key_result_id: string) => void;
  toggleKeyResult: (key_result_id: string) => void;
  editObjective?: (objective_id: string) => void;
}

const ViewObjectiveComponent = ({
  _id,
  title,
  key_results,
  due_date,
  addKeyResult,
  deleteKeyResult,
  toggleKeyResult,
  editObjective,
  sidebar = false,
}: ViewObjectiveProps) => {
  const progress =
    Math.round(
      (100 / key_results.length) *
        key_results.filter((key_result) => !!key_result.completedAt).length
    ) || 0;

  const formatted_due_date = moment(due_date).format("MMM Do, YY");

  return (
    <div className={styles.container}>
      <div className={styles.title_container}>
        <h2 className={styles.title}>{title}</h2>
        {!sidebar && (
          <div
            className={styles.edit_button}
            onClick={() => editObjective && editObjective(_id)}
          >
            <img src={PencilIcon} />
          </div>
        )}
      </div>
      <div className={styles.progress_container}>
        <div>
          <ProgressBar progress={progress} />
          <div className={styles.progress_stats}>
            <span>{progress}% completed</span>
            <span>Due {formatted_due_date}</span>
          </div>
        </div>
      </div>
      <div className={styles.key_result}>
        {key_results.map((key_result) => (
          <KeyResultItem
            key={key_result._id}
            {...key_result}
            completed={!!key_result.completedAt}
            deleteKeyResult={deleteKeyResult}
            toggleKeyResult={toggleKeyResult}
          />
        ))}
        <AddKeyResultInput addKeyResult={addKeyResult} />
      </div>
    </div>
  );
};

export default ViewObjectiveComponent;
