interface AuthClaims {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  "https://api.mentorpass.co/timezone": string;
  "https://api.mentorpass.co/app_metadata/profile_created": boolean;
  "https://api.mentorpass.co/user_id": string;
  "https://api.mentorpass.co/connection_type": "google" | "Username-Password-Authentication";
}

interface PublicAuthClaims {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  name: string;
  nickname: string;
  picture: string;
  sub: string;
  updated_at: string;
  profile_created: boolean;
  user_id: string;
  timezone?: string;
  connection_type?: "google" | "Username-Password-Authentication";
}

const processAuthClaims = (user?: Partial<AuthClaims>): PublicAuthClaims => {
  if (!user) {
    throw Error("No user");
  }
  return {
    email: String(user.email),
    email_verified: !!user.email_verified,
    family_name: String(user.family_name),
    given_name: String(user.given_name),
    name: String(user.name),
    nickname: String(user.nickname),
    picture: String(user.picture),
    sub: String(user.sub),
    updated_at: String(user.updated_at),
    profile_created:
      !!user["https://api.mentorpass.co/app_metadata/profile_created"],
    user_id: user["https://api.mentorpass.co/user_id"] as string,
    timezone: user["https://api.mentorpass.co/timezone"],
    connection_type: user["https://api.mentorpass.co/connection_type"],
  };
};

export default processAuthClaims;
