import React, { useEffect, useState } from "react";
import moment from "moment";
import styles from "../BookSession.module.css";
import { CalendarIcon } from "../../../../../components/Icons";
import fallback_image from "../../../../../images/avatar-placeholder.gif";
import { useNavigate } from "react-router-dom";

interface BookSessionSuccessProps {
	mentor_profile_image_url?: string | null;
	mentor_given_name: string;
	session_duration: string;
	session_iso_datetime: string;
	is_loading: boolean;
}

const BookSessionSuccess = ({
	mentor_profile_image_url,
	mentor_given_name,
	session_duration,
	session_iso_datetime,
}: BookSessionSuccessProps) => {
	const navigate = useNavigate();

	return (
		<>
			<div className={styles.successAvatar}>
				<div className={styles.successAvatarIcon}>
					<img src={CalendarIcon} alt="Calendar Icon" />
				</div>
				<img
					className={styles.successAvatarImage}
					src={mentor_profile_image_url || fallback_image}
					alt={`${mentor_given_name} Avatar`}
				/>
			</div>
			<h2>Session Requested!</h2>
			<p>
				Your request for a {session_duration} minute session on{" "}
				{moment(session_iso_datetime).format("dddd, MMMM Do")} has been sent
				to {mentor_given_name}. They have two business days to respond to the
				request, otherwise your credits will be refunded.
			</p>
			<button onClick={() => navigate("/")}>Back to Dashboard</button>
		</>
	);
};

export default BookSessionSuccess;
