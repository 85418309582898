import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import {
  SessionQueryResult,
  SessionQueryResultsWithDetails,
} from "entities/Session";
import { API_URL, createHeaders } from "utils/api";

interface IUseMenteeSession {
  id: string;
}

const getSession = async (
  token: string,
  id: string,
): Promise<SessionQueryResultsWithDetails> => {
  const response = await fetch(`${API_URL}/session/${id}`, {
    method: "GET",
    mode: "cors",
    headers: createHeaders(token),
  });

  if (!response.ok) {
    throw new Error("Error fetching session");
  }

  return response.json();
};

const useSession = ({ id }: IUseMenteeSession) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data, isLoading, refetch, error } = useQuery({
    queryKey: ["session", id],
    queryFn: async () =>
      getAccessTokenSilently().then((token) => getSession(token, id)),
  });

  return {
    session: data,
    error,
    is_loading: isLoading,
    refetch,
  };
};

export default useSession;
