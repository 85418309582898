import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { ThreeDots } from "react-loader-spinner";
import { API_URL, createHeaders } from "utils/api";
import { loadStripe } from "@stripe/stripe-js";
import { LIVE_KEY, TEST_KEY } from "./constants";

const MentorPlusCheckout = () => {
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        const fetchMentorPlussCheckoutSession = async (token: string) => {
            const response = await fetch(`${API_URL}/stripe/create_mentor_plus_checkout_session`, {
                method: "GET",
                mode: "cors",
                headers: createHeaders(token),
            });

            if (response.status !== 200) {
                throw Error("Failed to connect to stripe, please contatct support");
            }
            const session = await response.json();

            const stripe = await loadStripe(
                process.env.REACT_APP_ENVIRONMENT === "PRODUCTION" ? LIVE_KEY : TEST_KEY
            );
            if (!stripe) throw Error("Failed to load stripe");
            const error = await stripe.redirectToCheckout({
                sessionId: session.id,
            });

            if (error) {
                console.error(error);
                throw Error(String(error));
            }
        };

        getAccessTokenSilently().then(fetchMentorPlussCheckoutSession);

    }, [])

    return <div>
        <ThreeDots
            data-testid="three-dots-loading"
            height="24"
            width="32"
            radius="8"
            color="#fff"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            visible={true}
        />
    </div>;
};

export default MentorPlusCheckout;
