import React, { useState } from "react";
import CompanyComponent from "./Company.component";
import useBusiness from "hooks/mentee/useBusiness";
import useUpdateBusiness from "hooks/mentee/useUpdateBusiness";

interface CompanyProfileProps {
  id: string;
}
const CompanyProfile = ({ id }: CompanyProfileProps) => {

  const { business, is_loading: business_is_loading } = useBusiness();
  const { update, is_loading: is_updating } = useUpdateBusiness();

  return (
    <CompanyComponent
      id={id}
      business={business}
      is_loading={business_is_loading}
      is_updating={is_updating}
      submit={update}
    />
  );
};

export default CompanyProfile;
