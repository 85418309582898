import classNames from "classnames/bind";

import styles from "./SuccessCheckmark.module.css";

const cx = classNames.bind(styles);

interface ISuccessCheckmarkProps {
  dark: boolean;
}

const SuccessCheckmark: React.FC<ISuccessCheckmarkProps> = ({ dark }) => {
  const checkmarkStyles = cx({
    successCheckmark: true,
    dark,
  });

  return (
    <div data-testid="SuccessCheckmark" className={checkmarkStyles}>
      <div className={styles.checkIcon}>
        <span className={cx([styles.iconLine, styles.lineTip])} />
        <span className={cx([styles.iconLine, styles.lineLong])} />
        <div className={styles.iconCircle} />
        <div className={styles.iconFix} />
      </div>
    </div>
  );
};

export default SuccessCheckmark;
