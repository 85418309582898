import { useState } from "react";
import style from "./MenteeSessions.module.css";
import TabHeader from "../../Molecules/SessionsTabHeader/TabHeader.component";
import { Grid } from "react-loader-spinner";
import SessionCard from "./MenteeSessionCard";
import useMenteeSessions, {
  MenteeSessions,
  SesssionViewStatus,
} from "../../../hooks/mentee/useMenteeSessions";
import MenteeNoSessionResults from "../../Molecules/MenteeNoSessionResults";
import { MenteeSessionQueryResult } from "../../../entities/MenteeSession";
import MenteeSessionRequestCard from "./MenteeSessionRequestCard";

type SessionDisplayData = Record<
  SesssionViewStatus,
  {
    sessions: MenteeSessionQueryResult[];
    no_results: JSX.Element;
  }
>;

const createSessionData = (sessions: MenteeSessions): SessionDisplayData => ({
  [SesssionViewStatus.UPCOMING]: {
    sessions: sessions[SesssionViewStatus.UPCOMING],
    no_results: (
      <MenteeNoSessionResults title="You have no upcoming sessions." />
    ),
  },
  [SesssionViewStatus.REQUEST]: {
    sessions: sessions[SesssionViewStatus.REQUEST],
    no_results: (
      <MenteeNoSessionResults title="You have no pending session requests." />
    ),
  },
  [SesssionViewStatus.PAST]: {
    sessions: sessions[SesssionViewStatus.PAST],
    no_results: <MenteeNoSessionResults title="You have no past sessions." />,
  },
});

const makeSessionsOptions = (
  sessions: MenteeSessions
): {
  id: SesssionViewStatus;
  title: string;
  alert_quantity: number;
}[] => {
  return [
    {
      id: SesssionViewStatus.REQUEST,
      title: "Request",
      alert_quantity: sessions[SesssionViewStatus.REQUEST].length,
    },
    {
      id: SesssionViewStatus.UPCOMING,
      title: "Upcoming",
      alert_quantity: sessions[SesssionViewStatus.UPCOMING].length,
    },
    {
      id: SesssionViewStatus.PAST,
      title: "Past",
      alert_quantity: sessions[SesssionViewStatus.PAST].length,
    },
  ];
};

const MenteeSessionsDashboard = () => {
  const [active_tab, setTab] = useState<SesssionViewStatus>(
    SesssionViewStatus.UPCOMING
  );

  const { sessions, is_loading } = useMenteeSessions();

  const view_session_data =
    (sessions && createSessionData(sessions)) || undefined;

  return (
    <div data-testid="MenteeSessions" className={style.mySessions}>
      <div>
        <TabHeader
          active_tab={active_tab}
          onTabChange={(value) => setTab(value as SesssionViewStatus)}
          options={makeSessionsOptions(sessions)}
        />
        <div className={style.sessions_list}>
          {is_loading ? (
            <Grid
              wrapperStyle={{
                margin: "auto",
                display: "block",
                marginTop: "12px",
              }}
              color="#5440F0"
              height={42}
              width={42}
              wrapperClass="center"
            />
          ) : (
            <div className={style.no_sessions}>
              {view_session_data[active_tab].sessions.length
                ? view_session_data[active_tab].sessions
                    .sort((a, b) => {
                      const a_time = a.slot || a.ideal_proposed_time;
                      const b_time = b.slot || b.ideal_proposed_time;
                      return (
                        new Date(b_time).getTime() - new Date(a_time).getTime()
                      );
                    })
                    .map((session) =>
                      active_tab === SesssionViewStatus.REQUEST ? (
                        <MenteeSessionRequestCard
                          key={session._id}
                          session={session}
                        />
                      ) : (
                        <SessionCard key={session._id} session={session} />
                      )
                    )
                : view_session_data[active_tab]["no_results"]}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MenteeSessionsDashboard;
