import useLogout from "hooks/useLogout";
import LogoutComponent from "./Logout";

const Logout = ({ closeModal }: { closeModal: () => void }) => {

    const logout = useLogout();

    return <LogoutComponent closeModal={closeModal} logout={logout} />
}

export default Logout;