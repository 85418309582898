import React, { useState } from "react";
import style from "../../SessionReview.module.css";
import Chip from "components/Atoms/Chip";
import {
  Bulb,
  SmileyStar,
  HeartCard,
  OutlinedEye,
  Flash,
  GivingSpirit,
} from "components/Icons";

const RATING_BLOCK = [
  {
    id: 1,
    isSelected: false,
    icon: Bulb,
    text: "Super Genius",
  },
  {
    id: 2,
    isSelected: false,
    icon: SmileyStar,
    text: "Inspirational",
  },
  {
    id: 3,
    isSelected: false,
    icon: HeartCard,
    text: "Great Listener",
  },
  {
    id: 4,
    isSelected: false,
    icon: OutlinedEye,
    text: "Insightful Questions",
  },
  {
    id: 5,
    isSelected: false,
    icon: Flash,
    text: "Specific Advice",
  },
  {
    id: 6,
    isSelected: false,
    icon: GivingSpirit,
    text: "Extra Generous",
  },
];

interface ThirdStepProps {
  id: string;
  data: {
    to: {
      given_name: string;
    };
  };
  updateBestQuality: (value: string) => void;
  nextStep: () => void;
}

const ThirdStep = ({
  id,
  data,
  updateBestQuality,
  nextStep,
}: ThirdStepProps) => {
  const [rating, setRating] = useState(RATING_BLOCK);

  const onSelect = (id: number) => {
    const updatedRating = RATING_BLOCK.map((rating) => {
      if (rating.id === id) {
        const updatedObj = { ...rating, isSelected: !rating.isSelected };
        return updatedObj;
      } else return rating;
    });
    setRating(updatedRating);
    updateBestQuality(RATING_BLOCK[id - 1].text);
  };

  return (
    <div data-testid={id} className={style.thirdStep}>
      <p className={style.label}>Question 2/4</p>
      <p className={style.question}>What did {data.to.given_name} do well?</p>
      <div className={style.blocks}>
        {rating.map((block) => (
          <Chip.Icon
            id={block.text}
            content={block}
            onSelect={() => onSelect(block.id)}
            size={"160px"}
          />
        ))}
      </div>
      <button
        id={`${id}-clearAndSkip`}
        className={style.skipBtn}
        onClick={nextStep}
      >
        Skip
      </button>
    </div>
  );
};

export default ThirdStep;
