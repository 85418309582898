import React, { useContext, useEffect } from "react";
import { useActor } from "@xstate/react";
import CreditAvatarComponent from "./CreditAvatar.component";
import { BUSINESS_EVENT } from "services/Business/Business.schema";

import { Business } from "entities/Business";
import { useAppSelector } from "hooks/redux";
import { GlobalServiceContext } from "services/GlobalServiceProvider";

const doesBalanceExist = (business?: Business): boolean =>
  typeof business?.balance === "number";

const CreditAvatar = () => {
  const { business_service } = useContext(GlobalServiceContext);
  const [state, send] = useActor(business_service);

  const is_mobile = useAppSelector(
    (state) => state.mobile.is_mobile
  ) as boolean;

  useEffect(() => {
    if (
      !state.matches("loading") &&
      !doesBalanceExist(state?.context?.business)
    ) {
      send(BUSINESS_EVENT.GET_BUSINESS);
    }
  }, [state.value]);

  return (
    <CreditAvatarComponent
      credit_balance={state.context.business?.balance}
      credits_loading={state.matches("loading")}
      is_mobile={is_mobile}
    />
  );
};

export default React.memo(CreditAvatar);
