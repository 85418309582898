import React from "react";
import classNames from "classnames/bind";

import styles from "./NotificationPin.module.css";

const cx = classNames.bind(styles);

interface NotificationPinProps {
  isActive?: boolean;
  borderWidth: number;
  top?: number;
  left?: number;
  right?: number;
  bottom?: number;
  children: React.ReactNode;
  className?: string;
  icon?: string;
  size?: number;
}

const NotificationPin: React.FC<NotificationPinProps> = ({
  isActive,
  children,
  borderWidth = 1,
  size = 8,
  className = "",
  top,
  left,
  right,
  bottom,
  icon,
}) => {
  const notificationClasses = cx({
    container: true,
    hidden: !isActive,
    [className]: !!className,
  });

  return (
    <div data-testid="notification_pin" className={notificationClasses}>
      <div
        data-testid="pin"
        className={styles.pin}
        style={{
          borderWidth,
          width: size + borderWidth,
          height: size + borderWidth,
          top,
          left,
          right,
          bottom,
        }}
      >
        {icon && <img src={icon} alt="icon" className={styles.icon} />}
      </div>
      {children}
    </div>
  );
};

export default NotificationPin;
