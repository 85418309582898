import useUpdateUserMetadata from "hooks/user/useUpdateUserMetadata";
import UpdateTimezoneAlertComponent from "./UpdateTimezoneAlert"

const UpdateTimezoneAlert = ({ data, closeModal }: {
    data: {
        browser_tz: string;
        profile_tz: string;
    };
    closeModal: () => void
}) => {

    const { update } = useUpdateUserMetadata({});

    const updateTimezone = (timezone: string) => update({ timezone })

    return <UpdateTimezoneAlertComponent data={data} update={updateTimezone} closeModal={closeModal} />

}

export default UpdateTimezoneAlert