import OnboardingStep from "../../Molecules/OboardingStep";
import { MentorpassIcon } from "../../Icons";

import styles from "./OnboardingProgress.module.css";

export interface Step {
  id: string;
  path: string;
  title: string;
  is_complete: boolean;
  is_current_step: boolean;
}

export interface OnboardingProgressProps {
  steps: Step[];
  navigate: (path: string) => void;
}

const OnboardingProgress = ({ steps, navigate }: OnboardingProgressProps) => {
  const createOnClickHandler = (path: string) => () => navigate(path);

  return (
    <div data-testid="onboarding_progress" className={styles.container}>
      <div className={styles.title_container}>
        <img src={MentorpassIcon} />
        <h4>Account step up</h4>
      </div>
      <div className={styles.steps}>
        {steps.map((step, index) => (
          <OnboardingStep
            key={index}
            {...step}
            onClick={createOnClickHandler(step.path)}
          />
        ))}
      </div>
    </div>
  );
};

export default OnboardingProgress;
