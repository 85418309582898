import React, { ChangeEvent, FocusEvent } from "react";
import styles from "./AltTextInput.module.css";

type TextInputType = "text" | "email" | "password" | "number" | "url" | "tel";

interface TextInputProps {
  id: string;
  name: string;
  label: string;
  type: TextInputType;
  value: string;
  error?: string;
  placeholder: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void;
}

const TextInput: React.FC<TextInputProps> = ({
  id,
  name,
  label,
  type,
  value,
  error,
  placeholder,
  handleChange,
  handleBlur,
}) => {
  return (
    <div data-testid='AltTextInput_container' className={styles.container}>
      <label className={error ? styles.error_label : ""}>{label}</label>
      <input
        id={id}
        data-hj-allow
        className={error ? styles.error_input : ""}
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={handleChange}
        onBlur={handleBlur}
        value={value}
      />
      {error && <p className={styles.error}>{error}</p>}
    </div>
  );
};

export default TextInput;
