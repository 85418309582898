import React from "react";
import Header from "../../../../../components/Organisms/Header/Header.component";
import { RightArrowThin, BackArrow } from "../../../../../components/Icons";
import style from "../Profile.module.css";
import { useNavigate } from "react-router-dom";

interface ProfileHeaderProps {
  name: string;
  preview: boolean;
}

const ProfileHeader = ({ name, preview }: ProfileHeaderProps) => {
  const navigate = useNavigate();

  const goBack = () => {
    console.log("Go back...");
    navigate(-1);
  };
  return (
    <Header
      isMentee={false} // Needs to be updated....
      toggleMobileMenu={() => {}}
      children={
        <div data-testid="Profile_header" className={style.profileHeader}>
          <div className={style.headerLeft}>
            <div className={style.ldh_desktop}>
              <div>
                <h3 className={style.title}>Browse Mentors</h3>
              </div>
              <div className={style.breadCrumbs}>
                <button className={style.buttonAsAnchor} onClick={goBack}>
                  {preview ? "Account" : "All Mentors"}
                </button>
                <img src={RightArrowThin} alt="right-arrow" />
                <button className={style.buttonAsAnchor}>{name}</button>
              </div>
            </div>
            <div className={style.ldh_mobile}>
              <button className={style.buttonAsAnchor} onClick={goBack}>
                <img src={BackArrow} alt="right-arrow" width="18px" />
              </button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default ProfileHeader;
